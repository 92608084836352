import React from 'react';

import MailTo from 'components/BasicElements/MailTo';
import LinkTo from 'components/BasicElements/LinkTo';


export default (
  <div className="dsa-terms-use">
    <h2>1. An overview of data protection</h2>
    <h3>General information</h3>
    <p>
      The following information will provide you with an easy to navigate overview of what will happen with your
      personal data when you visit this website. The term “personal data“ comprises all data that can be used to
      personally identify you. For detailed information about the subject matter of data protection, please consult our
      Data Protection Declaration, which we have included beneath this copy.
    </p>
    <h3>Data recording on this website</h3>
    <p>
      <strong>Who is the responsible party for the recording of data on this website (i.e. the “controller“)?</strong>
    </p>
    <p>
      The data on this website is processed by the operator of the website, whose contact information is available under
      section “Information Required by Law“ on this website.
    </p>
    <p>
      <strong>How do we record your data?</strong>
    </p>
    <p>
      We collect your data as a result of your sharing of your data with us. This may, for instance be information you
      enter into our contact form.
    </p>
    <p>
      Our IT systems automatically record other data when you visit our website. This data comprises primarily technical
      information (e.g. web browser, operating system or time the site was accessed). This information is recorded
      automatically when you access this website.
    </p>
    <p>
      <strong>What are the purposes we use your data for?</strong>
    </p>
    <p>
      A portion of the information is generated to guarantee the error free provision of the website. Other data may be
      used to analyse your user patterns.
    </p>
    <p>
      <strong>What rights do you have as far as your information is concerned?</strong>
    </p>
    <p>
      You have the right to receive information about the source, recipients and purposes of your archived personal data
      at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are
      rectified or eradicated. Please do not hesitate to contact us at any time under the address disclosed in section
      “Information Required by Law“ on this website if you have questions about this or any other data protection
      related issues. You also have the right to log a complaint with the competent supervising agency.
    </p>
    <p>
      Moreover, under certain circumstances, you have the right to demand the restriction of the processing of your
      personal data. For details, please consult the Data Protection Declaration under section “Right to Restriction of
      Data Processing.“
    </p>
    <h3>Analysis tools and tools provided by third parties</h3>
    <p>
      There is a possibility that your browsing patterns will be statistically analysed when your visit this website.
      Such analyses are performed primarily with cookies and with what we refer to as analysis programmes. As a rule,
      the analyses of your browsing patterns are conducted anonymously; i.e. the browsing patterns cannot be traced back
      to you.
    </p>
    <p>
      You have the option to object to such analyses or you can prevent their performance by not using certain tools.
      For detailed information about the tools and about your options to object, please consult our Data Protection
      Declaration below.
    </p>
    <h2>2. Hosting</h2>
    <h3>External Hosting</h3>
    <p>
      This website is hosted by an external service provider (host). Personal data collected on this website are stored
      on the servers of the host. These may include, but are not limited to, IP addresses, contact requests, metadata
      and communications, contract information, contact information, names, web page access, and other data generated
      through a web site.
    </p>
    <p>
      The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6
      para. 1 lit. b DSGVO) and in the interest of secure, fast and efficient provision of our online services by a
      professional provider (Art. 6 para. 1 lit. f DSGVO).
    </p>
    <p>
      Our host will only process your data to the extent necessary to fulfil its performance obligations and to follow
      our instructions with respect to such data.
    </p>
    <p><strong>Execution of a contract data processing agreement</strong></p>
    <p>
      In order to guarantee processing in compliance with data protection regulations, we have concluded an order
      processing contract with our host.
    </p>
    <h2>3. General information and mandatory information</h2>
    <h3>Data protection</h3>
    <p>
      The operators of this website and its pages take the protection of your personal data very seriously. Hence, we
      handle your personal data as confidential information and in compliance with the statutory data protection
      regulations and this Data Protection Declaration.
    </p>
    <p>
      Whenever you use this website, a variety of personal information will be collected. Personal data comprises data
      that can be used to personally identify you. This Data Protection Declaration explains which data we collect as
      well as the purposes we use this data for. It also explains how, and for which purpose the information is
      collected.
    </p>
    <p>
      We herewith advise you that the transmission of data via the Internet (i.e. through e-mail communications) may be
      prone to security gaps. It is not possible to completely protect data against third party access.
    </p>
    <h3>Information about the responsible party (referred to as the “controller“ in the GDPR)</h3>
    <p>The data processing controller on this website is:</p>
    <br />
    <span> The Vault (Alchemist Accelerator, LLC)</span><br />
    <address>
      1000 Brickell Ave, Ste 715 PMB 5087<br />
      Miami, FL 33131<br />
      United States
    </address>
    <br />
    <p>
      Phone number: +1 (415) 275-1419<br />
      Email address to: <MailTo to="support@alchemistaccelerator.com" />
    </p>
    <p>
      The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions
      as to the purposes of and resources for the processing of personal data (e.g. names, e-mail addresses, etc.).
    </p>
    <h3>Revocation of your consent to the processing of data</h3>
    <p>
      A wide range of data processing transactions are possible only subject to your express consent. You can also
      revoke at any time any consent you have already given us. To do so, all you are required to do is sent us an
      informal notification via e-mail. This shall be without prejudice to the lawfulness of any data collection that
      occurred prior to your revocation.
    </p>
    <h3>
      Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)
    </h3>
    <p>
      IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY
      TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO
      APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA
      IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR
      AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
      PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS
      THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21 SECT. 1 GDPR).
    </p>
    <p>
      IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO AT ANY
      TIME OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING. THIS ALSO
      APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR
      PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21
      SECT. 2 GDPR).
    </p>
    <h3>Right to log a complaint with the competent supervisory agency</h3>
    <p>
      In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency,
      in particular in the member state where they usually maintain their domicile, place of work or at the place where
      the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative
      or court proceedings available as legal recourses.
    </p>
    <h3>Right to data portability</h3>
    <p>
      You have the right to demand that we hand over any data we automatically process on the basis of your consent or
      in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format.
      If you should demand the direct transfer of the data to another controller, this will be done only if it is
      technically feasible.
    </p>
    <h3>SSL and/or TLS encryption</h3>
    <p>
      For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries
      you submit to us as the website operator, this website uses either an SSL or a TLS encryption programme. You can
      recognise an encrypted connection by checking whether the address line of the browser switches from “http://“ to
      “https://“ and also by the appearance of the lock icon in the browser line.
    </p>
    <p>
      If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.
    </p>
    <h3>Information about, rectification and eradication of data</h3>
    <p>
      Within the scope of the applicable statutory provisions, you have the right to at any time demand information
      about your archived personal data, their source and recipients as well as the purpose of the processing of your
      data. You may also have a right to have your data rectified or eradicated. If you have questions about this
      subject matter or any other questions about personal data, please do not hesitate to contact us at any time at the
      address provided in section “Information Required by Law.“
    </p>
    <h3>Right to demand processing restrictions</h3>
    <p>
      You have the right to demand the imposition of restrictions as far as the processing of your personal data is
      concerned. To do so, you may contact us at any time at the address provided in section “Information Required by
      Law.“ The right to demand restriction of processing applies in the following cases:
    </p>
    <ul>

      <li>
        In the event that you should dispute the correctness of your data archived by us, we will usually need some time
        to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we
        restrict the processing of your personal data.
      </li>
      <li>If the processing of your personal data was/is conducted in an unlawful manner, you have the option to
        demand the restriction of the processing of your data in lieu of demanding the eradication of this data.
      </li>
      <li>If we do not need your personal data any longer and you need it to exercise, defend or claim legal
        entitlements, you have the right to demand the restriction of the processing of your personal data instead
        of its eradication.
      </li>
      <li>If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights will have
        to be weighed against each other. As long as it has not been determined whose interests prevail, you have
        the right to demand a restriction of the processing of your personal data.
      </li>
    </ul>
    <p>
      If you have restricted the processing of your personal data, these data – with the exception of their
      archiving – may be processed only subject to your consent or to claim, exercise or defend legal
      entitlements or to protect the rights of other natural persons or legal entities or for important public
      interest reasons cited by the European Union or a member state of the EU.
    </p>
    <h3>Rejection of unsolicited e-mails</h3>
    <p>
      We herewith object to the use of contact information published in conjunction with the mandatory
      information to be provided in section “Information Required by Law“ to send us promotional and
      information material that we have not expressly requested. The operators of this website and its pages
      reserve the express right to take legal action in the event of the unsolicited sending of promotional
      information, for instance via SPAM messages.
    </p>
    <h2>4. Recording of data on this website</h2>
    <h3>Cookies</h3>
    <p>
      In some instances, our website and its pages use so-called cookies. Cookies do not cause any damage to
      your computer and do not contain viruses. The purpose of cookies is to make our website more user
      friendly, effective and more secure. Cookies are small text files that are placed on your computer and
      stored by your browser.
    </p>
    <p>
      Most of the cookies we use are so-called “session cookies.“ They are automatically deleted after your
      leave our site. Other cookies will remain archived on your device until you delete them. These cookies
      enable us to recognise your browser the next time you visit our website.
    </p>
    <p>
      You can adjust the settings of your browser to make sure that you are notified every time cookies are
      placed and to enable you to accept cookies only in specific cases or to exclude the acceptance of
      cookies for specific situations or in general and to activate the automatic deletion of cookies when you
      close your browser. If you deactivate cookies, the functions of this website may be limited.
    </p>
    <p>
      Cookies that are required for the performance of the electronic communications transaction or to provide
      certain functions you want to use (e.g. the shopping cart function), are stored on the basis of Art. 6
      Sect. 1 lit. f GDPR. The website operator has a legitimate interest in storing cookies to ensure the
      technically error free and optimised provision of the operator’s services. If a corresponding agreement
      has been requested (e.g. an agreement to the storage of cookies), the processing takes place exclusively
      on the basis of Art. 6 para. 1 lit. a GDPR; the agreement can be revoked at any time.
    </p>
    <p>
      If other cookies (e.g. cookies for the analysis of your browsing patterns) should be stored, they are
      addressed separately in this Data Protection Declaration.
    </p>
    <h3>Server log files</h3>
    <p>
      The provider of this website and its pages automatically collects and stores information in so-called
      server log files, which your browser communicates to us automatically. The information comprises:
    </p>
    <ul>
      <li>The type and version of browser used</li>
      <li>The used operating system</li>
      <li>Referrer URL</li>
      <li>The hostname of the accessing computer</li>
      <li>The time of the server inquiry</li>
      <li>The IP address</li>
    </ul>
    <p>
      This data is not merged with other data sources.
    </p>
    <p>
      This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of the
      website has a legitimate interest in the technically error free depiction and the
      optimization of the operator’s website. In order to achieve this, server log files must be
      recorded.
    </p>
    <h3>Request by e-mail, telephone or fax</h3>
    <p>
      If you contact us by e-mail, telephone or fax, your request, including all resulting
      personal data (name, request) will be stored and processed by us for the purpose of
      processing your request. We do not pass these data on without your consent.
    </p>
    <p>
      The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if your request is
      related to the execution of a contract or if it is necessary to carry out pre-contractual
      measures. In all other cases, the processing is based on your consent (Article 6 (1) a
      GDPR) and/or on our legitimate interests (Article 6 (1) (f) GDPR), since we have a
      legitimate interest in the effective processing of requests addressed to us.
    </p>
    <p>
      The data sent by you to us via contact requests remain with us until you request us to
      delete, revoke your consent to the storage or the purpose for the data storage lapses
      (e.g. after completion of your request). Mandatory statutory provisions - in particular
      statutory retention periods - remain unaffected.
    </p>
    <h3>Registration on this website</h3>
    <p>
      You have the option to register on this website to be able to use additional website
      functions. We shall use the data you enter only for the purpose of using the respective
      offer or service you have registered for. The required information we request at the time
      of registration must be entered in full. Otherwise we shall reject the registration.
    </p>
    <p>
      To notify you of any important changes to the scope of our portfolio or in the event of
      technical modifications, we shall use the e-mail address provided during the registration
      process.
    </p>
    <p>
      We shall process the data entered during the registration process on the basis of your
      consent (Art. 6 Sect. 1 lit. a GDPR).
    </p>
    <p>
      The data recorded during the registration process shall be stored by us as long as you are
      registered on this website. Subsequently, such data shall be deleted. This shall be
      without prejudice to mandatory statutory retention obligations.
    </p>
    <h3>The comment function on this website</h3>
    <p>
      When you use the comment function on this website, information on the time the comment was
      generated and your e-mail-address and, if you are not posting anonymously, the user name
      you have selected will be archived in addition to your comments.
    </p>
    <p>
      <strong>Storage of the IP address</strong>
    </p>
    <p>
      Our comment function stores the IP addresses of all users who enter comments. Given that
      we do not review the comments prior to publishing them, we need this information in order
      to take action against the author in the event of rights violations, such as defamation or
      propaganda.
    </p>
    <p>
      <strong>Storage period for comments</strong>
    </p>
    <p>
      Comments and any affiliated information (e.g. the IP address) shall be stored by us and
      remain on this website until the content the comment pertained to has been deleted in its
      entirety or if the comments had to be deleted for legal reasons (e.g. insulting comments).
    </p>
    <p>
      <strong>Legal basis</strong>
    </p>
    <p>
      Comments are stored on the basis of your consent (Art. 6 Sect. 1 lit. a GDPR). You have
      the right to revoke at any time any consent you have already given us. To do so, all you
      are required to do is sent us an informal notification via e-mail. This shall be without
      prejudice to the lawfulness of any data collection that occurred prior to your revocation.
    </p>
    <h3>Processing of data (customer and contract data)</h3>
    <p>
      We collect, process and use personal data only to the extent necessary for the
      establishment, content organization or change of the legal relationship (data inventory).
      These actions are taken on the basis of Art. 6 Sect. 1 lit. b GDPR, which permits the
      processing of data for the fulfilment of a contract or pre-contractual actions. We
      collect, process and use personal data concerning the use of this website (usage data)
      only to the extent that this is necessary to make it possible for users to utilize the
      services and to bill for them.
    </p>
    <p>
      The collected customer data shall be eradicated upon completion of the order or the
      termination of the business relationship. This shall be without prejudice to any statutory
      retention mandates.
    </p>
    <h3>Data transfer upon closing of contracts for services and digital content</h3>
    <p>
      We share personal data with third parties only if this is necessary in conjunction with
      the handling of the contract; for instance, with the financial institution tasked with the
      processing of payments.
    </p>
    <p>
      Any further transfer of data shall not occur or shall only occur if you have expressly
      consented to the transfer. Any sharing of your data with third parties in the absence of
      your express consent, for instance for advertising purposes, shall not occur.
    </p>
    <p>
      The basis for the processing of data is Art. 6 Sect. 1 lit. b GDPR, which permits the
      processing of data for the fulfilment of a contract or for pre-contractual actions.
    </p>
    <h2>5. Social media</h2>
    <h3>LinkedIn plug-in</h3>
    <p>
      This website uses functions of the LinkedIn network. The provider is LinkedIn Corporation,
      2029 Stierlin Court, Mountain View, CA 94043, USA.
    </p>
    <p>
      Any time you access a page of this website that contains functions of LinkedIn, a
      connection to LinkedIn’s servers is established. LinkedIn is notified that you have
      visited this website with your IP address. If you click on LinkedIn’s “Recommend“ button
      and are logged into your LinkedIn account at the time, LinkedIn will be in a position to
      allocate your visit to this website to your user account. We have to point out that we as
      the provider of the websites do not have any knowledge of the content of the transferred
      data and its use by LinkedIn.
    </p>
    <p>
      The use of the LinkedIn plug-in is based on Art. 6 Sect. 1 lit. f GDPR. The operator of
      the website has a legitimate interest in being as visible as possible on social media.
    </p>
    <p>
      For further information on this subject, please consult LinkedIn’s Data Privacy Declaration at:
      {' '}<LinkTo href="https://www.linkedin.com/legal/privacy-policy" />.
    </p>
    <h2>6. Analysis tools and advertising</h2>
    <h3>Google Analytics</h3>
    <p>
      This website uses functions of the web analysis service Google Analytics. The provider of
      this service is Google Ireland Limited (“Google“), Gordon House, Barrow Street, Dublin 4,
      Ireland.
    </p>
    <p>
      Google Analytics uses so-called cookies. Cookies are text files, which are stored on your
      computer and that enable an analysis of the use of the website by users. The information
      generated by cookies on your use of this website is usually transferred to a Google server
      in the United States, where it is stored.
    </p>
    <p>
      The storage of Google Analytics cookies and the utilization of this analysis tool are
      based on Art. 6 Sect. 1 lit. f GDPR. The operator of this website has a legitimate
      interest in the analysis of user patterns to optimize both, the services offered online
      and the operator’s advertising activities. If a corresponding agreement has been requested
      (e.g. an agreement to the storage of cookies), the processing takes place exclusively on
      the basis of Art. 6 para. 1 lit. a GDPR; the agreement can be revoked at any time.
    </p>
    <p>
      <strong>IP anonymization</strong>
    </p>
    <p>
      On this website, we have activated the IP anonymization function. As a result, your IP
      address will be abbreviated by Google within the member states of the European Union or in
      other states that have ratified the Convention on the European Economic Area prior to its
      transmission to the United States. The full IP address will be transmitted to one of
      Google’s servers in the United States and abbreviated there only in exceptional cases. On
      behalf of the operator of this website, Google shall use this information to analyse your
      use of this website to generate reports on website activities and to render other services
      to the operator of this website that are related to the use of the website and the
      Internet. The IP address transmitted in conjunction with Google Analytics from your
      browser shall not be merged with other data in Google’s possession.
    </p>
    <p>
      <strong>Browser plug-in</strong>
    </p>
    <p>
      You do have the option to prevent the archiving of cookies by making pertinent changes to
      the settings of your browser software. However, we have to point out that in this case you
      may not be able to use all of the functions of this website to their fullest extent.
      Moreover, you have the option prevent the recording of the data generated by the cookie
      and affiliated with your use of the website (including your IP address) by Google as well
      as the processing of this data by Google by downloading and installing the browser plug-in
      available under the following link: <LinkTo href="https://tools.google.com/dlpage/gaoptout?hl=en" />.
    </p>
    <p><strong>Objection to the recording of data</strong></p>
    <p>
      You have the option to prevent the recording of your data by Google Analytics by clicking
      on the following link. This will result in the placement of an opt out cookie, which
      prevents the recording of your data during future visits to this website:
      <a href="https://javascript:gaOptout()">Google Analytics deactivation</a>.
    </p>
    <p>
      For more information about the handling of user data by Google Analytics, please consult
      Google’s Data Privacy Declaration at: <LinkTo href="https://support.google.com/analytics/answer/6004245?hl=en" />.
    </p>
    <p><strong>Contract data processing</strong></p>
    <p>
      We have executed a contract data processing agreement with Google and are implementing the
      stringent provisions of the German data protection agencies to the fullest when using
      Google Analytics.
    </p>
    <p><strong>Demographic parameters provided by Google Analytics</strong></p>
    <p>
      This website uses the function “demographic parameters“ provided by Google Analytics. It
      makes it possible to generate reports providing information on the age, gender and
      interests of website visitors. The sources of this information are interest-related
      advertising by Google as well as visitor data obtained from third party providers. This
      data cannot be allocated to a specific individual. You have the option to deactivate this
      function at any time by making pertinent settings changes for advertising in your Google
      account or you can generally prohibit the recording of your data by Google Analytics as
      explained in section “Objection to the recording of data.“
    </p>
    <p><strong>Archiving period</strong></p>
    <p>
      Data on the user or incident level stored by Google linked to cookies, user IDs or
      advertising IDs (e.g. DoubleClick cookies, Android advertising ID) will be anonymized or
      deleted after 50 month. For details please click the following link:
      {' '}<LinkTo href="https://support.google.com/analytics/answer/7667196?hl=en" />.
    </p>
    <h2>7. Plug-ins and Tools</h2>
    <h3>Vimeo</h3>
    <p>
      This website uses plug-ins of the video portal Vimeo. The provider is Vimeo Inc., 555 West
      18th Street, New York, New York 10011, USA.
    </p>
    <p>
      If you visit one of the pages on our website into which a Vimeo plug-in has been
      integrated, a connection to Vimeo’s servers will be established. As a consequence, the
      Vimeo server will receive information as to which of our pages you have visited. Moreover,
      Vimeo will receive your IP address. This will also happen if you are not logged into Vimeo
      or do not have an account with Vimeo. The information recorded by Vimeo will be
      transmitted to Vimeo’s server in the United States.
    </p>
    <p>
      If you are logged into your Vimeo account, you enable Vimeo to directly allocate your
      browsing patterns to your personal profile. You can prevent this by logging out of your
      Vimeo account.
    </p>
    <p>
      The use of Vimeo is based on our interest in presenting our online content in an appealing
      manner. Pursuant to Art. 6 Sect. 1 lit. f GDPR, this is a legitimate interest. If a
      corresponding agreement has been requested (e.g. an agreement to the storage of cookies),
      the processing takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the
      agreement can be revoked at any time.
    </p>
    <p>
      For more information on how Vimeo handles user data, please consult the Vimeo Data Privacy
      Policy under: {' '}<LinkTo href="https://vimeo.com/privacy" />.
    </p>
    <h3>Google Maps</h3>
    <p>
      Via an API, this website uses the mapping service Google Maps. The provider is Google
      Ireland Limited (“Google“), Gordon House, Barrow Street, Dublin 4, Ireland.
    </p>
    <p>
      To enable the use of the Google Maps features, your IP address must be stored. As a rule,
      this information is transferred to one of Google’s servers in the United States, where it
      is archived. The operator of this website has no control over the data transfer.
    </p>
    <p>
      We use Google Maps to present our online content in an appealing manner and to make the
      locations disclosed on our website easy to find. This constitutes a legitimate interest as
      defined in Art. 6 Sect. 1 lit. f GDPR.
    </p>
    <p>
      For more information on the handling of user data, please review Google’s Data Privacy
      Declaration under: <LinkTo href="https://policies.google.com/privacy?hl=en" />.
    </p>
  </div>);

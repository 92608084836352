import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { ButtonGreyPlain } from './ButtonLinkSec';


const ExpandButon = ({ onClick, text, disabled, className, expanded }) => (
  <ButtonGreyPlain
    onClick={onClick}
    disabled={disabled}
    className={classNames(`btn-medium text-capitalize ${className || ''}`, { 'pl-0': !disabled })}
  >
    {text} <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
  </ButtonGreyPlain>
);

ExpandButon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

export default ExpandButon;

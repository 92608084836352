import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectLocation } from 'containers/App/selectors';

const mapStateToProps = createStructuredSelector({ location: makeSelectLocation() });

export default connect(mapStateToProps)(Switch);


import { makeSelectObjectFromRefSelector } from 'containers/App/selectors';

const selectPeopleConnectionRef = (state) => state.getIn(['peopleConnection', 'connectionRef']);
const makeSelectConnection = () => makeSelectObjectFromRefSelector(selectPeopleConnectionRef);

const selectIsConnAlreadyAccepted = (state) => state.getIn(['peopleConnection', 'isConnAlreadyAccepted']);
const selectIsConnAlreadyDenied = (state) => state.getIn(['peopleConnection', 'isConnAlreadyDenied']);
const selectErrorConnStatus = (state) => state.getIn(['peopleConnection', 'errorConnectionStatus']);

const selectFeedbackSentErr = (state) => state.getIn(['peopleConnection', 'feedbackSentErr']);

export { makeSelectConnection, selectIsConnAlreadyAccepted, selectIsConnAlreadyDenied, selectErrorConnStatus, selectFeedbackSentErr };

import { fromJS } from 'immutable';

import { SET_CHAT_HISTORY, SET_IS_LOADING } from './constants';


const initialState = fromJS({
  isLoading: false,
  chatHistory: [],
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return state.set('isLoading', action.isLoading);
    case SET_CHAT_HISTORY:
      return state.set('chatHistory', action.chatHistory);
    default:
      return state;
  }
}

export default reducer;

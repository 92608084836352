/* eslint-disable no-underscore-dangle */
/**
 * Gets the people
 */
import { fromJS } from 'immutable';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { stringify } from 'qs';

import { showAlert } from 'containers/AlertBox/actions';
import { RESOURCE_PEOPLE, API_PEOPLE_BASE_URL, RESOURCE_ALCHEMIST_CLASSES, PERMISSION, API_RECSYS_FEEDBACK_BASE_URL, RESOURCE_RECSYS_FEEDBACK } from 'containers/App/constants';
import { loadLists } from 'containers/AuthProvider/saga';
import { makeSelectProfile, makeSelectAccount, makeSelectUserIsOneOf, makeSelectUserIs, makeSelectMyCompany } from 'containers/AuthProvider/selectors';
import { makeSelectObject } from 'containers/App/selectors';
import { generateFiltersLocalString, processAdvancedFilters } from 'utils/filter/filter';
import { getFiltersString, getStandardSagas } from 'utils/filter/saga';
import { logError } from 'utils/log';
import { post, request } from 'utils/request';
import { refApiUrl, resRef } from 'utils/refs';
import { putAction, parseUrlFilters, updateObjFromApi, debounceSaga, waitAndSelectAccount, refreshCurrentRoute } from 'utils/sagas';
import { ifsIncludes } from 'containers/People/Single/saga';
import { OR_OP } from 'utils/filter/constants';
import { extractData } from 'utils/jsonApiExtract';
import { updateIncludedFromRequest } from 'actions';
import { preprocessError } from 'utils/Forms/sagas';
import { COLOR } from 'containers/AlertBox/constants';

import {
  makeSelectFilter,
  makeSelectFilterGroupsOperators,
  makeSelectMarkedPeopleLists,
  makeSelectMarkedPeopleRefs,
  makeSelectSelectedPerson, makeSelectTotalCount,
  selectAdvFiltersOperator,
  selectPeopleListsIsAddSearchResults, selectPeopleListsSearchResultsOffset, selectPeopleListsSearchResultsToAdd,
  selectPeoplePage,
  selectSortObj,
} from './selectors';
import {
  PEOPLE_LOAD_CONTENT_SUCCESS, PEOPLE_SET_SELECTED_OBJECT, ADD_MARKED_PEOPLE_TO_MARKED_LISTS, PEOPLE_PAGE_IDENTIFIER,
  PEOPLE_BROWSE_SELECTED_OBJECT, REQUEST_MERGE,
  SET_IS_RECOMMENDATION_USEFUL,
  RECSYS_RANKING,
} from './constants';
import {
  contentLoadingError, loadContent, setFiltersAndSort, setSelectedObject, initialLoad, clearMarks, setPeopleListsModal,
  clearMarkedPeopleLists, increaseSearchResultsOffset, resetSearchResultsOffset,
} from './actions';
import { preprocessSort } from './Filters/filters';
import { requestMerge } from './Merge/saga';
import { selectedPersonConnectionsFail, selectedPersonConnectionsFetch, selectedPersonConnectionsSuccess } from '../Single/actions';

export function* preprocessAppliedFilters(appliedFilters, prefix = '') {
  let newFilters = fromJS({});

  if (appliedFilters.get('search')) {
    const isAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
    const searchFields = ['nicename', 'title', 'bragline', 'company.name', 'tags.text', 'areas_of_interest.text',
      'investmentcriteria'];
    if (isAdmin) {
      searchFields.push('profile_emails.email');
    }
    newFilters = newFilters.set(
      `${searchFields.map((s) => `${prefix}${s}`).join(',')}:${appliedFilters.get('searchOp') || 'search'}`,
      appliedFilters.get('search').replace(/%/, '\\%')
    );
  }

  if (appliedFilters.get('category')) {
    newFilters = newFilters.set(`${prefix}roles.title:eq`, appliedFilters.get('category'));
  }
  newFilters = processAdvancedFilters(appliedFilters.getIn(['advancedFilters', 'filters']), newFilters);

  return newFilters;
}

export function* preprocessAppliedFiltersForPeoplePage(appliedFilters, prefix = '') {
  let newFilters = fromJS({});

  if (appliedFilters.get('category')) {
    newFilters = newFilters.set(`${prefix}roles.title:eq`, appliedFilters.get('category'));
  }
  newFilters = processAdvancedFilters(appliedFilters.getIn(['advancedFilters', 'filters']), newFilters);

  return newFilters;
}

function* getSelectedPerson() {
  const selectedPerson = yield select(makeSelectSelectedPerson());
  yield updatePersonProfileForComponent({ selectedObject: selectedPerson });
}

export function* updatePersonProfileForComponent({ selectedObject: profilePersonRef }) {
  if (!profilePersonRef) return null;

  const profilePerson = yield select(makeSelectObject(profilePersonRef));

  if (!profilePerson
    || (profilePerson.company
      && profilePerson.company().current_members_rel
      && profilePerson.company_membership
      && profilePerson.tags)) return null;

  const include = ['tags', 'comments.author.profile', 'company_membership.company', 'areas_of_service',
    'location', 'press_topics', 'areas_of_interest', 'not_areas_of_interest', 'angel_prefer_geographies',
    'erogated_investments.investment_round.company.tags', 'erogated_investments.investing_company',
    'company.aclass', 'company.stages_of_interest', 'company.fund_prefer_geographies'];
  const myProfile = yield select(makeSelectProfile());

  if (myProfile?.is_admin) {
    include.push('account', 'coach_classes');
  }

  const isFounderOrClassCoach = yield select(makeSelectUserIsOneOf([PERMISSION.founder, PERMISSION.class_coach]));
  if (myProfile?.is_admin || isFounderOrClassCoach) {
    include.push(...ifsIncludes);
  }

  if (myProfile && (myProfile.is_admin || profilePersonRef.id === myProfile.id)) {
    include.push('profile_emails');
  }

  try {
    yield updateObjFromApi(profilePerson, include.join(','));
  } catch (err) {
    logError(err);
    yield put(contentLoadingError(err));
  }
  return null;
}

function* refreshCurrentRouteLocalSaga() {
  const filterImmutable = yield select(makeSelectFilter());

  yield refreshCurrentRoute({
    action: initialLoad(),
    condition: (location) => location.pathname === `/${RESOURCE_PEOPLE}`
      && `${generateFiltersLocalString(filterImmutable)}` !== location.search && !location.search,
  });
}

/**
 * Load the filters from the url search parameters
 */
export function* initialLoadSaga() {
  const queryParams = yield parseUrlFilters();

  yield put(setFiltersAndSort(queryParams.filter || {}, queryParams.sort));
  yield put(setSelectedObject(false));
  yield put(loadContent());
}

function* addToMarkedLists(...args) {
  const isAddSearchResults = yield select(selectPeopleListsIsAddSearchResults);
  if (isAddSearchResults) {
    yield addSearchResultsToMarkedLists(...args);
  } else {
    yield addMarkedPeopleToMarkedLists(...args);
  }
}
function* addSearchResultsToMarkedLists() {
  const offset = yield select(selectPeopleListsSearchResultsOffset);
  const totalCount = yield select(makeSelectTotalCount());
  const limit = yield select(selectPeopleListsSearchResultsToAdd);
  const markedPeopleListsRefs = yield select(makeSelectMarkedPeopleLists());
  const filterString = yield getFiltersString({
    preprocessAppliedFilters: preprocessAppliedFiltersForPeoplePage,
    preprocessSort,
    pageSelector: selectPeoplePage,
  });

  try {
    yield all(markedPeopleListsRefs.map((listRef) => call(post, `${refApiUrl(listRef)}/from_search?_offset=${offset}&_limit=${limit}${filterString}`)));

    yield loadLists();

    yield put(showAlert('Success! The users have been added to the selected lists.'));
    if (offset + limit < totalCount) {
      yield put(increaseSearchResultsOffset());
    } else {
      yield put(resetSearchResultsOffset());
      yield put(setPeopleListsModal(false));
    }
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    if (errorJson?.errors?.[0]?.detail) {
      yield put(showAlert(errorJson.errors[0].detail, COLOR.danger));
    }
  } finally {
    yield put(clearMarkedPeopleLists());
  }
}

function* addMarkedPeopleToMarkedLists() {
  const markedPeopleRefs = yield select(makeSelectMarkedPeopleRefs());
  const markedPeopleListsRefs = yield select(makeSelectMarkedPeopleLists());

  try {
    yield all(markedPeopleListsRefs.map((listRef) => call(post, `${refApiUrl(listRef)}/relationship/users`, { data: markedPeopleRefs })));

    yield loadLists();

    yield put(showAlert('Success! The selected users have been added to the selected lists.'));
    yield put(setPeopleListsModal(false));
    yield put(clearMarks());
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    if (errorJson?.errors?.[0]?.detail) {
      yield put(showAlert(errorJson.errors[0].detail, COLOR.danger));
    }
  } finally {
    yield put(clearMarkedPeopleLists());
  }
}

function* overrideFetchOptionsSaga() {
  yield waitAndSelectAccount();
  const operator = yield select(selectAdvFiltersOperator);
  const searchedValue = (yield select(makeSelectFilter()))?.get('appliedFilters')?.get('search');
  const encodedSearchedValue = encodeURIComponent(searchedValue);
  const searchQueryParam = encodedSearchedValue ? `&search=${encodedSearchedValue}` : '';
  const groupsOperators = yield select(makeSelectFilterGroupsOperators());
  return {
    baseApiUrl: `${API_PEOPLE_BASE_URL}?include=roles,company.aclass&fields[${RESOURCE_ALCHEMIST_CLASSES}]=title,number${searchQueryParam}`,
    controlClientUrl: `/${RESOURCE_PEOPLE}`,
    fopOverride: {
      [`_0:${operator}:[_1:${operator}:[_2:${operator}:[_3:${operator}:[_4:${operator}:[_5:${operator}:[_6:${operator}:[_7:${operator}:[_8:${operator}:[_9:${operator}:[_10:${operator}:[_11:${operator}:[_12:${operator}:[_13:${operator}:[_14:${operator}:[_15:${operator}:[_16:${operator}:_17]]]]]]]]]]]]]]]]`]: operator,
      _1: groupsOperators._1 || OR_OP,
      _2: groupsOperators._2 || OR_OP,
      _3: groupsOperators._3 || OR_OP,
      _4: groupsOperators._4 || OR_OP,
      _5: groupsOperators._5 || OR_OP,
      _6: groupsOperators._6 || OR_OP,
      _7: groupsOperators._7 || OR_OP,
      _8: groupsOperators._8 || OR_OP,
      _9: groupsOperators._9 || OR_OP,
      _10: groupsOperators._10 || OR_OP,
      _11: groupsOperators._11 || OR_OP,
      _12: groupsOperators._12 || OR_OP,
      _13: groupsOperators._13 || OR_OP,
      _14: groupsOperators._14 || OR_OP,
      _15: groupsOperators._15 || OR_OP,
      _16: groupsOperators._16 || OR_OP,
      _17: groupsOperators._17 || OR_OP,
    },
    pageSize: 20,
    _count: 'False',
  };
}

export function* getConnectionsSaga(peopleRequest) {
  const myAccount = yield select(makeSelectAccount());
  const { inclusions } = extractData(peopleRequest);
  try {
    const connectionsRequest = yield request(`${refApiUrl(myAccount)}/messages?include=company&filter[user_id:in]=[${inclusions?.people ? Object.keys(inclusions.people).join() : ''}]`);
    yield put(updateIncludedFromRequest(connectionsRequest));
  } catch (err) {
    logError(err);
  }
}

export function* getSingleConnectionSaga(peopleId) {
  const myAccount = yield select(makeSelectAccount());
  try {
    yield put(selectedPersonConnectionsFetch());
    const connectionsRequest = yield request(`${refApiUrl(myAccount)}/messages?include=company&filter[user_id:in]=[${peopleId}]`);
    yield put(updateIncludedFromRequest(connectionsRequest));
    yield put(selectedPersonConnectionsSuccess());
  } catch (err) {
    logError(err);
    yield put(selectedPersonConnectionsFail());
  }
}

export function* getRecsysFeedback(peopleRequest) {
  const myAccount = yield select(makeSelectAccount());
  const { inclusions } = extractData(peopleRequest);
  try {
    const queryParamsStr = stringify({
      filter: {
        'for_profile_id:in': inclusions?.people ? `[${Object.keys(inclusions.people).join()}]` : '[]',
        'by_account_id:eq': myAccount?.id,
      },
    });
    const recsysRequest = yield request(`${API_RECSYS_FEEDBACK_BASE_URL}/me?${queryParamsStr}`);
    yield put(updateIncludedFromRequest(recsysRequest));
  } catch (err) {
    logError(err);
  }
}

function* fireCallbackSaga(peopleRequest) {
  const isFounderOrAdmin = yield select(makeSelectUserIsOneOf([PERMISSION.founder, PERMISSION.admin]));
  const currentSort = yield select(selectSortObj);
  yield getConnectionsSaga(peopleRequest);
  if (peopleRequest && currentSort?.sortKey === RECSYS_RANKING && isFounderOrAdmin) {
    yield getRecsysFeedback(peopleRequest);
  }

  yield loadMyCompanyTeamMembers();
}

function* setIsRecommendationForPersonUseFul(action) {
  try {
    if (action.person) {
      const isUsefulRecommendationReq = yield call(
        post,
        `${API_RECSYS_FEEDBACK_BASE_URL}?include=for_profile`,
        {
          data: {
            type: RESOURCE_RECSYS_FEEDBACK,
            attributes: { like: action.isUseful },
            relationships: { for_profile: { data: resRef(action.person) } },
          },
        }
      );
      yield put(showAlert('Submitted successfully'));
      yield put(updateIncludedFromRequest(isUsefulRecommendationReq));
    }
  } catch (err) {
    logError(err);
  }
}

export function* loadMyCompanyTeamMembers() {
  const isFounder = yield select(makeSelectUserIs(PERMISSION.founder));
  const isOnboarding = yield select(makeSelectUserIs(PERMISSION.onboarding));
  // isOnboarding is added to catch onboarding founders
  if (isFounder || isOnboarding) {
    const myCompany = yield select(makeSelectMyCompany());
    if (myCompany && !myCompany.current_members_rel) {
      yield updateObjFromApi(myCompany, 'current_members_rel.user');
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_CONTENT actions and calls getPeople when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(PEOPLE_SET_SELECTED_OBJECT, updatePersonProfileForComponent);
  yield takeLatest(PEOPLE_BROWSE_SELECTED_OBJECT, debounceSaga(getSelectedPerson, 1000));
  yield takeLatest(LOCATION_CHANGE, refreshCurrentRouteLocalSaga);
  // yield takeLatest(PEOPLE_LOAD_CONTENT_SUCCESS, checkSelectedObject);
  yield takeLatest(PEOPLE_LOAD_CONTENT_SUCCESS, putAction, setSelectedObject(false));
  yield takeLatest(ADD_MARKED_PEOPLE_TO_MARKED_LISTS, addToMarkedLists);
  yield takeLatest(REQUEST_MERGE, requestMerge);
  yield takeLatest(SET_IS_RECOMMENDATION_USEFUL, setIsRecommendationForPersonUseFul);

  yield getStandardSagas({
    preprocessAppliedFilters: preprocessAppliedFiltersForPeoplePage,
    preprocessSort,
    overrideFetchOptionsSaga,
    pageIdentifier: PEOPLE_PAGE_IDENTIFIER,
    callback: fireCallbackSaga,
  })();
}

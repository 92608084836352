import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';

import { resRef } from 'utils/refs';


const ChooseButton = ({
  selectedObjects, item, markObject, checkedIcon, uncheckedIcon, className, disabled, stopPropagation, disableUnchecked,
}) => {
  const isChosen = !!(selectedObjects && selectedObjects.find((obj) => item.id === obj.id));
  return (
    <button
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        markObject(resRef(item), isChosen);
      }}
      className={className}
      disabled={disableUnchecked ? (disableUnchecked && !isChosen) : disabled}
      type="button"
      aria-label="Bookmark"
    ><FontAwesomeIcon icon={isChosen ? checkedIcon : uncheckedIcon} />
    </button>);
};

ChooseButton.propTypes = {
  selectedObjects: PropTypes.any,
  item: PropTypes.object,
  markObject: PropTypes.func,
  checkedIcon: PropTypes.any,
  uncheckedIcon: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  disableUnchecked: PropTypes.bool,
};

ChooseButton.defaultProps = {
  className: 'dsa-company-button dsa-list_icon col',
  checkedIcon: faCheck,
  uncheckedIcon: faPlus,
};

export default ChooseButton;

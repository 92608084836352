export const LOAD_CONNECTION = 'alchemist/People/Connection/LOAD_CONNECTION';
export const SET_CONNECTION = 'alchemist/People/Connection/SET_CONNECTION';
export const SEND_FEEDBACK = 'alchemist/People/Connection/SEND_FEEDBACK';
export const FEEDBACK_SENT = 'alchemist/People/Connection/FEEDBACK_SENT';

export const ALREADY_ACCEPTED = 'alchemist/People/Connection/ALREADY_ACCEPTED';
export const ALREADY_DENIED = 'alchemist/People/Connection/ALREADY_DENIED';
export const ERROR_ON_CONNECT = 'alchemist/People/Connection/ERROR_ON_CONNECT';

export const FEEDBACK_SENT_ERROR = 'alchemist/People/Connection/FEEDBACK_SENT_ERROR';

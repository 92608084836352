import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import ModalSimple from 'components/Modals/ModalSimple';
import { checkLandscapeModeEnabled } from 'utils/general';

const LandscapeModal = () => {
  const landscapeEnabled = checkLandscapeModeEnabled();
  const [modalIsVisible, setModalIsVisible] = useState(landscapeEnabled);

  useEffect(() => {
    const handleOrientationChange = () => {
      const isLandscape = checkLandscapeModeEnabled();
      if (isLandscape) {
        setModalIsVisible(true);
      } else {
        setModalIsVisible(false);
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <ModalSimple
      isOpen={modalIsVisible}
      toggle={() => setModalIsVisible(false)}
      zIndex={3130}
    >
      <>
        <div className="dsa-action-buttons dsa-modal__close">
          <button onClick={() => setModalIsVisible(false)} className="close" type="button" aria-label="Close">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <ModalBody>
          <p className="text-center">Landscape mode not supported</p>
        </ModalBody>
      </>
    </ModalSimple>
  );
};

export default LandscapeModal;

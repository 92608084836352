/*
 * Admin Alchemist Class reducer
 */
import { fromJS } from 'immutable';

import { CLASS_TYPE } from 'containers/App/constants';

import {
  LOAD_ALCHEMIST_CLASSES,
  LOAD_ALCHEMIST_CLASSES_SUCCESS,
  LOAD_ALCHEMIST_CLASSES_ERROR,
  SELECT_ALCHEMIST_CLASS,
  ADD_ALCHEMIST_CLASS,
  ADD_NEW_ALCHEMIST_CLASS, SET_CLASS_TYPE, SET_CLASS_NAME,
  SET_SHOW_ERROR_SUMMARY,
  SET_IS_ADDING_NEW_CLASS,
} from './constants';

const initialState = fromJS({
  loading: false,
  loadingNewClass: false,
  error: false,
  classType: CLASS_TYPE.alchemist,
  loadedObjects: false,
  selectedClass: false,
  mustShowErrorSummary: 0,
  isAddingNewClass: false,
});

function classesSection(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALCHEMIST_CLASSES:
      return state.set('loading', true).set('error', false).set('loadedObjects', false);
    case LOAD_ALCHEMIST_CLASSES_SUCCESS:
      return state.set('loading', false).set('loadedObjects', action.objects);
    case ADD_ALCHEMIST_CLASS:
      return state
        .update('loadedObjects', (objRefs) => objRefs ? [action.alchemistClassRef, ...objRefs] : [action.alchemistClassRef])
        .set('loadingNewClass', false);
    case ADD_NEW_ALCHEMIST_CLASS:
      return state.set('loadingNewClass', true).set('isAddingNewClass', true);
    case LOAD_ALCHEMIST_CLASSES_ERROR:
      return state.set('error', action.error).set('loading', false);
    case SELECT_ALCHEMIST_CLASS:
      return state.set('selectedClass', action.alchemistClassRef);
    case SET_CLASS_TYPE:
      return state.set('classType', action.classType).set('selectedClass', false);
    case SET_CLASS_NAME:
      return state.set('alchemistClassName', action.className);
    case SET_SHOW_ERROR_SUMMARY:
      return state.update('mustShowErrorSummary', (currentMustShow) => action.mustShow ? currentMustShow + 1 : 0);
    case SET_IS_ADDING_NEW_CLASS:
      return state.set('isAddingNewClass', action.isAddingNewClass);
    default:
      return state;
  }
}

export default classesSection;

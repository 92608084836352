import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button } from 'reactstrap';

import { logError } from 'utils/log';
import { requestHooks } from 'utils/request';

class AutoReload extends React.Component {
  static reloadApp(e) {
    window.location.reload(true);
    e.preventDefault();
  }

  constructor(props) {
    super(props);
    this.previousHash = null;
    this.lastCheck = null;
    this.state = {
      codeHasChanged: false,
    };
    this.timeToCheckForUpdate = false;
    requestHooks.onFetch.push(() => {
      if (this.timeToCheckForUpdate) {
        this.checkVersion();
      }
    });
  }

  componentDidMount() {
    this.checkVersion();
    this.setTimerSoft();
    this.setHardCheckInterval();
  }

  componentWillUnmount() {
    clearTimeout(this.softTimeout);
    clearInterval(this.hardCheckInterval);
  }

  setTimerSoft() {
    const { tryDelay } = this.props;
    this.softTimeout = setInterval(() => {
      this.timeToCheckForUpdate = true;
    }, tryDelay);
  }

  setHardCheckInterval() {
    const { forceDelay } = this.props;
    this.hardCheckInterval = setInterval(() => {
      if (new Date() - this.lastCheck >= forceDelay) {
        this.checkVersion();
      }
    }, forceDelay);
  }

  checkVersion() {
    clearTimeout(this.softTimeout);
    this.timeToCheckForUpdate = false;
    this.lastCheck = new Date();
    return fetch(this.props.url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('offline');
        }
        return response.text();
      })
      .then((html) => {
        const hash = html;
        if (!this.previousHash) {
          this.previousHash = hash;
          this.setTimerSoft();
          return;
        }
        if (this.previousHash !== hash) {
          this.previousHash = hash;
          this.setState({ codeHasChanged: true });
        }
        this.setTimerSoft();
      })
      .catch((error) => {
        logError('failed to check VERSION', error);
        this.setTimerSoft();
      });
  }

  render() {
    if (!this.state.codeHasChanged) return null;
    return (
      <Alert isOpen={this.state.codeHasChanged} color="success">
        Website code has been updated, please save your work and refresh the page
        <Button className="ms-6" onClick={AutoReload.reloadApp}>Refresh Now</Button>
        <button onClick={this.closeAlert} className="close" type="button" aria-label="Close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      </Alert>
    );
  }
}

AutoReload.propTypes = {
  url: PropTypes.string,
  tryDelay: PropTypes.number,
  forceDelay: PropTypes.number,
};

AutoReload.defaultProps = {
  url: '/VERSION',
  tryDelay: 5 * 60 * 1000, // 5 minutes
  forceDelay: 60 * 60 * 1000, // 1 hour
};

export default AutoReload;

/*
 *
 * GdprConfirm reducer
 *
 */

import { fromJS } from 'immutable';

import { CANCELLATION_SENT, CONFIRMATION_SENT, SEND_CANCELLATION, SEND_CONFIRMATION } from './constants';

const initialState = fromJS({
  loadingConfirmation: false,
  loadingCancellation: false,
  requestFailed: false,
});


function gdprConfirmationReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_CONFIRMATION:
      return state.set('loadingConfirmation', true);
    case SEND_CANCELLATION:
      return state.set('loadingCancellation', true);
    case CONFIRMATION_SENT:
      return state.set('loadingConfirmation', false).set('requestFailed', action.requestFailed);
    case CANCELLATION_SENT:
      return state.set('loadingCancellation', false).set('requestFailed', action.requestFailed);
    default:
      return state;
  }
}

export default gdprConfirmationReducer;

import { all, select, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';

import { PERMISSION, API_ACCOUNTS_HINTS_BASE_URL } from 'containers/App/constants';
import { makeSelectUserIs, makeSelectUserIsOneOf, selectToken } from 'containers/AuthProvider/selectors';
import { askConfirmationSaga } from 'containers/ConfirmModal/saga';
import { toggleAdvFiltersWindow, setAdvFilter } from 'containers/People/Search/actions';
import { post } from 'utils/request';

import { HINT_TYPES } from './constants';
import { makeSelectHints } from './selectors';

export function* showHints() {
  const hints = yield select(makeSelectHints());

  /**
   * We just go though all hints and check if conditions are met to show it
   */
  const isThereHintsToDisplay = hints.length !== 0;
  if (isThereHintsToDisplay) {
    yield all(hints.map((hint) => call(showHint, hint)));
  }
}

// TODO: Move different hints to actions
function* showHint(hint) {
  const isAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
  const isFounderOrCoach = yield select(makeSelectUserIsOneOf([PERMISSION.founder, PERMISSION.class_coach]));
  const userToken = yield select(selectToken);
  const decodedUserToken = jwtDecode(userToken);

  const isFreeConnectHint = hint?.code === HINT_TYPES.FREE_CONNECT && isFounderOrCoach && !isAdmin;

  if (isFreeConnectHint) {
    const hintAnswer = yield askConfirmationSaga(
      'Introducing Free Connects',
      // We need to put nbsp; after \n to get 2 lines break because of react-markdown ¯\_(ツ) _/¯
      'Free Connects allows you to connect with underutilized profiles for free. These connections **will NOT decrease your message credits** and **will NOT affect your conversion rate.** \n &nbsp; \nTo view eligible profiles go to **All Filters** and select the **Free Connects** checkbox.',
      'View eligible profiles',
      'Remind me later'
    );

    // If user press "Ok" - we need to expand All Filters block and apply Free Connect filter
    if (hintAnswer) {
      if (window.location.pathname !== '/people') {
        yield put(push('/people?filter[advancedFilters][filters][0][field]=is_free_message_due_to_inactivity:eq&filter[advancedFilters][filters][0][value]=True&isFilterWindowOpened=true'));
      } else {
        yield put(toggleAdvFiltersWindow());
        yield put(setAdvFilter('is_free_message_due_to_inactivity:eq', 'True'));
      }
    }

    const isCrossClick = typeof hintAnswer === 'undefined';
    // If user closes modal and there is no impersonator
    if (isCrossClick && !decodedUserToken?.impersonator) {
      // Don't show the free connect hint modal again
      yield call(post, API_ACCOUNTS_HINTS_BASE_URL, {
        data: {
          type: 'account_hints',
          relationships: {
            hint: {
              data: { type: 'hints', id: hint.id },
            },
          },
        },
      });
    }
  }
}

import PropTypes from 'prop-types';
import { parse } from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import OnePager from 'components/OnePager/OnePager';
import LoadingPage from 'components/LoadingSpinner/LoadingPage';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './saga';
import { makeSelectVerifyingProfile } from './selectors';
import { loadVerifiable } from './actions';


class VerifiableDecline extends React.Component {
  componentDidMount() {
    const { location, match } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const verifiableCode = queryParams.code;
    this.props.loadVerifiableInfo(match.params.verifiableType, verifiableCode);
  }

  render() {
    const { verifyingProfile } = this.props;
    if (!verifyingProfile) return <LoadingPage text="Loading page..." />;

    return (
      <OnePager title={`Thanks for letting us know, ${verifyingProfile.firstname}`}>
        <p>We’ll make sure to send a note to their team and let them know about the mishap.</p>
        <a className="dsa-link-button link" href="http://alchemistaccelerator.com/portfolio/">
          Check out the Alchemist Portfolio
        </a>
      </OnePager>
    );
  }
}

VerifiableDecline.propTypes = {
  location: PropTypes.any,
  loadVerifiableInfo: PropTypes.any,
  verifyingProfile: PropTypes.object,
  match: PropTypes.object,
};


export function mapDispatchToProps(dispatch) {
  return {
    loadVerifiableInfo: (verifiableType, verifiableCode) => dispatch(loadVerifiable(verifiableType, verifiableCode, false)),
  };
}
const mapStateToProps = createStructuredSelector({
  verifyingProfile: makeSelectVerifyingProfile(),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'peopleVerifiable', reducer });
const withSaga = injectSaga({ key: 'peopleVerifiable', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(VerifiableDecline);

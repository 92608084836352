import Ajv from 'ajv';
import jsonSchemaDraft04 from 'ajv/lib/refs/json-schema-draft-04.json';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import update from 'lodash/update';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import rewritePattern from 'regexpu-core';


export const extractJsonApiFormSchemaDataPart = (schemaProperties) => mapValues(
  schemaProperties,
  (property) => get(property, 'properties.data') && hasTypeObject(property)
    ? fixNodeType(allowStringId(property.properties.data), property.type)
    : property
);

const allowStringId = (schemaNode) => {
  const newSchemaNode = { ...schemaNode };
  return !get(newSchemaNode, 'properties.id.type')
    ? newSchemaNode
    : update(newSchemaNode, 'properties.id.type', (type) => extendTypes(type, ['string']));
};

/**
 * Include null as allowed type for relationship nodes when corresponding
 * We take the FE validation of rel nodes by copying the content of node.properties.data, however the allowance for
 * type 'null' is included in node.type and not in node.properties.data.type
 * */
const fixNodeType = (schemaNode, parentType) => update(
  schemaNode,
  'type',
  (type) => Array.isArray(parentType) && parentType.includes('null') ? extendTypes(type, ['null']) : type
);

const extendTypes = (type, newTypes) => uniq(Array.isArray(type) ? [...type, ...newTypes] : [type, ...newTypes]);

const hasTypeObject = (property) =>
  Array.isArray(property.type) ? property.type.includes('object') : property.type === 'object';

const adaptSubSchema = (subSchemaObj) => {
  const subSchema = {
    ...subSchemaObj.schema,
    properties: extractJsonApiFormSchemaDataPart(subSchemaObj.schema.properties),
  };

  if (subSchemaObj.fields) {
    subSchema.properties = pick(subSchema.properties, subSchemaObj.fields);

    const required = intersection(subSchema.required, subSchemaObj.fields);

    if (required && required.length) {
      subSchema.required = required;
    } else {
      delete subSchema.required;
    }
  }
  return subSchemaObj.multi
    ? { items: subSchema, type: ['array', 'null'], contains: subSchemaObj.contains }
    : subSchema;
};

export const generateSchema = (fields, schema, subSchemas) => {
  const ajv = new Ajv({ allErrors: true, schemaId: 'auto' });
  ajv.addKeyword('regexp', {
    type: 'string',
    compile: (sch) => {
      let matcher;
      try {
        matcher = new RegExp(sch.pattern, sch.flags);
      } catch (e) {
        matcher = new RegExp(rewritePattern(sch.pattern, sch.flags), sch.flags.replace('u', ''));
      }
      return (data) => matcher.test(data);
    },
  });
  ajv.addMetaSchema(jsonSchemaDraft04);

  const schemaPart = {
    properties: extractJsonApiFormSchemaDataPart(fields ? pick(schema.properties, fields) : schema.properties),
  };
  if (subSchemas) {
    const schemaExtension = mapValues(subSchemas, adaptSubSchema);
    schemaPart.properties = { ...schemaPart.properties, ...schemaExtension };
  }

  const required = fields ? intersection(schema.required, fields) : schema.required;
  if (required && required.length) schemaPart.required = required;
  return ajv.compile(schemaPart);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { omit } from 'lodash';

import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';
import LoadingSingleInline from 'components/LoadingSpinner/LoadingSingleInline';
import TrackableExtLink from 'containers/Analytics/TrackableComponents/TrackableExtLink';


const ButtonType = ({
  to, href, color = '', outline = '', size = '', isSending, children, ...props
}) => {
  const newProps = { ...omit(props, ['dispatch']) };
  const childrenWithLoader = !isSending ? children : <>{children}<LoadingSingleInline /></>;
  if (props.disabled || isSending || (!to && !href)) {
    return (
      <Button color={color} outline={!!outline} size={size} {...newProps} disabled={isSending || props.disabled}>
        {childrenWithLoader}
      </Button>);
  }

  const linkClasses = classNames('btn', `btn-${outline && 'outline-'}${color}`, size && `btn-${size}`);
  if (to) {
    const LinkButton = ClassNameExtendableHoC(Link, linkClasses);
    return <LinkButton to={to} {...newProps}>{childrenWithLoader}</LinkButton>;
  }
  if (href) {
    const ExtLinkButton = ClassNameExtendableHoC(TrackableExtLink, linkClasses);
    return href && <ExtLinkButton href={href} {...newProps}>{childrenWithLoader}</ExtLinkButton>;
  }

  return null;
};

ButtonType.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isSending: PropTypes.bool,
  children: PropTypes.any,
};

const ButtonBase = ClassNameExtendableHoC(
  ButtonType,
  'dsa-button',
  ({
    noMargin, actionDone, showDisabled, invertUnderline, anchorTextStyle, multiLine, linkStyle, isSending, inlineText,
    isFlexButton, noPadX, alignVer, fontWeightMed, plain, grey, white,
  }) => [
    linkStyle,
    {
      'no-margin': noMargin,
      'is-sending': isSending,
      'action-done': actionDone,
      'show-disabled': showDisabled,
      'invert-underline': invertUnderline,
      'dsa-anchor-link': anchorTextStyle,
      'dsa-button-flex': isFlexButton,
      'flex-column center-content-x-y': multiLine,
      'dsa-inline-text': inlineText,
      'px-0': noPadX,
      'align-ver': alignVer,
      'font-w-med': fontWeightMed,
      'p-0 border-0 me-0': plain,
      grey,
      white,
    },
  ],
  ['noMargin', 'actionDone', 'linkStyle', 'showDisabled', 'invertUnderline', 'inlineText', 'noPadX', 'alignVer', 'fontWeightMed', 'grey', 'white', 'plain', 'isFlexButton', 'multiLine']
);


ButtonBase.propTypes = {
  isSending: PropTypes.bool,
  noMargin: PropTypes.bool,
  showDisabled: PropTypes.bool,
  actionDone: PropTypes.bool,
  invertUnderline: PropTypes.bool,
  anchorTextStyle: PropTypes.bool,
  multiLine: PropTypes.bool,
  isFlexButton: PropTypes.bool,
  inlineText: PropTypes.bool,
  noPadX: PropTypes.bool,
  alignVer: PropTypes.bool,
  plain: PropTypes.bool,
  grey: PropTypes.bool,
  fontWeightMed: PropTypes.bool,
  linkStyle: PropTypes.string,
  white: PropTypes.bool,
};

export default ButtonBase;

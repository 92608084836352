import { call, select, takeLatest } from 'redux-saga/effects';
import { parse } from 'qs';

import { makeSelectLocation } from 'containers/App/selectors';
import { logError } from 'utils/log';
import { patch } from 'utils/request';
import { API_IFS_INVITATIONS_BASE_URL } from 'containers/App/constants';

import { MARK_AS_FLOATER } from './constants';

function* markMeAsFloater() {
  const location = yield select(makeSelectLocation());
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  try {
    return yield call(patch, `${API_IFS_INVITATIONS_BASE_URL}/${queryParams?.code}/mark_as_floater`);
  } catch (e) {
    logError(e);
  }
  return true;
}

export default function* defaultSaga() {
  yield takeLatest(MARK_AS_FLOATER, markMeAsFloater);
}

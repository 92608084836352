import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import isEqual from 'lodash/isEqual';


const RadioButton = ({
  label, value, setValue, name, currentValue, disabled, bold = '',
  outerCircleClassName,
}) => {
  const isChecked = currentValue === value || isEqual(currentValue, value);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label className={disabled ? 'disabled' : ''}>
      <span className="fa-layers fa-fw dsa-label_input-circle dsa-inline col-radio-button">
        <input
          type="radio"
          className="d-none"
          name={name}
          value={value}
          checked={isChecked}
          onChange={() => setValue(value)}
          disabled={disabled}
        />
        {isChecked && <FontAwesomeIcon icon={faCircleSolid} className="dsa-label_inner-circle" />}
        <FontAwesomeIcon icon={faCircle} className={`dsa-label_outer-circle ${outerCircleClassName || ''}`} />
      </span>
      <span className={bold && 'font-weight-bold'}>{label}</span>
    </label>
  );
};

RadioButton.propTypes = {
  value: PropTypes.any,
  currentValue: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  outerCircleClassName: PropTypes.string,
};

export default RadioButton;

import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlighter';

import { ConsumeSearchContextHOC } from './SearchContext';

const AAHighlightInner = ({ search, noLinkClassName, ...props }) => search
  ? <Highlighter matchElement="span" matchClass="dsa-highlight-search" search={search || false} {...props} />
  : <span className={noLinkClassName}>{props.children}</span> || null;

AAHighlightInner.propTypes = {
  search: PropTypes.any,
  children: PropTypes.any,
  noLinkClassName: PropTypes.string,
};

export const AAHighlight = ConsumeSearchContextHOC(AAHighlightInner);


import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import TruncateTooltip from 'components/BasicElements/TruncateTooltip';
import { RESOURCE_COMPANIES, RESOURCE_PEOPLE } from 'containers/App/constants';


const PeopleOrCompanyResultRow = (props) => (
  <Row
    className={`dsa-list_item${props.isActive ? ' active' : ''}`}
    key={props.item.id}
  >
    <Col onClick={() => props.onClickRow?.(props.item)} className="dsa-list_item_primary-col">
      <Row className="dsa-list_inner-row">
        <Col className="dsa-list_data dsa-list_avatar-col">
          <img className="dsa-list_avatar" src={props.item.avatarLink('md')} alt={props.item.name} />
        </Col>
        <Col className="dsa-list_data dsa-list_user-name">
          <TruncateTooltip highlightText={props.search} text={props.item.name} len={33} />
        </Col>
        <Col className="dsa-list_data">
          {(props.item.type === RESOURCE_PEOPLE && props.item.company
            && <TruncateTooltip highlightText={props.search} len={26} text={props.item.company().name} />)
            || (props.item.type === RESOURCE_COMPANIES && props.item.teamMembers
              && `${props.item.teamMembers().filter((u) => u.account).length} members`)}
        </Col>
        <Col className="dsa-list_data">
          {props.item.current_position
            && <TruncateTooltip highlightText={props.search} text={props.item.current_position} len={25} />}
        </Col>
      </Row>
    </Col>
  </Row>
);

PeopleOrCompanyResultRow.propTypes = {
  item: PropTypes.object,
  onClickRow: PropTypes.func,
  isActive: PropTypes.bool,
  search: PropTypes.string,
};

export default PeopleOrCompanyResultRow;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollDiv from 'components/Generic/ScrollDiv';
import AaPropTypes from 'utils/AaPropTypes';

const ProfileContent = ({
  className = '', innerRef, children, onScroll, isNotScrollable = false, scrollDivClass,
}) => {
  const ParentElement = isNotScrollable ? 'div' : ScrollDiv;

  const hideGooglePlacesDropdown = () => {
    Array.from(document.getElementsByClassName('pac-container') ?? []).forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.display = 'none';
    });
  };

  const handleScroll = (e) => {
    hideGooglePlacesDropdown();
    onScroll?.(e);
  };

  return (
    <ParentElement
      className={classNames('dsa-profile__content', className)}
      ref={isNotScrollable ? innerRef : null}
      innerRef={isNotScrollable ? null : innerRef}
      onScroll={handleScroll}
      onTouchMove={hideGooglePlacesDropdown}
      scrollDivClass={isNotScrollable ? null : scrollDivClass}
    >
      {children}
    </ParentElement>);
};

ProfileContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  scrollDivClass: PropTypes.string,
  innerRef: AaPropTypes.ref,
  onScroll: PropTypes.func,
  isNotScrollable: PropTypes.bool,
};

export default ProfileContent;

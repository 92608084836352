/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooter, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import { showAlert } from 'containers/AlertBox/actions';
import { RESOURCE_PEOPLE_LISTS, RESOURCE_PEOPLE, PERMISSION } from 'containers/App/constants';
import { makeSelectLocation } from 'containers/App/selectors';
import { SAVE_FORM_SECTION } from 'containers/Forms/constants';
import Field from 'containers/Forms/Field';
import { closeSharePeopleListModal } from 'containers/People/PeopleListManageModal/actions';
import { clearMarkedEventLists, clearMarkedOwnLists, closePeopleListCreateModal, loadEventLists, loadOwnLists } from 'containers/People/PeopleLists/actions';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';

import FormBase, { withFormWrapper } from '..';
import { saveFormSection } from '../actions';

const makeSaveUserListAction = (isEventList) => (values, section, objRef, successAction, resourceType, apiEndpoint) => (
  {
    type: SAVE_FORM_SECTION,
    values: isEventList ? { ...values, event_list: true } : values,
    section,
    objRef,
    successAction,
    resourceType,
    apiEndpoint,
  }
);

class EditPeopleList extends React.Component {
  componentDidMount() {
    this.props.loadSchema(RESOURCE_PEOPLE_LISTS);
  }

  render() {
    const {
      schemas, peopleList, dispatch, location, isEventList, isAdmin,
    } = this.props;

    if (!schemas || !schemas[RESOURCE_PEOPLE_LISTS]) return null;
    const schema = schemas[RESOURCE_PEOPLE_LISTS];
    const closeAction = peopleList && peopleList.id ? closeSharePeopleListModal() : closePeopleListCreateModal();
    const succcessAlert = peopleList && peopleList.id ? 'List Changes Saved' : 'New List Created';

    let pageSuccessActions = [];
    if (location.pathname === `/${RESOURCE_PEOPLE}/lists`) {
      pageSuccessActions = [loadOwnLists(), clearMarkedOwnLists()];
      if (isAdmin) pageSuccessActions = [...pageSuccessActions, loadEventLists(), clearMarkedEventLists()];
    }

    const successActions = [showAlert(succcessAlert), closeAction, ...pageSuccessActions];

    return (
      <FormBase
        formName={RESOURCE_PEOPLE_LISTS}
        schema={schema}
        editedObject={peopleList}
        saveAction={isAdmin ? makeSaveUserListAction(isEventList) : saveFormSection}
        successAction={successActions}
        SaveButtonComponent={peopleList && peopleList.id ? SaveChangesButton : CreateListButton}
        CloseButtonComponent={DiscardCloseButton}
        closeForm={() => dispatch(closeAction)}
        resourceType={RESOURCE_PEOPLE_LISTS}
        ButtonsWrapper={ModalFooter}
      >
        <ModalBody>
          <fieldset className="dsa-form-horizontal__group">
            <Field name="title" colSize="col-100" />
            <Field name="description" colSize="col-100" />
          </fieldset>
        </ModalBody>
      </FormBase>
    );
  }
}

EditPeopleList.propTypes = {
  loadSchema: PropTypes.func,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  schemas: PropTypes.object,
  peopleList: PropTypes.object,
  isAdmin: PropTypes.bool,
  isEventList: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  isAdmin: makeSelectUserIs(PERMISSION.admin),
});

export default connect(mapStateToProps)(withFormWrapper(EditPeopleList));

const SaveChangesButton = ({ disabled, submitting }) => (
  <ButtonPrim
    disabled={disabled}
    isSending={submitting}
    type="submit"
    className="disabled-while-loading"
  >
    Save Changes
  </ButtonPrim>
);
SaveChangesButton.propTypes = { disabled: PropTypes.bool, submitting: PropTypes.bool };

const DiscardCloseButton = (props) => (
  <ButtonSec onClick={props.onClick} type="button">{props.isSubmitDisabled ? '' : 'Discard & '}Close</ButtonSec>);

DiscardCloseButton.propTypes = { isSubmitDisabled: PropTypes.bool, onClick: PropTypes.func };

const CreateListButton = ({ disabled, submitting }) => (
  <ButtonPrim
    disabled={disabled}
    isSending={submitting}
    type="submit"
    className="disabled-while-loading"
  >
    Create List
  </ButtonPrim>
);
CreateListButton.propTypes = { ...SaveChangesButton.propTypes };

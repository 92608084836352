import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from 'reactstrap';

import DropdownSuperClass from 'components/Dropdown/DropdownSuperClass';
import { DropdownFilters } from 'components/Dropdown/DropdownStyles';
import ToggleSec from 'components/Dropdown/ToggleButtons/ToggleSec';


class DropdownNavMenu extends DropdownSuperClass {
  render() {
    const { children } = this.props;
    return (
      <DropdownFilters
        isOpen={this.state.isDropdownOpen}
        onMouseOver={() => this.handleIsDropdownOpen(true)}
        onMouseLeave={() => this.handleIsDropdownOpen(false)}
        toggle={this.toggleDropdown}
      >
        <ToggleSec
          noMargin
          size="sm"
          caret
          onClick={(e) => {
            e.preventDefault();
            return this.toggleDropdown();
          }}
        />
        <DropdownMenu end className="py-0">
          {children}
        </DropdownMenu>
      </DropdownFilters>
    );
  }
}

DropdownNavMenu.propTypes = { children: PropTypes.any };

export default DropdownNavMenu;

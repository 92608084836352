/*
 *
 * OAuthLanding constants
 *
 */

export const GET_CREDENTIALS = 'app/OAuthLanding/GET_CREDENTIALS';
export const LOGIN_ERROR = 'app/OAuthLanding/LOGIN_ERROR';

export const USER_NOT_FOUND_CODE = 'V000';
export const USER_CANCELLED_LOGIN = 'user_cancelled_login';
export const USER_CANCELLED_AUTHORIZE = 'user_cancelled_authorize';

/**
 * Direct selector to the admin state domain
 */
import { createSelector } from 'reselect';
import fromPairs from 'lodash/fromPairs';
import { isValid } from 'redux-form/immutable';

import { makeSelectObjectArrayFromRefArray, makeSelectLocation } from 'containers/App/selectors';
import { makeSelectIsFormSubmitting } from 'containers/Forms/selectors';
import { makeSubSelector } from 'utils/selectors';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});
const selectAdminDomain = (state) => state ? state.get('admin', localInitialState) : localInitialState;

/**
 * Other specific selectors
 */

const makeSelectCurrentSettingsGroup = () => createSelector(
  makeSelectLocation(),
  (location) => location.pathname.match(/^\/admin\/([\w-]+)/)?.[1],
);

const selectSiteSettingsRefs = createSelector(
  selectAdminDomain,
  makeSelectCurrentSettingsGroup(),
  (adminState, settingsGroup) => adminState.getIn(['loadedObjects', settingsGroup])
);
const makeSelectSiteSettings = () => makeSelectObjectArrayFromRefArray(selectSiteSettingsRefs);

const makeSelectSiteSettingsNormalized = () => createSelector(
  makeSelectSiteSettings(),
  (siteSettings) => fromPairs(siteSettings.map((siteSetting) => [siteSetting.key, siteSetting.value]))
);

const makeSelectLoading = () => makeSubSelector(selectAdminDomain, ['loading']);

const makeSelectIsCurrentSettingsGroupValid = () => createSelector(
  makeSelectCurrentSettingsGroup(),
  (state) => state,
  (formName, state) => isValid(formName)(state),
);

const makeSelectIsSubmitting = () => createSelector(
  makeSelectCurrentSettingsGroup(),
  (state) => state,
  (formName, state) => makeSelectIsFormSubmitting(formName)(state)
);

const makeSelectIsSubmittingByFormName = (formName) => createSelector(
  (state) => state,
  (state) => makeSelectIsFormSubmitting(formName)(state)
);

export {
  makeSelectSiteSettings,
  makeSelectLoading,
  makeSelectCurrentSettingsGroup,
  makeSelectSiteSettingsNormalized,
  makeSelectIsCurrentSettingsGroupValid,
  makeSelectIsSubmitting,
  makeSelectIsSubmittingByFormName,
};

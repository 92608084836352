import { fromJS } from 'immutable';

import { SET_INVITATION } from './constants';

const initialState = fromJS({ invitationRef: false });

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INVITATION:
      return state.set('invitationRef', action.invitationRef);
    default:
      return state;
  }
}

import { CONNECTION_LOST, CONNECTION_RECOVERED } from './constants';

function isConnected(state = true, action) {
  switch (action.type) {
    case CONNECTION_LOST:
      return false;
    case CONNECTION_RECOVERED:
      return true;
    default:
      return state;
  }
}

export default isConnected;

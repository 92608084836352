/*
 *
 * GdprDocsModal constants
 *
 */
import termsGdpr from './Templates/termsGdpr';
// import dataAgreement from './Templates/dataAgreement';
import privacyPolicy from './Templates/privacyPolicy';

export const OPEN_DOC_MODAL = 'alchemist/Gdpr/Footer/OPEN_DOC_MODAL';

export const DOC_TYPE = {
  termsGdpr: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy',
  // dataAgreement: 'dataAgreement',
};

export const DOC_TYPES_NICE = {
  [DOC_TYPE.termsGdpr]: 'Terms and Conditions',
  [DOC_TYPE.privacyPolicy]: 'Privacy Policy',
  // [DOC_TYPE.dataAgreement]: 'Data Processing Agreement',
};

export const LEGAL_DOCS = {
  [DOC_TYPE.termsGdpr]:
    {
      title: 'Terms and Conditions',
      document: termsGdpr,
    },
  [DOC_TYPE.privacyPolicy]:
    {
      title: 'Privacy Policy',
      document: privacyPolicy,
    },
};

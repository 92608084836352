import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';

import { API_BASE } from 'containers/App/constants';


export const isReferable = (res) => isObject(res) && 'type' in res && 'id' in res;
/** extract Resource Reference from an object */
export const resRef = (res) => (res && { type: res.type, id: res.id, ...(res.lid ? { lid: res.lid } : {}) });

/** extract Resource Reference from an object */
export const resRefForArrayOrObj = (resArray) => Array.isArray(resArray) ? resArray.map(resRef) : resRef(resArray);

export const refUrl = (objRef) => {
  if (!objRef.id || (typeof objRef.id === 'number' && objRef.id < 0)) {
    return `/${objRef.type}`;
  }
  return `/${objRef.type}/${objRef.id}`;
};

export const refApiUrl = (objRef) => `${API_BASE}${refUrl(objRef)}`;

export const refCompare = (obj1, obj2) => isEqual(resRef(obj1), resRef(obj2));

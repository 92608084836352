import React from 'react';


const LiVerticalDivider = () => (
  <li className="dsa-site-sidebar_item d-block">
    <div className="dsa-site-sidebar_vertical-divider" />
  </li>
);

export default LiVerticalDivider;

import * as Sentry from '@sentry/browser';

export function logError(...errMsg) {
// eslint-disable-next-line no-console
  if (console) console.error(...errMsg);
}
export function logInfo(...infoMsg) {
// eslint-disable-next-line no-console
  if (console) console.log(...infoMsg);
}
export function logWarning(...warnMsg) {
// eslint-disable-next-line no-console
  if (console) console.warn(...warnMsg);
}
export function logTable(...msgsArray) {
// eslint-disable-next-line no-console
  // if (console && console.table) console.table(...msgsArray);
}
export function logToSentry(errMsg, level = Sentry.Severity.warning, extra, tags) {
  if (window.sentryDSN && Sentry) {
    Sentry.withScope((scope) => {
      if (tags) scope.setTags(tags);
      if (extra) scope.setExtras(extra);
      scope.setLevel(level);
      Sentry.captureException(errMsg);
    });
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import CloseButton from './CloseButton';


const CloseButtonWrapper = ({ closeAction, additionalButton }) => (
  <div className="dsa-action-buttons dsa-modal__close">
    {additionalButton && additionalButton}
    <CloseButton closeAction={closeAction} />
  </div>
);

CloseButtonWrapper.propTypes = {
  closeAction: PropTypes.func,
  additionalButton: PropTypes.element,
};

export default CloseButtonWrapper;

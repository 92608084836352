/**
 * Direct selector to the admin state domain
 */
import { createSelector } from 'reselect';
import pick from 'lodash/pick';
import { getFormSyncErrors, getFormSubmitErrors, hasSubmitFailed } from 'redux-form/immutable';

import { getFormRegisteredFields, makeSelectFormFieldPositions } from 'containers/Forms/selectors';
import { makeSelectObjectFromRefSelector, makeSelectObjectArrayFromRefArray } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';
import { toJS } from 'utils/general';
import { asHour12, getLongDate, getTimezoneAbbreviation, parseDateString } from 'utils/dateTime';

import { makeSelectCurrentSettingsGroup } from '../selectors';
import reducer from './reducer';

const localInitialState = reducer(undefined, {});
const selectAlchemistClassesAdminDomain = (state) => state
  ? state.get('alchemistClassesAdmin', localInitialState)
  : localInitialState;

/**
 * Other specific selectors
 */

const selectAlchemistClassesRefs = makeSubSelector(selectAlchemistClassesAdminDomain, ['loadedObjects']);
const makeSelectAlchemistClasses = () => makeSelectObjectArrayFromRefArray(selectAlchemistClassesRefs);
const selectSelectedAlchemistClassesRef = makeSubSelector(selectAlchemistClassesAdminDomain, ['selectedClass']);
const makeSelectSelectedAlchemistClass = () => makeSelectObjectFromRefSelector(selectSelectedAlchemistClassesRef);

const makeSelectLoading = () => makeSubSelector(selectAlchemistClassesAdminDomain, ['loading']);
const makeSelectLoadingNewClass = () => makeSubSelector(selectAlchemistClassesAdminDomain, ['loadingNewClass']);
const selectClassType = makeSubSelector(selectAlchemistClassesAdminDomain, ['classType']);
const selectClassName = makeSubSelector(selectAlchemistClassesAdminDomain, ['alchemistClassName']);

const makeSelectErrors = () => createSelector(
  makeSelectCurrentSettingsGroup(),
  (state) => state,
  (formName, state) => {
    const formRegisteredFields = getFormRegisteredFields(formName)(state);
    if (!formRegisteredFields) return {};

    return pick({
      ...toJS(getFormSyncErrors(formName)(state) || {}),
      ...toJS(getFormSubmitErrors(formName)(state) || {}),
    }, Object.keys(formRegisteredFields));
  },
);

const makeSelectSyncErrors = () => createSelector(
  makeSelectCurrentSettingsGroup(),
  (state) => state,
  (formName, state) => ({ ...toJS(getFormSyncErrors(formName)(state) || {}) }),
);

const selectErrorFieldsPos = createSelector(
  makeSelectCurrentSettingsGroup(),
  (state) => state,
  (formName, state) => makeSelectFormFieldPositions(formName)(state)
);

const hasClassFormSubmitFailed = () => createSelector(
  makeSelectCurrentSettingsGroup,
  (state) => state,
  (formName, state) => hasSubmitFailed(formName)(state)
);

const selectClassFormError = makeSubSelector(selectAlchemistClassesAdminDomain, ['mustShowErrorSummary']);
const selectIsAddingNewClass = makeSubSelector(selectAlchemistClassesAdminDomain, ['isAddingNewClass']);

const selectDdEventStartDate = createSelector(
  makeSelectSelectedAlchemistClass(),
  (aaClass) => {
    const eventStartTzDate = aaClass?.livestream && aaClass.livestream_start_tzaware
      ? parseDateString(aaClass.livestream_start_tzaware)
      : parseDateString(aaClass?.demo_date_pst_aware);
    return aaClass && eventStartTzDate ? `${getLongDate(eventStartTzDate)} ${getTimezoneAbbreviation(eventStartTzDate)}` : `{{ Make sure you have ${aaClass?.livestream && !aaClass.livestream_start_tzaware ? 'demo date and agenda details' : 'demo date'}. }}`;
  }
);

const selectDemoDayRangeDate = createSelector(
  makeSelectSelectedAlchemistClass(),
  (aaClass) => {
    const streamStartTzDate = parseDateString(aaClass?.livestream_start_tzaware);
    const eventStartTzDate = aaClass?.livestream && streamStartTzDate ? streamStartTzDate : parseDateString(aaClass?.demo_date_pst_aware);
    const weekRegEndTzDate = parseDateString(aaClass?.demo_week_registration_end);
    return aaClass && eventStartTzDate && weekRegEndTzDate
      ? `${getLongDate(eventStartTzDate)} ${asHour12(eventStartTzDate)} - ${getLongDate(weekRegEndTzDate)} ${asHour12(weekRegEndTzDate)} ${getTimezoneAbbreviation(weekRegEndTzDate)}`
      : `{{ Make sure you have ${aaClass?.livestream && !aaClass.livestream_start_tzaware ? 'demo date and agenda details' : 'demo date'}. }}`;
  }
);

const selectDemoClassTitleOrRomanNumber = createSelector(
  makeSelectSelectedAlchemistClass(),
  (demoingClass) => demoingClass?.titleOrRomanTitle?.(),
);

const selectDdAgendaHtml = createSelector(
  makeSelectSelectedAlchemistClass(),
  (aaClass) => aaClass?.dd_event_agenda
    && `<ul>${aaClass.dd_event_agenda().map((eventAgenda) => `<li>${eventAgenda.fullHtmlDescriptionTzAware}</li>`).join('')}</ul>`
);

const selectReplacedDdEventDetails = createSelector(
  makeSelectSelectedAlchemistClass(),
  selectDdEventStartDate,
  selectDemoDayRangeDate,
  selectDdAgendaHtml,
  (aaClass, eventStartDate, ddRangeDate, ddAgendaHtml) => {
    let details = aaClass?.demoday_event_details;
    details = details?.replace(/{% event_start_date %}/g, eventStartDate);
    details = details?.replace(/{% range_demo_day_date %}/g, ddRangeDate);
    details = details?.replace(/{% dd_event_agenda %}/g, ddAgendaHtml);
    return details;
  }
);

export {
  makeSelectAlchemistClasses,
  makeSelectLoading,
  makeSelectLoadingNewClass,
  makeSelectSelectedAlchemistClass,
  selectClassType,
  selectClassName,
  makeSelectErrors,
  selectErrorFieldsPos,
  makeSelectSyncErrors,
  hasClassFormSubmitFailed,
  selectClassFormError,
  selectIsAddingNewClass,
  selectSelectedAlchemistClassesRef,
  selectDdEventStartDate,
  selectDemoDayRangeDate,
  selectDemoClassTitleOrRomanNumber,
  selectDdAgendaHtml,
  selectReplacedDdEventDetails,
};

import React from 'react';
import PropTypes from 'prop-types';

import { makeValueLabelArray } from 'utils/Forms/general';

export const OptionsFromSchemaHoc = (WrappedInput) => {
  const InputWithWrap = ({
    schemaNode, mapper, labeler, ...props
  }) => {
    const enumPart = schemaNode && (
      (schemaNode.items && schemaNode.items && schemaNode.items.enum) || schemaNode.enum);
    const generatedLabeler = mapper ? ((val) => mapper[val]) : labeler;
    const options = enumPart ? makeValueLabelArray(enumPart, generatedLabeler) : [];

    return <WrappedInput options={options} {...props} />;
  };

  InputWithWrap.propTypes = {
    schemaNode: PropTypes.object,
    mapper: PropTypes.object,
    labeler: PropTypes.func,
  };
  return InputWithWrap;
};

export default OptionsFromSchemaHoc;

import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import DropdownSuperClass from 'components/Dropdown/DropdownSuperClass';
import { DropdownFilters } from 'components/Dropdown/DropdownStyles';
import ToggleSec from 'components/Dropdown/ToggleButtons/ToggleSec';
import DropdownItemButton from 'components/Dropdown/DropdownItemButton';
import TooltipSimple from 'components/Tooltips/TooltipSimple';

import { selectChatHistory } from './selectors';
import { sendMessage, setChatHistory } from './actions';


class RegenerateButton extends DropdownSuperClass {
  constructor(props) {
    super(props);
    this.regenerate = this.regenerate.bind(this);
  }

  regenerate(option) {
    const { chatHistory, setChat, sendMsg } = this.props;
    setChat([...chatHistory, ['user', `Make it ${option}`]]);
    sendMsg(`Make it ${option}`);
  }

  render() {
    return (
      <DropdownFilters isOpen={this.state.isDropdownOpen} toggle={this.toggleDropdown} direction="up">
        <TooltipSimple className="px-0" tooltipText="Re-generate">
          <ToggleSec className="dsa-regenerate-btn" noMargin size="sm" IconComponent={() => <i className="fa-kit fa-regenerate-options"></i>} />
        </TooltipSimple>
        <DropdownMenu className="dsa-regenerate-options dsa-dropdown-menu__blue p-0">
          <DropdownItemButton className="p-0">
            <button className="p-4 w-100" type="button" onClick={() => this.regenerate('shorter')}>
              Shorter
            </button>
          </DropdownItemButton>
          <DropdownItemButton className="p-0">
            <button className="p-4 w-100" type="button" onClick={() => this.regenerate('longer')}>
              Longer
            </button>
          </DropdownItemButton>
          <DropdownItemButton className="p-0">
            <button className="p-4 w-100" type="button" onClick={() => this.regenerate('simpler')}>
              Simpler
            </button>
          </DropdownItemButton>
          <DropdownItemButton className="p-0">
            <button className="p-4 w-100" type="button" onClick={() => this.regenerate('more casual')}>
              More casual
            </button>
          </DropdownItemButton>
          <DropdownItemButton className="p-0">
            <button className="p-4 w-100" type="button" onClick={() => this.regenerate('more professional')}>
              More professional
            </button>
          </DropdownItemButton>
        </DropdownMenu>
      </DropdownFilters>
    );
  }
}

RegenerateButton.propTypes = {
  chatHistory: PropTypes.array,
  setChat: PropTypes.func,
  sendMsg: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  chatHistory: selectChatHistory,
});

const mapDispatchToProps = (dispatch) => ({
  setChat: (chatHistory) => dispatch(setChatHistory(chatHistory)),
  sendMsg: (msg) => dispatch(sendMessage(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegenerateButton);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const MailTo = ({ to, blend, passedClassName }) => (
  <a href={`mailto:${to}`} className={classNames('dsa-mailto', { blend }, passedClassName)} target="_blank">{to}</a>
);

MailTo.propTypes = { to: PropTypes.string, blend: PropTypes.bool, passedClassName: PropTypes.string };


export default MailTo;

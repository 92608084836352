// Individual exports for testing
import { put, select, takeLatest, call, spawn } from 'redux-saga/effects';
import { parse, stringify } from 'qs';
import maxBy from 'lodash/maxBy';

import { updateIncluded } from 'actions';
import { displayAlert, showAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { API_BASE, API_IFS_INVITATIONS_BASE_URL, RESOURCE_COMPANIES } from 'containers/App/constants';
import { makeSelectObject } from 'containers/App/selectors';
import { makeSelectAccount, makeSelectProfile, makeSelectUser }
  from 'containers/AuthProvider/selectors';
import { formRequest } from 'utils/Forms/general';
import { extractData } from 'utils/jsonApiExtract';
import { logError } from 'utils/log';
import { resRef, refApiUrl } from 'utils/refs';
import { request } from 'utils/request';
import { pushLocation, updateObjFromApi, waitSelect } from 'utils/sagas';
import { PARTICIPANT_TYPE } from 'containers/Admin/InvestorFeedbackSummit/IfsEmails/constants';
import { preprocessError } from 'utils/Forms/sagas';
import { baseLoginFlow } from 'containers/AuthProvider/saga';

import { INITIAL_LOAD, ATTENDANCE_SENT, FOUNDER_ATTENDANCE_SENT, LOAD_COMPANY, LOAD_REG_COMPANIES } from './constants';
import { setInvitation, setRegistrantCompanies } from './actions';
import { selectIfsInvitation } from './selectors';

export const allIfsEventAgenda = Object.keys(PARTICIPANT_TYPE).map((pType) => `participant_set.ifs.${pType}_event_agenda`);
const ifsInclusions = `${allIfsEventAgenda.join()},user.company.tags,selected_events,areas_of_interest,selected_companies,selected_investors,founder_meets.investor`;

function* initialLoad() {
  const landingQParams = sessionStorage.getItem('landingQueryParams')
    ? parse(sessionStorage.getItem('landingQueryParams'), { ignoreQueryPrefix: true })
    : {};

  try {
    const myProfile = yield waitSelect(makeSelectProfile());
    let invitation = yield fetchInvitationById(landingQParams.invitation);
    if (!invitation || invitation.canceled || invitation.user().id !== myProfile.id) {
      invitation = yield fetchInvitationFromDifferentSrc(myProfile);
      if (!invitation) {
        yield put(showAlert('Invitation not found'));
        return;
      }

      if (invitation.canceled) {
        yield put(displayAlert('Invitation is canceled. If you have questions, please reach out to [support@alchemistaccelerator.com](mailto:support@alchemistaccelerator.com).'));
        return;
      }
    }
    yield put(setInvitation(resRef(invitation)));
    if (!invitation.registration_started && !invitation.registrations_stopped) {
      yield formRequest({ ...resRef(invitation), attributes: { registration_started: true } });
    }
  } catch (e) {
    yield put(showAlert('There was an error accessing your data', COLOR.danger));
    logError(e);
  }
}

function* checkForAccountAndRedirect(action) {
  const user = yield select(makeSelectUser());
  if (user && !user.no_account) {
    const myAccount = yield waitSelect(makeSelectAccount());
    yield updateObjFromApi(myAccount);
    if (action?.type === FOUNDER_ATTENDANCE_SENT) {
      yield completeFounderReg();
    } else {
      yield redirectAuthUser();
    }
    return;
  }
  yield spawn(checkTokenAndRedirect);
}

export function* fetchInvitationById(invitationId) {
  if (!invitationId) {
    return null;
  }
  const invitationReq = yield call(
    request,
    `${API_IFS_INVITATIONS_BASE_URL}/${invitationId}?include=${ifsInclusions}`
  );
  // eslint-disable-next-line prefer-const
  let { inclusions, items: [invitationRef] } = extractData(invitationReq);
  yield put(updateIncluded(inclusions));
  return yield select(makeSelectObject(invitationRef));
}

export function* fetchInvitationFromDifferentSrc(profile) {
  try {
    const invReq = yield request(`${refApiUrl(profile)}/ifs_invitations?include=${ifsInclusions}`);
    const { inclusions, items: invitationRefs } = extractData(invReq);
    yield put(updateIncluded(inclusions));

    const invitationList = [];
    for (let i = 0; i < invitationRefs.length; i++) {
      const invitation = yield select(makeSelectObject(invitationRefs[i]));
      invitationList.push(invitation);
    }
    return maxBy(invitationList, 'invited_at');
  } catch (e) {
    logError(e);
    return null;
  }
}

function* checkTokenAndRedirect() {
  yield baseLoginFlow();
  yield redirectAuthUser();
}

function* redirectAuthUser() {
  const myProfile = yield waitSelect(makeSelectProfile());
  yield pushLocation(myProfile.needsRegistration
    ? '/register-profile/form?flow=ifs'
    : `/ifs/edit-profile/${myProfile.id}`);
}

function* redirectFounder() {
  const myProfile = yield waitSelect(makeSelectProfile());
  yield pushLocation(`/ifs/edit-profile/${myProfile.id}`);
}

function* completeFounderReg() {
  const invitation = yield select(selectIfsInvitation);
  if (invitation) {
    try {
      yield formRequest({ ...resRef(invitation), attributes: { registration_finished: true } });
      yield redirectFounder();
    } catch (err) {
      const { errorJson } = yield preprocessError(err);
      if (errorJson?.errors?.[0]?.detail?.includes('Your selected options are in the past')) {
        yield put(showAlert('Your selected options are in the past, please change your registration.'));
        yield pushLocation('/ifs/attendance');
      }
    }
  } else {
    yield put(showAlert('Invitation not found'));
    yield pushLocation('/ifs/attendance');
  }
}

export function* reloadCompany(action) {
  if (action.companyRef) {
    yield updateObjFromApi(action.companyRef, 'tags');
  }
}

export function* getRegistrantCompanies(ifsId) {
  const qParamsStr = stringify({
    include: 'tags.text',
    sort: '-id',
    fields: {
      [`ifs/${ifsId}/founder_types_registrants_companies_with_feedback_session`]: 'name',
      [RESOURCE_COMPANIES]: 'name,oneliner,description,startup_teamdescription,website,tags',
    },
    page: { size: 0 },
  });
  const companiesReq = yield request(`${API_BASE}/ifs/${ifsId}/founder_types_registrants_companies_with_feedback_session?${qParamsStr}`);
  const { inclusions, items } = extractData(companiesReq);
  yield put(updateIncluded(inclusions));
  return items;
}

function* loadRegistrantCompanies(action) {
  if (action.ifsId) {
    try {
      const registrantCompanies = yield getRegistrantCompanies(action.ifsId);
      yield put(setRegistrantCompanies(registrantCompanies));
    } catch (error) {
      logError(error);
    }
  }
}

export default function* defaultSaga() {
  yield takeLatest(INITIAL_LOAD, initialLoad);
  yield takeLatest(ATTENDANCE_SENT, checkForAccountAndRedirect);
  yield takeLatest(FOUNDER_ATTENDANCE_SENT, checkForAccountAndRedirect);
  yield takeLatest(LOAD_COMPANY, reloadCompany);
  yield takeLatest(LOAD_REG_COMPANIES, loadRegistrantCompanies);
}

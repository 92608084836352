import { createSelector } from 'reselect';
import { parse } from 'qs';

import { fetchObjectFromIncluded } from 'utils/jsonApiExtract';
import { toJS } from 'utils/general';
import { makeSubSelectorToJS } from 'utils/selectors';

const selectRoute = (state) => state.get('router');

export const makeSelectLocation = () => makeSubSelectorToJS(selectRoute, ['location']);

export const makeSelectQueryParam = (qParam) => createSelector(
  makeSelectLocation(),
  (location) => parse(location.search, { ignoreQueryPrefix: true })[qParam],
);

export const selectIncluded = (state) => state.get('included');

export const makeSelectObjectRaw = (objRef) => createSelector(
  selectIncluded,
  (included) => included.getIn([objRef.type, objRef.id.toString()]).toJS()
);

export const makeSelectObject = (objRef) => createSelector(
  selectIncluded,
  (included) => fetchObjectFromIncluded(objRef, included),
);

export const makeSelectObjectFromRefSelector = (refSelector) => createSelector(
  refSelector,
  selectIncluded,
  (objRef, included) => objRef && fetchObjectFromIncluded(toJS(objRef), included),
);

export const makeSelectObjectArrayFromRefArray = (refsSelector, fallbackTo = []) => createSelector(
  refsSelector,
  selectIncluded,
  (refs, included) => refs ? toJS(refs.map((ref) => fetchObjectFromIncluded(ref, included))) : fallbackTo,
);

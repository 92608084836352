import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonsHolder from 'components/Buttons/ButtonsHolder';
import LoadingSpinner from 'components/LoadingSpinner';
import { Text } from 'components/Text/Body';


const LoadingTextBase = ({ text, textColor }) => (
  <ButtonsHolder className="py-5">
    <LoadingSpinner />
    <Text className={classNames('mt-5', textColor && `_color-${textColor}_`)}>{text}</Text>
  </ButtonsHolder>
);

LoadingTextBase.propTypes = { text: PropTypes.string, textColor: PropTypes.string };


export default LoadingTextBase;

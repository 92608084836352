/*
 *
 * Verification actions
 *
 */
import { makeFormActionFunction } from 'utils/Forms/actions';

import { EMAIL_RESENT, RESEND_EMAIL, RESET_PASSWORD, REQUEST_PASSWORD_RESET, SHOW_WAIT_FOR_EMAIL, PROCESS_NEW_PASSWORD, NEW_PASSWORD_PROCESSED, ENABLE_NEW_PASSWORD_BTN, DISABLE_NEW_PASSWORD_BTN } from './constants';

export const resetPassword = makeFormActionFunction(RESET_PASSWORD);
export const requestPasswordReset = makeFormActionFunction(REQUEST_PASSWORD_RESET);
export const showWaitForEmail = () => ({ type: SHOW_WAIT_FOR_EMAIL });
export const resendEmail = () => ({ type: RESEND_EMAIL });
export const emailResent = () => ({ type: EMAIL_RESENT });

export const processNewPassword = () => ({ type: PROCESS_NEW_PASSWORD });
export const setNewPasswordIsProcessed = () => ({ type: NEW_PASSWORD_PROCESSED });
export const enableNewPasswordBtn = () => ({ type: ENABLE_NEW_PASSWORD_BTN });
export const disableNewPasswordBtn = () => ({ type: DISABLE_NEW_PASSWORD_BTN });

import React from 'react';
import copy from 'copy-to-clipboard';
import { useStore } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { fetchBeBranch } from 'utils/dev';
import { toJS } from 'utils/general';

const isStaging = window.location.host.includes('staging');
const isLocalhost = window.location.host.includes('localhost:');

const BranchWatermark = () => {
  if (!isStaging && !isLocalhost) {
    return null;
  }
  const store = useStore();
  const getStateJSON = () => {
    window.state = toJS(store.getState());
    return JSON.stringify(toJS(store.getState()));
  };
  const copyStateOnCtrlF11 = (e) => {
    if (e.ctrlKey && e.keyCode === 122 && copy(getStateJSON())) {
      window.alert('State Json Copied');
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', copyStateOnCtrlF11, false);
    return () => document.removeEventListener('keydown', copyStateOnCtrlF11, false);
  });
  if (!isStaging) {
    return null;
  }
  const [beBranch, setBeBranch] = React.useState(null);
  React.useEffect(() => {
    if (!beBranch) fetchBeBranch(setBeBranch);
  });
  if (['HEAD', 'master'].includes(window.GIT_BRANCH) && ['HEAD', 'master', 'Missing Info', null].includes(beBranch)) {
    return null;
  }

  return (
    <div className="branch-watermark">
      <b>FE branch:</b> {window.GIT_BRANCH}
      {beBranch &&
        <> -- <b>BE branch</b>: {beBranch}</>}
      {' -- '}
      <CopyToClipboard
        text="Failed To Copy"
        onCopy={() => {
          copy(getStateJSON());
          window.alert('State JSON Copied!');
        }}
      >
        <button>Copy State JSON</button>
      </CopyToClipboard>
    </div>);
};

export default BranchWatermark;

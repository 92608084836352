import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';

const ClassNameExtendableHoC = (Component, defaultClassName, classMapper, blockProps = []) => {
  const Wrapped = ({ className, ...props }) => (
    <Component
      className={classNames(defaultClassName, classMapper && classMapper(props), className)}
      {...omit(props, blockProps)}
    />);

  Wrapped.propTypes = {
    className: PropTypes.string,
  };
  return Wrapped;
};

export default ClassNameExtendableHoC;

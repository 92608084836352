import {
  DEIMPERSONATE,
  FINISH_LOGOUT,
  FULL_ACCOUNT_DATA_LOADED,
  FULL_COMPANIES_DATA_LOADED,
  IMPERSONATE_USER,
  INITIAL_CHECK_TOKEN,
  LOAD_HINTS,
  LOAD_PROFILE,
  SET_HINTS,
  SET_IS_AUTHENTICATED,
  SET_IS_CHECKING,
  SET_PROFILE,
  SET_TOKEN,
  SET_UPCOMING_CLASS,
  SET_UPCOMING_CLASSX,
  SET_USER,
  SHOW_HINT,
  START_LOGOUT,
  UPDATE_SITE_SETTINGS,
  TRACK_LMS_PAGE,
  UPDATE_SIGNATURES,
  SET_ACTIVE_AX_CLASSES,
  SET_CLASS_FROM_LINKEDIN_SIGNIN,
  SET_COMPANY_THEME,
  SET_LISTS_FOR_MODAL_LOADING,
  SET_LISTS_FOR_MODAL_LOADED,
} from './constants';

export const updateSiteSettings = (siteSettings) => ({ type: UPDATE_SITE_SETTINGS, siteSettings });
export const updateSignatures = (signatures) => ({ type: UPDATE_SIGNATURES, signatures });

export const setUser = (user) => ({ type: SET_USER, user });

export const setToken = () => ({ type: SET_TOKEN });

export const setIsAuthenticated = (isAuthenticated) => ({ type: SET_IS_AUTHENTICATED, isAuthenticated });

export const setIsChecking = (isChecking) => ({ type: SET_IS_CHECKING, isChecking });

export const loadProfileAction = (profileId) => ({ type: LOAD_PROFILE, profileId });

export const loadHintsAction = () => ({ type: LOAD_HINTS });

export const setHints = (hintsData) => ({ type: SET_HINTS, hintsData });

export const showHintAction = () => ({ type: SHOW_HINT });

export const setProfile = (profileData) => ({ type: SET_PROFILE, profileData });

export const logout = () => ({ type: START_LOGOUT });
export const finishLogout = () => ({ type: FINISH_LOGOUT });

export const impersonateUser = (uid) => ({ type: IMPERSONATE_USER, uid });
export const deimpersonate = () => ({ type: DEIMPERSONATE });

export const initialCheckToken = () => ({ type: INITIAL_CHECK_TOKEN });
export const fullAccountDataLoaded = () => ({ type: FULL_ACCOUNT_DATA_LOADED });
export const fullCompaniesDataLoaded = () => ({ type: FULL_COMPANIES_DATA_LOADED });
export const setUpcomingClass = (upcomingClassRef) => ({ type: SET_UPCOMING_CLASS, upcomingClassRef });
export const setUpcomingClassX = (upcomingClassXRef) => ({ type: SET_UPCOMING_CLASSX, upcomingClassXRef });
export const setActiveAxClasses = (activeAxClasses) => ({ type: SET_ACTIVE_AX_CLASSES, activeAxClasses });
export const setClassFromLinkedinSignin = (classFromLinkedinSignin) => ({ type: SET_CLASS_FROM_LINKEDIN_SIGNIN, classFromLinkedinSignin });

export const trackLMSPage = () => ({ type: TRACK_LMS_PAGE });
export const setCompanyTheme = (theme) => ({ type: SET_COMPANY_THEME, theme });

export const setListsForModalLoading = () => ({ type: SET_LISTS_FOR_MODAL_LOADING });
export const setListsForModalLoaded = () => ({ type: SET_LISTS_FOR_MODAL_LOADED });

import { fromJS } from 'immutable';

import { getDecodedToken } from 'utils/auth';
import { changeTheme } from 'utils/theme';

import {
  FULL_ACCOUNT_DATA_LOADED,
  FINISH_LOGOUT,
  SET_IS_AUTHENTICATED,
  SET_IS_CHECKING,
  SET_HINTS,
  SET_PROFILE,
  SET_USER,
  UPDATE_SITE_SETTINGS,
  START_LOGOUT,
  SET_UPCOMING_CLASS,
  SET_UPCOMING_CLASSX,
  SET_TOKEN, FULL_COMPANIES_DATA_LOADED, UPDATE_SIGNATURES, SET_ACTIVE_AX_CLASSES, SET_CLASS_FROM_LINKEDIN_SIGNIN, SET_COMPANY_THEME,
  LS_KEY_THEME_PRIMARY_COLOR, LS_KEY_THEME_LINK_COLOR, LS_KEY_THEME_TYPE, SET_LISTS_FOR_MODAL_LOADING, SET_LISTS_FOR_MODAL_LOADED,
} from './constants';

// TODO: Check initial state(not all fields included to initial state)
const initialState = fromJS({
  authenticated: false,
  checking: false,
  upcomingClass: null,
  upcomingClassX: null,
  activeAxClasses: [],
  siteSettings: {},
  signatures: [],
  classFromLinkedinSignin: {},
  listsForModalLoading: false,
});

function authProviderReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SITE_SETTINGS:
      return state.mergeIn(['siteSettings'], action.siteSettings);
    case UPDATE_SIGNATURES:
      return state.set('signatures', action.signatures);
    case SET_USER:
      return state.set('user', action.user);
    case SET_TOKEN:
      return state.set('token', localStorage.token).set('user', getDecodedToken());
    case SET_IS_AUTHENTICATED:
      return state.set('authenticated', action.isAuthenticated);
    case SET_IS_CHECKING:
      return state.set('checking', action.isChecking);
    case SET_PROFILE:
      return state.set('profile', action.profileData);
    case SET_HINTS:
      return state.set('hints', action.hintsData);
    case FULL_ACCOUNT_DATA_LOADED:
      return state.set('accountLoaded', true);
    case FULL_COMPANIES_DATA_LOADED:
      return state.set('companiesDataLoaded', true);
    case START_LOGOUT:
      return state.set('loggingOut', true);
    case SET_UPCOMING_CLASS:
      return state.set('upcomingClass', action.upcomingClassRef);
    case SET_UPCOMING_CLASSX:
      return state.set('upcomingClassX', action.upcomingClassXRef);
    case SET_ACTIVE_AX_CLASSES:
      return state.set('activeAxClasses', action.activeAxClasses);
    case SET_CLASS_FROM_LINKEDIN_SIGNIN:
      return state.set('classFromLinkedinSignin', action.classFromLinkedinSignin);
    case FINISH_LOGOUT:
      return initialState;
    case SET_COMPANY_THEME:
      localStorage.setItem(LS_KEY_THEME_PRIMARY_COLOR, action.theme.themePrimaryColor);
      localStorage.setItem(LS_KEY_THEME_LINK_COLOR, action.theme.themeLinkColor);
      localStorage.setItem(LS_KEY_THEME_TYPE, action.theme.themeType);
      changeTheme();
      return state;
    case SET_LISTS_FOR_MODAL_LOADING:
      return state.set('listsForModalLoading', true);
    case SET_LISTS_FOR_MODAL_LOADED:
      return state.set('listsForModalLoading', false);
    default:
      return state;
  }
}

export default authProviderReducer;

import React from 'react';

import DocPager from 'components/DocPager/DocPager';
import LiVerticalDivider from 'components/SideBar/LiVerticalDivider';
import { HeadingLarge } from 'components/Text/Headings';
import DesignSystemMain from 'designSystemDocs/Sections/DesignSystemMain';
import BetaIcon from 'components/Form/BetaIcon/BetaIcon';

const DesignSystemBetaIcon = () => (
  <DesignSystemMain>
    <DocPager>
      <HeadingLarge>Beta Icon</HeadingLarge>
      <LiVerticalDivider />
      <BetaIcon />
    </DocPager>
  </DesignSystemMain>
);

export default DesignSystemBetaIcon;

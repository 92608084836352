import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';
import EmptyListMessage from 'components/BasicList/EmptyListMessage';


const NoResultsMessage = DefaultPropsHoC(
  EmptyListMessage,
  {
    title: 'No results found.',
    text: 'Your search returned no results. Try shortening or rephrasing your search.',
  }
);

export default NoResultsMessage;

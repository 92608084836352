import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ButtonBase from './ButtonBase';

const ButtonLink = DefaultPropsHoC(ButtonBase, { color: 'link' });
const ButtonLinkSm = DefaultPropsHoC(ButtonBase, { color: 'link', size: 'sm' });
const ButtonLinkLg = DefaultPropsHoC(ButtonBase, { color: 'link', size: 'lg' });


export {
  ButtonLink,
  ButtonLinkSm,
  ButtonLinkLg,
};

import keys from 'lodash/keys';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';

import { resRefForArrayOrObj } from './refs';

export const emptyStringsToNull = (str) => str === '' ? null : str;

/**
 * Convert FormData To JSONApi
 * @param includedObj
 * @param formData
 * @returns {{relationships: *, attributes: *, id: (*), type: *}}
 */
export const formDataToJApi = (includedObj, formData) => ({
  type: includedObj.type,
  id: includedObj.id > 0 ? includedObj.id : undefined,
  isDeleted: formData.isDeleted,
  attributes: mapValues(pick(formData, keys(includedObj.attributes)), emptyStringsToNull),
  relationships: getRelationships(formData, includedObj),
  ...(formData.lid ? { lid: formData.lid } : {}),
});

function getRelationships(formData, includedObj) {
  const relationKeys = keys(includedObj.relationships);
  const onlySchemaFields = pick(formData, relationKeys);
  const onlyFilled = omitBy(onlySchemaFields, (item) => isEmpty(item) && item !== null && !Array.isArray(item));

  return mapValues(onlyFilled, (relationValue, relKey) => ({
    data: relationValue
      ? resRefForArrayOrObj(isFunction(relationValue) ? relationValue() : relationValue)
      : (Array.isArray(includedObj.relationships[relKey]) && []) || relationValue,
  }));
}

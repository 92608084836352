import { takeLatest, put } from 'redux-saga/effects';

import { API_GDPR_REQUESTS_BASE_URL } from 'containers/App/constants';
import { preprocessError } from 'utils/Forms/sagas';
import { logError } from 'utils/log';
import { post } from 'utils/request';

import { SEND_CANCELLATION, SEND_CONFIRMATION } from './constants';
import { confirmationSent, cancellationSent } from './actions';

function* confirmGdprRequest(isCancel, { code }) {
  const baseUrl = `${API_GDPR_REQUESTS_BASE_URL}/${code}`;
  try {
    yield post(isCancel ? `${baseUrl}/cancel` : baseUrl);
    yield put(isCancel ? cancellationSent() : confirmationSent());
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    logError(errorJson);
    if (isCancel && errorJson && errorJson.errors && errorJson.errors[0]
      && errorJson.errors[0].detail.match(/This GDPR request has been already cancelled/)) {
      yield put(cancellationSent());
    } else {
      yield put(isCancel ? cancellationSent(true) : confirmationSent(true));
    }
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(SEND_CANCELLATION, confirmGdprRequest, true);
  yield takeLatest(SEND_CONFIRMATION, confirmGdprRequest, false);
}

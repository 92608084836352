import { formatStr } from 'utils/general';

import {
  SET_SELECTED_OBJECT, BROWSE_SELECTED_OBJECT,
  INITIAL_LOAD, LOAD_CONTENT, LOAD_CONTENT_SUCCESS, LOAD_CONTENT_ERROR, UNLIST_ELEMENT,
  LOAD_NEXT_PAGE, LOAD_NEXT_PAGE_SUCCESS, SET_HAS_MORE, SET_TOTAL_COUNT, SET_MAX_PAGE,
  SET_TEMP_FILTER, SET_ADV_FILTER, REMOVE_TEMP_FILTER, REMOVE_ADV_FILTER, SET_SEARCH, SET_SEARCH_OPERATOR,
  SET_CATEGORY, SET_FILTERS_AND_SORT, TOGGLE_SORT,
  APPLY_TEMP_FILTERS, REVERT_TEMP_FILTERS, SET_ADV_FILTERS_OPERATOR,
  APPLY_TEMP_FILTERS_GROUP, REVERT_TEMP_FILTERS_GROUP, CLEAR_FILTERS_GROUP,
  CLOSE_ADV_FILTER_ELEMENTS, OPEN_ADV_FILTER_ELEMENT, SET_IS_SEARCH_FOCUSED,
  TOGGLE_ADV_FILTERS_WINDOW, TOGGLE_FILTERS_UI_SECTION,
  MARK_OBJECT, MARK_OBJECTS, MARK_ALL, CLEAR_MARKS, CLEAR_FILTERS, CLEAR_FILTERS_KEY, APPLY_TEMP_FILTERS_KEY, SET_SORT, SET_MAX_PAGE_CONTENT,
  SET_FILTER_GROUPS_OPERATORS,
  SET_INDIVIDUAL_OPTIONS_OPERATORS,
} from './constants';

/**
 * setSelectedObject:   set The selectedObject, for when a user clicks an element of a list
 * loadNextPage:        This action starts the saga to load another page
 * loadContent:         Load the content, this action should start the request saga
 * contentLoaded:       Dispatched when the objects are loaded by the request saga
 * contentLoadingError: Dispatched when loading the content fails
 *
 * @param  {string} page  The name of the filtered page for which the actions are for
 *
 * @return {object}       An object with the content load actions for a filtered page
 */
export function generateLoadedContentActions(page) {
  return {
    setSelectedObject: (selectedObject, fromSaga) => (
      { type: formatStr(SET_SELECTED_OBJECT, page), selectedObject, fromSaga }),
    browseSelectedObject: (change) => ({ type: formatStr(BROWSE_SELECTED_OBJECT, page), change }),
    markObject: (markedObjectRef, unselect) => ({ type: formatStr(MARK_OBJECT, page), markedObjectRef, unselect }),
    markAll: () => ({ type: formatStr(MARK_ALL, page) }),
    markObjects: (markedObjectsRefs) => ({ type: formatStr(MARK_OBJECTS, page), markedObjectsRefs }),
    clearMarks: () => ({ type: formatStr(CLEAR_MARKS, page) }),
    loadNextPage: () => ({ type: formatStr(LOAD_NEXT_PAGE, page) }),
    loadNextPageSuccess: (objects) => ({ type: formatStr(LOAD_NEXT_PAGE_SUCCESS, page), objects }),
    setHasMore: (hasMore) => ({ type: formatStr(SET_HAS_MORE, page), hasMore }),
    setTotalCount: (totalCount) => ({ type: formatStr(SET_TOTAL_COUNT, page), totalCount }),
    loadContent: () => ({ type: formatStr(LOAD_CONTENT, page) }),
    contentLoaded: (objects) => ({ type: formatStr(LOAD_CONTENT_SUCCESS, page), objects }),
    contentLoadingError: (error) => ({ type: formatStr(LOAD_CONTENT_ERROR, page), error }),
    initialLoad: (routeParams) => ({ type: formatStr(INITIAL_LOAD, page), routeParams }),
    unlistElement: (obj) => ({ type: formatStr(UNLIST_ELEMENT, page), obj }),
    setContentMaxPage: (pageNumber) => ({ type: formatStr(SET_MAX_PAGE_CONTENT, page), pageNumber }),
  };
}

/**
 * setSearch:         set the text search
 * setCategory:       set the category from the nav-tabs below the search (e.g.: roles for people)
 * setTempFilter:     set the filters before clicking "Apply Filters"
 * removeTempFilter:  remove a temporary filter
 * removeAdvFilter:   remove an advanced filter and it's temporary counterpart
 * setFiltersAndSort: set all applied filters (used after parsing location.search on initialLoad)
 * applyTempFilters:  move the temporary filters to applied filters when clicking "Apply Filters" (should be set to
 *                    trigger the loadContent action that riggers the saga to load said content from the API)
 * toggleSort:        toggle the sort of a certain sortKey (if another sortKey is set, it's removed)
 * setMaxPage:        set the number of the last page that was fetched
 *
 * @param  {string} page  The name of the filtered page for which the actions are for
 *
 * @return {object}       An object with the content load actions for a filtered page
 */
export function generateFiltersActions(page) {
  return {
    setSearch: (text) => ({ type: formatStr(SET_SEARCH, page), text }),
    setCategory: (category) => ({ type: formatStr(SET_CATEGORY, page), category }),
    setTempFilter: (field, value, isContains) => ({
      type: formatStr(SET_TEMP_FILTER, page), field, value, isContains,
    }),
    setAdvFilter: (field, value, isContains) => ({
      type: formatStr(SET_ADV_FILTER, page), field, value, isContains,
    }),
    removeTempFilter: (field, value) => ({ type: formatStr(REMOVE_TEMP_FILTER, page), field, value }),
    removeAdvFilter: (field, value) => ({ type: formatStr(REMOVE_ADV_FILTER, page), field, value }),
    setFiltersAndSort: (filtersObj, sort) => ({ type: formatStr(SET_FILTERS_AND_SORT, page), filtersObj, sort }),
    applyTempFilters: () => ({ type: formatStr(APPLY_TEMP_FILTERS, page) }),
    applyTempFiltersGroup: (group) => ({ type: formatStr(APPLY_TEMP_FILTERS_GROUP, page), group }),
    applyTempFiltersKey: (key, group) => ({ type: formatStr(APPLY_TEMP_FILTERS_KEY, page), key, group }),
    revertTempFilters: () => ({ type: formatStr(REVERT_TEMP_FILTERS, page) }),
    revertTempFiltersGroup: (group) => ({ type: formatStr(REVERT_TEMP_FILTERS_GROUP, page), group }),
    clearFiltersGroup: (group) => ({ type: formatStr(CLEAR_FILTERS_GROUP, page), group }),
    clearFiltersKey: (key, group) => ({ type: formatStr(CLEAR_FILTERS_KEY, page), key, group }),
    clearFilters: () => ({ type: formatStr(CLEAR_FILTERS, page) }),
    setSort: (sortKey, order) => ({ type: formatStr(SET_SORT, page), sortKey, order }),
    toggleSort: (sortKey, defaultOrder) => ({ type: formatStr(TOGGLE_SORT, page), sortKey, defaultOrder }),
    setMaxPage: (pageNumber) => ({ type: formatStr(SET_MAX_PAGE, page), pageNumber }),
    setAdvFiltersOperator: (operator) => ({ type: formatStr(SET_ADV_FILTERS_OPERATOR, page), operator }),
    setSearchOperator: (operator) => ({ type: formatStr(SET_SEARCH_OPERATOR, page), operator }),
    setFilterGroupsOperators: (operatorsObj) => ({ type: formatStr(SET_FILTER_GROUPS_OPERATORS, page), operatorsObj }),
    setIndividualOptionsOperators: (operatorsObj) => ({ type: formatStr(SET_INDIVIDUAL_OPTIONS_OPERATORS, page), operatorsObj }),
  };
}

export function generateFiltersUiActions(page) {
  return {
    openAdvFilterElement: (advFilter) => ({ type: formatStr(OPEN_ADV_FILTER_ELEMENT, page), advFilter }),
    closeAdvFilterElements: () => ({ type: formatStr(CLOSE_ADV_FILTER_ELEMENTS, page) }),
    toggleAdvFiltersWindow: () => ({ type: formatStr(TOGGLE_ADV_FILTERS_WINDOW, page) }),
    toggleFiltersUiSection: (section) => ({ type: formatStr(TOGGLE_FILTERS_UI_SECTION, page), section }),
    setIsSearchFocused: (isSearchFocused) => ({ type: formatStr(SET_IS_SEARCH_FOCUSED, page), isSearchFocused }),
  };
}

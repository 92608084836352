import { DEFAULT_LINK_COLOR_HEX, DEFAULT_PRIMARY_ALCHEMIST_COLOR_HEX, THEME_TYPES } from 'containers/Forms/Company/constants';

import { LS_KEY_THEME_TYPE, LS_KEY_THEME_PRIMARY_COLOR, LS_KEY_THEME_LINK_COLOR } from '../containers/AuthProvider/constants';

export function changeTheme() {
  const root = document.documentElement;
  const themeType = localStorage.getItem(LS_KEY_THEME_TYPE);
  const primaryColor = localStorage.getItem(LS_KEY_THEME_PRIMARY_COLOR);
  const linkColor = localStorage.getItem(LS_KEY_THEME_LINK_COLOR);

  if (primaryColor && linkColor && themeType && themeType?.toString() === THEME_TYPES.custom.toString()) {
    root.style.setProperty('--primary', `#${primaryColor}`);
    root.style.setProperty('--link-color', `#${linkColor}`);
  } else {
    changeThemeToDefault();
  }
}

export function changeThemeToDefault() {
  const root = document.documentElement;
  root.style.setProperty('--primary', DEFAULT_PRIMARY_ALCHEMIST_COLOR_HEX);
  root.style.setProperty('--link-color', DEFAULT_LINK_COLOR_HEX);
}

export function resetLocalStorageTheme() {
  localStorage.setItem(LS_KEY_THEME_TYPE, null);
  localStorage.setItem(LS_KEY_THEME_PRIMARY_COLOR, null);
  localStorage.setItem(LS_KEY_THEME_LINK_COLOR, null);
}

import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import OnePager from 'components/OnePager/OnePager';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';

import { LOAD_CONNECTION } from './constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectConnection, selectErrorConnStatus, selectIsConnAlreadyAccepted, selectIsConnAlreadyDenied } from './selectors';


class ConnectionAccept extends React.Component {
  componentDidMount() {
    const { location } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    this.registrationRef = queryParams.ref;
  }

  render() {
    // const from = this.props.connection && this.props.connection.by_user && this.props.connection.by_user();
    // const to = this.props.connection && this.props.connection.user && this.props.connection.user();
    const { errorConnStatus, isConnAlreadyAccepted, isConnAlreadyDenied, location, connection } = this.props;
    let title = connection === false ? 'Do you want to accept the connection?' : 'Thank you for accepting!';
    if (errorConnStatus) {
      title = 'We are sorry, something went wrong!';
    } else if (isConnAlreadyAccepted) {
      title = 'You have already accepted this request.';
    } else if (isConnAlreadyDenied) {
      title = 'You have already denied this request.';
    }

    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const connectionCode = queryParams.code;
    const connectionId = queryParams.id;

    return (
      <OnePager title={title}>
        {!connection && !errorConnStatus && !isConnAlreadyAccepted && !isConnAlreadyDenied &&
          <ButtonPrim onClick={() => this.props.loadConnectionInfo(connectionId, connectionCode)}>Accept</ButtonPrim>
        }

        {errorConnStatus
          ? (
            <p>
              Can you please forward the email to <a className="dsa-mailto" href="mailto:support@alchemistaccelerator.com" target="_blank">support@alchemistaccelerator.com</a> and request an introduction? We will send the introduction email.
            </p>
          )
          : connection && !isConnAlreadyDenied && (
            <p>
              {isConnAlreadyAccepted ? '' : 'We just sent you the introduction email. '}If you did not receive an introduction email, please forward the email to <a className="dsa-mailto" href="mailto:support@alchemistaccelerator.com" target="_blank">support@alchemistaccelerator.com</a> requesting an introduction again and we will resend the introduction email.
            </p>
          )
        }
        {this.props.connection && this.props.connection.type === 'CM' &&
          <>
            <p>
              Also, if you want to receive more detailed information on our founders when receiving requests, please
              register a profile using the button below.
            </p>
            <div>
              <Link
                to={`/register-profile${this.registrationRef ? `?ref=${this.registrationRef}` : ''}`}
                className="dsa-link-button primary"
              >Register a Profile
              </Link>
            </div>
          </>}
        <div className="mt-7">
          <a className="dsa-link-button link" href="http://alchemistaccelerator.com/portfolio/">
            Check out the Alchemist Portfolio
          </a>
        </div>
      </OnePager>
    );
  }
}

ConnectionAccept.propTypes = {
  location: PropTypes.any,
  connection: PropTypes.any,
  loadConnectionInfo: PropTypes.any,
  isConnAlreadyAccepted: PropTypes.bool,
  isConnAlreadyDenied: PropTypes.bool,
  errorConnStatus: PropTypes.bool,
};


export function mapDispatchToProps(dispatch) {
  return {
    loadConnectionInfo: (connectionId, connectionCode) => dispatch({
      type: LOAD_CONNECTION, connectionId, connectionCode, status: true,
    }),
  };
}

const mapStateToProps = createStructuredSelector({
  connection: makeSelectConnection(),
  isConnAlreadyAccepted: selectIsConnAlreadyAccepted,
  isConnAlreadyDenied: selectIsConnAlreadyDenied,
  errorConnStatus: selectErrorConnStatus,
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'peopleConnection', reducer });
const withSaga = injectSaga({ key: 'peopleConnection', saga });
export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ConnectionAccept);

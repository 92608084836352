import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RoundedAlchemistLogo from 'components/Brand/RoundedAlchemistLogo';
import CopyButton from 'components/Buttons/Specific/CopyButton';
import { showAlert as showAlertAction } from 'containers/AlertBox/actions';
import RichTextDisplay from 'components/Text/EscapedHtmlText';

import { selectChatHistory } from './selectors';
import RegenerateButton from './RegenerateButton';


const Message = ({ role, text, showAlert, chatHistory, index }) => {
  const messageClass = role === 'user' ? 'dsa-user-message' : 'dsa-assistant-message';
  const isMsgFromAssistant = role === 'assistant';

  return (
    <div className="mb-6">
      <div className={classNames(messageClass)}>
        {isMsgFromAssistant && <RoundedAlchemistLogo />}
        <p className={classNames('mb-0', { 'mt-3 ms-3': isMsgFromAssistant })}>
          {isMsgFromAssistant
            ? <RichTextDisplay>{text}</RichTextDisplay>
            : text
          }
        </p>
      </div>
      {isMsgFromAssistant &&
      <div className="dsa-buttons-holder">
        {chatHistory && index === (chatHistory.length - 1) && <RegenerateButton />}
        <CopyButton
          buttonId="emailTemplate"
          tooltipText="Copy"
          showTooltip
          text={text}
          onCopy={() => showAlert('Email template is copied!')}
          isText
        />
      </div>
      }
    </div>
  );
};

Message.propTypes = {
  role: PropTypes.string,
  text: PropTypes.string,
  showAlert: PropTypes.func,
  chatHistory: PropTypes.array,
  index: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  chatHistory: selectChatHistory,
});

const mapDispatchToProps = (dispatch) => ({
  showAlert: (txt) => dispatch(showAlertAction(txt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);

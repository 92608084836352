import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { errorClassNames } from 'utils/Forms/general';
import { ENGLISH_REGEX } from 'utils/constants';

import FullFieldHoc from '../HoC/FullFieldHoc';
import SimpleFieldHoc from '../HoC/SimpleFieldHoc';
import reduxFormProps from '../reduxFormProps';
import SimplifyReduxFormInput from '../HoC/SimplifyReduxFormInput';

const InputWrapper = ({ afterIcon, children }) => afterIcon
  ? (
    <InputGroup>
      {children}
    </InputGroup>
  )
  : (children);

InputWrapper.propTypes = {
  afterIcon: PropTypes.object,
  children: PropTypes.any,
};

export const ReactstrapInputAdapter = ({
  input, inputProps, meta, className = '', label, placeholder,
  type, disabled, autocomplete, afterIcon, onAfterIconClick, afterContent, inputClassName,
  disableEmoticons, englishOnlyCharacters, passedOnChange, innerRef, ...props
}) => {
  const { checked, name, onBlur, onChange, onDragStart, onDrop, onFocus, value } = input;
  const [isFocused, setIsFocused] = useState(false);

  const changeHandler = (e) => {
    const inputValue = e.target.value;
    const emojiRegex = /\p{Extended_Pictographic}/gu;

    if (disableEmoticons && emojiRegex.test(inputValue)) {
      const sanitizedValue = inputValue.replace(emojiRegex, '');
      return onChange(sanitizedValue);
    }

    if (['email', 'login'].includes(name) && e.target.value) {
      return onChange(e.target.value.trim());
    }
    const returnFunc = passedOnChange || onChange;
    return returnFunc(e);
  };

  const onKeyDownHandler = (e) => {
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }

    if (englishOnlyCharacters) {
      if (!ENGLISH_REGEX.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <InputWrapper afterIcon={afterIcon}>
      <Input
        innerRef={innerRef}
        checked={checked}
        name={name}
        onBlur={(e) => {
          onBlur(e);
          setIsFocused(false);
        }}
        onChange={changeHandler}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onFocus={(e) => {
          onFocus(e);
          setIsFocused(true);
        }}
        value={value}
        // onKeyDown={props.onKeyDown ? props.onKeyDown : undefined}
        onKeyDown={onKeyDownHandler}
        autoComplete={autocomplete}
        placeholder={placeholder || label}
        type={type}
        disabled={disabled}
        className={errorClassNames(`${className} ${afterIcon ? 'br-none' : ''}`, meta)}
        spellCheck
        rows={props.rows}
      />
      {afterIcon &&
        <InputGroupText onClick={onAfterIconClick} className={errorClassNames(isFocused ? '_focused_' : '', meta)}>
          <FontAwesomeIcon icon={afterIcon} />
        </InputGroupText>}
    </InputWrapper>
  );
};

ReactstrapInputAdapter.propTypes = {
  ...reduxFormProps,
  inputProps: PropTypes.object,
  colSize: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  popoverText: PropTypes.string,
  placement: PropTypes.string,
  popoverWidth: PropTypes.string,
  disableEmoticons: PropTypes.bool,
  englishOnlyCharacters: PropTypes.bool,
  afterIcon: PropTypes.object,
  onAfterIconClick: PropTypes.func,
  innerRef: PropTypes.object,
  passedOnChange: PropTypes.func,
};

export const RenderField = FullFieldHoc(ReactstrapInputAdapter, { type: 'text', colSize: 'col-50', className: '' });
export const RenderInputField = SimpleFieldHoc(ReactstrapInputAdapter);
export const NonFormInput = SimplifyReduxFormInput(RenderInputField);
export const NonFormField = SimplifyReduxFormInput(RenderField);

import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';

const SectionTitle = ClassNameExtendableHoC('h2', 'dsa-section-title', ({ isSectionTitle }) => ([
  { 'px-6 pt-4': isSectionTitle },
]), ['isSectionTitle']);
const Title = ClassNameExtendableHoC('h4', 'dsa-title');
const TitleMd = ClassNameExtendableHoC('h4', 'dsa-title _sm-md_');
const SubTitle = ClassNameExtendableHoC('p', 'dsa-title__sub');
const SubTitleAmple = ClassNameExtendableHoC('p', 'dsa-title__sub dsa-ample');
const TitleUpper = ClassNameExtendableHoC('h3', 'dsa-title__upper');
const TitleLargeUpper = ClassNameExtendableHoC('h3', 'dsa-title__upper _large_');
const TitleLarge = ClassNameExtendableHoC('h3', 'dsa-title _large_');
const TextUpperMd = ClassNameExtendableHoC('span', 'dsa-title__upper _md_');
const SecTitle = ClassNameExtendableHoC('small', 'dsa-sec-title');

export { SectionTitle, Title, TitleMd, SubTitle, TitleUpper, TitleLargeUpper, SecTitle, SubTitleAmple, TextUpperMd, TitleLarge };

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import MainContent from 'components/MainContent';
import OnboardingContent from 'components/Onboarding/OnboardingContent';

import ManageTeam from './Loadable';


const ManageTeamPage = (props) => (
  <MainContent>
    <Helmet>
      <title>Manage Team</title>
      <meta name="description" content="Page to manage team" />
    </Helmet>
    <OnboardingContent>
      <ManageTeam companyId={props.match.params.companyId} />
    </OnboardingContent>
  </MainContent>);

ManageTeamPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ companyId: PropTypes.string.isRequired }).isRequired }).isRequired,
};
export default ManageTeamPage;

import jwtDecode from 'jwt-decode';

import { logError } from 'utils/log';
import { FLOW_TYPE } from 'containers/AuthProcess/constants';

import { getLinkedInAuthLink } from './linkedIn';

export const getDecodedToken = () => {
  try {
    return localStorage.token && jwtDecode(localStorage.token);
  } catch (error) {
    logError(`invalid token:${error}`);
  }
  return {};
};

if (window.location.origin.includes('://localhost:')) {
  window.jwtDecode = (token, ...args) => jwtDecode(token || localStorage.token, ...args);
}

export const tokenRoleStartsWithAny = (keywords, token = getDecodedToken()) => token?.roles?.filter((principal) => keywords.some((keyword) => principal.startsWith(keyword)));

export const tokenActiveDDVariationsRoles = () => tokenRoleStartsWithAny([
  'class_demo', 'class_website', 'dry_run', 'customer_summit',
]);


export const reloginPath = (decodedToken, redirectPath = '', queryParams) => {
  const token = decodedToken || getDecodedToken();
  const path = redirectPath === '' ? `/signin${queryParams || ''}` : redirectPath;
  return localStorage.lastLoginFromForm || !token || token.from_login_form
    ? path
    : getLinkedInAuthLink(FLOW_TYPE.standard);
};

export const setTokenInLocalStorage = (token) => {
  localStorage.token = token;
  const decodedToken = jwtDecode(token);
  if (token) {
    localStorage.lastLoginFromForm = decodedToken && decodedToken.from_login_form ? 'true' : '';
  }
};

export function ssoTokenIsSetAndCurrent() {
  const token = getSsoTokenFromCookie();

  if (token) {
    const decodedToken = jwtDecode(token);
    if (Date.now() < decodedToken.exp * 1000) return decodedToken;
  }
  return false;
}

export function getSsoTokenFromCookie() {
  return document.cookie.match(/^(?:.*;)?\s*simple-jwt-login-token\s*=\s*([^;]+)(?:.*)?$/)?.[1];
}

export function setLmsSsoCookie(ssoToken) {
  // used when an admin deimpersonates a user
  setOrDeleteSsoCookie(ssoToken, 'create');
}

export function deleteLmsSsoCookie() {
  // used when logging out
  setOrDeleteSsoCookie('', 'delete');
}

function setOrDeleteSsoCookie(ssoToken, action) {
  const isLocalhost = ['localhost', '127.0.0.1'].includes(window.location.hostname);
  const domain = isLocalhost ? 'localhost' : '.alchemistaccelerator.com';
  let ssoCookie = `simple-jwt-login-token=${ssoToken}; domain=${domain}; path=/;`;
  if (action === 'create') {
    const samesite = isLocalhost ? 'Lax' : 'None';
    // expires 14 days from now
    const expires = new Date(Date.now() + (14 * 24 * 60 * 60 * 1000));
    const secure = !isLocalhost;
    ssoCookie += ` expires = ${expires}; same-site=${samesite};`;
    if (secure) ssoCookie += ' secure;';
  } else if (action === 'delete') {
    ssoCookie += ' Max-Age=0;';
  }
  document.cookie = ssoCookie;
}

export const isTokenExpired = (token) => {
  const currentTime = Math.floor(Date.now() / 1000);
  return token.exp < currentTime;
};

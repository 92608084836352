export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const MOBILE = 0;
export const TABLET = 1;
export const DESKTOP = 2;

export const MOBILE_WIDTH = 576;

export const responsiveSizes = {
  [MOBILE]: [-Infinity, MOBILE_WIDTH],
  [TABLET]: [577, 1079],
  [DESKTOP]: [1080, +Infinity],
};

export const ENGLISH_REGEX = /^[a-zA-Z -]*$/;
export const NO_EMOTICONS_FIELD_WARNING = 'cannot contain emoticons';
export const ENGLISH_ONLY_FIELD_WARNING = 'cannot contain emoticons or non-english characters';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { updateIncluded } from 'actions';
import { showAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { API_CONNECTIONS_BASE_URL, RESOURCE_CONNECTIONS } from 'containers/App/constants';
import { extractData } from 'utils/jsonApiExtract';
import { patch } from 'utils/request';
import { preprocessError } from 'utils/Forms/sagas';

import { LOAD_CONNECTION, SEND_FEEDBACK, FEEDBACK_SENT } from './constants';
import { setConnection, setErrorOnConnect, setFeedbackSentError, setIsConnAlreadyAccepted, setIsConnAlreadyDenied } from './actions';
import { makeSelectObject } from '../../App/selectors';

function* acceptConnection({ connectionCode, connectionId, status }) {
  const inputData = { data: { type: 'connections', id: connectionId, attributes: { accepted: status } } };
  const uri = `${API_CONNECTIONS_BASE_URL}/${connectionId}?_code=${connectionCode}&include=user,by_user.company`;
  try {
    const connectionResponse = yield call(patch, uri, inputData);

    const { inclusions, items: [connectionRef] } = extractData(connectionResponse);

    yield put(updateIncluded(inclusions));
    const connection = yield select(makeSelectObject(connectionRef));
    yield put(setConnection(connectionRef, status, connection.messageType));
  } catch (e) {
    const { errorJson } = yield preprocessError(e);
    if (e.response && e.response.status === 400 && errorJson?.errors?.[0]?.detail === 'Cant accept a previously declined connection') {
      yield put(showAlert('Request Already Denied!', COLOR.warning));
      yield put(setIsConnAlreadyDenied(true));
    } else if (e.response && e.response.status === 400) {
      yield put(showAlert('Request Already Accepted!', COLOR.warning));
      yield put(setIsConnAlreadyAccepted(true));
    } else {
      yield put(showAlert(`Something went wrong when ${status ? 'accepting' : 'declining'} the connection`, COLOR.danger));
      yield put(setErrorOnConnect(true));
      throw e;
    }
  }
}

function* sendFeedback({ connectionId, connectionCode, feedback }) {
  const inputData = {
    data: { type: RESOURCE_CONNECTIONS, id: connectionId, attributes: { recipient_feedback: feedback } },
  };
  const uri = `${API_CONNECTIONS_BASE_URL}/${connectionId}?_code=${connectionCode}`;
  try {
    yield call(patch, uri, inputData);
    yield put({ type: FEEDBACK_SENT });
  } catch (e) {
    const { errorJson } = yield preprocessError(e);
    if (e.response && e.response.status === 400 && errorJson?.errors?.[0]?.detail === 'Required') {
      yield put(setFeedbackSentError('Feedback is required'));
    } else {
      yield put(showAlert('Something went wrong when saving feedback', COLOR.danger));
      throw e;
    }
  }
}

export default function* connectionSaga() {
  // Message Sagas
  yield takeLatest(LOAD_CONNECTION, acceptConnection);
  yield takeLatest(SEND_FEEDBACK, sendFeedback);
}

import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import { makeSubSelector } from 'utils/selectors';
import { makeSelectMyCompany } from 'containers/AuthProvider/selectors';

import reducer from './reducer';
import { FOUNDER_CHOOSE_MODAL } from './constants';

const localInitialState = reducer(undefined, {});
const selectModalDomain = (state) => state ? state.get(FOUNDER_CHOOSE_MODAL, localInitialState) : localInitialState;

const selectIsModalOpen = makeSubSelector(selectModalDomain, ['isModalOpen']);

const selectOrderedCompanyClasses = createSelector(
  makeSelectMyCompany(),
  (companyObj) => {
    const allClasses = companyObj?.aclasses_rel?.();

    if (allClasses?.every((aRel) => !aRel.aclass?.().considered_active)) {
      const classesToReturn = [];
      const aaClass = find(allClasses, (aRel) => !aRel.aclass?.().isAlchemistX);
      classesToReturn.push(aaClass);

      const axClasses = sortBy(filter(allClasses, (aRel) => aRel.aclass?.().isAlchemistX), 'start_date');
      // only reverse if all inactive classes are not future classes
      if (!(axClasses && axClasses.every((a) => new Date() < new Date(a.start_date)))) {
        axClasses.reverse();
      }

      classesToReturn.push(...axClasses);
      return classesToReturn.filter((a) => a);
    }

    const activeClasses = sortBy(filter(allClasses, (aRel) => aRel.aclass?.().considered_active), 'start_date').reverse() || [];
    const inactiveClasses = sortBy(filter(allClasses, (aRel) => !aRel.aclass?.().considered_active), 'start_date').reverse() || [];

    return [...activeClasses, ...inactiveClasses].filter((a) => a);
  }
);

export {
  selectIsModalOpen,
  selectOrderedCompanyClasses,
};

import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';
import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';
import { RESOURCE_COMPANIES } from 'containers/App/constants';

import AvatarBase from './AvatarBase';


const AvatarComponent = ClassNameExtendableHoC(
  AvatarBase,
  'dsa-avatar-img',
  ({ profile, size }) => ([
    `_${size}_`,
    { 'dsa-company-logo': profile?.type === RESOURCE_COMPANIES },
  ])
);


const AvatarXs = DefaultPropsHoC(AvatarComponent, { size: 'xs' });
const AvatarSm = DefaultPropsHoC(AvatarComponent, { size: 'sm' });
const Avatar = AvatarSm;
const AvatarMd = DefaultPropsHoC(AvatarComponent, { size: 'md' });
const AvatarLg = DefaultPropsHoC(AvatarComponent, { size: 'lg' });
const AvatarXl = DefaultPropsHoC(AvatarComponent, { size: 'xl' });

export {
  Avatar,
  AvatarXs,
  AvatarSm,
  AvatarMd,
  AvatarLg,
  AvatarXl,
};

/* eslint-disable no-underscore-dangle */
import get from 'lodash/get';
import zipObjectDeep from 'lodash/zipObjectDeep';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { CHANGE, REGISTER_FIELD, INITIALIZE } from 'redux-form/lib/actionTypes';
import classNames from 'classnames';

import { deleteReq, patch, post } from '../request';
import { toJS } from '../general';
import ERRORS_MAPPER from './errorsMapper.json';
import LABEL_MAPPER from './labelMapper.json';
import { refApiUrl } from '../refs';


export const makeValueLabelArray = (options, labelCallback, removeFalsy = true, disabledCallback) => {
  const baseOptions = removeFalsy ? options.filter((option) => !!option || option === 0) : options;
  const optionGenerator = (option) => ({
    value: option,
    label: labelCallback ? labelCallback(option) : option,
    isDisabled: disabledCallback && disabledCallback(option),
  });
  return baseOptions.map(optionGenerator);
};

export const requestFunctionForObject = (obj) => {
  if (obj && obj.id > 0) return (obj.isDeleted ? deleteReq : patch);
  return post;
};

export const formRequest = (dataPayload, include, extraQParams) => requestFunctionForObject(dataPayload)(
  `${refApiUrl(dataPayload)}${extraQParams ? `?${extraQParams}` : ''}${addIncludes(include, extraQParams ? '&' : '?')}`,
  { data: dataPayload }
);

const addIncludes = (include, prefix = '?') => include
  ? `${prefix}include=${Array.isArray(include) ? include.join(',') : include}`
  : '';

export const adaptErrors = (errors, form, resourceType, registeredFields) => zipObjectDeep(
  errors.map((err) => getErrorKeyPathForField(errorField(err), registeredFields)),
  errors.map((err) => errorFor(errorField(err), form, resourceType, cleanErrorMsg(err)))
);
const errorField = (err) => `${err.dataPath}${err.keyword === 'required' ? `.${err.params.missingProperty}` : ''}`.replace('.', '');

export const errorClassNames = (className, { dirty, touched, error }) => classNames([
  className, (dirty || touched) && error && 'dsa-form-error', error === HIDE_BOX && 'keep-rounded']);

export const HIDE_BOX = 'HIDE_BOX';

const ERR_MSG_TRANSLATOR = {
  'should be string': HIDE_BOX,
  'should be object': HIDE_BOX,
  'should be boolean': HIDE_BOX,
  'should be integer': HIDE_BOX,
  'should be equal to one of the allowed values': HIDE_BOX,
  'should be array': HIDE_BOX,
  'should match format "date"': HIDE_BOX,
};

const cleanErrorMsg = (err) => err.keyword === 'required' ? HIDE_BOX : ERR_MSG_TRANSLATOR[err.message] || err.message;

const getErrorKeyPathForField = (field, registeredFields) => registeredFields && registeredFields[field] && registeredFields[field].type === 'FieldArray' ? `${field}._error` : field;


export const findFieldMapping = (MAPPER, field, form, resourceType) => {
  const fieldName = field.match(/\[\d+]/) ? field.replace(/\[\d+]/, '[]') : field;

  const formSpecificMapping = get(MAPPER, [form, fieldName]);
  if (formSpecificMapping === null || formSpecificMapping) return formSpecificMapping;

  const resTypeMapping = get(MAPPER, ['byResType', resourceType, fieldName]);
  if (resTypeMapping === null || resTypeMapping) return resTypeMapping;

  return get(MAPPER, ['byField', fieldName]);
};

export const labelFor = (field, form, resourceType) => findFieldMapping(LABEL_MAPPER, field, form, resourceType);

const errorFor = (field, form, resourceType, cleanedMsg) => {
  const mappedError = findFieldMapping(ERRORS_MAPPER, field, form, resourceType);
  const errorFromMapping = mappedError && (typeof mappedError === 'string' ? mappedError : mappedError[cleanedMsg]);

  return errorFromMapping || cleanedMsg;
};

export const setBlanksToNull = (valuesImmutable) => valuesImmutable.map((fieldValue) => {
  if (fieldValue === '') {
    return null;
  }
  return fieldValue && fieldValue.toJS
    ? setBlanksToNull(fieldValue)
    : fieldValue;
});

const removeEmptyRows = (values, mFMField) => ({
  [mFMField]: values[mFMField] && values[mFMField].filter((row) => !isEmpty(omit(row, 'type'))),
});

export const generateFullValuesPreprocessor = (valuesPreprocessor, multiFormMainField) => {
  if (!valuesPreprocessor && !multiFormMainField) return (valuesImm) => toJS(setBlanksToNull(valuesImm));
  if (!multiFormMainField) return (valuesImm) => valuesPreprocessor(toJS(setBlanksToNull(valuesImm)));
  // ToDo: should removeEmptyRows be removed? at least for validation?
  if (!valuesPreprocessor) return (valuesImm) => removeEmptyRows(toJS(setBlanksToNull(valuesImm)), multiFormMainField);

  return (valuesImm) => valuesPreprocessor(removeEmptyRows(toJS(setBlanksToNull(valuesImm)), multiFormMainField));
};

export const putSelectedIdsFirst = (liked) => (m1, m2) => {
  if (liked.includes(m1.id) && !liked.includes(m2.id)) return -1;
  if (!liked.includes(m1.id) && liked.includes(m2.id)) return 1;
  return 0;
};

export const isFieldChange = (form, field) => ({ type, meta = {} }) => type === CHANGE && meta.form === form && meta.field === field;

export const isFieldRegistration = (form, field) => ({ type, meta = {}, payload = {} }) => type === REGISTER_FIELD && meta.form === form && payload.name === field;

export const isFormInitialized = (form) => ({ type, meta = {} }) => type === INITIALIZE && meta.form === form;

export const splitResponsesAndErrors = (attempts, field) => attempts.reduce(
  (acc, currAttempt, idx) => {
    // Skip falseys
    if (!currAttempt) return acc;
    const newAcc = { ...acc };
    const idxAfterRemovals = idx - newAcc.deletedIdxs.length;
    if (currAttempt.success) {
      newAcc.responses.push(currAttempt.response);
      if (currAttempt.response.data) {
        newAcc.idxToId.push({ idx: idxAfterRemovals, id: currAttempt.response.data.id });
      }
      if (!currAttempt.response.data || get(currAttempt, 'response.data.attributes.is_deleted')) {
        newAcc.deletedIdxs.push(idx);
      } else {
        newAcc.successIdxs.push(idxAfterRemovals);
      }
    } else {
      newAcc.errorIdxs.push(idxAfterRemovals);
      if (!newAcc.errors) {
        newAcc.errors = { [field]: {} };
      }
      if (currAttempt.error) {
        if (currAttempt.error._error) {
          if (!newAcc.errors[field]._error) {
            newAcc.errors[field] = { _error: [] };
          }
          newAcc.errors[field]._error[idxAfterRemovals] = currAttempt.error._error;
        } else {
          newAcc.errors[field][idxAfterRemovals] = currAttempt.error;
        }
      }
    }
    return newAcc;
  },
  {
    errors: null, responses: [], successIdxs: [], errorIdxs: [], idxToId: [], deletedIdxs: [],
  }
);

export const isUniquenessError = (error) => error && error.detail
  && (error.detail.includes('Please enter a unique value')
    || (Array.isArray(error) && error[0].detail.includes('Please enter a unique value'))
    || error.detail.includes('duplicate key value violates unique constraint'));


export const isRequiredInSchema = (fieldName, subSchemas, schema) => {
  const fieldNameMatch = fieldName.match(/([a-z_]+)\[\d+]\.([a-z_]+)/i);
  if (!fieldNameMatch) {
    return schema && schema.required && schema.required.includes(fieldName);
  }
  return (schema && schema.required && schema.required.includes(fieldNameMatch[2]))
    || get(subSchemas, [fieldNameMatch[1], 'schema', 'required'], []).includes(fieldNameMatch[2]);
};

/**
 * replaces backend error message placeholders with mapped content
 *
 * @param {Object} MAPPER - mapping object which contains frontend labels for resourceTypes fields
 * @param {string} initialErrorMessage - initial error message with placeholders to be replaced: [$resource_name.field_name]
 * @param {string} formName - if field_name by resource type is not found, then search by fornName
 * @returns {string} - updated error message with placeholders replaced with their mapped content
 * if the label is not found, return placeholders replaced with field_name
 * if initialErrorMessage is empty string or it's type is not string, return initialErrorMessage
 */
export function replaceBackendErrorPlaceholder(MAPPER, initialErrorMessage = '', formName = '') {
  if (!initialErrorMessage.length && typeof initialErrorMessage !== 'string') {
    return initialErrorMessage;
  }

  // extract all the placeholders from initial message and store them in the array
  const regex = /\[\$(.*?)\]/g;
  const matches = [...initialErrorMessage.matchAll(regex)].map((match) => match[1]);

  // return initial message if there are no backend placeholders in initial message
  if (!matches.length) {
    return initialErrorMessage;
  }

  // replace each placeholder with its corresponding label from mapper
  // if label is not found then replace with field name from backend
  let updatedErrorMessage = initialErrorMessage;
  matches.forEach((placeholder) => {
    const [resourceType, fieldName] = placeholder.split('.');
    const resTypeMapping = get(MAPPER, ['byResType', resourceType, fieldName]);
    const formNameMapping = get(MAPPER, [formName, fieldName]);

    if (resTypeMapping !== null && resTypeMapping !== undefined) {
      updatedErrorMessage = updatedErrorMessage.replace(`[$${placeholder}]`, resTypeMapping);
      // stop further replacing if field name is found by resource type
      return;
    }

    if (formNameMapping !== null && formNameMapping !== undefined) {
      updatedErrorMessage = updatedErrorMessage.replace(`[$${placeholder}]`, formNameMapping);
    } else {
      updatedErrorMessage = updatedErrorMessage.replace(`[$${placeholder}]`, fieldName);
    }
  });

  return updatedErrorMessage;
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Main = ({ children, className, noSidebar }) => (
  <main className={classNames('dsa-main', className, [{ '_no-sidebar_': noSidebar }])}>
    {children}
  </main>
);


Main.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  noSidebar: PropTypes.bool,
};

export default Main;

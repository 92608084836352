export const timezoneAbbreviationFromDB = {
  'Africa/Abidjan': 'GMT',
  'Africa/Accra': 'GMT',
  'Africa/Addis_Ababa': 'EAT',
  'Africa/Algiers': 'CET',
  'Africa/Asmara': 'EAT',
  'Africa/Asmera': 'EAT',
  'Africa/Bamako': 'GMT',
  'Africa/Bangui': 'WAT',
  'Africa/Banjul': 'GMT',
  'Africa/Bissau': 'GMT',
  'Africa/Blantyre': 'CAT',
  'Africa/Brazzaville': 'WAT',
  'Africa/Bujumbura': 'CAT',
  'Africa/Cairo': 'EET',
  'Africa/Casablanca': '+01',
  'Africa/Ceuta': 'CET',
  'Africa/Conakry': 'GMT',
  'Africa/Dakar': 'GMT',
  'Africa/Dar_es_Salaam': 'EAT',
  'Africa/Djibouti': 'EAT',
  'Africa/Douala': 'WAT',
  'Africa/El_Aaiun': '+01',
  'Africa/Freetown': 'GMT',
  'Africa/Gaborone': 'CAT',
  'Africa/Harare': 'CAT',
  'Africa/Johannesburg': 'SAST',
  'Africa/Juba': 'CAT',
  'Africa/Kampala': 'EAT',
  'Africa/Khartoum': 'CAT',
  'Africa/Kigali': 'CAT',
  'Africa/Kinshasa': 'WAT',
  'Africa/Lagos': 'WAT',
  'Africa/Libreville': 'WAT',
  'Africa/Lome': 'GMT',
  'Africa/Luanda': 'WAT',
  'Africa/Lubumbashi': 'CAT',
  'Africa/Lusaka': 'CAT',
  'Africa/Malabo': 'WAT',
  'Africa/Maputo': 'CAT',
  'Africa/Maseru': 'SAST',
  'Africa/Mbabane': 'SAST',
  'Africa/Mogadishu': 'EAT',
  'Africa/Monrovia': 'GMT',
  'Africa/Nairobi': 'EAT',
  'Africa/Ndjamena': 'WAT',
  'Africa/Niamey': 'WAT',
  'Africa/Nouakchott': 'GMT',
  'Africa/Ouagadougou': 'GMT',
  'Africa/Porto-Novo': 'WAT',
  'Africa/Sao_Tome': 'GMT',
  'Africa/Timbuktu': 'GMT',
  'Africa/Tripoli': 'EET',
  'Africa/Tunis': 'CET',
  'Africa/Windhoek': 'CAT',
  'America/Adak': 'HST',
  'America/Anchorage': 'AKST',
  'America/Anguilla': 'AST',
  'America/Antigua': 'AST',
  'America/Araguaina': '-03',
  'America/Argentina/Buenos_Aires': '-03',
  'America/Argentina/Catamarca': '-03',
  'America/Argentina/ComodRivadavia': '-03',
  'America/Argentina/Cordoba': '-03',
  'America/Argentina/Jujuy': '-03',
  'America/Argentina/La_Rioja': '-03',
  'America/Argentina/Mendoza': '-03',
  'America/Argentina/Rio_Gallegos': '-03',
  'America/Argentina/Salta': '-03',
  'America/Argentina/San_Juan': '-03',
  'America/Argentina/San_Luis': '-03',
  'America/Argentina/Tucuman': '-03',
  'America/Argentina/Ushuaia': '-03',
  'America/Aruba': 'AST',
  'America/Asuncion': '-03',
  'America/Atikokan': 'EST',
  'America/Atka': 'HST',
  'America/Bahia': '-03',
  'America/Bahia_Banderas': 'CST',
  'America/Barbados': 'AST',
  'America/Belem': '-03',
  'America/Belize': 'CST',
  'America/Blanc-Sablon': 'AST',
  'America/Boa_Vista': '-04',
  'America/Bogota': '-05',
  'America/Boise': 'MST',
  'America/Buenos_Aires': '-03',
  'America/Cambridge_Bay': 'MST',
  'America/Campo_Grande': '-04',
  'America/Cancun': 'EST',
  'America/Caracas': '-04',
  'America/Catamarca': '-03',
  'America/Cayenne': '-03',
  'America/Cayman': 'EST',
  'America/Chicago': 'CST',
  'America/Chihuahua': 'CST',
  'America/Ciudad_Juarez': 'MST',
  'America/Coral_Harbour': 'EST',
  'America/Cordoba': '-03',
  'America/Costa_Rica': 'CST',
  'America/Creston': 'MST',
  'America/Cuiaba': '-04',
  'America/Curacao': 'AST',
  'America/Danmarkshavn': 'GMT',
  'America/Dawson': 'MST',
  'America/Dawson_Creek': 'MST',
  'America/Denver': 'MST',
  'America/Detroit': 'EST',
  'America/Dominica': 'AST',
  'America/Edmonton': 'MST',
  'America/Eirunepe': '-05',
  'America/El_Salvador': 'CST',
  'America/Ensenada': 'PST',
  'America/Fort_Nelson': 'MST',
  'America/Fort_Wayne': 'EST',
  'America/Fortaleza': '-03',
  'America/Glace_Bay': 'AST',
  'America/Godthab': '-02',
  'America/Goose_Bay': 'AST',
  'America/Grand_Turk': 'EST',
  'America/Grenada': 'AST',
  'America/Guadeloupe': 'AST',
  'America/Guatemala': 'CST',
  'America/Guayaquil': '-05',
  'America/Guyana': '-04',
  'America/Halifax': 'AST',
  'America/Havana': 'CST',
  'America/Hermosillo': 'MST',
  'America/Indiana/Indianapolis': 'EST',
  'America/Indiana/Knox': 'CST',
  'America/Indiana/Marengo': 'EST',
  'America/Indiana/Petersburg': 'EST',
  'America/Indiana/Tell_City': 'CST',
  'America/Indiana/Vevay': 'EST',
  'America/Indiana/Vincennes': 'EST',
  'America/Indiana/Winamac': 'EST',
  'America/Indianapolis': 'EST',
  'America/Inuvik': 'MST',
  'America/Iqaluit': 'EST',
  'America/Jamaica': 'EST',
  'America/Jujuy': '-03',
  'America/Juneau': 'AKST',
  'America/Kentucky/Louisville': 'EST',
  'America/Kentucky/Monticello': 'EST',
  'America/Knox_IN': 'CST',
  'America/Kralendijk': 'AST',
  'America/La_Paz': '-04',
  'America/Lima': '-05',
  'America/Los_Angeles': 'PST',
  'America/Louisville': 'EST',
  'America/Lower_Princes': 'AST',
  'America/Maceio': '-03',
  'America/Managua': 'CST',
  'America/Manaus': '-04',
  'America/Marigot': 'AST',
  'America/Martinique': 'AST',
  'America/Matamoros': 'CST',
  'America/Mazatlan': 'MST',
  'America/Mendoza': '-03',
  'America/Menominee': 'CST',
  'America/Merida': 'CST',
  'America/Metlakatla': 'AKST',
  'America/Mexico_City': 'CST',
  'America/Miquelon': '-03',
  'America/Moncton': 'AST',
  'America/Monterrey': 'CST',
  'America/Montevideo': '-03',
  'America/Montreal': 'EST',
  'America/Montserrat': 'AST',
  'America/Nassau': 'EST',
  'America/New_York': 'EST',
  'America/Nipigon': 'EST',
  'America/Nome': 'AKST',
  'America/Noronha': '-02',
  'America/North_Dakota/Beulah': 'CST',
  'America/North_Dakota/Center': 'CST',
  'America/North_Dakota/New_Salem': 'CST',
  'America/Nuuk': '-02',
  'America/Ojinaga': 'CST',
  'America/Panama': 'EST',
  'America/Pangnirtung': 'EST',
  'America/Paramaribo': '-03',
  'America/Phoenix': 'MST',
  'America/Port-au-Prince': 'EST',
  'America/Port_of_Spain': 'AST',
  'America/Porto_Acre': '-05',
  'America/Porto_Velho': '-04',
  'America/Puerto_Rico': 'AST',
  'America/Punta_Arenas': '-03',
  'America/Rainy_River': 'CST',
  'America/Rankin_Inlet': 'CST',
  'America/Recife': '-03',
  'America/Regina': 'CST',
  'America/Resolute': 'CST',
  'America/Rio_Branco': '-05',
  'America/Rosario': '-03',
  'America/Santa_Isabel': 'PST',
  'America/Santarem': '-03',
  'America/Santiago': '-03',
  'America/Santo_Domingo': 'AST',
  'America/Sao_Paulo': '-03',
  'America/Scoresbysund': '-01',
  'America/Shiprock': 'MST',
  'America/Sitka': 'AKST',
  'America/St_Barthelemy': 'AST',
  'America/St_Johns': 'NST',
  'America/St_Kitts': 'AST',
  'America/St_Lucia': 'AST',
  'America/St_Thomas': 'AST',
  'America/St_Vincent': 'AST',
  'America/Swift_Current': 'CST',
  'America/Tegucigalpa': 'CST',
  'America/Thule': 'AST',
  'America/Thunder_Bay': 'EST',
  'America/Tijuana': 'PST',
  'America/Toronto': 'EST',
  'America/Tortola': 'AST',
  'America/Vancouver': 'PST',
  'America/Virgin': 'AST',
  'America/Whitehorse': 'MST',
  'America/Winnipeg': 'CST',
  'America/Yakutat': 'AKST',
  'America/Yellowknife': 'MST',
  'Antarctica/Casey': '+11',
  'Antarctica/Davis': '+07',
  'Antarctica/DumontDUrville': '+10',
  'Antarctica/Macquarie': 'AEDT',
  'Antarctica/Mawson': '+05',
  'Antarctica/McMurdo': 'NZDT',
  'Antarctica/Palmer': '-03',
  'Antarctica/Rothera': '-03',
  'Antarctica/South_Pole': 'NZDT',
  'Antarctica/Syowa': '+03',
  'Antarctica/Troll': '+00',
  'Antarctica/Vostok': '+06',
  'Arctic/Longyearbyen': 'CET',
  'Asia/Aden': '+03',
  'Asia/Almaty': '+06',
  'Asia/Amman': '+03',
  'Asia/Anadyr': '+12',
  'Asia/Aqtau': '+05',
  'Asia/Aqtobe': '+05',
  'Asia/Ashgabat': '+05',
  'Asia/Ashkhabad': '+05',
  'Asia/Atyrau': '+05',
  'Asia/Baghdad': '+03',
  'Asia/Bahrain': '+03',
  'Asia/Baku': '+04',
  'Asia/Bangkok': '+07',
  'Asia/Barnaul': '+07',
  'Asia/Beirut': 'EET',
  'Asia/Bishkek': '+06',
  'Asia/Brunei': '+08',
  'Asia/Calcutta': 'IST',
  'Asia/Chita': '+09',
  'Asia/Choibalsan': '+08',
  'Asia/Chongqing': 'CST',
  'Asia/Chungking': 'CST',
  'Asia/Colombo': '+0530',
  'Asia/Dacca': '+06',
  'Asia/Damascus': '+03',
  'Asia/Dhaka': '+06',
  'Asia/Dili': '+09',
  'Asia/Dubai': '+04',
  'Asia/Dushanbe': '+05',
  'Asia/Famagusta': 'EET',
  'Asia/Gaza': 'EET',
  'Asia/Harbin': 'CST',
  'Asia/Hebron': 'EET',
  'Asia/Ho_Chi_Minh': '+07',
  'Asia/Hong_Kong': 'HKT',
  'Asia/Hovd': '+07',
  'Asia/Irkutsk': '+08',
  'Asia/Istanbul': '+03',
  'Asia/Jakarta': 'WIB',
  'Asia/Jayapura': 'WIT',
  'Asia/Jerusalem': 'IST',
  'Asia/Kabul': '+0430',
  'Asia/Kamchatka': '+12',
  'Asia/Karachi': 'PKT',
  'Asia/Kashgar': '+06',
  'Asia/Kathmandu': '+0545',
  'Asia/Katmandu': '+0545',
  'Asia/Khandyga': '+09',
  'Asia/Kolkata': 'IST',
  'Asia/Krasnoyarsk': '+07',
  'Asia/Kuala_Lumpur': '+08',
  'Asia/Kuching': '+08',
  'Asia/Kuwait': '+03',
  'Asia/Macao': 'CST',
  'Asia/Macau': 'CST',
  'Asia/Magadan': '+11',
  'Asia/Makassar': 'WITA',
  'Asia/Manila': 'PHT',
  'Asia/Muscat': '+04',
  'Asia/Nicosia': 'EET',
  'Asia/Novokuznetsk': '+07',
  'Asia/Novosibirsk': '+07',
  'Asia/Omsk': '+06',
  'Asia/Oral': '+05',
  'Asia/Phnom_Penh': '+07',
  'Asia/Pontianak': 'WIB',
  'Asia/Pyongyang': 'KST',
  'Asia/Qatar': '+03',
  'Asia/Qostanay': '+06',
  'Asia/Qyzylorda': '+05',
  'Asia/Rangoon': '+0630',
  'Asia/Riyadh': '+03',
  'Asia/Saigon': '+07',
  'Asia/Sakhalin': '+11',
  'Asia/Samarkand': '+05',
  'Asia/Seoul': 'KST',
  'Asia/Shanghai': 'CST',
  'Asia/Singapore': '+08',
  'Asia/Srednekolymsk': '+11',
  'Asia/Taipei': 'CST',
  'Asia/Tashkent': '+05',
  'Asia/Tbilisi': '+04',
  'Asia/Tehran': '+0330',
  'Asia/Tel_Aviv': 'IST',
  'Asia/Thimbu': '+06',
  'Asia/Thimphu': '+06',
  'Asia/Tokyo': 'JST',
  'Asia/Tomsk': '+07',
  'Asia/Ujung_Pandang': 'WITA',
  'Asia/Ulaanbaatar': '+08',
  'Asia/Ulan_Bator': '+08',
  'Asia/Urumqi': '+06',
  'Asia/Ust-Nera': '+10',
  'Asia/Vientiane': '+07',
  'Asia/Vladivostok': '+10',
  'Asia/Yakutsk': '+09',
  'Asia/Yangon': '+0630',
  'Asia/Yekaterinburg': '+05',
  'Asia/Yerevan': '+04',
  'Atlantic/Azores': '-01',
  'Atlantic/Bermuda': 'AST',
  'Atlantic/Canary': 'WET',
  'Atlantic/Cape_Verde': '-01',
  'Atlantic/Faeroe': 'WET',
  'Atlantic/Faroe': 'WET',
  'Atlantic/Jan_Mayen': 'CET',
  'Atlantic/Madeira': 'WET',
  'Atlantic/Reykjavik': 'GMT',
  'Atlantic/South_Georgia': '-02',
  'Atlantic/St_Helena': 'GMT',
  'Atlantic/Stanley': '-03',
  'Australia/ACT': 'AEDT',
  'Australia/Adelaide': 'ACDT',
  'Australia/Brisbane': 'AEST',
  'Australia/Broken_Hill': 'ACDT',
  'Australia/Canberra': 'AEDT',
  'Australia/Currie': 'AEDT',
  'Australia/Darwin': 'ACST',
  'Australia/Eucla': '+0845',
  'Australia/Hobart': 'AEDT',
  'Australia/LHI': '+11',
  'Australia/Lindeman': 'AEST',
  'Australia/Lord_Howe': '+11',
  'Australia/Melbourne': 'AEDT',
  'Australia/NSW': 'AEDT',
  'Australia/North': 'ACST',
  'Australia/Perth': 'AWST',
  'Australia/Queensland': 'AEST',
  'Australia/South': 'ACDT',
  'Australia/Sydney': 'AEDT',
  'Australia/Tasmania': 'AEDT',
  'Australia/Victoria': 'AEDT',
  'Australia/West': 'AWST',
  'Australia/Yancowinna': 'ACDT',
  'Brazil/Acre': '-05',
  'Brazil/DeNoronha': '-02',
  'Brazil/East': '-03',
  'Brazil/West': '-04',
  CET: 'CET',
  CST6CDT: 'CST',
  'Canada/Atlantic': 'AST',
  'Canada/Central': 'CST',
  'Canada/Eastern': 'EST',
  'Canada/Mountain': 'MST',
  'Canada/Newfoundland': 'NST',
  'Canada/Pacific': 'PST',
  'Canada/Saskatchewan': 'CST',
  'Canada/Yukon': 'MST',
  'Chile/Continental': '-03',
  'Chile/EasterIsland': '-05',
  Cuba: 'CST',
  EET: 'EET',
  EST: 'EST',
  EST5EDT: 'EST',
  Egypt: 'EET',
  Eire: 'GMT',
  'Etc/GMT': 'GMT',
  'Etc/GMT+0': 'GMT',
  'Etc/GMT+1': '-01',
  'Etc/GMT+10': '-10',
  'Etc/GMT+11': '-11',
  'Etc/GMT+12': '-12',
  'Etc/GMT+2': '-02',
  'Etc/GMT+3': '-03',
  'Etc/GMT+4': '-04',
  'Etc/GMT+5': '-05',
  'Etc/GMT+6': '-06',
  'Etc/GMT+7': '-07',
  'Etc/GMT+8': '-08',
  'Etc/GMT+9': '-09',
  'Etc/GMT-0': 'GMT',
  'Etc/GMT-1': '+01',
  'Etc/GMT-10': '+10',
  'Etc/GMT-11': '+11',
  'Etc/GMT-12': '+12',
  'Etc/GMT-13': '+13',
  'Etc/GMT-14': '+14',
  'Etc/GMT-2': '+02',
  'Etc/GMT-3': '+03',
  'Etc/GMT-4': '+04',
  'Etc/GMT-5': '+05',
  'Etc/GMT-6': '+06',
  'Etc/GMT-7': '+07',
  'Etc/GMT-8': '+08',
  'Etc/GMT-9': '+09',
  'Etc/GMT0': 'GMT',
  'Etc/Greenwich': 'GMT',
  'Etc/UCT': 'UTC',
  'Etc/UTC': 'UTC',
  'Etc/Universal': 'UTC',
  'Etc/Zulu': 'UTC',
  'Europe/Amsterdam': 'CET',
  'Europe/Andorra': 'CET',
  'Europe/Astrakhan': '+04',
  'Europe/Athens': 'EET',
  'Europe/Belfast': 'GMT',
  'Europe/Belgrade': 'CET',
  'Europe/Berlin': 'CET',
  'Europe/Bratislava': 'CET',
  'Europe/Brussels': 'CET',
  'Europe/Bucharest': 'EET',
  'Europe/Budapest': 'CET',
  'Europe/Busingen': 'CET',
  'Europe/Chisinau': 'EET',
  'Europe/Copenhagen': 'CET',
  'Europe/Dublin': 'GMT',
  'Europe/Gibraltar': 'CET',
  'Europe/Guernsey': 'GMT',
  'Europe/Helsinki': 'EET',
  'Europe/Isle_of_Man': 'GMT',
  'Europe/Istanbul': '+03',
  'Europe/Jersey': 'GMT',
  'Europe/Kaliningrad': 'EET',
  'Europe/Kiev': 'EET',
  'Europe/Kirov': 'MSK',
  'Europe/Kyiv': 'EET',
  'Europe/Lisbon': 'WET',
  'Europe/Ljubljana': 'CET',
  'Europe/London': 'GMT',
  'Europe/Luxembourg': 'CET',
  'Europe/Madrid': 'CET',
  'Europe/Malta': 'CET',
  'Europe/Mariehamn': 'EET',
  'Europe/Minsk': '+03',
  'Europe/Monaco': 'CET',
  'Europe/Moscow': 'MSK',
  'Europe/Nicosia': 'EET',
  'Europe/Oslo': 'CET',
  'Europe/Paris': 'CET',
  'Europe/Podgorica': 'CET',
  'Europe/Prague': 'CET',
  'Europe/Riga': 'EET',
  'Europe/Rome': 'CET',
  'Europe/Samara': '+04',
  'Europe/San_Marino': 'CET',
  'Europe/Sarajevo': 'CET',
  'Europe/Saratov': '+04',
  'Europe/Simferopol': 'MSK',
  'Europe/Skopje': 'CET',
  'Europe/Sofia': 'EET',
  'Europe/Stockholm': 'CET',
  'Europe/Tallinn': 'EET',
  'Europe/Tirane': 'CET',
  'Europe/Tiraspol': 'EET',
  'Europe/Ulyanovsk': '+04',
  'Europe/Uzhgorod': 'EET',
  'Europe/Vaduz': 'CET',
  'Europe/Vatican': 'CET',
  'Europe/Vienna': 'CET',
  'Europe/Vilnius': 'EET',
  'Europe/Volgograd': 'MSK',
  'Europe/Warsaw': 'CET',
  'Europe/Zagreb': 'CET',
  'Europe/Zaporozhye': 'EET',
  'Europe/Zurich': 'CET',
  GB: 'GMT',
  'GB-Eire': 'GMT',
  GMT: 'GMT',
  'GMT+0': 'GMT',
  'GMT-0': 'GMT',
  GMT0: 'GMT',
  Greenwich: 'GMT',
  HST: 'HST',
  Hongkong: 'HKT',
  Iceland: 'GMT',
  'Indian/Antananarivo': 'EAT',
  'Indian/Chagos': '+06',
  'Indian/Christmas': '+07',
  'Indian/Cocos': '+0630',
  'Indian/Comoro': 'EAT',
  'Indian/Kerguelen': '+05',
  'Indian/Mahe': '+04',
  'Indian/Maldives': '+05',
  'Indian/Mauritius': '+04',
  'Indian/Mayotte': 'EAT',
  'Indian/Reunion': '+04',
  Iran: '+0330',
  Israel: 'IST',
  Jamaica: 'EST',
  Japan: 'JST',
  Kwajalein: '+12',
  Libya: 'EET',
  MET: 'MET',
  MST: 'MST',
  MST7MDT: 'MST',
  'Mexico/BajaNorte': 'PST',
  'Mexico/BajaSur': 'MST',
  'Mexico/General': 'CST',
  NZ: 'NZDT',
  'NZ-CHAT': '+1345',
  Navajo: 'MST',
  PRC: 'CST',
  PST8PDT: 'PST',
  'Pacific/Apia': '+13',
  'Pacific/Auckland': 'NZDT',
  'Pacific/Bougainville': '+11',
  'Pacific/Chatham': '+1345',
  'Pacific/Chuuk': '+10',
  'Pacific/Easter': '-05',
  'Pacific/Efate': '+11',
  'Pacific/Enderbury': '+13',
  'Pacific/Fakaofo': '+13',
  'Pacific/Fiji': '+12',
  'Pacific/Funafuti': '+12',
  'Pacific/Galapagos': '-06',
  'Pacific/Gambier': '-09',
  'Pacific/Guadalcanal': '+11',
  'Pacific/Guam': 'ChST',
  'Pacific/Honolulu': 'HST',
  'Pacific/Johnston': 'HST',
  'Pacific/Kanton': '+13',
  'Pacific/Kiritimati': '+14',
  'Pacific/Kosrae': '+11',
  'Pacific/Kwajalein': '+12',
  'Pacific/Majuro': '+12',
  'Pacific/Marquesas': '-0930',
  'Pacific/Midway': 'SST',
  'Pacific/Nauru': '+12',
  'Pacific/Niue': '-11',
  'Pacific/Norfolk': '+12',
  'Pacific/Noumea': '+11',
  'Pacific/Pago_Pago': 'SST',
  'Pacific/Palau': '+09',
  'Pacific/Pitcairn': '-08',
  'Pacific/Pohnpei': '+11',
  'Pacific/Ponape': '+11',
  'Pacific/Port_Moresby': '+10',
  'Pacific/Rarotonga': '-10',
  'Pacific/Saipan': 'ChST',
  'Pacific/Samoa': 'SST',
  'Pacific/Tahiti': '-10',
  'Pacific/Tarawa': '+12',
  'Pacific/Tongatapu': '+13',
  'Pacific/Truk': '+10',
  'Pacific/Wake': '+12',
  'Pacific/Wallis': '+12',
  'Pacific/Yap': '+10',
  Poland: 'CET',
  Portugal: 'WET',
  ROC: 'CST',
  ROK: 'KST',
  Singapore: '+08',
  Turkey: '+03',
  UCT: 'UTC',
  'US/Alaska': 'AKST',
  'US/Aleutian': 'HST',
  'US/Arizona': 'MST',
  'US/Central': 'CST',
  'US/East-Indiana': 'EST',
  'US/Eastern': 'EST',
  'US/Hawaii': 'HST',
  'US/Indiana-Starke': 'CST',
  'US/Michigan': 'EST',
  'US/Mountain': 'MST',
  'US/Pacific': 'PST',
  'US/Samoa': 'SST',
  UTC: 'UTC',
  Universal: 'UTC',
  'W-SU': 'MSK',
  WET: 'WET',
  Zulu: 'UTC',
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Reactstrap removed `.form-group` class to <FormGroup /> bc of Bootstrap 5 change
const FormGroup = ({ children, className = '', row, check = false, tag }) => {
  const Wrapper = tag || 'div';
  return (
    <Wrapper
      className={classNames(`${className}`, {
        row,
        'form-check': check,
        'form-group': !check,
      })}
    >
      {children}
    </Wrapper>
  );
};

FormGroup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  row: PropTypes.bool,
  check: PropTypes.bool,
  tag: PropTypes.string,
};

export default FormGroup;

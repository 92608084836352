import React from 'react';
import PropTypes from 'prop-types';
import { openConsentManager } from '@segment/consent-manager';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';

import ResourceCard from 'components/AlchemistProgram/ResourceCard';
import PeopleListsContent from 'containers/People/PeopleLists/PeopleListsContent';

import { openDocModal } from '../DocsModal/actions';
import { DOC_TYPE, LEGAL_DOCS } from '../DocsModal/constants';
import GdprMain from '../GdprMain';


const LegalDocuments = (props) => (
  <GdprMain>
    <div className="dsa-program">
      <PeopleListsContent>
        <div className="dsa-program__header">
          GDPR
        </div>
        <Row className="dsa-program__resources pr-6">
          {LEGAL_DOCUMENTS.map((res) => (
            <ResourceCard
              key={res.id}
              resource={res}
            />))}
        </Row>
      </PeopleListsContent>
    </div>
  </GdprMain>);

LegalDocuments.propTypes = {
  openDocModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  openDocModal,
};

export default connect(null, mapDispatchToProps)(LegalDocuments);

const LEGAL_DOCUMENTS = [
  {
    id: 1,
    onClick: null,
    to: `/gdpr/doc/${DOC_TYPE.termsGdpr}`,
    title: LEGAL_DOCS[DOC_TYPE.termsGdpr].title,
    // description: 'Search through all Alchemist resources and FAQs.',
  },
  {
    id: 2,
    to: `/gdpr/doc/${DOC_TYPE.privacyPolicy}`,
    title: LEGAL_DOCS[DOC_TYPE.privacyPolicy].title,
    // description: 'All documents specific to your class.',
  },
  {
    id: 3,
    to: '/gdpr/manage',
    title: 'Manage Alchemist Profile',
  },
  {
    id: 6,
    onClick: (e) => {
      e.preventDefault();
      openConsentManager();
    },
    title: 'Cookie Policy',
    // description: 'Fundraising education, email templates, and legal documents.',
  },
  {
    id: 4,
    onClick: null,
    to: '/gdpr/faq',
    title: 'FAQs',
    // description: 'All the secrets for building a successful company.',
  },
];

import { useContext } from 'react';

import { findFieldMapping, isRequiredInSchema } from 'utils/Forms/general';
import LABEL_MAPPER from 'utils/Forms/labelMapper.json';

import { SchemaContext } from './index';

export const useIsRequiredFromSchema = (fieldName) => {
  const contextValues = useContext(SchemaContext);
  if (fieldName && contextValues) {
    const { schema, subSchemas } = contextValues;
    return fieldName && isRequiredInSchema(fieldName, subSchemas, schema);
  }
  return undefined;
};

export const useLabelFromMapper = (fieldName) => {
  const contextValues = useContext(SchemaContext);
  if (fieldName && contextValues) {
    const { formName, resourceType } = contextValues;
    return findFieldMapping(LABEL_MAPPER, fieldName, formName, resourceType);
  }
  return undefined;
};

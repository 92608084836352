export default function errorToWarnAdapt() {
  // eslint-disable-next-line no-console
  console.error = (function newConsoleError() {
    const { error } = console;

    return function consoleError(...args) {
      if (args[0] && args[0].match && args[0].match(/^warn(ing)?:/i)) {
        // eslint-disable-next-line no-console
        console.warn(...args);
      } else {
        error.apply(console, args);
      }
    };
  }());
}

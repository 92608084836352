import React from 'react';

import SideBarSection from 'components/SideBar/SideBarSection';
import LiVerticalDivider from 'components/SideBar/LiVerticalDivider';

import SideBarItem from './SideBarItem';
import SideBarHeading from './SideBarHeading';
import SideBarBrand from './SideBarBrand';


class SideBarSec extends React.Component {
  constructor(props) {
    super(props);
    this.state = { accountMenuOpen: false, isSidebarOpened: false };
    this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
    this.closeAccountMenu = this.closeAccountMenu.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
  }

  toggleAccountMenu() {
    this.setState(({ accountMenuOpen }) => ({ accountMenuOpen: !accountMenuOpen }));
  }

  closeAccountMenu() {
    if (this.state.accountMenuOpen) {
      this.setState(() => ({ accountMenuOpen: false }));
    }
  }

  closeSidebar() {
    if (this.state.isSidebarOpened) {
      this.setState(() => ({ isSidebarOpened: false }));
    }
  }

  render() {
    return (
      <div className="dsa-site-sidebar _sec_ d-flex">
        <SideBarBrand label="Design System" to="/design-system" />
        <SideBarSection>
          <SideBarHeading label="Basic Elements">
            <SideBarItem label="Avatar" to="/design-system/basic-elements/avatars" />
            <SideBarItem label="Badges" to="/d" />
            <SideBarItem label="Buttons" to="/design-system/basic-elements/buttons" />
            <SideBarItem label="Description Lists" to="/d" />
            <SideBarItem label="DragAndDrop" to="/d" />
            <SideBarItem label="Dropdown" to="/d" />
            <SideBarItem label="HeaderOffset" to="/d" />
            <SideBarItem label="LinkTo" to="/d" />
            <SideBarItem label="ListColBase" to="/d" />
            <SideBarItem label="ListColButton" to="/d" />
            <SideBarItem label="ListColMultiline" to="/d" />
            <SideBarItem label="MailTo" to="/d" />
            <SideBarItem label="Nav Item" to="/d" />
            <SideBarItem label="Nav Menu" to="/d" />
            <SideBarItem label="NonSortableHeader" to="/d" />
            <SideBarItem label="Search Input" to="/d" />
            <SideBarItem label="SingleColMobile" to="/d" />
            <SideBarItem label="SortableHeader" to="/d" />
            <SideBarItem label="Text" to="/d" />
            <LiVerticalDivider />
          </SideBarHeading>
          <SideBarHeading label="Components">
            <SideBarItem label="Dropdown Menu" to="/d" />
            <SideBarItem label="Filters" to="/d" />
            <SideBarItem label="List Header" to="/d" />
            <SideBarItem label="List Row" to="/d" />
            <SideBarItem label="Beta Icon" to="/design-system/components/beta-icon" />
            <LiVerticalDivider />
          </SideBarHeading>
          <SideBarHeading label="Patterns">
            <SideBarItem label="Basic Search Lists" to="/d" />
            <SideBarItem label="Filters Advanced" to="/d" />
            <SideBarItem label="Filters Simple" to="/d" />
            <SideBarItem label="Message quota" to="/d" />
            <SideBarItem label="Profile Preview" to="/d" />
            <SideBarItem label="Profile Single View" to="/d" />
            <SideBarItem label="Sidebar Menu" to="/d" />
            <SideBarItem label="Site Header" to="/d" />
            <LiVerticalDivider />
          </SideBarHeading>
        </SideBarSection>
      </div>);
  }
}

export default SideBarSec;

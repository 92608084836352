import React from 'react';
import PropTypes from 'prop-types';

import { responsiveSizes } from 'utils/constants';

const oMap = (o, f) => Object.assign({}, ...Object.keys(o).map((k) => ({ [k]: f(o[k]) })));

const ResponsiveContext = React.createContext({});
export const ResponsiveConsumer = ResponsiveContext.Consumer;

export class ResponsiveProvider extends React.Component {
  static width() {
    return window.innerWidth;
  }
  constructor(props) {
    super(props);

    this.onWindowResize = () => this.setState(this.actualResponsiveState());

    this.state = this.actualResponsiveState();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  actualResponsiveState() {
    const width = ResponsiveProvider.width();
    return { width, ...oMap(this.props.sizes, (v) => width >= v[0] && width <= v[1]) };
  }

  render() {
    return (
      <ResponsiveContext.Provider value={this.state}>
        {this.props.children}
      </ResponsiveContext.Provider>);
  }
}

ResponsiveProvider.propTypes = {
  children: PropTypes.any,
  sizes: PropTypes.objectOf(PropTypes.array),
};
ResponsiveProvider.defaultProps = {
  sizes: responsiveSizes,
};

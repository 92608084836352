import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faWifi } from '@fortawesome/pro-light-svg-icons/faWifi';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';


export const SOCIAL_FOOTER_INFO = {
  demoDayClass: {
    faIcon: faHandshake,
    text: 'Connect to Our Recent Class',
    url: '',
  },
  blog: {
    faIcon: faWifi,
    text: 'Subscribe to Our Blog',
    url: 'http://blog.alchemistaccelerator.com/',
  },
  twitter: {
    faIcon: faTwitter,
    text: 'Follow Us on Twitter',
    url: 'https://twitter.com/alchemistacc',
  },
};

export const DD_PAGE_ID = {
  livestream: 'livestream',
  companies: 'companies',
};
export const PAGE_ID_TO_URL = {
  [DD_PAGE_ID.livestream]: 'livestream',
  [DD_PAGE_ID.companies]: 'companies',
};

export const BADGES_COLORS = ['#0D7C8F', '#56B6C6', '#A1E0E4', '#7BB79F', '#14774C', '#688F1C', '#D9E065', '#B54E1A', '#B2AC41', '#E3A69F', '#D9D9CC', '#03A806', '#9C6D49', '#D08B78', '#467B11', '#8C9C85', '#C92B2B', '#0E86CC', '#95D4B7', '#219653', '#099BB8', '#9C5DDB', '#03273B', '#B79B55', '#2F80ED', '#F2994A', '#8242C1', '#395564', ''];

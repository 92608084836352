/*
 *
 * LoginForm constants
 *
 */

export const RESET_PASSWORD = 'alchemist/AuthProcess/ResetPassword/RESET_PASSWORD';
export const REQUEST_PASSWORD_RESET = 'alchemist/AuthProcess/ResetPassword/REQUEST_PASSWORD_RESET';
export const RESEND_EMAIL = 'alchemist/AuthProcess/ResetPassword/RESEND_EMAIL';
export const EMAIL_RESENT = 'alchemist/AuthProcess/ResetPassword/EMAIL_RESENT';
export const SHOW_WAIT_FOR_EMAIL = 'alchemist/AuthProcess/ResetPassword/SHOW_WAIT_FOR_EMAIL';
export const PROCESS_NEW_PASSWORD = 'alchemist/AuthProcess/ResetPassword/PROCESS_NEW_PASSWORD';
export const NEW_PASSWORD_PROCESSED = 'alchemist/AuthProcess/ResetPassword/NEW_PASSWORD_PROCESSED';
export const ENABLE_NEW_PASSWORD_BTN = 'alchemist/AuthProcess/ResetPassword/ENABLE_NEW_PASSWORD_BTN';
export const DISABLE_NEW_PASSWORD_BTN = 'alchemist/AuthProcess/ResetPassword/DISABLE_NEW_PASSWORD_BTN';

export const ERROR_TYPE = {
  alreadyVerified: 'alreadyVerified',
  linkError: 'linkError',
  unknown: 'unknown',
};
export const BE2FE_ERROR_MAPPER = {
  'Not a newly initialized password verify': ERROR_TYPE.alreadyVerified,
  'Token is invalid': ERROR_TYPE.linkError,
};
export const ERROR_KEY_TO_TEXT = {
  [ERROR_TYPE.alreadyVerified]: 'Email already verified, please use login form',
  [ERROR_TYPE.linkError]: 'Verification Code mismatch. Please check your verification URL and contact the Vault Team with specifying your email and verification URL',
  [ERROR_TYPE.unknown]: 'An unknown error occurred while trying to verify your Email. Please contact the Vault Team with specifying your email and verification URL',
};

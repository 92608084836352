/*
 * HomeReducer
 */
import { fromJS } from 'immutable';

import { SELECTED_PERSON_NOT_FOUND, SET_SELECTED_PERSON, SELECTED_PERSON_CONNECTIONS_FETCH, SELECTED_PERSON_CONNECTIONS_SUCCESS, SELECTED_PERSON_CONNECTIONS_FAIL } from './constants';

const initialLoadedContentState = fromJS({ selectedPerson: false, notFound: false, isFetchingConnections: false });

function peopleSinglePage(state = initialLoadedContentState, action) {
  switch (action.type) {
    case SELECTED_PERSON_NOT_FOUND:
      return state.set('notFound', true);
    case SET_SELECTED_PERSON:
      return state.set('selectedPerson', action.selectedPerson).set('notFound', false);
    case SELECTED_PERSON_CONNECTIONS_FETCH:
      return state.set('isFetchingConnections', true);
    case SELECTED_PERSON_CONNECTIONS_SUCCESS:
    case SELECTED_PERSON_CONNECTIONS_FAIL:
      return state.set('isFetchingConnections', false);
    default:
      return state;
  }
}

export default peopleSinglePage;

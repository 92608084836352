import { all, call, put, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

import { API_NOTIFS_BASE_URL, RESOURCE_CAPABILITIES, RESOURCE_NOTIFICATIONS, RELATIONS, API_CAPABILITIES_BASE_URL } from 'containers/App/constants';
import { makeSelectNotifications, selectToken } from 'containers/AuthProvider/selectors';
import { askConfirmationSaga } from 'containers/ConfirmModal/saga';
import { patch, request } from 'utils/request';
import { updateIncludedFromRequest } from 'actions';
import { makeSelectObject } from 'containers/App/selectors';


export function* showCapabilityNotifs() {
  const notifs = yield select(makeSelectNotifications());
  const capabilityNotifs = notifs?.filter((n) => n.object_resource === RESOURCE_CAPABILITIES);
  if (capabilityNotifs?.length > 0) {
    yield all(capabilityNotifs.map((notif) => call(showCapabilityNotif, notif)));
  }
}

export function* showCapabilityNotif(notif) {
  const userToken = yield select(selectToken);
  const decodedUserToken = jwtDecode(userToken);
  const capabilityReq = yield call(request, `${API_CAPABILITIES_BASE_URL}/${notif.object_id}?include=membership`);
  yield put(updateIncludedFromRequest(capabilityReq));
  const capability = yield select(makeSelectObject({
    id: notif.object_id,
    type: notif.object_resource,
  }));
  const hintAnswer = yield askConfirmationSaga(
    // ToDo: needs to change
    `Your ${RELATIONS.MAPPER[capability?.membership?.()?.relation] || ''} account has been closed`,
    notif.message,
    'Ok',
    null
  );
  // If user press "Ok" and there is no impersonator, patch the notif as viewed
  if (hintAnswer && !decodedUserToken?.impersonator) {
    yield call(patch, `${API_NOTIFS_BASE_URL}/${notif.id}`, {
      data: {
        id: notif.id,
        type: RESOURCE_NOTIFICATIONS,
        data: {
          viewed: true,
        },
      },
    });
  }
}

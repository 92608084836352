import React from 'react';
import { Route as RoutePure } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ErrorBoundary } from 'components/React/FailSafe';

const PureRouteWithErrorBoundary = ({ component: Component, ...rest }) => (
  <RoutePure
    render={
      (routeProps) => (
        <ErrorBoundary>
          <Component {...routeProps} />
        </ErrorBoundary>
      )}
    {...rest}
  />
);

PureRouteWithErrorBoundary.propTypes = {
  component: PropTypes.any,
};

export default PureRouteWithErrorBoundary;

import React from 'react';
import PropTypes from 'prop-types';
import { faPresentationScreen } from '@fortawesome/pro-light-svg-icons/faPresentationScreen';
import { faPresentation } from '@fortawesome/pro-solid-svg-icons';

import { CLASS_TYPE } from 'containers/App/constants';

import { SideBarLinkItem } from './SideBarItem';
import IfsAgendaSidebarLink from './IfsAgendaSidebarLink';


const DemoDaySidebar = ({
  children, myAccount, closeSidebar, demoDayClassToShow = null,
}) => (
  <>
    {Object.entries(demoDayClassToShow === null ? myAccount.demoDayClassByType() : demoDayClassToShow).map(([classType, aaClass]) => (
      <SideBarLinkItem
        to={`/demo-day${aaClass.isAlchemistX ? '/ax' : '/aa'}/${aaClass.number}/companies`}
        faIcon={classType === CLASS_TYPE.alchemist ? faPresentation : faPresentationScreen}
        onClick={closeSidebar}
      >
        <span>{aaClass.isAlchemistX && <>AX </>}Demo Day</span>
      </SideBarLinkItem>
    ))}
    <IfsAgendaSidebarLink closeSidebar={closeSidebar} />
    {children}
  </>
);

DemoDaySidebar.propTypes = {
  children: PropTypes.any,
  myAccount: PropTypes.object,
  closeSidebar: PropTypes.func,
  demoDayClassToShow: PropTypes.object,
};

export default DemoDaySidebar;

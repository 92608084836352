import React from 'react';
import PropTypes from 'prop-types';

import ScrollDiv from 'components/Generic/ScrollDiv';
import AaPropTypes from 'utils/AaPropTypes';

const OnboardingContent = ({
  className = '', children, innerRef, Component = ScrollDiv, ...props
}) => {
  const innerRefProp = Component === ScrollDiv ? { innerRef } : {};
  return (
    <Component className={`dsa-onboarding__content ${className}`} {...innerRefProp} {...props}>
      {children}
    </Component>
  );
};

OnboardingContent.propTypes = {
  children: PropTypes.any,
  innerRef: AaPropTypes.ref,
  className: PropTypes.string,
  Component: PropTypes.any,
};

export default OnboardingContent;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RadioLabel from 'components/Inputs/RadioLabel';
import { errorClassNames } from 'utils/Forms/general';
import TooltipBase from 'components/Icons/TooltipBase';

import FullFieldHoc from '../HoC/FullFieldHoc';
import OptionsFromSchemaHoc from '../HoC/OptionsFromSchemaHoc';
import SimplifyReduxFormInput from '../HoC/SimplifyReduxFormInput';
import SimpleFieldHoc from '../HoC/SimpleFieldHoc';

const RadioButtonWrapper = ({ wrapperNeeded, children }) => {
  if (wrapperNeeded) {
    return (
      <div className="d-flex">{children}</div>
    );
  }

  return (children);
};

RadioButtonWrapper.propTypes = {
  wrapperNeeded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const RadioButtons = ({
  options, meta, input, valueKey, labelKey, boldOptions, children, isHorizontal, captionMapper, disabledMapper,
  isCheckboxBtnLike = false, isLabelFirst = false, isDisabled, showCheck, holderClassName, radioClassName, PrefixComponent,
  prefixComponentProps, adminField, isUnstyled,
}) => (
  <div className={classNames(holderClassName || '', { 'd-md-flex': isHorizontal }, errorClassNames('', meta))}>
    {options && options.map(({ [valueKey]: value, [labelKey]: label, tooltipText }) => {
      const isChecked = input.value === value || input?.value?.toString() === value?.toString();

      const radioLabel = (
        <RadioButtonWrapper wrapperNeeded={PrefixComponent && isChecked}>
          {(PrefixComponent && isChecked) ? <PrefixComponent {...prefixComponentProps} /> : null}
          <RadioLabel
            key={typeof label === 'function' ? `${value}` : `${value}-${label}`}
            className={classNames('dsa-checkbox__sec', radioClassName, {
              _strong_: boldOptions,
              'dsa-checkbox__btn-like': isCheckboxBtnLike,
              '_with-tooltip_': tooltipText?.length > 0,
              _unstyled_: isUnstyled,
            })}
            name={input.name}
            value={value}
            isChecked={isChecked}
            onChange={() => input.onChange(isChecked ? null : value)}
            label={label || value}
            caption={captionMapper && captionMapper[value]}
            disabled={disabledMapper?.[value] || isDisabled}
            isCheckboxBtnLike={isCheckboxBtnLike}
            isLabelFirst={isLabelFirst}
            showCheck={showCheck}
            isAdminField={adminField}
          >
            {children}
          </RadioLabel>
        </RadioButtonWrapper>
      );

      if (tooltipText?.length > 0) {
        return (
          <TooltipBase hoverContent={radioLabel}>{tooltipText}</TooltipBase>
        );
      }
      return radioLabel;
    })}
  </div>);

RadioButtons.propTypes = {
  input: PropTypes.any,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  captionMapper: PropTypes.objectOf(PropTypes.string),
  disabledMapper: PropTypes.objectOf(PropTypes.bool),
  options: PropTypes.array,
  meta: PropTypes.object,
  boldOptions: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  children: PropTypes.any,
  isCheckboxBtnLike: PropTypes.bool,
  isLabelFirst: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showCheck: PropTypes.bool,
  holderClassName: PropTypes.bool,
  radioClassName: PropTypes.string,
  PrefixComponent: PropTypes.element,
  prefixComponentProps: PropTypes.object,
  adminField: PropTypes.bool,
  isUnstyled: PropTypes.bool,
};
RadioButtons.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
};

const RadioButtonsAdapterWithSchemaOpts = OptionsFromSchemaHoc(RadioButtons);

export const RadioButtonsInputField = SimpleFieldHoc(RadioButtonsAdapterWithSchemaOpts, { colSize: 'col-75' });
export const RadioButtonsField = FullFieldHoc(
  RadioButtonsAdapterWithSchemaOpts,
  { className: 'dsa-checkbox__group', popoverWidth: 'dsa-checkbox-shorter', colSize: 'col-75' }
);

export const NonFormRadioButtonsField = SimplifyReduxFormInput(RadioButtonsField);
export const NonFormRadioButtonsInputField = SimplifyReduxFormInput(RadioButtonsInputField);

import debounce from 'lodash/debounce';
import some from 'lodash/some';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { call, put, select, takeLatest, takeEvery, delay, take, race } from 'redux-saga/effects';

import { updateIncludedFromRequest } from 'actions';
import { API_ACCOUNTS_BASE_URL } from 'containers/App/constants';
import { FULL_ACCOUNT_DATA_LOADED, FULL_COMPANIES_DATA_LOADED, SET_PROFILE } from 'containers/AuthProvider/constants';
import { makeSelectIsCheckingAuth, makeSelectProfile, makeSelectMyCompany, makeSelectUser, selectCompaniesDataLoaded }
  from 'containers/AuthProvider/selectors';
import { SET_TOTAL_COUNT } from 'utils/filter/constants';
import { logError, logInfo } from 'utils/log';
import { formatStr } from 'utils/general';
import { request } from 'utils/request';
import { waitAndSelectAccount, waitSelect } from 'utils/sagas';

import { identify, track } from './actions';
import { TRACK_LIST_BROWSE_STOP_TO_VIEW, TRACK_SEARCH_RETURN } from './constants';

function* identifyListener() {
  const tokenAccount = yield select(makeSelectUser());
  if (!tokenAccount || Number.isNaN(tokenAccount.sub)) {
    return;
  }
  if (yield checkImpersonator()) {
    return;
  }
  if (tokenAccount.no_account) {
    yield call(identifyListenerNoAccount);
    return;
  }
  const { timeout } = yield race({
    accountFullLoaded: take(FULL_ACCOUNT_DATA_LOADED),
    timeout: delay(60000),
  });
  if (timeout) {
    logError('no account info in 10 seconds');
    return;
  }
  yield call(identifyListenerAccount);
}

function* identifyListenerAccount() {
  const tokenAccount = yield select(makeSelectUser());
  const extraAccountInfoResponse = yield call(request, `${API_ACCOUNTS_BASE_URL}/${tokenAccount.sub}/extra`);
  yield put(updateIncludedFromRequest(extraAccountInfoResponse));
  const account = yield waitAndSelectAccount();
  const profile = yield select(makeSelectProfile());

  yield waitSelect(selectCompaniesDataLoaded, 50, FULL_COMPANIES_DATA_LOADED);
  const company = yield select(makeSelectMyCompany());
  yield put(identify(tokenAccount, account, profile, company));
}

function* identifyListenerNoAccount() {
  const tokenAccount = yield select(makeSelectUser());
  const profile = yield waitSelect(makeSelectProfile());
  yield put(identify(tokenAccount, {}, profile, profile.company && profile.company()));
}

function* checkImpersonator() {
  const isChecking = yield select(makeSelectIsCheckingAuth());
  if (!isChecking && window.analytics && window.segment_code) {
    const tokenUser = yield select(makeSelectUser());
    if ((tokenUser && tokenUser.impersonator)) {
      logInfo('Segment disabled for impersonator');
      return true;
    }
  }
  return false;
}

const debouncedPushDataLayer = debounce(pushDataLayer, 500);
const setSearchRegEx = /alchemist\/(.+)\/SET_SEARCH/;
const browseSelectedObjectRegEx = /alchemist\/(.+)\/BROWSE_SELECTED_OBJECT/;
const excluded = [/@@.+/, /.+UPDATE_INCLUDED/, /.+_FORM_SCHEMA/, /alchemist\/Analytics.+/, /.+REGISTER_FIELD_POSITION/,
  // setSearchRegEx, browseSelectedObjectRegEx, /alchemist\/(.+)\/SET_TOTAL_COUNT/];
  /alchemist\/(.+)\/SET_TOTAL_COUNT/, /alchemist\/DemoDay\/StartUps\/SET_COMPANIES_COUNTS/];
// const excluded = [/@@.+/, /.+UPDATE_INCLUDED/, /.+_FORM_SCHEMA/, /alchemist\/Analytics.+/, /alchemist\/.+\/BROWSE_SELECTED_OBJECT$/];

function* listenAllForAnalytics({ type, ...rest }) {
  if (rest['@@redux-saga/SAGA_ACTION'] || some(excluded, (exclusion) => type.match(exclusion))) {
    return;
  }
  const sanitized = omitDeep(rest, ['password', 'login', 'token']);
  yield put(track(type, sanitized));
  yield virtualPageView();
}
function* trackSearch({ type, text }) {
  const page = type.match(setSearchRegEx)[1];
  const { totalCount } = yield take(formatStr(SET_TOTAL_COUNT, page));
  yield put(track(formatStr(TRACK_SEARCH_RETURN, page), { text, totalCount }));
}
function* trackListBrowse({ type }) {
  const page = type.match(browseSelectedObjectRegEx)[1];
  yield delay(1000);
  yield put(track(formatStr(TRACK_LIST_BROWSE_STOP_TO_VIEW, page)));
}

const omitDeep = (collection, excludeKeys) =>
  cloneDeepWith(collection, (value, key) => (value && excludeKeys.includes(key)) ? null : undefined);

function* virtualPageView() {
  const chunk = { event: 'virtual.pageview' };
  debouncedPushDataLayer(chunk);
}

function pushDataLayer(chunk) {
  if (window.dataLayer) {
    window.dataLayer.push(chunk);
  }
}
const typeByRegEx = (typeRegEx) => ({ type }) => type && type.match(typeRegEx);
export default function* () {
  yield takeLatest(typeByRegEx(setSearchRegEx), trackSearch);
  yield takeLatest(typeByRegEx(browseSelectedObjectRegEx), trackListBrowse);
  yield takeLatest(SET_PROFILE, identifyListener);
  yield takeEvery('*', listenAllForAnalytics);
}

import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';

const HeadingLarge = ClassNameExtendableHoC('h1', 'dsa-heading _large_');
const HeadingExtraLarge = ClassNameExtendableHoC('h1', 'dsa-heading _extra-large_');
const HeadingMedium = ClassNameExtendableHoC('h2', 'dsa-heading _medium_');
const HeadingMd = ClassNameExtendableHoC('h2', 'dsa-heading _md_'); // 18px
const HeadingMediumNew = ClassNameExtendableHoC('h2', 'dsa-heading _md-new_');
const HeadingSmallNew = ClassNameExtendableHoC('h2', 'dsa-heading _sm-new_');
const HeadingSmall = ClassNameExtendableHoC('h3', 'dsa-heading _small_');
const HeadingBase = ClassNameExtendableHoC('h4', 'dsa-heading _base_');

const SecHeadingLarge = ClassNameExtendableHoC('small', 'dsa-sec-heading _large_');
const SecHeadingSmall = ClassNameExtendableHoC('small', 'dsa-sec-heading _small_ text-muted');

export { HeadingLarge, HeadingMedium, HeadingMd, HeadingMediumNew, HeadingSmall, HeadingBase, SecHeadingLarge, SecHeadingSmall, HeadingSmallNew, HeadingExtraLarge };

import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ButtonPrimSm } from 'components/Buttons/ButtonPrim';
import { ButtonSecSm } from 'components/Buttons/ButtonSec';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { openModalForList } from 'containers/People/PeopleListManageModal/actions';
import { SHARE_SECTION, EDIT_SECTION } from 'containers/People/PeopleListManageModal/constants';
import ArchivedListsButton from 'components/Buttons/Specific/ArchivedListsButton';

import { makeSelectMergeEnabled, selectMarkedOwnLists } from '../selectors';
import {
  clearMarkedOwnLists as clearMarkedOwnListsAction,
  deletePeopleLists,
  openPeopleListCreateModal,
  openPeopleListMergeModal,
  archivePeopleLists,
} from '../actions';


const OwnListsButtons = ({
  openShareModalForList, selectedOwnLists, clearMarkedOwnLists, openCreateListModal, deleteLists, isMergeEnabled,
  openMergeModal, isDesktop, archiveLists, showArchiveLink, linkTo,
}) => {
  const singleActionsEnabled = selectedOwnLists.length === 1;
  return (
    <>
      <div className="dsa-people-lists__section__buttons-bunch">
        <ButtonPrimSm disabled={!!selectedOwnLists.length} onClick={openCreateListModal}>New List</ButtonPrimSm>
        <ButtonPrimSm
          disabled={!singleActionsEnabled}
          onClick={() => openShareModalForList(selectedOwnLists[0], SHARE_SECTION)}
        >
          Share
        </ButtonPrimSm>
        <ButtonPrimSm
          disabled={!singleActionsEnabled}
          onClick={() => openShareModalForList(selectedOwnLists[0], EDIT_SECTION)}
        >
          Edit
        </ButtonPrimSm>
        <ButtonPrimSm disabled={!isMergeEnabled} onClick={openMergeModal}>Merge</ButtonPrimSm>
        <ButtonPrimSm
          disabled={!selectedOwnLists.length}
          onClick={deleteLists}
        >Delete
        </ButtonPrimSm>
        <ButtonPrimSm
          disabled={!selectedOwnLists.length}
          onClick={archiveLists}
        >
          Archive
        </ButtonPrimSm>
        {isDesktop && showArchiveLink && <ArchivedListsButton to={linkTo} className="ml-5" />}
      </div>
      {isDesktop &&
        <div className="dsa-people-lists__section__buttons-clear">
          <span className="me-5">{selectedOwnLists.length} list selected</span>
          <ButtonSecSm onClick={clearMarkedOwnLists}>Clear</ButtonSecSm>
        </div>}
    </>
  );
};

OwnListsButtons.propTypes = {
  openShareModalForList: PropTypes.func,
  clearMarkedOwnLists: PropTypes.func,
  openCreateListModal: PropTypes.func,
  openMergeModal: PropTypes.func,
  deleteLists: PropTypes.func,
  selectedOwnLists: PropTypes.array,
  // areSectionActionsEnabled: PropTypes.bool,
  isMergeEnabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  archiveLists: PropTypes.func,
  showArchiveLink: PropTypes.bool,
  linkTo: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  openShareModalForList: (personList, section) => dispatch(openModalForList(personList, section)),
  clearMarkedOwnLists: () => dispatch(clearMarkedOwnListsAction()),
  openCreateListModal: () => dispatch(openPeopleListCreateModal()),
  openMergeModal: () => dispatch(openPeopleListMergeModal()),
  deleteLists: () => dispatch(deletePeopleLists()),
  archiveLists: () => dispatch(archivePeopleLists()),
});

const mapStateToProps = createStructuredSelector({
  selectedOwnLists: selectMarkedOwnLists,
  isMergeEnabled: makeSelectMergeEnabled(),
  // areSectionActionsEnabled: makeSelectOwnListsOnlyActionsEnabled(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(OwnListsButtons));

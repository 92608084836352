import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Breaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';


const RichTextDisplay = ({ className = '', showBreaks, ...props }) => (
  <ReactMarkdown
    {...props}
    className={`dsa-force-styles ${className}`}
    linkTarget="_blank"
    plugins={showBreaks && [Breaks]}
    components={showBreaks && { inlineCode: (innerProps) => <span className="dsa-highlight-search" {...innerProps} /> }}
    rehypePlugins={[rehypeRaw, rehypeSanitize]}
  />);

RichTextDisplay.propTypes = { className: PropTypes.string, showBreaks: PropTypes.bool };

export default RichTextDisplay;

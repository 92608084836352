import { ALREADY_ACCEPTED, ALREADY_DENIED, ERROR_ON_CONNECT, FEEDBACK_SENT_ERROR, SET_CONNECTION } from './constants';

export const setConnection = (connectionRef, accepted, messageType) => ({
  type: SET_CONNECTION,
  connectionRef,
  accepted,
  messageType,
});

export const setIsConnAlreadyDenied = (isConnAlreadyDenied) => ({
  type: ALREADY_DENIED,
  isConnAlreadyDenied,
});

export const setIsConnAlreadyAccepted = (isConnAlreadyAccepted) => ({
  type: ALREADY_ACCEPTED,
  isConnAlreadyAccepted,
});

export const setErrorOnConnect = (status) => ({
  type: ERROR_ON_CONNECT,
  status,
});

export const setFeedbackSentError = (feedbackSentErr) => ({ type: FEEDBACK_SENT_ERROR, feedbackSentErr });

import React from 'react';

class Hack extends React.Component {
  componentDidMount() {
    document.querySelectorAll('[data-consent-manager-dialog] table tr').forEach((tr) =>
      tr.querySelectorAll('th').forEach((th) => {
        if (th.innerText && th.innerText.trim() === 'Advertising') {
          // eslint-disable-next-line no-param-reassign
          tr.style = 'display: none;';
          setTimeout(() => {
            tr.querySelector('input[name="advertising"][value="false"]').click();
            tr.remove();
          }, 100);
        }
      }));
  }
  render() {
    return 'Cookie Policy Settings';
  }
}

export default Hack;

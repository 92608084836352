import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faHandHoldingDollar } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import AlchLogoMark from 'containers/Companies/Search/AlchLogoMark';
import { RESOURCE_COMPANIES } from 'containers/App/constants';
import { STARTUP_STATUS } from 'containers/Companies/Single/constants';
import TooltipBase from 'components/Icons/TooltipBase';

const AvatarImage = ({ profile, className, size = '', isStartupMultiClasses, isCompanyRow }) => {
  const location = useLocation();
  const isCompanies = profile?.type === RESOURCE_COMPANIES && location?.pathname?.includes(RESOURCE_COMPANIES);

  if (isCompanies && isCompanyRow) {
    const isAlchemistX = profile?.aclass?.()?.isAlchemistX;
    return (
      <div className="dsa-companies-avatar__holder position-relative">
        <div className="dsa-holder-company-status">
          <TooltipBase
            hoverContent={(
              profile?.status === STARTUP_STATUS.acquired &&
              <div className="dsa-holder-company-status__icon">
                <FontAwesomeIcon icon={faHandHoldingDollar} size="xs" color="#fff" />
              </div>
            )}
          >
            {STARTUP_STATUS.acquired}
          </TooltipBase>
          {profile?.status === STARTUP_STATUS.shutdown &&
            <div className="dsa-holder-company-status__icon ml-2">
              <FontAwesomeIcon icon={faLock} size="xs" color="#fff" />
            </div>}
        </div>
        <img
          className={className}
          src={profile.avatarLink?.(size)}
          alt={`${profile.name}'s Logo`}
        />
        <div className={classNames('dsa-holder-alch-logo', { '_alch-x_': isAlchemistX, '_multi-classes_': isStartupMultiClasses })}>
          <AlchLogoMark isAlchemist={!isAlchemistX} isStartupMultiClasses={isStartupMultiClasses} />
        </div>
      </div>
    );
  }

  return (
    <img
      className={className}
      src={profile.avatarLink?.(size)}
      alt={`${profile.name}'s${profile.type === 'companies' ? ' Logo' : ' Profile Image'}`}
    />
  );
};

AvatarImage.propTypes = {
  profile: PropTypes.object.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isStartupMultiClasses: PropTypes.bool,
  isCompanyRow: PropTypes.bool,
};


const AvatarBase = ({ profile, className, size, isStartupMultiClasses, isCompanyRow }) => (
  profile.public === false || profile.reachable === false
    ? (
      <div className={`dsa-avatar__holder ${className}`}>
        <AvatarImage profile={profile} />
        <div className="dsa-avatar__opaquer center-content-x-y">
          <FontAwesomeIcon icon={faLock} size={['sm', 'xs'].includes(size) && 'sm'} />
        </div>
      </div>)
    : (
      <AvatarImage
        profile={profile}
        className={className}
        size={['sm', 'xs'].includes(size) && 'lg'}
        isCompanies={profile?.type === RESOURCE_COMPANIES}
        isStartupMultiClasses={isStartupMultiClasses}
        isCompanyRow={isCompanyRow}
      />
    )
);

AvatarBase.propTypes = {
  profile: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.string,
  isStartupMultiClasses: PropTypes.bool,
  isCompanyRow: PropTypes.bool,
};


export default AvatarBase;

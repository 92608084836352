import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const LinkTo = ({
  to, href, children, target = '_blank', className, altColor, inv, darkerBlue,
}) => {
  const classes = classNames(
    className,
    'dsa-anchor-link',
    { 'color-alt': altColor, 'invert-underline': inv, 'darker-blue': darkerBlue },
  );
  return (!to
    ? <a href={href} className={classes} target={target}>{children || href}</a>
    : <Link to={to} className={classes} target={target}>{children}</Link>);
};

LinkTo.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
  altColor: PropTypes.bool,
  inv: PropTypes.bool,
  darkerBlue: PropTypes.bool,
};

export default LinkTo;

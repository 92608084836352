import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ButtonPrimSm } from 'components/Buttons/ButtonPrim';
import { ButtonSecSm } from 'components/Buttons/ButtonSec';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

import { selectMarkedOwnLists } from '../selectors';
import {
  clearMarkedOwnLists as clearMarkedOwnListsAction,
  deletePeopleLists,
  restorePeopleLists,
} from '../actions';
import { OWN_LISTS } from '../constants';


const OwnListsButtonsArchive = ({ selectedOwnLists, clearMarkedOwnLists, deleteLists, isDesktop, restoreLists }) => (
  <>
    <div className="dsa-people-lists__section__buttons-bunch">
      <ButtonPrimSm
        disabled={!selectedOwnLists.length}
        onClick={restoreLists}
      >
        Restore
      </ButtonPrimSm>
      <ButtonPrimSm
        disabled={!selectedOwnLists.length}
        onClick={deleteLists}
      >
        Delete
      </ButtonPrimSm>
    </div>
    {isDesktop &&
      <div className="dsa-people-lists__section__buttons-clear">
        <span className="me-5">{selectedOwnLists.length} list selected</span>
        <ButtonSecSm onClick={clearMarkedOwnLists}>Clear</ButtonSecSm>
      </div>}
  </>
);

OwnListsButtonsArchive.propTypes = {
  clearMarkedOwnLists: PropTypes.func,
  deleteLists: PropTypes.func,
  selectedOwnLists: PropTypes.array,
  isDesktop: PropTypes.bool,
  restoreLists: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  clearMarkedOwnLists: () => dispatch(clearMarkedOwnListsAction()),
  deleteLists: () => dispatch(deletePeopleLists()),
  restoreLists: () => dispatch(restorePeopleLists(OWN_LISTS)),
});

const mapStateToProps = createStructuredSelector({
  selectedOwnLists: selectMarkedOwnLists,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(OwnListsButtonsArchive));

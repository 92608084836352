import { groupBy, maxBy, flatMap, uniqBy, sortBy, find } from 'lodash';

import {
  RELATIONS,
  RESOURCE_ACCOUNTS,
  RESOURCE_ALCHEMIST_CLASSES,
  RESOURCE_COMPANIES,
  RESOURCE_CONNECTIONS,
  RESOURCE_PEOPLE,
  RESOURCE_PEOPLE_LISTS,
  RESOURCE_PUBLIC_PEOPLE_LISTS,
  RESOURCE_REVIEWS,
  ROLES,
  RESOURCE_DEMO_DAY_INVITATIONS,
  CLASS_TYPE, RESOURCE_EVENT_AGENDAS, RESOURCE_CUSTOMER_SUMMITS, RESOURCE_DEMO_DAYS, ATTEND_TYPE, RESOURCE_IFS_INVITATIONS, RESOURCE_DRY_RUNS, RESOURCE_CLASS_WEBSITES, CAPABILITIES,
} from 'containers/App/constants';

import { refCompare } from './refs';
import { addDays, getMonthsToNow, parseDateString, time24To12, asHour12 } from './dateTime';
import { getImgLink, hasRoleOneOf } from './fieldProcessors';
import { romanize } from './general';

const orderClassesByDemoDateDistanceToToday = (classes) => sortBy(
  classes,
  (c) => Math.abs(new Date() - new Date(c.demo_date_pst_aware)) / 1000 / 60 / 60 / 24
);

export const orderClasses = (classes = []) => {
  const passedClasses = classes?.filter((c) => new Date() > new Date(c.demo_access_end_date_pst_aware || c.demoday_active_until));
  const activeClasses = classes?.filter((c) => new Date() < new Date(c.demo_access_end_date_pst_aware || c.demoday_active_until));
  const futureActiveClasses = activeClasses.filter((c) => new Date() < new Date(c.demo_date_pst_aware));
  const passedActiveClasses = activeClasses.filter((c) => new Date() > new Date(c.demo_date_pst_aware));

  const finalFaClasses = orderClassesByDemoDateDistanceToToday(futureActiveClasses);
  const finalPaClasses = orderClassesByDemoDateDistanceToToday(passedActiveClasses);

  return [
    ...finalPaClasses,
    ...finalFaClasses,
    ...(!finalFaClasses.length && !finalPaClasses.length
      ? [maxBy(passedClasses, 'demo_date_pst_aware')]
      : []),
  ];
};

export function postProcessObject(obj) {
  let localCopy = { ...obj };
  switch (obj.resRef && obj.resRef.type) {
    case RESOURCE_ACCOUNTS:
      localCopy = postProcAccount(obj);
      break;
    case RESOURCE_PUBLIC_PEOPLE_LISTS:
      localCopy.subType = () => 'public';
      break;
    case RESOURCE_PEOPLE_LISTS:
      localCopy.subType = function subType() {
        if (this?.isSharedWithMe) {
          return 'sharedWithMe';
        }
        return this?.event_list ? 'eventList' : 'own';
      };
      break;
    case RESOURCE_ALCHEMIST_CLASSES:
      localCopy.isAlchemistX = obj.class_type === CLASS_TYPE.alchemistx;
      localCopy.internalName = obj.title ? `${obj.title} (${obj.number})` : obj.number;
      localCopy.internalFormattedName = `Alchemist${localCopy.isAlchemistX ? 'X' : ''} ${localCopy.internalName}`;
      localCopy.formattedName = `Alchemist ${obj.number}`;
      localCopy.romanNumber = () => romanize(obj.number);
      localCopy.romanTitle = () => `Class ${romanize(obj.number)}`;
      localCopy.romanLongTitle = () => `Alchemist Class ${romanize(obj.number)}`;
      localCopy.titleOrRoman = () => localCopy.isAlchemistX ? obj.title : localCopy.romanNumber();
      localCopy.titleOrRomanTitle = () => localCopy.isAlchemistX ? `Class ${obj.title}` : localCopy.romanTitle();
      localCopy.titleOrRomanLongTitle = () => localCopy.isAlchemistX ? `Class ${obj.title}` : localCopy.romanLongTitle();
      localCopy.demoDayAccessLink = () => localCopy.isAlchemistX ?
        `${window.location.origin}/demo-day/ax/${obj.number}/companies` :
        `${window.location.origin}/demo-day/aa/${obj.number}/companies`;
      break;
    case RESOURCE_REVIEWS:
      localCopy.trueAnonymous = obj.anonymous || !obj.author;
      break;
    case RESOURCE_EVENT_AGENDAS:
      localCopy.fullHtmlDescription = (obj.start && obj.end) ?
        `<strong>${time24To12(obj.start)} - ${time24To12(obj.end)} :</strong> ${obj.description}` :
        `${obj.description}`;
      localCopy.fullHtmlDescriptionTzAware = (obj.start_tzaware && obj.end_tzaware) ?
        `<strong>${asHour12(parseDateString(obj.start_tzaware))} - ${asHour12(parseDateString(obj.end_tzaware))} :</strong> ${obj.description}` :
        `${obj.description}`;
      localCopy.notAllowedRemotely = !obj.allow_remotely;
      break;
    case RESOURCE_DEMO_DAY_INVITATIONS:
      localCopy.isInvited = obj.invited_at;
      localCopy.isRegistered = obj.registered_to_attend_as;
      if (obj.selected_events) {
        const selectedEvents = obj.selected_events();
        selectedEvents.sort((event1, event2) => event1.start < event2.start ? -1 : 1);
        localCopy.selected_events = () => selectedEvents;
        localCopy.startTime = selectedEvents.filter((event) => event.start)[0]?.start_tzaware || 0;
        localCopy.endTime = selectedEvents.filter((event) => event.end).reverse()[0]?.end_tzaware || 0;
      } else {
        const aaClass = obj.demo_day && obj.demo_day().aa_class && obj.demo_day().aa_class();

        if (ATTEND_TYPE.remotely_recorded === obj.registered_to_attend_as) {
          localCopy.startTime = new Date(obj.rec_access_start_tzaware);
          localCopy.endTime = new Date(obj.rec_access_end_tzaware);
        } else {
          const attendAsStartEnd = {
            [ATTEND_TYPE.remotely]: ['livestream_start_tzaware', 'livestream_end_tzaware'],
            [ATTEND_TYPE.remotely_live]: ['livestream_start_tzaware', 'livestream_end_tzaware'],
            [ATTEND_TYPE.in_person]: ['in_person_start', 'in_person_end'],
          }[obj.registered_to_attend_as];
          localCopy.startTime = aaClass?.[attendAsStartEnd?.[0]];
          localCopy.endTime = aaClass?.[attendAsStartEnd?.[1]];
        }
      }
      break;
    case RESOURCE_IFS_INVITATIONS:
      localCopy.isInvited = obj.invited_at;
      localCopy.isRegistered = obj.registered_at;
      if (obj.selected_events) {
        const selectedEvents = obj.selected_events();
        selectedEvents.sort((event1, event2) => event1.start < event2.start ? -1 : 1);
        localCopy.selected_events = () => selectedEvents;
        localCopy.selectedEventIds = () => selectedEvents.map((e) => e.id);
        localCopy.startTime = selectedEvents.filter((event) => event.start)[0]?.start_tzaware || 0;
        localCopy.endTime = selectedEvents.filter((event) => event.end).reverse()[0]?.end_tzaware || 0;
      }
      break;
    case RESOURCE_PEOPLE:
      localCopy = postProcPeople(obj);
      break;
    case RESOURCE_COMPANIES:
      localCopy = postProcCompanies(obj);
      break;

    case RESOURCE_CONNECTIONS:
      localCopy.messageType = obj.type;
      localCopy.type = RESOURCE_CONNECTIONS;
      localCopy.stateHistory = obj.state_history
        ? obj.state_history.reduce((acc, current) => ({ ...acc, ...current }), {})
        : {};
      if (obj.state === 'draft') {
        localCopy.stateHistory.draft = obj.created_at;
      }
      break;
    default:
      break;
  }

  return localCopy;
}

export function postProcPeople(obj) {
  const localCopy = { ...obj };
  const registeredAt = parseDateString(obj.registration_finish_date);
  const lastRegisteredAt = parseDateString(obj.registration_last_finish_date);

  localCopy.avatarLink = (sizePostfix) => getImgLink(obj.profileimg, sizePostfix, '/default_user.jpg');
  localCopy.name = obj.nicename;
  localCopy.needsRegistration = !registeredAt
    || (lastRegisteredAt && addDays(lastRegisteredAt, 250) < new Date())
    || obj.has_empty_required_fields
    || (obj.roles && obj.roles().length === 1 && obj.roles()[0].title === 'network');
  localCopy.isInvestor = hasRoleOneOf(obj, [ROLES.investor, ROLES.angel, ROLES.strategic_investor]);
  if (obj.demo_day_users_rel) {
    // Returns Sneak Peek, Dry Run and Demo Day invitations where demo day is ongoing or in the future
    const allEligibleInvitations = obj.demo_day_users_rel()
      .filter((inv) => inv.demo_day?.().aa_class
        && new Date() < new Date(inv.demo_day().aa_class().demo_access_end_date_pst_aware || inv.demo_day().aa_class().demoday_active_until));

    if (allEligibleInvitations?.length) {
      localCopy.allEligibleInvitations = () => allEligibleInvitations;

      // upcomingDemoDay returns 1 most recent demo_day object
      const upcomingDemoDay = getMostRecentDD(allEligibleInvitations?.map((inv) => inv.demo_day()));
      const upcomingDdInvitation = upcomingDemoDay && obj.demo_day_users_rel().find((inv) => inv.demo_day?.().id === upcomingDemoDay.id);
      if (upcomingDdInvitation) {
        // upcomingDdInvitation returns 1 most recent DD invitation, either Sneak Peek / Dry Run / Demo Day (registered or not)
        localCopy.upcomingDdInvitation = () => upcomingDdInvitation;
      }
    }
  }
  if (obj.erogated_investments) {
    const verifiedInvestments = obj.erogated_investments().filter(({ verified }) => verified);
    if (verifiedInvestments.length) {
      localCopy.verifiedInvestments = () => verifiedInvestments;
    }
  }
  if (!registeredAt) {
    localCopy.isInvited = true;
  } else {
    const monthsSinceCreated = getMonthsToNow(registeredAt);
    if (monthsSinceCreated < 3) {
      localCopy.isNew = true;
    } else {
      const monthsSinceModified = getMonthsToNow(lastRegisteredAt);
      localCopy.isUpdated = monthsSinceModified < 3;
    }
  }
  if (obj.meetings) {
    const filteredMeetings = obj.meetings().filter((meetingTime) => ['weekday', 'start_time', 'end_time'].filter((k) => meetingTime[k]).length > 0);
    localCopy.filteredMeetings = () => filteredMeetings;
  }
  if (obj.company_membership) {
    const experiences = obj.company_membership().filter((cm) => RELATIONS.isExperience(cm));
    if (experiences) {
      localCopy.experiences = () => experiences;

      const previousExperiences = experiences.filter((cm) => cm.end_date);
      if (previousExperiences.length) {
        localCopy.previousExperiences = () => previousExperiences;
      }

      const currentExperiences = experiences.filter((cm) => !cm.end_date);
      if (currentExperiences.length) {
        localCopy.currentExperiences = () => currentExperiences;
      }

      const primaryMembership = currentExperiences.find((cm) => cm.company && !cm.is_deleted && cm.is_primary);
      if (primaryMembership) {
        localCopy.primaryMembership = () => primaryMembership;

        if (!localCopy.company) localCopy.company = primaryMembership.company;
      }

      const teamMemberships = experiences.filter((cm) => RELATIONS.isTeam(cm));
      if (teamMemberships.length > 0) {
        localCopy.teamMemberships = () => teamMemberships;

        const founderMemberships = teamMemberships.filter((fm) => fm.relation === RELATIONS.FOUNDER);
        if (founderMemberships.length > 0) {
          localCopy.founderMemberships = () => founderMemberships;
        }
      }
    }

    const coachMemberships = obj.company_membership().filter((fm) => fm.relation === RELATIONS.COACH);
    if (coachMemberships.length > 0) {
      localCopy.coachedCompanies = () => coachMemberships.map((membership) => membership.company && membership.company());
    }
  }

  return localCopy;
}

function postProcCompanies(obj) {
  const localCopy = { ...obj };

  localCopy.avatarLink = () => getImgLink(obj.logo, null, '/default_company.png');
  localCopy.publicPath = obj.slug && `/${RESOURCE_COMPANIES}/public/${obj.slug}`;
  if (obj.current_members_rel) {
    localCopy.current_members_rel = () => obj.current_members_rel().filter((m) => m.user && m.user().public !== false);
    let founderMemberships = localCopy.current_members_rel().filter((fm) => fm.relation === RELATIONS.FOUNDER);

    const isAxClassOver = obj.aclass?.().vault_access_end_date ? new Date() > new Date(obj.aclass?.().vault_access_end_date) : false;
    if (obj.aclass?.().class_type === CLASS_TYPE.alchemistx && isAxClassOver) {
      founderMemberships = localCopy.current_members_rel().filter((fm) => [RELATIONS.FOUNDER, RELATIONS.ALUMNI].includes(fm.relation));
    }

    if (founderMemberships.length > 0) {
      localCopy.founderMemberships = () => founderMemberships;
      localCopy.founderMembers = () => founderMemberships.map((membership) => membership.user());
    }
    const teamMemberships = localCopy.current_members_rel().filter(RELATIONS.isCurrentTeam.bind(RELATIONS));
    if (teamMemberships.length > 0) {
      localCopy.teamMemberships = () => teamMemberships;

      const teamMembers = teamMemberships.filter((membership) => membership.user)
        .map((membership) => membership.user());
      if (teamMembers.length > 0) {
        localCopy.teamMembers = () => uniqBy(teamMembers, 'id');
      }
    }
    const teamAndRelatedMemberships = localCopy.current_members_rel().filter(RELATIONS.isExperience.bind(RELATIONS));
    if (teamAndRelatedMemberships.length > 0) {
      localCopy.teamAndRelatedMemberships = () => teamAndRelatedMemberships;

      const teamAndRelatedMembers = teamAndRelatedMemberships.filter((membership) => membership.user)
        .map((membership) => membership.user());
      if (teamAndRelatedMembers.length > 0) {
        localCopy.teamAndRelatedMembers = () => teamAndRelatedMembers;
      }
    }
    const advisorRels = obj.current_members_rel().filter((fm) => fm.relation === RELATIONS.ADVISOR);
    if (advisorRels.length > 0) {
      localCopy.advisorRels = () => advisorRels;
    }

    const coachRels = localCopy.current_members_rel().filter((fm) => fm.relation === RELATIONS.COACH);
    if (coachRels.length > 0) {
      localCopy.coachRels = () => coachRels;
    }
  }

  if (['Shutdown', 'Acquired'].includes(obj.status) && localCopy.users_rel) {
    const compareDate = obj.status === 'Shutdown' ? 'shutdowndate' : 'acquireddate';
    const founderMemberships = localCopy.users_rel().filter((fm) => fm.relation === RELATIONS.ALUMNI && (
      fm.end_date === obj[compareDate]
      || new Date(fm.end_date) > new Date(obj[compareDate])
    ));
    if (founderMemberships.length > 0) {
      localCopy.founderMemberships = () => founderMemberships;
      localCopy.founderMembers = () => founderMemberships.map((membership) => membership.user());
    }
  }

  if (obj.investment_rounds) {
    localCopy.lastRound = maxBy(obj.investment_rounds(), (round) => round.announced);
    const receivedInvestments = flatMap(
      obj.investment_rounds(),
      (invRound) => invRound.investments
        ? invRound.investments().map((inv) => ({ ...inv, investment_round: () => invRound }))
        : []
    );
    const fundInvestments = receivedInvestments.filter((investment) => investment.investing_company);
    if (fundInvestments.length > 0) {
      localCopy.fundInvestments = () => fundInvestments;
    }
    const angelInvestments = receivedInvestments.filter((investment) => !investment.investing_company);
    if (angelInvestments.length > 0) {
      localCopy.angelInvestments = () => angelInvestments;
    }
  }
  if (localCopy.team_notes) {
    localCopy.teamNotesFor = (profile) => localCopy.team_notes().find((teamNotesForProfile) => refCompare(teamNotesForProfile.profile && teamNotesForProfile.profile(), profile));
  }
  return localCopy;
}

function postProcAccount(account) {
  const localCopy = { ...account };

  localCopy.getPendingNotificationsFor = (resType) => localCopy.notifications
    ? localCopy.notifications().filter((notif) => notif.object_resource === resType && !notif.viewed)
    : [];

  localCopy.hasPendingNotificationsFor = (resType) => localCopy.getPendingNotificationsFor(resType).length > 0;
  localCopy.getLPsNextQuotaIncrease = () => {
    if (account.quota_auto_increase_at) {
      return account.quota_auto_increase_at;
    }
    const lpCapabilityRel = find(account.capabilities_rel?.(), (rel) => rel.capability === CAPABILITIES.limited_partner);
    return lpCapabilityRel?.onboarding_end_date && addDays(parseDateString(lpCapabilityRel.onboarding_end_date), 365);
  };

  if (account.profile?.().demo_day_users_rel) {
    // These are user's DD invitations that the user already registered for.
    const registeredDdInvitations = account.profile?.().demo_day_users_rel?.().filter((inv) => DD_TYPE_W_REG.includes(inv.demo_day?.().type) && inv.registered_at);
    if (registeredDdInvitations) {
      localCopy.registeredDdInvitations = () => registeredDdInvitations;
    }

    // We don't filter invitations by demo_access_end_date or demoday_active_until here. Because of this, we get to show DD sidebar to DD users even if demo day has passed.
    const demoDays = account.profile?.().demo_day_users_rel?.()
      .filter((inv) => (DD_TYPE_W_REG.includes(inv.demo_day?.().type) && inv.registered_at)
        || (!DD_TYPE_W_REG.includes(inv.demo_day?.().type)))?.map((inv) => inv.demo_day);


    const pickHighest = (latestClass, demoDay) => latestClass && demoDay().aa_class().number < latestClass.number ? latestClass : demoDay().aa_class();

    const orderedAxClasses = orderClasses(demoDays.map((dD) => dD?.().aa_class?.())?.filter((aaClass) => aaClass.class_type === CLASS_TYPE.alchemistx));

    localCopy.demoDayClassByType = () => {
      const demoDayClassByType = groupBy(demoDays, (dd) => dd?.().aa_class().class_type);
      Object.keys(demoDayClassByType).map((classType) => {
        if (classType === CLASS_TYPE.alchemistx) {
          demoDayClassByType[classType] = orderedAxClasses?.[0];
        } else {
          demoDayClassByType[classType] = demoDayClassByType[classType]?.reduce(pickHighest, null);
        }
        return null;
      });
      return demoDayClassByType;
    };
    localCopy.demoDayClass = () => demoDays?.reduce(pickHighest, null);
    localCopy.activeAxClasses = () => uniqBy(orderedAxClasses, 'id');

    localCopy.forDryRun = () => getMostRecentDD(account.profile?.().demo_day_users_rel?.().filter((inv) => inv.demo_day?.().type === RESOURCE_DRY_RUNS)?.map((inv) => inv.demo_day()));

    localCopy.forClassWebsite = () => getMostRecentDD(account.profile?.().demo_day_users_rel?.().filter((inv) => inv.demo_day?.().type === RESOURCE_CLASS_WEBSITES)?.map((inv) => inv.demo_day()));
  }
  if (account.shared_lists_with_me_rel) {
    const sharedCanEditListsRels = account.shared_lists_with_me_rel
      && account.shared_lists_with_me_rel().filter((rel) => rel.update);
    if (sharedCanEditListsRels && sharedCanEditListsRels.length) {
      localCopy.sharedCanEditListsRels = () => sharedCanEditListsRels;
      localCopy.sharedCanEditLists = () => sharedCanEditListsRels.map((listRel) => listRel.user_list());
    }
  }
  if (localCopy.sharedCanEditLists || localCopy.user_lists || localCopy.event_lists) {
    const myUserLists = localCopy.user_lists ? localCopy.user_lists() : [];
    const mySharedCanEditLists = localCopy.sharedCanEditLists ? localCopy.sharedCanEditLists() : [];
    const eventLists = localCopy.event_lists ? localCopy.event_lists() : [];
    localCopy.allEditableLists = () => uniqBy([...myUserLists, ...mySharedCanEditLists, ...eventLists], (list) => list.id);
  }
  localCopy.quotaLeft = (localCopy.connect_me_quota || 0) - (localCopy.eligible_messages_count || 0);
  localCopy.profile__nicename = account.profile ? account.profile().nicename : 'N/A';

  if (account.bookmarked_companies) {
    localCopy.findCompanyInFavorites = (searchedCompany) => !!account.bookmarked_companies().find((company) => searchedCompany?.id === company.id);
  }

  localCopy.findMessagesForCompany = (company, connections) => connections.filter((connection) => refCompare(connection?.relationships?.company?.data, company));

  localCopy.hasContacted = (to, connections, isLp) => {
    const user = to.user ? to.user() : to;
    return user.message_type?.map((messageType) => !!localCopy.findAllConnectionThreadsTo(user, isLp ? 'RI_LP' : messageType, true, connections).length).some((x) => x);
  };

  /**
    * finds all connection threads related to a specific user based on defined criteria.
    * @param {Object} user - user object for whom the connections are being searched.
    * @param {string} messageType - optional. type of message being filtered.
    * @param {boolean} filterOutDrafts - optional. indicates whether to filter out drafts or not.
    * @param {Array<Object>} connections - array of connections to filter.
    * @returns {Array<Object>} - Returns an array of connections matching the specified criteria.
 */
  localCopy.findAllConnectionThreadsTo = (user, messageType, filterOutDrafts, connections) => {
    if (!connections) {
      return [];
    }

    // get the id of the user which is currently logged in
    const currentUserId = account?.profile?.()?.id;

    // condition function to filter connections based on specific criteria.
    // it's needed because users can send connections to themselves, and we need to handle message history inside message modal (navigate to messages tab and click any row to open modal).
    // in such cases when user opens message sent to himself we should show only such messages in message history.
    // to detect these messages we make sure that sender id (by_network_user or by_user) is same as recipient id (user_id).
    // eventually we use this function to get first condition in connection filtering.
    // if modal is opened for message from/to different user, we just return all the connections related to this user, both incoming and outgoing.
    const getCondition = (connection) => user?.id === currentUserId
      // in case if the message is for the current user
      ? (connection?.relationships?.by_network_user?.data?.id === connection?.meta?.user_id || connection?.relationships?.by_user?.data?.id === connection?.meta?.user_id)
      // in case if the message is for different user
      : (connection?.relationships?.by_network_user?.data?.id === user?.id || connection?.relationships?.by_user?.data?.id === user?.id || connection?.meta?.user_id === user?.id);

    return connections.filter(
      (connection) => getCondition(connection)
        && (messageType ? connection.attributes?.type === messageType : true)
        && (!filterOutDrafts || !connection.attributes?.draft)
        && !connection?.meta?.is_follow_up
        && !connection?.meta?.parent_id
    );
  };

  localCopy.findConnectionTo = (to, messageType, connections) => localCopy.findAllConnectionThreadsTo(to, messageType, false, connections).reduce(
    (lastMsg, msg) => compareMsgPriority(msg, lastMsg) ? msg : lastMsg,
    null
  );

  localCopy.canRecontact = (to, messageType, passedLastMsg, connections) => {
    const latestMsg = passedLastMsg || localCopy.findConnectionTo(to, messageType, connections);

    if (!latestMsg) return false;
    if (latestMsg.draft || latestMsg.attributes?.draft) return true;

    const startupLastraiseDate = account.profile && account.profile().company
      && new Date(account.profile().company().startup_lastraise_date);
    const latestMsgSentAtDate = latestMsg.sent_at && new Date(parseDateString(latestMsg.sent_at));

    return startupLastraiseDate && latestMsgSentAtDate < startupLastraiseDate;
  };

  localCopy.findConnectionToToOpen = (to, messageType, connections) => {
    const latestMsg = localCopy.findConnectionTo(to, messageType, connections);
    if (!latestMsg) return null;
    return latestMsg.draft || latestMsg.attributes?.draft || !localCopy.canRecontact(to, messageType, latestMsg, connections) ? latestMsg : null;
  };

  return localCopy;
}

const compareMsgPriority = (msgA, msgB) => !msgB || (msgA.attributes?.draft && !msgB.attributes?.draft) || (msgB.attributes?.draft === msgA.attributes?.draft && msgB.id < msgA.id);

const DD_TYPE_W_REG = [
  RESOURCE_CUSTOMER_SUMMITS,
  RESOURCE_DEMO_DAYS,
];

const DD_TYPE_PRIORITY = {
  [RESOURCE_DRY_RUNS]: 0,
  [RESOURCE_CLASS_WEBSITES]: 1,
  [RESOURCE_CUSTOMER_SUMMITS]: 2,
  [RESOURCE_DEMO_DAYS]: 3,
};

const compareDdPriority = (dD1, dD2, isGT = true) => (
  dD1 && dD1.aa_class
  && (
    !dD2.aa_class
    || (isGT ? dD1.aa_class().demo_date > dD2.aa_class().demo_date : dD1.aa_class().demo_date < dD2.aa_class().demo_date)
    || (dD1.aa_class().demo_date === dD2.aa_class().demo_date && DD_TYPE_PRIORITY[dD1.type] < DD_TYPE_PRIORITY[dD2.type])
  )
);

const getMostRecentDD = (demoDays) => {
  const futureDDs = demoDays?.filter((dD) => new Date() < new Date(dD.aa_class().demo_date_pst_aware));
  const activeDDs = demoDays?.filter((dD) => new Date() > new Date(dD.aa_class().demo_date_pst_aware) && new Date() < new Date(dD.aa_class().demo_access_end_date_pst_aware || dD.aa_class().demoday_active_until));

  // Returns Demo Day with passed demo_date but still active or still ongoing
  if (activeDDs.length > 0) {
    return activeDDs.reduce((mainDD, dD) => !mainDD || compareDdPriority(dD, mainDD) ? dD : mainDD, null);
  }

  // Returns earliest future Demo Day
  if (futureDDs.length > 0) {
    return futureDDs.reduce((mainDD, dD) => !mainDD || compareDdPriority(dD, mainDD, false) ? dD : mainDD, null);
  }

  // Returns last Demo Day with passed demo date
  return demoDays?.reduce((mainDD, dD) => !mainDD || compareDdPriority(dD, mainDD) ? dD : mainDD, null);
};

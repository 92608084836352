

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SearchContextProvider } from 'components/Search/SearchContext';

const MainContent = ({
  children, className, noSidebar, search, innerRef, onScroll,
}) => (
  <SearchContextProvider value={{ search }}>
    <main className={classNames('dsa-site-main-content', className, [{ '_no-sidebar_': noSidebar }])} ref={innerRef} onScroll={onScroll}>
      {children}
    </main>
  </SearchContextProvider>
);


MainContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  noSidebar: PropTypes.bool,
  search: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  innerRef: PropTypes.object,
  onScroll: PropTypes.func,
};

export default MainContent;

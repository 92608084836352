import { eventChannel } from 'redux-saga';
import { put, delay, take } from 'redux-saga/effects';

import { connectionLost, connectionRecovered } from './actions';

export function* offlineReadyRequest(url, options) {
  let response;
  let attempts = 0;
  while (!response) {
    try {
      response = yield fetch(url, options);
    } catch (e) {
      if (window.navigator && !window.navigator.onLine) {
        yield put(connectionLost());
        yield take(eventChannel((emitter) => {
          const emitChannelEmitter = () => { emitter('online'); };
          window.addEventListener('online', emitChannelEmitter);
          return () => window.removeEventListener('online', emitChannelEmitter);
        }));
        yield put(connectionRecovered());
      } else {
        yield delay(Math.min(attempts * attempts * 20, 5000));
        attempts += 1;
      }
    }
  }
  return response;
}

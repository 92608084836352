import { stringify, parse } from 'qs';

export const LINKEDIN_CLIENT_ID = '75wr8lh1sb32mf';

export function getLinkedInAuthLink(flow, unauthHash, ddInviteFlag) {
  const existingParams = sessionStorage.getItem('landingQueryParams');
  const parsedExistingParams = parse(existingParams, { ignoreQueryPrefix: true });
  const parsedNewParams = parse(window.location.search, { ignoreQueryPrefix: true });

  Object.keys(parsedNewParams).forEach((key) => {
    parsedExistingParams[key] = parsedNewParams[key];
  });

  sessionStorage.setItem('landingQueryParams', stringify(parsedExistingParams));

  const csrfHash = `${Math.random().toString().substr(2, 9)}`;
  saveLinkedinCsrf(csrfHash);
  const conf = {
    response_type: 'code',
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: `${window.location.origin}/oauthlanding`,
    state: [csrfHash, flow, unauthHash || '', ddInviteFlag || ''].join('--'),
    scope: 'r_emailaddress r_liteprofile',
  };
  return `https://www.linkedin.com/oauth/v2/authorization?${stringify(conf)}`;
}

function saveLinkedinCsrf(linkedinCsrfHash) {
  window.sessionStorage.setItem('linkedinCsrfHash', linkedinCsrfHash);
}

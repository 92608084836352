import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faStar as faStarFull } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf';
import { faStar as faStarEmpty } from '@fortawesome/pro-light-svg-icons/faStar';
import { faAngellist } from '@fortawesome/free-brands-svg-icons/faAngellist';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { parseNumber, formatNumber } from 'libphonenumber-js';
import startCase from 'lodash/startCase';

import {
  MESSAGE_TYPE_MAPPER, RESOURCE_CUSTOMER_SUMMITS, MESSAGE_STATE_MAPPER, INVESTMENT_ROUNDS, RESOURCE_INVESTMENT_ROUNDS,
  ROLE_MAPPER, MESSAGE_STATE_COLOR_MAPPER, COMPANY_TYPE_MAPPER, MESSAGE_STATE_TO_TEXT_MAPPER, CAPABILITIES, PERMISSION,
} from 'containers/App/constants';
import { ButtonLinkSec } from 'components/Buttons/ButtonLinkSec';
import LinkedInIcon from 'components/Icons/Social/LinkedInIcon';

import { getFormattedDate, getLongDate, getMonthYearDate } from './dateTime';


export const getConnectionStateNice = (connection) => connection &&
  (MESSAGE_STATE_MAPPER[connection.state] || connection.state);
export const getConnectionStateColor = (connection) => connection && (MESSAGE_STATE_COLOR_MAPPER[connection.state]);
export const getMessageTypeNice = (person, messageType) => MESSAGE_TYPE_MAPPER[messageType || person.message_type] || person.message_type;
export const getCompanyTypeNice = (companyType) => COMPANY_TYPE_MAPPER[companyType] || companyType;
export const getMessageStatusDate = (connection) => (connection && connection.stateHistory[connection.state]) || '';
const getMessageStatusDateForProfile = (connection) => {
  const state = connection?.meta?.state;
  const stateItem = connection?.meta?.state_history.find((historyItem) => state in historyItem)?.[state];
  return stateItem || '';
};

export function getMessageButtonText(connection, messageType, isLp) {
  return (connection &&
    `${MESSAGE_STATE_TO_TEXT_MAPPER[connection.meta?.state]} ${getFormattedDate(getMessageStatusDateForProfile(connection))}`)
    || (isLp ? 'Request Intro' : MESSAGE_TYPE_MAPPER[messageType]);
}

export function getMessageButtonHref(profile, messageType) {
  return (messageType === 'book_a_slot' && profile.book_a_slot_link)
    || (messageType === 'send_an_email' && `mailto:${profile.email}`)
    || null;
}

export function getRoles(person) {
  if (!person || !person.roles) return [];

  return person.roles().map((role) => {
    if (role.title === 'founder') {
      const alchemistClass = person.company && person.company() && person.company().aclass && person.company().aclass();
      if (!alchemistClass || alchemistClass.number === 0) {
        return 'Alchemist';
      }
      return alchemistClass.title || `Alchemist ${alchemistClass.number}`;
    }

    return ROLE_MAPPER[role.title] || startCase(role.title);
  });
}

export function hasRole(person, searchedRole) {
  if (!person || !person.roles) return false;

  return !!person.roles().find((role) => searchedRole === role.title);
}

export function hasRoleOneOf(person, searchedRoles) {
  if (!person || !person.roles) return false;

  return rolesArrayHasRoleOneOf(person.roles(), searchedRoles);
}

export const rolesArrayHasRoleOneOf = (rolesArray, searchedRoles) => Boolean(
  rolesArray.find((role) => searchedRoles.indexOf(role.title) >= 0)
);

export const isAccessHigherThan = (userHighestAccess, role) => CAPABILITIES[userHighestAccess] < CAPABILITIES[role];

export const hasAccessOtherThan = (userRoles, permission) => {
  // Invalid capabilities / accesses are being removed from userRoles first.
  // Then, PERMISSION.demoday and 'investor' are also removed because these does not show more than Demo Day page
  const otherAccess = userRoles?.filter((c) => Object.keys(CAPABILITIES).includes(c)).filter((c) => ![PERMISSION.demoday, 'investor', permission].includes(c));
  return otherAccess?.length > 0;
};

// same as hasAccessOtherThan with small modification
export const hasAccessOtherThanThese = (userRoles, permissions = []) => {
  const otherAccess = userRoles?.filter((c) => Object.keys(CAPABILITIES).includes(c)).filter((c) => ![PERMISSION.demoday, 'investor', ...permissions].includes(c));
  return otherAccess?.length > 0;
};

export function getObjectsField(tags, field) {
  return tags ? tags().map((tag) => tag[field]) : [];
}

export function getTagNamesJoined(tags, join = ' · ') {
  return getObjectsFieldJoined(tags, 'text', join);
}

export function getObjectsFieldJoined(arrayFunction, field, join = ' · ') {
  return getObjectsField(arrayFunction, field).join(join);
}

export function getPersonLinks(person) {
  const {
    linkedin_profile: linkedinProfile, twitter, angelco, website, crunchbase_profile: crunchbaseProfile,
    signal_profile: signalProfile,
  } = person;
  return [
    linkedinProfile &&
    <a href={linkedinProfile} target="_blank" className="dsa-social-icon" key="linkedInLink">
      <FontAwesomeIcon icon={faLinkedin} />
    </a>,
    twitter &&
    <a href={`https://twitter.com/${twitter}`} target="_blank" className="dsa-social-icon" key="twitterLink">
      <FontAwesomeIcon icon={faSquareXTwitter} />
    </a>,
    angelco &&
    <a href={angelco} target="_blank" className="dsa-social-icon" key="angelco">
      <FontAwesomeIcon icon={faAngellist} size="sm" />
    </a>,
    crunchbaseProfile &&
    <a href={crunchbaseProfile} target="_blank" className="dsa-social-icon" key="crunchbase">
      <svg className="svg-inline--fa" viewBox="0 0 24 24"><path d="m 16,10.4 c -0.8,0 -1.52,0.48 -1.92,1.2 l 0,0 c -0.24,0.56 -0.24,1.2 0,1.76 l 0,0 c 0,0.08 0.08,0.16 0.16,0.24 l 0,0 c 0.08,0.16 0.24,0.32 0.4,0.48 l 0,0 c 0.16,0.16 0.32,0.24 0.48,0.32 l 0,0 c 0.16,0.08 0.32,0.16 0.56,0.16 0,0 0.08,0 0.08,0 0.08,0 0.16,0 0.32,0 0.08,0 0.16,0 0.24,0 l 0,0 c 0.08,0 0.16,0 0.16,0 l 0,0 c 0.64,-0.16 1.2,-0.56 1.44,-1.12 l 0,0 C 18,13.36 18,13.28 18,13.2 l 0,0 c 0,-0.08 0.08,-0.16 0.08,-0.24 0,0 0,-0.08 0,-0.08 0.16,-1.12 -0.64,-2.24 -1.76,-2.4 C 16.24,10.4 16.08,10.4 16,10.4 Z" /><path d="M 21.6,0 2.4,0 C 1.04,0 0,1.04 0,2.4 L 0,21.6 C 0,22.96 1.04,24 2.4,24 l 19.2,0 C 22.96,24 24,22.96 24,21.6 L 24,2.4 C 24,1.04 22.96,0 21.6,0 Z M 7.92,14.64 c 0.8,0 1.6,-0.48 1.92,-1.2 l 1.68,0 c -0.48,2 -2.48,3.12 -4.48,2.64 -2,-0.48 -3.2,-2.48 -2.64,-4.48 0.56,-2 2.48,-3.12 4.48,-2.64 1.36,0.32 2.32,1.36 2.64,2.72 l -1.6,0 c -0.48,-1.04 -1.68,-1.6 -2.8,-1.12 -1.04,0.48 -1.6,1.68 -1.12,2.8 0.32,0.8 1.12,1.28 1.92,1.28 z m 10.48,0.64 c -1.28,1.04 -3.12,1.2 -4.48,0.24 l 0,0.4 -1.52,0 0,-10.56 1.52,0 0,4.16 c 0.56,-0.4 1.12,-0.56 1.84,-0.64 0.08,0 0.16,0 0.24,0 2,0 3.68,1.68 3.68,3.68 0,1.04 -0.48,2.08 -1.28,2.72 l 0,0 z" /></svg>
    </a>,
    signalProfile &&
    <a href={signalProfile} target="_blank" className="dsa-social-icon" key="crunchbase">
      <svg className="svg-inline--fa" viewBox="0 0 24 24">
        <g transform="translate(0.000000,25) scale(0.004500,-0.00450)" fill="#000000" stroke="none">
          <path d="M0 2558 l0 -2558 2560 0 2560 0 -2 2558 -3 2557 -2557 1 -2558 0 0 -2558z m2861 1960 c255 -57 412 -138 575 -297 40 -40 97 -104 127 -144 59 -78 122 -176 116 -181 -2 -2 -106 -64 -230 -138 l-226 -134 -29 45 c-39 65 -120 168 -157 202 -150 135 -444 164 -654 63 -277 -132 -356 -445 -166 -654 48 -53 149 -124 223 -158 25 -11 66 -29 91 -40 25 -12 70 -30 100 -42 474 -186 679 -299 854 -475 157 -157 250 -340 283 -558 8 -58 8 -311 0 -374 -29 -207 -101 -382 -224 -543 -55 -73 -167 -185 -224 -223 -25 -17 -47 -34 -50 -37 -7 -9 -136 -83 -180 -103 -94 -42 -239 -82 -345 -94 -122 -13 -406 -6 -434 11 -3 2 -22 7 -41 10 -61 9 -172 47 -255 86 -323 153 -550 474 -633 890 l-18 95 38 12 c21 7 51 14 66 17 15 2 90 18 167 36 77 17 151 33 165 36 14 3 51 11 83 18 41 10 57 10 58 2 17 -149 58 -308 99 -385 85 -158 246 -271 425 -297 272 -39 503 55 640 261 34 51 70 133 79 177 3 13 9 44 14 69 22 111 -4 294 -54 385 -103 188 -240 277 -719 471 -308 125 -488 234 -638 387 -176 181 -261 400 -252 651 2 55 7 111 10 125 3 14 8 41 11 60 10 54 52 165 86 228 155 283 478 497 813 538 22 3 41 7 43 9 8 8 323 2 363 -7z" />
        </g>
      </svg>
    </a>,
    website &&
    <a href={website} target="_blank" className="dsa-social-icon" key="website">
      <FontAwesomeIcon icon={faGlobe} size="sm" />
    </a>,
  ].filter(Boolean);
}

export function getCompanyLinks(company, textWebsite, hideDeckLink, showDemoVideoLink, hideWebsite) {
  const {
    website, linkedin, twitterhandle, angelcoprofile, crunchbase, deck_link: deckLink, demo_video_link: demoVideoLink,
  } = company;
  return [
    website && !hideWebsite &&
    <a href={website} target="_blank" className={`dsa-social-icon${textWebsite ? '__text-url' : ''}`} key="website">
      {textWebsite ? website : <FontAwesomeIcon icon={faGlobe} size="sm" />}
    </a>,
    linkedin && <LinkedInIcon linkedinLink={linkedin} key="linkedin" />,
    twitterhandle &&
    <a href={`https://twitter.com/${twitterhandle}`} target="_blank" className="dsa-social-icon" key="twitterLink">
      <FontAwesomeIcon icon={faSquareXTwitter} />
    </a>,
    angelcoprofile &&
    <a href={angelcoprofile} target="_blank" className="dsa-social-icon" key="angelco">
      <FontAwesomeIcon icon={faAngellist} size="sm" />
    </a>,
    crunchbase &&
    <a href={crunchbase} target="_blank" className="dsa-social-icon" key="crunchbase">
      <svg className="svg-inline--fa" viewBox="0 0 24 24"><path d="m 16,10.4 c -0.8,0 -1.52,0.48 -1.92,1.2 l 0,0 c -0.24,0.56 -0.24,1.2 0,1.76 l 0,0 c 0,0.08 0.08,0.16 0.16,0.24 l 0,0 c 0.08,0.16 0.24,0.32 0.4,0.48 l 0,0 c 0.16,0.16 0.32,0.24 0.48,0.32 l 0,0 c 0.16,0.08 0.32,0.16 0.56,0.16 0,0 0.08,0 0.08,0 0.08,0 0.16,0 0.32,0 0.08,0 0.16,0 0.24,0 l 0,0 c 0.08,0 0.16,0 0.16,0 l 0,0 c 0.64,-0.16 1.2,-0.56 1.44,-1.12 l 0,0 C 18,13.36 18,13.28 18,13.2 l 0,0 c 0,-0.08 0.08,-0.16 0.08,-0.24 0,0 0,-0.08 0,-0.08 0.16,-1.12 -0.64,-2.24 -1.76,-2.4 C 16.24,10.4 16.08,10.4 16,10.4 Z" /><path d="M 21.6,0 2.4,0 C 1.04,0 0,1.04 0,2.4 L 0,21.6 C 0,22.96 1.04,24 2.4,24 l 19.2,0 C 22.96,24 24,22.96 24,21.6 L 24,2.4 C 24,1.04 22.96,0 21.6,0 Z M 7.92,14.64 c 0.8,0 1.6,-0.48 1.92,-1.2 l 1.68,0 c -0.48,2 -2.48,3.12 -4.48,2.64 -2,-0.48 -3.2,-2.48 -2.64,-4.48 0.56,-2 2.48,-3.12 4.48,-2.64 1.36,0.32 2.32,1.36 2.64,2.72 l -1.6,0 c -0.48,-1.04 -1.68,-1.6 -2.8,-1.12 -1.04,0.48 -1.6,1.68 -1.12,2.8 0.32,0.8 1.12,1.28 1.92,1.28 z m 10.48,0.64 c -1.28,1.04 -3.12,1.2 -4.48,0.24 l 0,0.4 -1.52,0 0,-10.56 1.52,0 0,4.16 c 0.56,-0.4 1.12,-0.56 1.84,-0.64 0.08,0 0.16,0 0.24,0 2,0 3.68,1.68 3.68,3.68 0,1.04 -0.48,2.08 -1.28,2.72 l 0,0 z" /></svg>
    </a>,
    deckLink && !hideDeckLink && (
      <ButtonLinkSec href={deckLink} target="_blank" inlineText className="pl-md-6 pl-5" key="pitch-deck">
        Pitch Deck
      </ButtonLinkSec>),
    demoVideoLink && showDemoVideoLink &&
    <ButtonLinkSec href={demoVideoLink} target="_blank" inlineText className="pl-md-6 pl-5" key="pitch-video">
      Pitch Video
    </ButtonLinkSec>,
  ].filter(Boolean);
}

export function getCompanyDemoStatus(company) {
  if (!company) return '-';
  const alchClass = company.aclass && company.aclass();
  const isCompanyInActiveClass = alchClass && alchClass.active;
  const companyDidDemo = company.demo_video_link || company.alchemist_did_demo_on_class;
  if (companyDidDemo && alchClass) return getMonthYearDate(alchClass.demo_date, true, true);
  if (isCompanyInActiveClass) return 'In program';
  return '-';
}

export const getImgLink = (img, postfix, fallback = '/default_user.jpg') => {
  if (!img) return fallback;
  if (img.match(/^[^/:]+$/)) return `/upload/${img}${postfix ? `-${postfix}.jpg` : '.png'}`;
  return img;
};

export const getAcceptanceRatePercentage = (person) => (
  `${Math.round((person.messages_to_me_accepted_count / person.messages_to_me_count) * 100)}%`
);

export function ratingStars(rating, maxStars = 4) {
  const starsRated = [];
  for (let i = 0, faIcon = faStarFull; i < maxStars; i++) {
    if (i >= rating || (rating - i < 0.5 && rating - i >= 0)) { faIcon = faStarEmpty; }
    if (rating - i >= 0.5 && rating - i < 1) {
      starsRated[i] = (
        <span className="fa-layers fa-fw" data-star-number={i + 1} key={i}>
          <FontAwesomeIcon icon={faStarEmpty} />
          <FontAwesomeIcon icon={faStarHalf} />
        </span>
      );
    } else {
      starsRated[i] = (
        <span className="fa-layers fa-fw" data-star-number={i + 1} key={i}>
          <FontAwesomeIcon icon={faIcon} />
        </span>);
    }
  }
  return starsRated;
}

export const yesNoNull = (val, yes = 'Yes', no = 'No') => typeof val === 'boolean' && (val ? yes : no);

export function fixInt(intOrIntStr, prefix = '$') {
  if (!intOrIntStr) return intOrIntStr;
  const intStr = intOrIntStr.toString();
  if (intStr.match(/\$/)) return intStr;
  if (intStr.match(/\d\.\d{3}/)) return `${prefix}${intStr.replace(/./g, ',')}`;
  if (intStr.match(/\d,\d{3}/)) return `${prefix}${intStr}`;
  if (!Number.isNaN(intStr)) return `${prefix}${parseInt(intStr, 10).toLocaleString()}`;

  return intStr;
}


export const getMessageRate = (profile) => {
  if (!profile.messages_to_me_count) return null;
  return `${Math.round((profile.messages_to_me_accepted_count / profile.messages_to_me_count) * 100)}%`;
};

export const getMembershipTitle = (membership) => (membership.title || membership.user().title);

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && re.test(String(email).toLowerCase());
}

export function isUrl(value) {
  const re = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return value && re.test(value);
}

export function fixPhoneNumber(phoneNumber) {
  const phoneNumberNo00 = phoneNumber.replace(/^00/, '+');
  const parsedPhoneNumber = parseNumber(phoneNumber);

  if (phoneNumberNo00.match(/^[^+]/) || parsedPhoneNumber.country === 'US') {
    return formatNumber(phoneNumberNo00, 'US', 'National');
  }
  return formatNumber(phoneNumberNo00, parsedPhoneNumber.country, 'International');
}
export const truncate = (str, len = 45) => str && str.length > len ? `${str.substr(0, len - 1)}…` : str;

export const userCanSeeCoaches = (user, company) => user
  && (user.is_admin
    || (user.teamMemberships && !!user.teamMemberships().find((membership) => membership.company?.().id === company?.id)));

export function getFoundersNamesString(company) {
  if (!company) return '';

  const foundersNames = company.founderMembers && company.founderMembers().map((founder) => founder.name);

  if (foundersNames.length === 1) return foundersNames[0];
  const lastFounder = foundersNames.slice(-1);
  return `${foundersNames.slice(0, foundersNames.length - 1).join(', ')} and ${lastFounder}`;
}

export const getFoundersPlurality = (company) => (
  company && company.founderMembers && company.founderMembers().length > 1 ? 's' : ''
);

export const combineInvestmentRounds = (company) => INVESTMENT_ROUNDS.map((stage) => (company.investment_rounds
  && company.investment_rounds().find((invRound) => invRound.stage === stage))
  || {
    type: RESOURCE_INVESTMENT_ROUNDS,
    id: -1,
    stage,
    company,
    isNew: true,
  });

export function buildGCalEvent(aaClass, invitation) {
  const isCustomerSummit = invitation?.demo_day?.()?.type === RESOURCE_CUSTOMER_SUMMITS;

  if (isCustomerSummit) {
    const events = invitation
      ? invitation.selected_events && invitation.selected_events()
      : aaClass.evet_agenda && aaClass.evet_agenda();
    return `<p>
        <strong>Event Details:</strong><br />
        We are excited to invite you to the Customer Advisory Summit and Demo Day for ${aaClass.romanTitle()} of the
        <a href="http://alchemistaccelerator.com/">Alchemist Accelerator</a>!</br>
        The Summit is an exclusive, invite-only opportunity to meet, share and learn on innovation from several
        sources, including peer leaders on the advisory board, Silicon Valley thought leaders and CIO’s, and
        venture capitalists. And it is also an opportunity to see next gen startups both within and outside the
        accelerator that are relevant to your interests.
      </p>
      <p>
        <strong>When and where:</strong><br />
        ${getLongDate(aaClass.demo_date)}<br />
        ${aaClass.venue_name}, ${aaClass.venue_street_address}, ${aaClass.venue_city_state}<br />
        (Link to directions: <a href=${aaClass.venue_maps_url}>${aaClass.venue_maps_url}</a>)<br />
      </p>
      <p>
        <strong>Event Schedule:</strong><br />
        <ul>
          ${events && events.map((eventAgenda) => `<li>${eventAgenda.fullHtmlDescription}</li>`).join('')}
        </ul>
      </p>`.replace(/>\n/g, '>').replace(/\n/g, ' ').replace(/\s\s/g, ' ');
  }

  // ToDo: aaClass.agenda below should be looping through aaClass.dd_event_agenda
  return `<p>
      <strong>Event Details:</strong><br />
      We are excited to invite you to the Demo Day for ${aaClass?.titleOrRomanTitle()} of the
      <a href="http://alchemistaccelerator.com/">Alchemist Accelerator</a>! </br>
      We are showcasing the startups in our latest class to friends and investors. The Demo Day is the first time we
      will announce the companies publicly.</br>
      You can attend the Demo Day in person, or remotely via an invite-only video feed.
    </p>
    <p>
      <strong>When and where:</strong><br />
      ${getLongDate(aaClass.demo_date)}<br />
      ${aaClass.venue_name}, ${aaClass.venue_street_address}, ${aaClass.venue_city_state}<br />
      (Link to directions: <a href=${aaClass.venue_maps_url}>${aaClass.venue_maps_url}</a>)<br />
    </p>
    <p>
      <strong>Event Schedule:</strong><br />
      ${aaClass.agenda}
    </p>`.replace(/>\n/g, '>').replace(/\n/g, ' ').replace(/\s+/g, ' ');
}

export const replaceSingleQuotes = (string) => string?.replace(/'/g, '’');

import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import reduxFormProps from '../reduxFormProps';

class FieldClass extends React.Component {
  static propTypes = { registerFieldPosition: PropTypes.func, ...reduxFormProps };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.positionRegistered = false;
  }
  componentDidMount() {
    if (this.props.registerFieldPosition && this.inputRef.current) {
      this.registerPosition();
      this.positionRegistered = true;
    }
  }
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  componentDidUpdate() {
    if (this.props.registerFieldPosition && this.inputRef.current && !this.positionRegistered) {
      this.registerPosition();
      this.positionRegistered = true;
    }
  }
  registerPosition() {
    const { registerFieldPosition, formName, input } = this.props;

    setTimeout(
      () => registerFieldPosition(
        formName,
        input.name,
        this.inputRef.current && this.inputRef.current.getBoundingClientRect().top
      ),
      300
    );
    this.positionRegistered = true;
  }

  render() {
    return null;
  }
}

export default FieldClass;

import { extractData } from 'utils/jsonApiExtract';

import { RELOAD_FINISHED, REMOVE_FROM_INCLUDED, REQUEST_RELOAD, SET_IS_AUTH_ROUTE, UPDATE_INCLUDED } from './reducers';
import { resRef } from './utils/refs';

export const updateIncluded = (included) => ({ type: UPDATE_INCLUDED, included });
export const requestReload = (obj, included) => ({ type: REQUEST_RELOAD, objRef: resRef(obj), included });
export const reloadFinished = (obj) => ({ type: RELOAD_FINISHED, objRef: resRef(obj) });
export const updateIncludedFromRequest = (req) => (
  { type: UPDATE_INCLUDED, included: req.data ? extractData(req).inclusions : {} });

export const removeFromIncluded = (resource, id) => ({ type: REMOVE_FROM_INCLUDED, resource, id });
export const setIsAuthRoute = (isUserOnAuthRoute) => ({ type: SET_IS_AUTH_ROUTE, isUserOnAuthRoute });

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';

const LoadingSpinner = ({ size = '3x', className }) => (
  <div className={classNames('text-center', 'dsa-loading-spinner', className)}>
    <FontAwesomeIcon icon={faSpinner} pulse size={size} />
  </div>);

LoadingSpinner.propTypes = { size: PropTypes.string, className: PropTypes.string };

export default LoadingSpinner;

/*
 *
 * PersonModal actions
 *
 */
import { SAVE_FORM_SECTION } from 'containers/Forms/constants';
import { makeFormActionFunction } from 'utils/Forms/actions';

import {
  CLOSE_MODAL,
  OPEN_PERSON_MODAL,
  SET_PERSON_MODAL_SECTION,
  SET_MESSAGE,
  SET_MESSAGE_PARTS,
  SET_MESSAGE_REF,
  SEND_MESSAGE,
  SEND_REVIEW,
  SET_TOPICS,
  SET_REVIEW,
  SET_REVIEW_ANONYMOUS,
  SET_REVIEW_RATING,
  SET_COMMENT_TEXT,
  SAVE_LIST_CHANGES,
  SET_LIST_CHANGE,
  REVIEW_SENT,
  MESSAGE_SENT_ACTION,
  VIEW_QUOTA_DETAILS,
  RESET_LIST_CHANGES,
  SEND_FOLLOW_UP, FOLLOW_UP_SENT_ACTION,
  REMOVE_LIST_FROM_LISTS_CHANGES,
  OPEN_THREAD_MESSAGE,
  SET_THREAD_MESSAGE_LOADING,
  SET_THREAD_MESSAGE_LOADED,
} from './constants';


const closeModal = () => ({ type: CLOSE_MODAL });
const openPersonModal = (section, person, messageRef, messageType, isIncomingMessage) => ({
  type: OPEN_PERSON_MODAL,
  section,
  messageRef,
  person,
  messageType,
  isIncomingMessage,
});

const setPersonModalSection = (section) => ({ type: SET_PERSON_MODAL_SECTION, section });
const setMessage = (message) => ({ type: SET_MESSAGE, message });
const setMessageRef = (messageRef) => ({ type: SET_MESSAGE_REF, messageRef });
const setTopics = (topics) => ({ type: SET_TOPICS, topics });
const setMessageParts = (messageParts) => ({ type: SET_MESSAGE_PARTS, messageParts });
const sendMessage = () => ({ type: SEND_MESSAGE, draft: false });
const sendFollowUp = () => ({ type: SEND_FOLLOW_UP });
const followUpSentAction = () => ({ type: FOLLOW_UP_SENT_ACTION });
const messageSent = (success, draft, isDelete, messageType) => ({
  type: MESSAGE_SENT_ACTION, success, draft, isDelete, messageType,
});
const saveMessageDraft = () => ({ type: SEND_MESSAGE, draft: true });
const deleteMessage = () => ({ type: SEND_MESSAGE, isDeleted: true });
const viewQuotaDetails = () => ({ type: VIEW_QUOTA_DETAILS });

const sendReview = () => ({ type: SEND_REVIEW });
const reviewSent = () => ({ type: REVIEW_SENT });
const setReview = (review) => ({ type: SET_REVIEW, review });
const setReviewAnonymous = (isAnonymous) => ({ type: SET_REVIEW_ANONYMOUS, isAnonymous });
const setReviewRating = (rating) => ({ type: SET_REVIEW_RATING, rating });
const setCommentText = (text) => ({ type: SET_COMMENT_TEXT, text });

const saveListsChanges = () => ({ type: SAVE_LIST_CHANGES });
const setListChange = (listId, isAdded) => ({ type: SET_LIST_CHANGE, listId, isAdded });
const resetListChanges = () => ({ type: RESET_LIST_CHANGES });
const removeFromListsChanges = (listId) => ({ type: REMOVE_LIST_FROM_LISTS_CHANGES, listId });

export const saveProfileReportFor = (reportedProfile) => (values, ...args) => (
  {
    ...makeFormActionFunction(SAVE_FORM_SECTION)('', ...args),
    values: { ...values, reported_profile: reportedProfile },
  });

const openThreadMessage = (messageId, person) => ({ type: OPEN_THREAD_MESSAGE, messageId, person });
const setThreadMessageLoading = () => ({ type: SET_THREAD_MESSAGE_LOADING });
const setThreadMessageLoaded = () => ({ type: SET_THREAD_MESSAGE_LOADED });

export {
  closeModal,
  openPersonModal,
  setPersonModalSection,
  setMessage,
  setMessageRef,
  setTopics,
  sendMessage,
  sendFollowUp,
  followUpSentAction,
  messageSent,
  saveMessageDraft,
  deleteMessage,
  setMessageParts,
  sendReview,
  reviewSent,
  setReview,
  setReviewAnonymous,
  setReviewRating,
  setCommentText,
  saveListsChanges,
  setListChange,
  viewQuotaDetails,
  resetListChanges,
  removeFromListsChanges,
  openThreadMessage,
  setThreadMessageLoading,
  setThreadMessageLoaded,
};

export const NUM_OF_FEEDBACK_SESSION = 2;
export const SLOTS = 10;
export const SLOTS_PER_SESSION = SLOTS / NUM_OF_FEEDBACK_SESSION;
export const MINUTES_PER_SLOT = 25;

export const INFO_TAB = 1;
export const FEEDBACK_TAB = 2;

export const LS_KEY_FEEDBACK_SESSIONS_1_DURATION = 'LS_FEEDBACK_SESSIONS_1_DURATION';

export const MAX_SELECTED_STARTUPS = 10;

export const LS_KEY_LATEST_IFS_DATE = 'LS_KEY_LATEST_IFS_DATE';
export const LS_KEY_LATEST_IFS_EVENT_AGENDA = 'LS_KEY_LATEST_IFS_EVENT_AGENDA';
export const LS_KEY_INSTRUCTIONS_URL = 'LS_KEY_INSTRUCTIONS_URL';
export const LS_KEY_INSTRUCTIONS_TEXT = 'LS_KEY_INSTRUCTIONS_TEXT';

export const FLOATER_AGENDA_NOTIFICATION = 'Your agenda will primarily be populated during the Investor Feedback Summit(IFS). Use this page to track schedule changes and provide meeting feedback.';
export const NON_FLOATER_AGENDA_NOTIFICATION = 'Your agenda will evolve during the Investor Feedback Summit(IFS). Use this page to track schedule changes and provide meeting feedback.';

import React from 'react';
import PropTypes from 'prop-types';

import { getSignInUpString } from 'utils/general';
import { getLinkedInAuthLink } from 'utils/linkedIn';

import ButtonsHolder from '../ButtonsHolder';
import { ButtonPrim } from '../ButtonPrim';


const SignInLinkedInButton = ({
  flow, className = 'my-6', onClick, isSignUp, isSignString, unauthHash, ddInviteFlag, ButtonComponent, size,
}) => {
  const Button = ButtonComponent || ButtonPrim;
  return (
    <ButtonsHolder className={className}>
      <Button
        href={onClick ? null : getLinkedInAuthLink(flow, unauthHash, ddInviteFlag)}
        onClick={onClick}
        target={onClick ? null : '_self'}
        size={size}
      >
        Sign {isSignString || getSignInUpString(isSignUp)} with LinkedIn
      </Button>
    </ButtonsHolder>
  );
};

SignInLinkedInButton.propTypes = {
  flow: PropTypes.string,
  unauthHash: PropTypes.string,
  className: PropTypes.string,
  isSignString: PropTypes.string,
  isSignUp: PropTypes.bool,
  onClick: PropTypes.func,
  ddInviteFlag: PropTypes.string,
  ButtonComponent: PropTypes.node,
  size: PropTypes.string,
};

export default SignInLinkedInButton;


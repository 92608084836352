/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import {
  INITIAL_LOAD,
  LOAD_NEXT_PAGE,
  LOAD_CONTENT,
  SET_SEARCH,
} from 'utils/filter/constants';
import { formatStr } from 'utils/general';

export const SHARE_SECTION = 'share';
export const EDIT_SECTION = 'edit';

export const SHARE_PEOPLE_LIST_MODAL_INITIAL_LOAD = formatStr(INITIAL_LOAD, 'PeopleListManageModal');
export const SHARE_PEOPLE_LIST_MODAL_LOAD_NEXT_PAGE = formatStr(LOAD_NEXT_PAGE, 'PeopleListManageModal');
export const SHARE_PEOPLE_LIST_MODAL_LOAD_CONTENT = formatStr(LOAD_CONTENT, 'PeopleListManageModal');

export const SHARE_PEOPLE_LIST_MODAL_SET_SEARCH = formatStr(SET_SEARCH, 'PeopleListManageModal');

export const CLOSE_MODAL = 'alchemist/PeopleListManageModal/CLOSE_MODAL';
export const SET_SECTION = 'alchemist/PeopleListManageModal/SET_SECTION';
export const OPEN_FOR_LIST = 'alchemist/PeopleListManageModal/OPEN_FOR_LIST';
export const SET_SHARE = 'alchemist/PeopleListManageModal/SET_SHARE';
export const ADD_SHARE = 'alchemist/PeopleListManageModal/ADD_SHARE';
export const ADD_COMPANY_SHARE = 'alchemist/PeopleListManageModal/ADD_COMPANY_SHARE';
export const ADD_SHARES = 'alchemist/PeopleListManageModal/ADD_SHARES';
export const REMOVE_SHARE = 'alchemist/PeopleListManageModal/REMOVE_SHARE';
export const SEND_SHARES = 'alchemist/PeopleListManageModal/SEND_SHARES';

export const SET_PEOPLE_LISTS_MODAL = 'alchemist/People/SET_PEOPLE_LISTS_MODAL';
export const MARK_PEOPLE_LIST = 'alchemist/People/MARK_PEOPLE_LIST';
export const CLEAR_MARKED_PEOPLE_LISTS = 'alchemist/People/CLEAR_MARKED_PEOPLE_LISTS';

export const SET_SHARES_PROCESSING = 'alchemist/People/SET_SHARES_PROCESSING';
export const SET_SHARES_PROCESSED = 'alchemist/People/SET_SHARES_PROCESSED';
export const SET_PEOPLE_COMPANY_LOADING = 'alchemist/People/SET_PEOPLE_COMPANY_LOADING';

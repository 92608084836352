import React from 'react';
import PropTypes from 'prop-types';

const OnePagerHeader = (props) => (
  <div className={`dsa-one-pager__header ${props.className}`}>
    {props.children}
  </div>
);


OnePagerHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

OnePagerHeader.defaultProps = {
  className: '',
};

export default OnePagerHeader;

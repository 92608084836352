import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';


const H2 = ({
  children, className, mobileSize, isMobileTablet,
}) => (
  <h2 className={classNames('dsa-heading', className, isMobileTablet && mobileSize ? `_${mobileSize}_` : '_md-new_')}>
    {children}
  </h2>);


H2.propTypes = {
  children: PropTypes.any,
  mobileSize: PropTypes.string,
  className: PropTypes.string,
  isMobileTablet: PropTypes.bool,
};

export default ResponsiveHoC(H2);

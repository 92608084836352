import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LiVerticalDivider from './LiVerticalDivider';

export const SideBarItem = (props) => <li className={`dsa-site-sidebar_item ${props.className || ''}`}>{props.children}</li>;

SideBarItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export const SideBarLinkItem = ({
  onClick, to, children, href, faIcon, target = null, show = true, hasTopDivider, editComponent = null,
  containerClassName = '',
}) => (
  show
    ? (
      <>
        {hasTopDivider && <LiVerticalDivider />}
        <SideBarItem className={containerClassName}>
          <NavLink to={to} onClick={onClick} href={href} tag={to && NavLinkReact} target={href ? '_blank' : target}>
            {faIcon && <FontAwesomeIcon icon={faIcon} size="lg" fixedWidth />}
            {children}
          </NavLink>
          {editComponent}
        </SideBarItem>
      </>)
    : null);

SideBarLinkItem.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  target: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  faIcon: PropTypes.any,
  show: PropTypes.bool,
  hasTopDivider: PropTypes.bool,
  containerClassName: PropTypes.string,
  editComponent: PropTypes.node,
};

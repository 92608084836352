export const FILTER_OPERATORS = {
  EQ: 'eq',
  ENCLOSED: 'enclosed',
  SEARCH: 'search',
};

export const COMMON_ROLES = {
  founder: 'Founders',
  faculty: 'Faculty',
  mentor: 'Mentors',
  angel: 'Angels',
  investor: 'VCs',
  corporate: 'Corporate Innovator',
  service_provider: 'Service Providers',
  press: 'Press',
  alumni_mentor: 'Alumni Mentor',
};

export const ADMIN_ROLES = {
  limited_partner: 'Limited Partner',
  channel_partner: 'Channel Partner',
  industry_analyst: 'Industry Analyst',
  customer: 'Customer',
  lecturer: 'Lecturer',
  consultant: 'Consultant',
  acquirer: 'Acquirer',
  strategic_investor: 'Strategic Investor',
  partner: 'Partner',
  network: 'Network',
  admin: 'Admin',
};

export const ALL_ROLES = { ...COMMON_ROLES, ...ADMIN_ROLES };

/*
 *
 * Mobile Desktop Switcher reducer
 *
 */

import { fromJS } from 'immutable';

import { LOAD_DESKTOP_VERSION } from './constants';

const initialState = fromJS({
  isDesktopVersion: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DESKTOP_VERSION:
      return state.set('isDesktopVersion', !state.get('isDesktopVersion'));
    default:
      return state;
  }
}

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faArrowsCross } from '@fortawesome/pro-regular-svg-icons';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { RESOURCE_PEOPLE } from 'containers/App/constants';
import { refUrl } from 'utils/refs';
import { setDefaultImg, setDefaultCompanyImg } from 'utils/general';

import LiVerticalDivider from './LiVerticalDivider';


const AccountMenuContent = ({
  logoutAction, myProfile, isFounderPrimaryMembership, primaryCompany, isLocked, closeOnClick, isDesktop, iAmDemoDay, authUser, deimpersonate, hasMultiFounderCaps, openFounderPopup, hasMultiActiveClasses,
}) => (
  <>
    {isLocked
    || (
      <>
        <LiVerticalDivider />
        {myProfile &&
          <li className="dsa-site-sidebar_item">
            <NavLink
              tag={NavLinkReact}
              to={`/${RESOURCE_PEOPLE}/${myProfile.id}`}
              onClick={closeOnClick}
            >
              <img
                className="dsa-avatar dsa-avatar__popover"
                src={myProfile.avatarLink('lg')}
                alt={`${myProfile.name}'s profile`}
                onError={(e) => setDefaultImg(e)}
              />
              My Profile
            </NavLink>
          </li>}
        {myProfile && myProfile.company && !iAmDemoDay && (
          <>
            <li className="dsa-site-sidebar_item">
              <NavLink
                tag={NavLinkReact}
                to={refUrl(myProfile.company())}
                onClick={closeOnClick}
              >
                <img
                  className="dsa-avatar dsa-avatar__popover"
                  src={myProfile.company().avatarLink('lg')}
                  alt={`${myProfile.company().name}'s profile`}
                  onError={(e) => setDefaultCompanyImg(e)}
                />
                My Company
              </NavLink>
            </li>
            {isDesktop && isFounderPrimaryMembership && (
              <li className="dsa-site-sidebar_item">
                <NavLink
                  tag={NavLinkReact}
                  to={`${refUrl(primaryCompany)}/manage-team`}
                  onClick={closeOnClick}
                >
                  <FontAwesomeIcon icon={faUsers} size="lg" fixedWidth />
                  Manage Team
                </NavLink>
              </li>)}
          </>)}
      </>)}
    {hasMultiFounderCaps && hasMultiActiveClasses &&
      <li className="dsa-site-sidebar_item">
        <button
          type="button"
          className="nav-link"
          onClick={(e) => {
            openFounderPopup();
            return closeOnClick(e);
          }}
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faArrowsCross} fixedWidth className="dsa-avatar__width-xs" />
            Switch Class
          </div>
        </button>
      </li>
    }
    <li className="dsa-site-sidebar_item">
      <button className="nav-link" onClick={authUser?.impersonator ? deimpersonate : logoutAction} type="button">
        <FontAwesomeIcon icon={faSignOut} fixedWidth className="dsa-avatar__width-xs" />
        {authUser?.impersonator ? 'Stop impersonating' : 'Log Out'}
      </button>
    </li>
  </>
);

AccountMenuContent.propTypes = {
  closeOnClick: PropTypes.func,
  logoutAction: PropTypes.func,
  myProfile: PropTypes.object,
  primaryCompany: PropTypes.object,
  isFounderPrimaryMembership: PropTypes.bool,
  isLocked: PropTypes.bool,
  isDesktop: PropTypes.bool,
  iAmDemoDay: PropTypes.bool,
  authUser: PropTypes.object,
  deimpersonate: PropTypes.func,
  hasMultiFounderCaps: PropTypes.bool,
  openFounderPopup: PropTypes.func,
  hasMultiActiveClasses: PropTypes.bool,
};


export default ResponsiveHoC(AccountMenuContent);

import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooter, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import DsaModal from 'components/Modals/DsaModal';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';

import {
  makeSelectMergeIsLoading,
  makeSelectMergeModalIsOpen,
  makeSelectMergeTargetRef,
  selectMarkedMergeableLists,
} from '../selectors';
import { closePeopleListMergeModal, makeMerge, setMergeTarget } from '../actions';
import SelectListRadioButton from './SelectListRadioButton';


const PeopleListMergeModal = ({
  closeModal, isOpen, selectedList, markedLists, isLoading, loadMerge,
}) => (
  <DsaModal isOpen={isOpen} toggle={closeModal}>
    <ModalHeader tag="h1">Merge Lists</ModalHeader>
    <CloseButtonWrapper closeAction={closeModal} />
    <ModalBody>
      <legend>Into which list would you like to merge all the rest lists?</legend>
      {markedLists && markedLists.map((list) =>
        <SelectListRadioButton list={list} key={list.id} />)}
    </ModalBody>
    <ModalFooter>
      <ButtonPrim disabled={!selectedList || isLoading} onClick={loadMerge}>Merge</ButtonPrim>
      <ButtonSec onClick={closeModal}>Close</ButtonSec>
    </ModalFooter>
  </DsaModal>
);

PeopleListMergeModal.propTypes = {
  closeModal: PropTypes.func,
  loadMerge: PropTypes.func,
  markedLists: PropTypes.array,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedList: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ id: PropTypes.number, type: PropTypes.string }),
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: (field, value) => dispatch(closePeopleListMergeModal(field, value)),
  selectList: (list) => dispatch(setMergeTarget(list)),
  loadMerge: () => dispatch(makeMerge()),
});

const mapStateToProps = createStructuredSelector({
  isOpen: makeSelectMergeModalIsOpen(),
  selectedList: makeSelectMergeTargetRef(),
  markedLists: selectMarkedMergeableLists,
  isLoading: makeSelectMergeIsLoading(),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleListMergeModal);

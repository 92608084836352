import { createSelector } from 'reselect';

import { makeSubSelector } from 'utils/selectors';
import { makeSelectLocation, makeSelectObjectFromRefSelector } from 'containers/App/selectors';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});

const selectCompanySinglePage = (state) => state ? state.get('companySinglePage', localInitialState) : localInitialState;

const makeSelectCompanyTeamIsLoading = () => makeSubSelector(selectCompanySinglePage, ['companyTeamIsLoading']);
const makeSelectCompanyNotFound = () => makeSubSelector(selectCompanySinglePage, ['notFound']);
const makeSelectCompanyRef = () => makeSubSelector(selectCompanySinglePage, ['company']);
const makeSelectCompany = () => makeSelectObjectFromRefSelector(makeSelectCompanyRef());
const selectIsPublic = createSelector(
  makeSelectLocation(),
  (location) => location.pathname.match(/companies\/public\//)
);
const selectIsIfs = createSelector(
  makeSelectLocation(),
  (location) => location.pathname.match(/ifs/)
);

export {
  makeSelectCompany,
  makeSelectCompanyRef,
  makeSelectCompanyTeamIsLoading,
  makeSelectCompanyNotFound,
  selectIsPublic,
  selectIsIfs,
};


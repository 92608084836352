/*
 *
 * AlertBox constants
 *
 */

export const CLOSE_ALERT = 'app/AlertBox/CLOSE_ALERT';
export const SHOW_ALERT = 'app/AlertBox/SHOW_ALERT';
export const DISPLAY_ALERT = 'app/AlertBox/DISPLAY_ALERT';

export const COLOR = Object.freeze({
  danger: 'danger',
  warning: 'warning',
  success: 'success',
});


/**
 * Handles the people lists
 */
import { delay, call, all, put, select, takeLatest } from 'redux-saga/effects';
import { parse } from 'qs';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  API_ACCOUNTS_BASE_URL,
  API_PEOPLE_LISTS_BASE_URL,
  // API_PUBLIC_PEOPLE_LISTS_BASE_URL,
  RESOURCE_PEOPLE,
  RESOURCE_PEOPLE_LISTS,
  VARIETY,
} from 'containers/App/constants';
import { makeSelectLocation } from 'containers/App/selectors';
import { showAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { loadLists } from 'containers/AuthProvider/saga';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { fetchObjects } from 'utils/filter/saga';
import { handlePlural } from 'utils/general';
import { extractData } from 'utils/jsonApiExtract';
import { logError } from 'utils/log';
import { refApiUrl } from 'utils/refs';
import { deleteReq, post, patch } from 'utils/request';
import { updateObjFromApi, markNotifsAsViewed, waitAndSelectAccount, refreshCurrentRoute } from 'utils/sagas';
import { updateIncluded } from 'actions';
import { generateFiltersLocalString } from 'utils/filter/filter';
import { preprocessError } from 'utils/Forms/sagas';

import {
  loadOwnLists, loadSharedWithMeLists, setFiltersAndSort, clearMarkedOwnLists, clearMarkedSharedWithMeLists, clearMarkedEventLists, mergeDone, copyDone, loadEventLists, loadAllLists,
} from './actions';
import {
  // COPY_PUBLIC_LISTS,
  COPY_SHARED_WITH_ME_LISTS,
  DELETE_PEOPLE_LISTS,
  INITIAL_LOAD_ALL_LISTS,
  LOAD_ALL_LISTS,
  MAKE_MERGE,
  LOAD_OWN_LISTS,
  // LOAD_PUBLIC_LISTS,
  LOAD_SHARED_LISTS,
  PEOPLE_LISTS_SET_SEARCH,
  REMOVE_ACCESS_FROM_SHARED_WITH_ME_LISTS, PEOPLE_LISTS_IDENTIFIER, SHARED_WITH_ME_LISTS,
  OWN_LISTS, EVENT_LISTS, LOAD_EVENT_LISTS, LOAD_NEXT_PAGE_EVENT_LISTS, LOAD_NEXT_PAGE_OWN_LISTS, LOAD_NEXT_PAGE_SHARED_WITH_ME_LISTS,
  ARCHIVE_LISTS, RESTORE_LISTS, COPY_EVENT_LISTS,
} from './constants';
import {
  makeSelectEventListsContent,
  makeSelectFilter,
  makeSelectMergeTargetRef, makeSelectOwnListsContent, makeSelectSharedWithMeListsContent, selectMarkedEventLists, selectMarkedMergeableLists,
  selectMarkedOwnLists, selectMarkedSharedWithMeLists, selectMarkedSharedWithMeListsRels, selectPeopleListsPage,
} from './selectors';
import { preprocessAppliedFilters, preprocessSort } from './Filters/filters';
import { getListsPerRowNumber } from './utils';


function* deletePeopleLists(action) {
  let peopleLists = yield select(selectMarkedOwnLists);

  if (action.listType === EVENT_LISTS) {
    peopleLists = yield select(selectMarkedEventLists);
  }

  /* eslint no-alert: 0 */
  if (!window.confirm(`Are you sure you want to delete this ${handlePlural(peopleLists.length, 'list')}?`)) return null;

  try {
    yield all(peopleLists.map((peopleList) => call(deleteReq, `${API_PEOPLE_LISTS_BASE_URL}/${peopleList.id}`)));
    yield put(showAlert(`${handlePlural(peopleLists.length, 'List', 'lists')} removed successfully`));
  } catch (err) {
    logError(err);
    yield put(showAlert(
      `Error trying to remove ${handlePlural(peopleLists.length, 'a list', 'lists')}`,
      COLOR.danger
    ));
  }
  yield put(loadOwnLists());
  yield put(loadEventLists());
  yield put(clearMarkedOwnLists());
  yield put(clearMarkedEventLists());

  const myAccount = yield waitAndSelectAccount();
  yield updateObjFromApi(myAccount, 'user_lists');

  return null;
}

function* archivePeopleLists() {
  const peopleLists = yield select(selectMarkedOwnLists);

  try {
    yield all(peopleLists.map((peopleList) => call(patch, `${API_PEOPLE_LISTS_BASE_URL}/${peopleList.id}`, {
      data: {
        type: 'peoplelists',
        id: peopleList.id,
        attributes: {
          archived: true,
        },
      },
    })));

    yield put(showAlert(`${handlePlural(peopleLists.length, 'List', 'lists')} archived successfully`));
  } catch (err) {
    logError(err);
    yield put(showAlert(
      `Error trying to archive ${handlePlural(peopleLists.length, 'a list', 'lists')}`,
      COLOR.danger
    ));
  }
  yield put(loadOwnLists());
  yield put(clearMarkedOwnLists());

  const myAccount = yield waitAndSelectAccount();
  yield updateObjFromApi(myAccount, 'user_lists');

  return null;
}

function* restorePeopleLists(action) {
  let peopleLists = yield select(selectMarkedOwnLists);

  if (action.listType === EVENT_LISTS) {
    peopleLists = yield select(selectMarkedEventLists);
  }

  try {
    yield all(peopleLists.map((peopleList) => call(patch, `${API_PEOPLE_LISTS_BASE_URL}/${peopleList.id}`, {
      data: {
        type: 'peoplelists',
        id: peopleList.id,
        attributes: {
          archived: false,
        },
      },
    })));

    yield put(showAlert(`${handlePlural(peopleLists.length, 'List', 'lists')} restored successfully`));
  } catch (err) {
    logError(err);
    yield put(showAlert(
      `Error trying to restore ${handlePlural(peopleLists.length, 'a list', 'lists')}`,
      COLOR.danger
    ));
  }

  if (action.listType === EVENT_LISTS) {
    yield put(loadEventLists());
    yield put(clearMarkedEventLists());
  } else {
    yield put(loadOwnLists());
    yield put(clearMarkedOwnLists());
  }

  const myAccount = yield waitAndSelectAccount();
  yield updateObjFromApi(myAccount, 'user_lists');

  return null;
}

function* removeAccess() {
  const peopleListsRels = yield select(selectMarkedSharedWithMeListsRels);
  /* eslint no-alert: 0 */
  if (!window.confirm(`Are you sure you want to remove your access to this ${handlePlural(peopleListsRels.length, 'list')}?`)) return null;

  try {
    yield all(peopleListsRels.map((peopleListRel) => call(deleteReq, refApiUrl(peopleListRel))));
    yield put(showAlert(`Access to ${handlePlural(peopleListsRels.length, 'list')} removed successfully`));
  } catch (err) {
    logError(err);
    yield put(showAlert(
      `Error trying to remove access from ${handlePlural(peopleListsRels.length, 'a list', 'lists')}`,
      COLOR.danger
    ));
  }
  yield put(loadSharedWithMeLists());
  yield put(clearMarkedSharedWithMeLists());

  const myAccount = yield waitAndSelectAccount();
  yield updateObjFromApi(myAccount, 'user_lists');

  return null;
}

function* copySharedWithMeListsToMyLists() {
  yield copyToMyLists(selectMarkedSharedWithMeLists);
  yield put(clearMarkedSharedWithMeLists());
  return null;
}

function* copyEventListsToMyLists() {
  yield copyToMyLists(selectMarkedEventLists);
  yield put(clearMarkedEventLists());
  return null;
}

// function* copyPublicListsToMyLists() {
//   yield copyToMyLists(selectMarkedPublicLists);
//   yield put(clearMarkedPublicLists());
//   return null;
// }

function* createNewListAndMerge(list) {
  const newCopiedList = yield call(
    post,
    `${API_PEOPLE_LISTS_BASE_URL}/copy/${list.id}`
  );
  const { inclusions, items } = extractData(newCopiedList);
  yield updateIncluded(inclusions);
  const baseMergeUrl = `${refApiUrl(items[0])}/merge`;
  yield call(patch, `${baseMergeUrl}/${list.id}`);
  return null;
}

function* copyToMyLists(peopleListsSelector) {
  const peopleLists = yield select(peopleListsSelector);
  try {
    yield all(peopleLists.map((list) => call(createNewListAndMerge, list)));
    yield put(showAlert(`${handlePlural(peopleLists.length, 'List', 'lists')} successfully copied`));
  } catch (err) {
    logError(err);
    yield put(showAlert('Error trying to copy lists. Another list with that name might already exist', COLOR.danger));
  }
  yield put(copyDone());
  yield put(loadOwnLists());
}

/**
 * Only trigger the search when the user stops to breath
 */
function* debounceSearch() {
  yield delay(300);
  yield loadAllListsSaga();
}

function* loadAllListsSaga() {
  yield put(loadOwnLists());
  yield put(loadSharedWithMeLists());

  const isAdmin = yield select(makeSelectUserIs(VARIETY.admin));
  if (isAdmin) {
    yield put(loadEventLists());
  }
  // yield put(loadPublicLists());
}

function* loadListsSaga(
  baseApiUrl,
  filtersFirstChar,
  loadedContentIdentifier,
  loadedContentCustomSelector,
  filtersFieldsPrefix = '',
  isFetchNextPage = false,
) {
  yield fetchObjects({
    isFetchNextPage,
    filtersFirstChar,
    preprocessAppliedFilters: (appliedFilters) => preprocessAppliedFilters(appliedFilters, filtersFieldsPrefix),
    preprocessSort: (sort) => preprocessSort(sort, filtersFieldsPrefix),
    baseApiUrl,
    controlClientUrl: `/${RESOURCE_PEOPLE}/lists`,
    pageIdentifier: PEOPLE_LISTS_IDENTIFIER,
    pageSelector: selectPeopleListsPage,
    loadedContentCustomSelector,
    loadedContentIdentifier,
    contentMaxPageIsNeeded: true,
  });
}

function* loadOwnListsSaga(isFetchNextPage = false) {
  const myAccount = yield waitAndSelectAccount();
  const isAdmin = yield select(makeSelectUserIs(VARIETY.admin));
  const listsPerRow = getListsPerRowNumber();

  const filters = isAdmin ? '&filter[event_list:eq]=False' : '';
  yield loadListsSaga(
    `${API_ACCOUNTS_BASE_URL}/${myAccount.id}/user_lists?include=creator&page[size]=${(listsPerRow * 3) + 1}&fields[accounts]=id${filters}`,
    '&',
    OWN_LISTS,
    makeSelectOwnListsContent(),
    '',
    isFetchNextPage
  );
  if (!isFetchNextPage) {
    yield loadLists();
  }
}

function* loadSharedWithMeListsSaga(isFetchNextPage = false) {
  const myAccount = yield waitAndSelectAccount();
  const listsPerRow = getListsPerRowNumber();

  yield loadListsSaga(
    `${API_ACCOUNTS_BASE_URL}/${myAccount.id}/shared_lists_with_me_rel?include=user_list.creator.profile&page[size]=${(listsPerRow * 3) + 1}&fields[accounts]=id,profile&fields[people]=nicename`,
    '&',
    SHARED_WITH_ME_LISTS,
    makeSelectSharedWithMeListsContent(),
    'user_list.',
    isFetchNextPage
  );

  yield markNotifsAsViewed(RESOURCE_PEOPLE_LISTS);
}

// function* loadPublicListsSaga() {
//   yield loadListsSaga(
//     `${API_PUBLIC_PEOPLE_LISTS_BASE_URL}`,
//     '?',
//     PUBLIC_LISTS,
//     makeSelectPublicListsContent(),
//   );
// }

function* loadEventListsSaga(isFetchNextPage = false) {
  yield waitAndSelectAccount();
  const listsPerRow = getListsPerRowNumber();

  yield loadListsSaga(
    `${API_PEOPLE_LISTS_BASE_URL}?include=ifs_participant_sets,demo_day,creator.profile&page[size]=${(listsPerRow * 3) + 1}&fields[accounts]=id,profile&fields[people]=nicename&filter[event_list:eq]=True`,
    '&',
    EVENT_LISTS,
    makeSelectEventListsContent(),
    '',
    isFetchNextPage
  );
  yield markNotifsAsViewed(RESOURCE_PEOPLE_LISTS);
}

export function* makeMergeSaga() {
  const listsToMerge = yield select(selectMarkedMergeableLists);
  const mergeTargetRef = yield select(makeSelectMergeTargetRef());
  if (!mergeTargetRef) return;

  const listsToMergeIntoTarget = listsToMerge.filter((list) => list.id !== mergeTargetRef.id);
  const baseMergeUrl = `${refApiUrl(mergeTargetRef)}/merge`;
  try {
    yield call(patch, `${baseMergeUrl}/${listsToMergeIntoTarget.map((l) => l.id).join('/')}`);
    yield put(showAlert('Success! Lists merged.'));
    yield put(loadOwnLists());
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    if (errorJson?.errors?.[0]?.detail) {
      yield put(showAlert(errorJson.errors[0].detail, COLOR.danger));
    }
  } finally {
    yield clearAll();
    yield put(mergeDone());
  }
}

function* clearAll() {
  yield put(clearMarkedOwnLists());
  // yield put(clearMarkedPublicLists());
  yield put(clearMarkedSharedWithMeLists());
  yield put(clearMarkedEventLists());
}

/**
 * Load the filters from the url search parameters
 */
export function* initialLoadSaga() {
  const location = yield select(makeSelectLocation());
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  yield put(setFiltersAndSort(queryParams.filter || {}));
  yield loadAllListsSaga();
}

function* refreshCurrentRouteLocalSaga() {
  const filterImmutable = yield select(makeSelectFilter());

  yield refreshCurrentRoute({
    action: loadAllLists(),
    condition: (location) => location.pathname === `/${RESOURCE_PEOPLE}/lists`
    && `${generateFiltersLocalString(filterImmutable)}` !== location.search && !location.search,
  });
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  yield takeLatest(LOCATION_CHANGE, refreshCurrentRouteLocalSaga);
  yield takeLatest(LOAD_ALL_LISTS, loadAllListsSaga);
  yield takeLatest(LOAD_OWN_LISTS, loadOwnListsSaga, false);
  yield takeLatest(LOAD_SHARED_LISTS, loadSharedWithMeListsSaga, false);
  yield takeLatest(LOAD_EVENT_LISTS, loadEventListsSaga, false);
  // yield takeLatest(LOAD_PUBLIC_LISTS, loadPublicListsSaga);
  yield takeLatest(DELETE_PEOPLE_LISTS, deletePeopleLists);
  yield takeLatest(REMOVE_ACCESS_FROM_SHARED_WITH_ME_LISTS, removeAccess);
  yield takeLatest(COPY_SHARED_WITH_ME_LISTS, copySharedWithMeListsToMyLists);
  yield takeLatest(COPY_EVENT_LISTS, copyEventListsToMyLists);
  // yield takeLatest(COPY_PUBLIC_LISTS, copyPublicListsToMyLists);
  yield takeLatest(PEOPLE_LISTS_SET_SEARCH, debounceSearch);
  yield takeLatest(INITIAL_LOAD_ALL_LISTS, initialLoadSaga);
  yield takeLatest(MAKE_MERGE, makeMergeSaga);
  yield takeLatest(ARCHIVE_LISTS, archivePeopleLists);
  yield takeLatest(RESTORE_LISTS, restorePeopleLists);

  yield takeLatest(LOAD_NEXT_PAGE_OWN_LISTS, loadOwnListsSaga, true);
  yield takeLatest(LOAD_NEXT_PAGE_SHARED_WITH_ME_LISTS, loadSharedWithMeListsSaga, true);
  yield takeLatest(LOAD_NEXT_PAGE_EVENT_LISTS, loadEventListsSaga, true);
}

import React from 'react';
import PropTypes from 'prop-types';

import Message from './Message';


const ChatHistory = ({ messages }) => (
  <div className="chat-container">
    {messages.map(([role, text], index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Message key={`${role}-${index}`} role={role} text={text} index={index} />
    ))}
  </div>
);

ChatHistory.propTypes = {
  messages: PropTypes.array,
};

export default ChatHistory;

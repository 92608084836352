import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSec } from '../Buttons/ButtonSec';

const DefaultCloseButton = ({ isSubmitDisabled, ...props }) => (
  <ButtonSec {...props}>Cancel</ButtonSec>);

DefaultCloseButton.propTypes = {
  isSubmitDisabled: PropTypes.bool,
};

export default DefaultCloseButton;

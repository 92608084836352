import React from 'react';
import PropTypes from 'prop-types';

import { MainBox } from 'components/Lists/EmptyListWelcomeStyles';
import { SubTitle, Title } from 'components/Text/Titles';


const EmptyListMessage = ({ title, text, children }) => (
  <MainBox>
    <Title>{title}</Title>
    <SubTitle className="dsa-text-sec">{text}</SubTitle>
    {children}
  </MainBox>
);

EmptyListMessage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
};

export default EmptyListMessage;


import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TextI } from 'components/Text/Body';
import { TitleUpper } from 'components/Text/Titles';

const ProfileSubSection = ({
  title, count, children, verticalDivider, className, description, warning,
}) => (
  <div className={classNames('dsa-profile__sub-section', className, { warning })}>
    {title && (
      <TitleUpper className={classNames('me-7', { 'mb-3': description })}>
        {title} {count && <i>({count} total)</i>}
      </TitleUpper>)}
    {description && <TextI className="mb-6">{description}</TextI>}
    {children}
    {verticalDivider && <div className="dsa-vertical-divider" />}
  </div>
);

ProfileSubSection.propTypes = {
  children: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  count: PropTypes.number,
  className: PropTypes.string,
  description: PropTypes.string,
  verticalDivider: PropTypes.bool,
  warning: PropTypes.bool,
};

export default ProfileSubSection;

import PropTypes from 'prop-types';

export default {
  input: PropTypes.any,
  label: PropTypes.string,
  helpText: PropTypes.string,
  isHelpTextFloated: PropTypes.bool,
  /* eslint-disable react/no-unused-prop-types */
  placeholder: PropTypes.string,
  type: PropTypes.string,
  help: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  /* eslint-enable react/no-unused-prop-types */
};

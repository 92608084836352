import React from 'react';
import PropTypes from 'prop-types';

import { ContextPropsHOC } from 'utils/higherOrderComponents';

const SearchContext = React.createContext();

export const SearchContextProvider = SearchContext.Provider;

SearchContextProvider.propTypes = {
  value: PropTypes.shape({ search: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]) }),
};

export const ConsumeSearchContextHOC = ContextPropsHOC(SearchContext);

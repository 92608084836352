import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { parse } from 'qs';


import { ButtonSec } from 'components/Buttons/ButtonSec';
import SignInEmailButton from 'components/Buttons/Specific/SignInEmailButton';
import SignInLinkedInButton from 'components/Buttons/Specific/SignInLinkedInButton';
import MainContent from 'components/MainContent';
import OnePager from 'components/OnePager/OnePager';
import { Text } from 'components/Text/Body';
import { makeSelectLocation, makeSelectQueryParam } from 'containers/App/selectors';
import { showAlert } from 'containers/AlertBox/actions';
import { FLOW_TYPE } from 'containers/AuthProcess/constants';
import GdprFooter from 'containers/Gdpr/Footer';
import TermsParagraph from 'containers/Gdpr/TermsParagraph';
import { getSignInUpString } from 'utils/general';


class SignInPage extends React.Component {
  componentDidMount() {
    if (parse(this.props.location.search, { ignoreQueryPrefix: true }).fail) {
      this.props.sendAlertMessage('Oops! We don\'t have an account with those credentials.'
        + ' Please try again or send us a message via the help icon in the bottom right corner');
    }
  }

  render() {
    const isFail = parse(this.props.location.search, { ignoreQueryPrefix: true }).fail;
    return (
      <MainContent noSidebar>
        <Helmet>
          <title>The Vault</title>
          <meta name="google-site-verification" content="14HeQH9OrmWNieZUE-TR5YvE0OHdmeUVnFqHR45Ggaw" />
          <meta
            name="description"
            content="The Alchemist Accelerator Vault login page exclusively for Alchemist founders ‌to start connecting with our network."
          />
        </Helmet>
        <OnePager
          title={isFail ? 'LinkedIn Account not linked to The Vault' : 'Welcome to the Alchemist Vault!'}
          footer={<GdprFooter />}
        >
          {isFail
            ? (
              <>
                <Text>It seems that your LinkedIn account is not connected to our platform.</Text>
                <Text>Click below to login with a different LinkedIn Account.</Text>
                <ButtonSec noMargin className="mb-7" href="https://www.linkedin.com/login" target="_blank">
                  Switch LinkedIn Account
                </ButtonSec>
                <Text>After switching your LinkedIn Account, try again to sign in in this page.</Text>
              </>)
            : (
              <>
                <Text>The Alchemist Vault is a product specifically for Alchemist Accelerator Founders, providing invaluable program resources. Please use the button below to login!</Text>
                <Text>If you have any issues or questions, please message us using the help icon in the bottom right corner.</Text>
              </>)}
          <SignInLinkedInButton flow={FLOW_TYPE.standard} ddInviteFlag={this.props.ddInviteFlag} />
          <SignInEmailButton flow={FLOW_TYPE.standard} ddInviteFlag={this.props.ddInviteFlag} />
          <TermsParagraph signInText={`Sign ${getSignInUpString()}`} />
        </OnePager>
      </MainContent>
    );
  }
}

SignInPage.propTypes = {
  location: PropTypes.object,
  sendAlertMessage: PropTypes.func,
  ddInviteFlag: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  ddInviteFlag: makeSelectQueryParam('for'),
});

const mapDispatchToProps = (dispatch) => ({
  sendAlertMessage: (alertMsg) => dispatch(showAlert(alertMsg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);

import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Generic/Tooltip';
import { AAHighlight } from 'components/Search/AAHighlight';
import { ParSmall } from 'components/Text/Body';


class TooltipPrim extends React.Component {
  constructor(props) {
    super(props);
    this.outerRef = React.createRef();
    this.state = { needsTooltip: false };
    this.showTooltipContent = this.showTooltipContent.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.showTooltipContent, false);
    this.showTooltipContent();
  }

  componentDidUpdate() {
    this.showTooltipContent();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.showTooltipContent);
  }

  showTooltipContent() {
    if (!this.state.needsTooltip && this.outerRef.current
      && this.outerRef.current.scrollWidth > this.outerRef.current.clientWidth) {
      // componentDidUpdate: eslint-disable-next-line react/no-did-update-set-state
      // componentDidMount: eslint-disable-next-line react/no-did-mount-set-state
      this.setState(() => ({ needsTooltip: true }));
    }
  }

  render() {
    const {
      text, delay = 350, className = '', placement = 'top', children,
      tooltipClassName = '', noTooltip = false, isTextTruncate = true,
      alwaysNeeded,
    } = this.props;
    const content = children || text;
    const tooltipContent = text || children;

    const showTooltip = alwaysNeeded || this.state.needsTooltip;

    return (
      <>
        <div className={isTextTruncate ? `text-truncate ${className}` : className} ref={this.outerRef}>
          <AAHighlight onClick={() => this.showTooltipContent()}>
            {content}
          </AAHighlight>
        </div>
        {showTooltip && !noTooltip && (
          <Tooltip
            placement={placement}
            autohide={false}
            targetRef={this.outerRef}
            delay={delay}
            className={`dsa-tooltip-prim ${tooltipClassName}`}
          >
            <ParSmall
              className={tooltipClassName.includes('dsa-tooltip-prim__alchemist-blue')
                ? 'mb-0'
                : 'mb-0 dsa-text-sec'
              }
            ><AAHighlight>{tooltipContent}</AAHighlight>
            </ParSmall>
          </Tooltip>)}
      </>
    );
  }
}


TooltipPrim.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  delay: PropTypes.number,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.any,
  noTooltip: PropTypes.bool,
  isTextTruncate: PropTypes.bool,
  alwaysNeeded: PropTypes.bool,
};

export default TooltipPrim;

import { call, put } from 'redux-saga/effects';

import { updateIncludedFromRequest } from 'actions';
import { handleError } from 'utils/Forms/sagas';
import { patch } from 'utils/request';
import { putActions } from 'utils/sagas';


export function* saveSiteSettingsGroupSaga(action) {
  try {
    const siteSettingsRequest = yield call(
      patch,
      `/api/v1/sitesettings/${action.section}`,
      { data: { id: action.section, type: 'sitesettings', attributes: action.values } }
    );
    yield put(updateIncludedFromRequest(siteSettingsRequest));

    if (Array.isArray(action.successAction)) {
      yield putActions(action.successAction);
    } else if (action.successAction) {
      yield put(action.successAction);
    }
  } catch (err) {
    yield handleError(err, action.section);
  }
}

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { makeSelectUser } from 'containers/AuthProvider/selectors';


const CustomIntercomLauncher = ({ isDesktop, isMobileTablet, authUser }) => {
  const isImpersonator = authUser?.impersonator;
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [hideButtonIsVisible, setCloseButtonIsVisible] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const pathIsDemoDay = (path) => path.includes('/demo-day') && path.includes('/companies');

  const hideLauncher = () => {
    setIsHidden(true);
    setIsActive(false);
    if (window.Intercom) {
      window.Intercom('hide');
    }
  };

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('onUnreadCountChange', (unreadCount) => setUnreadMessages(unreadCount));
      window.Intercom('onHide', () => setIsActive(false));
    }
  }, [window.Intercom]);

  if (isImpersonator || pathIsDemoDay(location.pathname)) {
    if (isActive) {
      hideLauncher();
    }
    return null;
  }

  return (
    <div
      className={classNames('intercom-wrapper', { _hidden_: isHidden })}
      onMouseOver={() => setCloseButtonIsVisible(true)}
      onMouseLeave={() => setCloseButtonIsVisible(false)}
      onFocus={() => setCloseButtonIsVisible(true)}
    >
      {((isDesktop && hideButtonIsVisible) || isMobileTablet) &&
        <button
          type="button"
          className="intercom-hide"
          onClick={hideLauncher}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>}
      <button
        type="button"
        className="intercom-launcher"
        id="custom_intercom_launcher"
        onClick={() => {
          if (isHidden) {
            setIsHidden(false);
          }
          setIsActive((prev) => !prev);
        }}
      >
        <div className={classNames('intercom-icon-close', { 'intercom-open': isActive })}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z"
              fill="white"
            />
          </svg>
        </div>
        <div className={classNames('intercom-icon-open', { 'intercom-open': isActive })}></div>
        <span className={classNames('intercom-unread-count', { active: unreadMessages > 0 })}>{unreadMessages}</span>
      </button>
    </div>
  );
};

CustomIntercomLauncher.propTypes = {
  isDesktop: PropTypes.bool,
  isMobileTablet: PropTypes.bool,
  authUser: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  authUser: makeSelectUser(),
});

export default connect(mapStateToProps)(ResponsiveHoC(CustomIntercomLauncher));


import { createSelector } from 'reselect';

import { makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';
import { RELATIONS } from 'containers/App/constants';

import reducer from './reducer';
import { makeSelectProfile, makeSelectUser } from '../../AuthProvider/selectors';

const localInitialState = reducer(undefined, {});

const selectPeopleSinglePage = (state) => state ? state.get('peopleSinglePage', localInitialState) : localInitialState;

const makeSelectSelectedPersonNotFound = () => makeSubSelector(selectPeopleSinglePage, ['notFound']);
const makeSelectSelectedPersonRef = () => makeSubSelector(selectPeopleSinglePage, ['selectedPerson']);
const makeSelectedPersonIsFetchingConnections = () => makeSubSelector(selectPeopleSinglePage, ['isFetchingConnections']);

const makeSelectSelectedPerson = () => makeSelectObjectFromRefSelector(makeSelectSelectedPersonRef());
const selectIsSelf = createSelector(
  makeSelectSelectedPersonRef(),
  makeSelectUser(),
  (selectedPersonRef, user) => user.profileId === parseInt(selectedPersonRef.id, 10),
);

const selectShowTeamNotes = createSelector(
  makeSelectProfile(),
  selectIsSelf,
  (myProfile, isSelf) => !isSelf
    && (myProfile?.primaryMembership?.().relation === RELATIONS.FOUNDER || myProfile?.primaryMembership?.().relation === RELATIONS.EMPLOYEE)
);

export {
  selectIsSelf,
  makeSelectSelectedPerson,
  makeSelectSelectedPersonRef,
  makeSelectSelectedPersonNotFound,
  makeSelectedPersonIsFetchingConnections,
  selectShowTeamNotes,
};

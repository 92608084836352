/**
 * Sidebar Items State Domain
 */
import { createSelector } from 'reselect';
import { fromPairs } from 'lodash';

import { makeSelectObjectArrayFromRefArray } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';

import reducer, { initialState } from './reducer';
import { SIDEBAR_SETTINGS_GROUP } from './constants';

const localInitialState = reducer(undefined, initialState);
const selectEditSidebarModalDomain = (state) => state ? state.get('editSidebarItemModal', localInitialState) : localInitialState;

const selectSiteSettingsRefs = createSelector(
  selectEditSidebarModalDomain,
  (sidebarLinks) => sidebarLinks.getIn(['loadedSettingsObjects', SIDEBAR_SETTINGS_GROUP])
);
const makeSelectSiteSettings = () => makeSelectObjectArrayFromRefArray(selectSiteSettingsRefs);

const makeSelectSiteSettingsSidebarLinks = () => createSelector(
  makeSelectSiteSettings(),
  (siteSettings) => ({
    ...fromPairs(siteSettings.map((siteSetting) => [siteSetting.key, siteSetting.value])),
    ...fromPairs(siteSettings.map((siteSetting) => [`${siteSetting.key}_title`, siteSetting.title])),
  })
);

const selectIsEditSidebarModalOpen = makeSubSelector(selectEditSidebarModalDomain, ['isEditSidebarModalOpen']);
const selectEditedSidebarItem = makeSubSelector(selectEditSidebarModalDomain, ['sidebarItem']);


export {
  makeSelectSiteSettings,
  makeSelectSiteSettingsSidebarLinks,
  selectIsEditSidebarModalOpen,
  selectEditedSidebarItem,
};


let jiraCollectorAdded;
export function addJiraCollector() {
  if (jiraCollectorAdded) {
    return;
  }
  const scriptEl = document.createElement('script');
  scriptEl.type = 'text/javascript';
  scriptEl.src = 'https://alchemistaccelerator.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-7f820x/b/19/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=4c417092';
  document.body.insertBefore(scriptEl, null);
  jiraCollectorAdded = true;
}

/*
 *
 * IfsRegistration constants
 *
 */
import { PARTICIPANT_TYPE } from 'containers/Admin/InvestorFeedbackSummit/IfsEmails/constants';

export const INITIAL_LOAD = 'app/Ifs/INITIAL_LOAD';
export const SET_INVITATION = 'app/Ifs/Registration/SET_INVITATION';
export const ATTENDANCE_SENT = 'app/Ifs/Registration/ATTENDANCE_SENT';
export const FOUNDER_ATTENDANCE_SENT = 'app/Ifs/Registration/FOUNDER_ATTENDANCE_SENT';
export const SET_CONFIRM_MODAL_OPEN = 'app/Ifs/Registration/SET_CONFIRM_MODAL_OPEN';
export const LOAD_COMPANY = 'app/Ifs/Registration/LOAD_COMPANY';
export const LOAD_REG_COMPANIES = 'app/Ifs/Registration/LOAD_COMPANIES';
export const SET_REG_COMPANIES = 'app/Ifs/Registration/SET_REG_COMPANIES';
export const MARK_COMPANY = 'app/Ifs/Registration/MARK_COMPANY';
export const REORDER_MARKED_COMPANIES = 'app/Ifs/Registration/REORDER_MARKED_COMPANIES';
export const SET_TIMEZONE = 'app/Ifs/Registration/SET_TIMEZONE';
export const TOGGLE_TIMEZONE_ON_MOBILE = 'app/Ifs/Registration/TOGGLE_TIMEZONE_ON_MOBILE';

export const SUBMIT_IFS_REG_FORM = 'app/Ifs/Registration/SUBMIT_IFS_REG_FORM';

export const CONFIRM_MODAL_TITLE = 'Edit Participant Set';
export const INVESTOR_PARTICIPANT = [PARTICIPANT_TYPE.angels, PARTICIPANT_TYPE.backers, PARTICIPANT_TYPE.gold_circle, PARTICIPANT_TYPE.floaters_investor];
export const FOUNDER_PARTICIPANT = [PARTICIPANT_TYPE.new_class, PARTICIPANT_TYPE.demoing_class, PARTICIPANT_TYPE.alumni, PARTICIPANT_TYPE.floaters_founder];

export const HEARD_FROM_OPTIONS = {
  angel_groups: 'Angel Group Names',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  alch_team_member: 'Alchemist Team Member',
  alch_email: 'Alchemist Email',
};

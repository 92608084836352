import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { onlyText } from 'react-children-utilities';

import { trackExtLink } from '../actions';


const TrackableExtLink = ({
  href, target = '_blank', children, trackClick, ...props
}) => (
  <a
    href={href}
    target={target}
    {...props}
    onClick={() => {
      trackClick(href, onlyText(children));
      props.onClick?.();
    }}
  >{children}
  </a>
);


TrackableExtLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.any,
  trackClick: PropTypes.func,
  onClick: PropTypes.func,
};

export default connect(null, { trackClick: trackExtLink })(TrackableExtLink);

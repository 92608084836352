import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, ModalBody } from 'reactstrap';

import ModalSimple from 'components/Modals/ModalSimple';


const DocModal = ({ isOpen, toggle, children }) => (
  <ModalSimple
    isOpen={isOpen}
    toggle={toggle}
  >
    <ModalBody>
      {children}
    </ModalBody>
    <Row className="dsa-modal_form-buttons_wrapper">
      <Button
        color="link"
        className="dsa-modal_form-button"
        onClick={toggle}
      >Close
      </Button>
    </Row>
  </ModalSimple>
);

DocModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

export default DocModal;

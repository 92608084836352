import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OnePagerMain from './OnePagerMain';
import OnePagerHeader from './OnePagerHeader';
import OnePagerContent from './OnePagerContent';
import OnePagerSection from './OnePagerSection';
import H1 from '../Text/Headings/H1';
import { HeadingMedium, HeadingExtraLarge } from '../Text/Headings';


const OnePager = ({
  title, children, className, noLogo, footer, sectionSize, headerClassName,
  medHeading, textLeft, isAlchemistX, fixedSize, xlHeading, bigLogo, sectionClassName, isTitleStrong,
}) => {
  const TitleHeading = medHeading ? HeadingMedium : H1;
  const alchemistXLogo = bigLogo ? '/alchemistx-logo-big.png' : '/alchemistx-logo.png';
  return (
    <OnePagerMain className={classNames(className, { 'reset-text-align': textLeft, '_fixed-size_': fixedSize })}>
      <OnePagerHeader className={headerClassName}>
        {!noLogo &&
          <div className="center-content-y-x">
            <img src={isAlchemistX ? alchemistXLogo : '/alchemist-logo.png'} alt="Alchemist Accelerator Logo" />
          </div>}
      </OnePagerHeader>
      <OnePagerContent hasFooter={!!footer}>
        <OnePagerSection className={`${sectionClassName || ''} ${sectionSize ? `_${sectionSize}_` : ''}`}>
          {title && !xlHeading && <TitleHeading className={classNames('mb-7', { 'font-weight-bold': isTitleStrong })}>{title}</TitleHeading>}
          {title && xlHeading && <HeadingExtraLarge className={classNames('mt-7', { 'font-weight-bold': isTitleStrong })}>{title}</HeadingExtraLarge>}
          {children}
        </OnePagerSection>
      </OnePagerContent>
      {footer}
    </OnePagerMain>
  );
};
OnePager.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  noLogo: PropTypes.bool,
  medHeading: PropTypes.bool,
  sectionSize: PropTypes.string,
  textLeft: PropTypes.bool,
  isAlchemistX: PropTypes.bool,
  fixedSize: PropTypes.bool,
  xlHeading: PropTypes.bool,
  bigLogo: PropTypes.bool,
  headerClassName: PropTypes.string,
  sectionClassName: PropTypes.string,
  isTitleStrong: PropTypes.bool,
};

export default OnePager;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { ButtonGreyPlain } from './ButtonLinkSec';

const BackButton = ({ className, to, text }) => (
  <ButtonGreyPlain to={to || '#'} className={classNames(`${className || ''}`)}>
    <FontAwesomeIcon icon={faArrowLeft} className="me-3" />
    {text}
  </ButtonGreyPlain>
);

BackButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string,
};

export default BackButton;

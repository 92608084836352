/*
 * People List Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */


import { TOGGLE_SHARED_LIST_PREVIEW, SET_PREVIEWED_SHARED_LIST } from './constants';

const setPreviewedSharedList = (previewedSharedList) => ({ type: SET_PREVIEWED_SHARED_LIST, previewedSharedList });
const toggleSharedListPreview = (isShown) => ({ type: TOGGLE_SHARED_LIST_PREVIEW, isShown });

export {
  setPreviewedSharedList,
  toggleSharedListPreview,
};

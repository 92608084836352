import React from 'react';
import PropTypes from 'prop-types';

import ErrorBox from './ErrorBox';

const ErrorPart = ({ meta, errorProcessor }) => (
  meta.dirty || meta.touched
    ? (meta.error && <ErrorBox error={errorProcessor?.(meta.error) || meta.error} />)
    || (meta.warning && <span className="dsa-form-warning">{meta.warning}</span>)
    || null
    : null);

ErrorPart.propTypes = {
  errorProcessor: PropTypes.func,
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    warning: PropTypes.string,
  }),
};

export default ErrorPart;

/*
 * Admin constants
 */

export const LOAD_ALCHEMIST_CLASSES = 'app/Admin/ClassesSection/LOAD_ALCHEMIST_CLASSES';
export const LOAD_ALCHEMIST_CLASSES_SUCCESS = 'app/Admin/ClassesSection/LOAD_ALCHEMIST_CLASSES_SUCCESS';
export const LOAD_ALCHEMIST_CLASSES_ERROR = 'app/Admin/ClassesSection/LOAD_ALCHEMIST_CLASSES_ERROR';
export const SELECT_ALCHEMIST_CLASS = 'app/Admin/ClassesSection/SELECT_ALCHEMIST_CLASS';
export const ADD_ALCHEMIST_CLASS = 'app/Admin/ClassesSection/ADD_ALCHEMIST_CLASS';
export const ADD_NEW_ALCHEMIST_CLASS = 'app/Admin/ClassesSection/ADD_NEW_ALCHEMIST_CLASS';
export const SET_CLASS_TYPE = 'app/Admin/ClassesSection/SET_CLASS_TYPE';
export const SET_CLASS_NAME = 'app/Admin/ClassesSection/SET_CLASS_NAME';
export const SET_SHOW_ERROR_SUMMARY = 'app/Admin/ClassesSection/SET_SHOW_ERROR_SUMMARY';

export const SUBMIT_CLASS_FORM = 'app/Admin/ClassesSection/SUBMIT_CLASS_FORM';
export const SET_IS_ADDING_NEW_CLASS = 'app/Admin/ClassesSection/SET_IS_ADDING_NEW_CLASS';

export const ALL_DD_TYPES = 'Demo Day, Dry Run, Sneak Peek';
export const ONLY_SNEAK_PEEK = 'Only Sneak Peek';
export const ESTIMATE_DURATION = 'estimate';
export const FIXED_DURATION = 'fixed';

export const AX_DEFAULT_EVENT_DETAILS = '<h3><strong>Event Details:</strong></h3><p>We are excited to invite you to the Virtual Demo Day.</p><p>Register today to gain access to on-demand recordings of each of the startup pitches à la carte, and connect directly with any of the founders for follow-up discussion.</p><p>Please note that this event is invite-only.</p>';
export const AA_DEFAULT_EVENT_DETAILS = '<h3><strong>Event Details:</strong></h3><p>We are excited to invite you to the Demo Day for [$aaClass] of the Alchemist Accelerator!</p><p>We are showcasing the startups in our latest class to friends and investors. The Demo Day is the first time we will announce the companies publicly.</p><p><strong>When and where:</strong></p><p>{% range_demo_day_date %}</p><p>We will send you the access link closer to the event.</p><p><strong>Live Event Schedule:</strong></p><p>{% event_start_date %}</p><br />{% dd_event_agenda %}<p>As you know, all our startups monetize from enterprises. Our program focuses heavily on customer development. This is a momentous day when our budding startups will be able to showcase all of their hard work for the first time publicly. It also marks the time when many will be officially open to institutional investors that want to explore things further. At a previous Demo Day, 3 companies received term sheets and 1 company was acquired within 7 days of the Demo Day. Because of that - and out of respect for everyone&#x27;s time - we highly encourage (and will prioritize) funds that send decision makers to attend our Demo Days.</p><p>The event is invite-only. If you cannot make it - or would like us to extend an additional invitation to a person of the press or an investor whom you think would be a good fit - email us back their email, or better yet, <a href="https://influencerseries.wufoo.com/forms/alchemist-accelerator-request-press-invite/" target="_blank">nominate a person of the press here</a> and / or <a href="https://influencerseries.wufoo.com/forms/alchemist-accelerator-request-investor-invite/" target="_blank">nominate an investor here</a>.</p><p>Have questions about Alchemist [$aaClass] Demo Day? Click in the Intercom bubble in the bottom right of the page.</p>';


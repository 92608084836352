import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';
import omit from 'lodash/omit';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = { tooltipOpen: false };
  }

  componentDidMount() {
    if (this.props.targetRef) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState(({ hasRefreshed }) => ({ hasRefreshed: !hasRefreshed }));
    }
  }

  toggle() {
    this.setState(({ tooltipOpen }) => ({ tooltipOpen: !tooltipOpen }));
  }

  render() {
    const { target, targetRef, ...props } = this.props;
    if (!(target || targetRef?.current)) return null;
    return (
      <ReactstrapTooltip
        {...omit(props, ['isBlocked'])}
        isOpen={!this.props.isBlocked && this.state.tooltipOpen}
        toggle={this.toggle}
        target={target || (targetRef && targetRef.current)}
        boundariesElement="viewport"
        modifiers={[{ name: 'preventOverflow', enabled: false }]}
      />
    );
  }
}

Tooltip.propTypes = {
  ...ReactstrapTooltip.propTypes,
  target: PropTypes.any,
  isBlocked: PropTypes.bool,
};

export default Tooltip;

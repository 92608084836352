import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LoadingTextBase from './LoadingTextBase';


const LoadingContent = ({ textColor, text = 'Loading content...', withBg = false }) => (
  <div className={classNames('dsa-loading__content center-content-x-y', { '_with-bg_': withBg })}>
    <LoadingTextBase text={text} textColor={textColor} />
  </div>);

LoadingContent.propTypes = { text: PropTypes.string, textColor: PropTypes.string, withBg: PropTypes.bool };

export default LoadingContent;

/*
 *
 * Admin Connect Reducer
 *
 */

import { fromJS } from 'immutable';

import {
  OPEN_ADMIN_CONNECT_MODAL, SET_FORM_SCHEMA, SET_OUTRO, SET_SENDER, SET_SUBJECT,
} from './constants';

const initialState = fromJS({
  isModalOpen: false,
  selectedFounder: null,
  schema: null,
  sender: '',
  subject: '',
  outro: '',
});

function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ADMIN_CONNECT_MODAL:
      if (action.selectedFounder) {
        return state.set('isModalOpen', action.isModalOpen)
          .set('selectedFounder', action.selectedFounder);
      }
      return state.set('isModalOpen', action.isModalOpen);
    case SET_FORM_SCHEMA:
      return state.set('schema', action.schema);
    case SET_SENDER:
      return state.set('sender', action.sender);
    case SET_SUBJECT:
      return state.set('subject', action.subject);
    case SET_OUTRO:
      return state.set('outro', action.outroWithNewSender);
    default:
      return state;
  }
}

export default onboardingReducer;

/*
 * People/Search
 */
import { combineReducers } from 'redux-immutable';
import { fromJS, OrderedMap } from 'immutable';

import { RESOURCE_PEOPLE_LISTS_PERMISSIONS } from 'containers/App/constants';
import { generateLoadedContentReducer, generateFilterReducer, generateFiltersUiReducer } from 'utils/filter/reducer';
import { toJS } from 'utils/general';

import {
  CLOSE_MODAL,
  OPEN_FOR_LIST,
  SET_SHARE,
  ADD_SHARE,
  ADD_SHARES,
  REMOVE_SHARE,
  SET_SECTION,
  SET_SHARES_PROCESSING,
  SET_SHARES_PROCESSED,
  SET_PEOPLE_COMPANY_LOADING,
} from './constants';

// share = { user, update }
const initialShareModalState = fromJS({
  section: false,
  peopleListRef: false,
  shares: [],
  sharesProcessing: false,
  isPeopleCompanyLoading: false,
});

function main(state = initialShareModalState, action) {
  switch (action.type) {
    case REMOVE_SHARE:
      return state.update('shares', (shares) => shares.delete(action.idx));
    case SET_SHARE:
      return state.update(
        'shares',
        (shares) => shares.update(action.idx, (share) => ({ ...share, update: action.update }))
      );
    case ADD_SHARE:
      return state.update('shares', (shares) => !shares.map((share) => share.user.id).includes(action.person.id)
        ? shares.push(makeShare(action.person))
        : shares);
    case ADD_SHARES:
      return state.update('shares', (currentShares) => {
        const sharesUsersIds = currentShares.map((share) => share.user.id);
        return currentShares.push(...action.shares.filter(({ user }) => !sharesUsersIds.includes(user.id)));
      });
    case SET_SECTION:
      return state.set('section', action.section);
    case OPEN_FOR_LIST:
      return state
        .set('section', action.section)
        .set('peopleListRef', action.peopleListRef)
        .update(
          'shares',
          (currentShares) => state.get('peopleListRef') && toJS(state.get('peopleListRef')).id === action.peopleListRef.id
            ? currentShares
            : initialShareModalState.get('shares')
        );
    case CLOSE_MODAL:
      return initialShareModalState;
    case SET_SHARES_PROCESSING:
      return state.set('sharesProcessing', true);
    case SET_SHARES_PROCESSED:
      return state.set('sharesProcessing', false);
    case SET_PEOPLE_COMPANY_LOADING:
      return state.set('isPeopleCompanyLoading', action.isPeopleCompanyLoading);
    default:
      return state;
  }
}
// ToDO: move to proper place
export const makeShare = (user) => ({ type: RESOURCE_PEOPLE_LISTS_PERMISSIONS, user, update: true });
//
// const initialPeopleListsModalState = fromJS({ isPeopleListsOpen: false, selectedPeopleLists: [] });
//
// function peopleListsModal(state = initialPeopleListsModalState, action) {
//   switch (action.type) {
//     case SET_PEOPLE_LISTS_MODAL:
//       return state.set('isPeopleListsOpen', action.isOpen);
//     case CLEAR_MARKED_PEOPLE_LISTS:
//       return state.set('selectedPeopleLists', initialPeopleListsModalState.get('selectedPeopleLists'));
//     case MARK_PEOPLE_LIST:
//       return state.update('selectedPeopleLists', (markedPeopleLists) => action.unselect
//         ? markedPeopleLists.filter((objectRef) => objectRef.id !== action.markedPeopleListRef.id)
//         : markedPeopleLists.push(action.markedPeopleListRef));
//     default:
//       return state;
//   }
// }


export default combineReducers({
  loadedContent: generateLoadedContentReducer('PeopleListManageModal'),
  filter: generateFilterReducer('PeopleListManageModal', { sort: new OrderedMap({ message_acceptance_rate: -1 }) }),
  filtersUi: generateFiltersUiReducer('PeopleListManageModal'),
  main,
  // peopleListsModal,
});

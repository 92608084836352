import { List } from 'immutable';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';

import { RESOURCE_DEMO_DAYS, RESOURCE_PEOPLE_LISTS } from 'containers/App/constants';

import { mergeDeep, toJS } from './general';
import { postProcessObject } from './postProccessObject';
import { resRef } from './refs';
import { logError } from './log';

const preProcessObject = (obj) => {
  const localCopy = { ...obj };
  switch (obj?.type) {
    case RESOURCE_PEOPLE_LISTS:
      if (obj.relationships && obj.relationships.users_rel) {
        localCopy.meta.user_ids = new Set(obj.relationships.users_rel.data?.map((userRel) =>
          parseInt(userRel.id.split('_')[1], 10)));
      }
      break;
    case RESOURCE_DEMO_DAYS:
      localCopy.meta.loadedAt = new Date();
      break;
    default:
      break;
  }

  return localCopy;
};

export function extractData(jsonApiData, refExtractor = resRef) {
  const data = jsonApiData.data instanceof Array ? jsonApiData.data : [jsonApiData.data];

  const inclusions = {};
  data.concat(jsonApiData.included || []).forEach((objRaw) => {
    const obj = preProcessObject(objRaw);
    if (!inclusions[obj.type]) inclusions[obj.type] = {};

    if (!inclusions[obj.type][obj.id]) {
      inclusions[obj.type][obj.id] = obj;
    } else {
      inclusions[obj.type][obj.id] = mergeDeep(inclusions[obj.type][obj.id], obj);
    }
  });

  return { items: data.map(refExtractor), inclusions };
}

export const flattenJAPIObject = (japiObject, extraProps, prependProps) => ({
  ...prependProps,
  ...resRef(japiObject),
  ...japiObject?.attributes,
  ...japiObject?.meta,
  ...extraProps,
  resRef: resRef(japiObject),
});

export function fetchObjectFromIncluded(refImmOrNot, included, fallbackToRef = false) {
  if (!refImmOrNot) return null;
  const ref = toJS(refImmOrNot);
  if (!ref.id) {
    logError(ref);
    return null;
  }

  const objImmutable = included.getIn([ref.type, ref.id.toString()]);
  if (!objImmutable) {
    if (fallbackToRef) return ref;
    return null;
  }

  let objRels = {};

  if (objImmutable.get('relationships')) {
    objRels = objImmutable.get('relationships').filter((v) => !!v.get('data') || v.get('data') === null)
      .map((v) => v.get('data') === null || !v.get('data').size
        ? null
        : () => {
          if (List.isList(v.get('data'))) {
            return v.get('data').map((refInner) =>
              fetchObjectFromIncluded(refInner.toJS(), included, true)).toJS();
          }

          return fetchObjectFromIncluded(v.get('data').toJS(), included, true);
        }).toJS();
  }

  return postProcessObject(flattenJAPIObject(objImmutable.toJS(), objRels, omit(ref, ['id', 'type'])));
}

export const materializeObject = (obj) => {
  if (!isFunction(obj)) {
    return obj;
  }
  // If it takes multiple arguments, don't call it, it's not materializable
  return obj.length === 0 ? obj() : null;
};

export const deepMaterializeObject = (obj, depth = 1) => {
  if (!obj || depth < 0) return obj;

  const materializedObj = materializeObject(obj);

  if (Array.isArray(materializedObj)) return materializedObj.map((item) => deepMaterializeObject(item, depth - 1));
  if (!isObject(materializedObj)) return materializedObj;

  return mapValues(materializedObj, (item) => deepMaterializeObject(item, depth - 1));
};

const nullifyFunctions = (obj) => isFunction(obj) ? null : obj;
export const deepNullifyFunctions = (obj) => {
  const nullifiedObj = nullifyFunctions(obj);
  if (!nullifiedObj) {
    return nullifiedObj;
  }

  if (Array.isArray(nullifiedObj)) {
    return nullifiedObj.map((item) => deepNullifyFunctions(item));
  }
  if (!isObject(nullifiedObj)) return nullifiedObj;

  return mapValues(nullifiedObj, (item) => deepNullifyFunctions(item));
};

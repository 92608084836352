/*
 * GdprConfirm constants
 */

import { GDPR_REQUEST_TYPE } from '../constants';

export const SEND_CONFIRMATION = 'app/Gdpr/Confirm/SEND_CONFIRMATION';
export const SEND_CANCELLATION = 'app/Gdpr/Confirm/SEND_CANCELLATION';
export const CONFIRMATION_SENT = 'app/Gdpr/Confirm/CONFIRMATION_SENT';
export const CANCELLATION_SENT = 'app/Gdpr/Confirm/CANCELLATION_SENT';


export const TEXTS = {
  [GDPR_REQUEST_TYPE.erasureRequest]: {
    participle: 'deleted',
    mainTexts: [
      'We have received your request to delete your profile from our platform.',
      'It may take up to 5-7 business days. You will receive an email confirmation once your profile is deleted.',
    ],
    buttonText: 'Cancel Deletion',
  },
  [GDPR_REQUEST_TYPE.restrictionRequest]: {
    participle: 'deactivated',
    mainTexts: [
      'We have received your request to deactivate your profile.',
      'It may take up to 5-7 business days. You will receive an email confirmation once your profile deactivated.',
    ],
    buttonText: 'Cancel Deactivation',
  },
  [GDPR_REQUEST_TYPE.liftRestrictionRequest]: {
    mainTexts: [
      'We have received your request to reactivate your profile.',
      'It may take up to 5-7 business days. You will receive an email confirmation once your profile is reactivated.',
    ],
  },
  [GDPR_REQUEST_TYPE.subjectAccessRequest]: {
    participle: 'sent',
    mainTexts: [
      'We have received your request to access your profile data.',
      'You will receive an email with your profile data within 24 hours.',
    ],
  },
};

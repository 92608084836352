/*
 * Admin actions
 */
import { generateLoadedContentActions } from 'utils/filter/actions';

import {
  ADMIN_DD_PAGE_IDENTIFIER,
  LOAD_ALCHEMIST_CLASSES,
  LOAD_ALCHEMIST_CLASSES_ERROR,
  LOAD_ALCHEMIST_CLASSES_SUCCESS,
  SELECT_ALCHEMIST_CLASS,
  SELECT_PEOPLE_LIST,
  SELECT_ACTION,
  SYNC_PEOPLE_LIST,
  SYNC_PEOPLE_LIST_SUCCESS,
  FORM_SENT,
  IMPORT_FILES,
  CSV_MODAL_STEP,
  SET_PREVIEW,
  CONFIRM_PREVIEW_SEND,
  TOGGLE_SHOW_LAST_3_DEMO_DAYS,
  TOGGLE_SHOW_BOUNCED,
  SENDING_MAIL_ERROR,
  SET_ELIGIBLE_AVAILABLE_TOTAL_COUNT,
  LOAD_LAST_3_DEMO_DAYS_AVG,
  LOAD_SELECTED_CLASS,
  LOAD_SELECTED_CLASS_ERROR,
  ADD_SAVED_AUTO_TEMPLATE,
  SET_ARE_AUTO_TEMPLATES_SAVING,
  UPDATE_PREVIEW_TEXTS,
  SET_PREVIEW_TEXTS_IS_LOADING,
  SET_IS_REMINDERS_MODAL_OPEN,
  SET_BE_PLACEHOLDERS,
  SAVE_TEMPLATES,
  SET_SAVED_AUTO_TEMPLATES,
} from './constants';

export const loadAlchemistClasses = () => ({ type: LOAD_ALCHEMIST_CLASSES });
export const alchemistClassesLoaded = (objects) => ({ type: LOAD_ALCHEMIST_CLASSES_SUCCESS, objects });
export const alchemistClassesLoadingError = () => ({ type: LOAD_ALCHEMIST_CLASSES_ERROR });
export const setIsSelectedClassLoading = (isLoading) => ({ type: LOAD_SELECTED_CLASS, isLoading });
export const setSelectedClassLoadingError = (error) => ({ type: LOAD_SELECTED_CLASS_ERROR, error });
export const chooseAlchemistClass = (alchemistClassRef) => ({ type: SELECT_ALCHEMIST_CLASS, alchemistClassRef });
export const choosePeopleList = (peopleListRef) => ({ type: SELECT_PEOPLE_LIST, peopleListRef });
export const syncPeopleList = () => ({ type: SYNC_PEOPLE_LIST });
export const syncPeopleListSuccess = () => ({ type: SYNC_PEOPLE_LIST_SUCCESS });
export const chooseActionObj = (actionRef) => ({ type: SELECT_ACTION, actionRef });
export const formSent = (actionType) => ({ type: FORM_SENT, actionType });
export const importFilesCsv = (files) => ({ type: IMPORT_FILES, files });
export const setCSVModalStep = (step) => ({ type: CSV_MODAL_STEP, step });
export const setPreview = (preview) => ({ type: SET_PREVIEW, preview });
export const confirmPreviewSend = () => ({ type: CONFIRM_PREVIEW_SEND });
export const toggleShowLast3DemoDays = () => ({ type: TOGGLE_SHOW_LAST_3_DEMO_DAYS });
export const loadLast3DdAvg = (isLoading) => ({ type: LOAD_LAST_3_DEMO_DAYS_AVG, isLoading });
export const toggleShowBounced = () => ({ type: TOGGLE_SHOW_BOUNCED });
export const setEligibleAvailableTotalCount = (count) => ({ type: SET_ELIGIBLE_AVAILABLE_TOTAL_COUNT, count });
export const setSendingMailError = (error) => ({ type: SENDING_MAIL_ERROR, error });
export const setAreDdAutoTemplatesSaving = (areDdAutoTemplatesSaving) => ({ type: SET_ARE_AUTO_TEMPLATES_SAVING, areDdAutoTemplatesSaving });
export const addSavedAutoTemplate = (templateType) => ({ type: ADD_SAVED_AUTO_TEMPLATE, templateType });
export const setSavedAutoTemplates = (savedAutoTemplates) => ({ type: SET_SAVED_AUTO_TEMPLATES, savedAutoTemplates });
export const updatePreviewTexts = (previewTexts) => ({ type: UPDATE_PREVIEW_TEXTS, previewTexts });
export const setPreviewTextsIsLoading = (isLoading) => ({ type: SET_PREVIEW_TEXTS_IS_LOADING, isLoading });
export const setIsRemindersModalOpen = (modalState) => ({ type: SET_IS_REMINDERS_MODAL_OPEN, modalState });
export const setBePlaceholders = (bePlaceholders) => ({ type: SET_BE_PLACEHOLDERS, bePlaceholders });
export const saveTemplates = () => ({ type: SAVE_TEMPLATES });

export const { loadNextPage, loadContent, initialLoad } = generateLoadedContentActions(ADMIN_DD_PAGE_IDENTIFIER);

import { fromJS } from 'immutable';

import { ALREADY_ACCEPTED, ALREADY_DENIED, ERROR_ON_CONNECT, FEEDBACK_SENT, FEEDBACK_SENT_ERROR, SET_CONNECTION } from './constants';

const initialState = fromJS({
  connectionRef: false,
  isConnAlreadyAccepted: false,
  isConnAlreadyDenied: false,
  errorConnectionStatus: false,
  feedbackSentErr: false,
});

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CONNECTION:
      return state.set('connectionRef', action.connectionRef);
    case FEEDBACK_SENT:
      return state
        .set('feedbackSent', true)
        .set('feedbackSentErr', false);
    case ALREADY_ACCEPTED:
      return state.set('isConnAlreadyAccepted', action.isConnAlreadyAccepted);
    case ALREADY_DENIED:
      return state.set('isConnAlreadyDenied', action.isConnAlreadyDenied);
    case ERROR_ON_CONNECT:
      return state.set('errorConnectionStatus', action.status);
    case FEEDBACK_SENT_ERROR:
      return state.set('feedbackSentErr', action.feedbackSentErr);
    default:
      return state;
  }
}

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { updateIncluded } from 'actions';
import { displayAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { API_DEMO_DAY_INVITATIONS_BASE_URL, RESOURCE_DEMO_DAY_INVITATIONS } from 'containers/App/constants';
import { extractData } from 'utils/jsonApiExtract';
import { patch } from 'utils/request';
import { logError } from 'utils/log';
import { preprocessError } from 'utils/Forms/sagas';

import { makeSelectObject } from '../../App/selectors';
import { SAVE_INTEREST } from './constants';
import { setInvitation } from './actions';


function* updateSPConditions({ userRef, invitationId, status }) {
  const inputData = { data: { type: RESOURCE_DEMO_DAY_INVITATIONS, id: invitationId, attributes: { accepted_spconditions: status } } };
  const uri = `${API_DEMO_DAY_INVITATIONS_BASE_URL}/sneak_peek_interest/${invitationId}?_ref=${userRef}&include=user`;
  try {
    const invInterestResponse = yield call(patch, uri, inputData);

    const { inclusions, items: [invitationRef] } = extractData(invInterestResponse);

    yield put(updateIncluded(inclusions));
    const invitation = yield select(makeSelectObject(invitationRef));
    yield put(setInvitation(invitationRef, status, invitation.messageType));
  } catch (e) {
    if (e.response && e.response.status === 400) {
      logError(e);
      const { errorJson } = yield preprocessError(e);
      if (errorJson?.errors?.[0]?.detail) {
        yield put(displayAlert(errorJson.errors[0].detail, COLOR.warning));
      }
    } else {
      yield put(displayAlert(`Something went wrong when ${status ? 'accepting' : 'declining'} the connection`, COLOR.danger));
      throw e;
    }
  }
}

export default function* interestSaga() {
  // Message Sagas
  yield takeLatest(SAVE_INTEREST, updateSPConditions);
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollDiv from 'components/Generic/ScrollDiv';


const OnePagerContent = ({ hasFooter, children, className }) => {
  const ParentElement = hasFooter ? 'div' : ScrollDiv;
  const propsToPass = hasFooter ? null : { scrollDivClass: 'px-5 px-md-7 pb-7' };

  return (
    <ParentElement
      className={classNames(className, 'dsa-one-pager__content', { 'has-footer': hasFooter })}
      {...propsToPass}
    >
      {children}
    </ParentElement>
  );
};

OnePagerContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hasFooter: PropTypes.bool,
};

export default OnePagerContent;

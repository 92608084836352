import { createSelector } from 'reselect';

import { toJS } from './general';

export const makeSubSelector = (selector, searchKeyPath) => {
  const partialSelectors = [];
  partialSelectors[0] = createSelector(
    selector,
    (selectedDomain) => selectedDomain && selectedDomain.get(searchKeyPath[0]),
  );
  for (let i = 1; i < searchKeyPath.length; i++) {
    partialSelectors[i] = createSelector(
      partialSelectors[i - 1],
      (selectedDomain) => selectedDomain && selectedDomain.get(searchKeyPath[i]),
    );
  }
  return partialSelectors[searchKeyPath.length - 1];
};

export const makeSubSelectorToJS = (selector, searchKeyPath) =>
  makeSelectorToJS(makeSubSelector(selector, searchKeyPath));

export const makeSelectorToJS = (selector) => createSelector(selector, toJS);

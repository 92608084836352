// Individual exports for testing
import { select, call, fork, delay, cancelled, put } from 'redux-saga/effects';
import { parse, stringify } from 'qs';

import { showAlert } from 'containers/AlertBox/actions';
import { API_PEOPLE_BASE_URL, RESOURCE_DEMO_DAY_INVITATIONS } from 'containers/App/constants';
import { makeSelectLocation, makeSelectQueryParam } from 'containers/App/selectors';
// eslint-disable-next-line import/no-cycle
import { baseLoginFlow } from 'containers/AuthProvider/saga';
import { formRequest } from 'utils/Forms/general';
import { preprocessError } from 'utils/Forms/sagas';
import { logError } from 'utils/log';
import { resRef } from 'utils/refs';
import { request } from 'utils/request';
import { pushLocation } from 'utils/sagas';


export function* checkTokenAndWait() {
  // callDdSpecialAuth to update the token
  yield callDdSpecialAuth();
  yield baseLoginFlow();
}

export function* callDdSpecialAuth() {
  const location = yield select(makeSelectLocation());
  const qParams = location.search && parse(location.search, { ignoreQueryPrefix: true });
  const landingQParams = sessionStorage.getItem('landingQueryParams')
    && parse(sessionStorage.getItem('landingQueryParams'), { ignoreQueryPrefix: true });
  const unauthHash = (landingQParams && landingQParams.ref) || (qParams && qParams.ref);

  const userReqParams = { unauth_hash: unauthHash, code: qParams.code };
  return yield call(request, `${API_PEOPLE_BASE_URL}/dd_special_auth?${stringify(userReqParams)}`);
}

export function* markDemoDayRegistrationFinished() {
  const invitationId = yield select(makeSelectQueryParam('invitation'));
  const classId = yield select(makeSelectQueryParam('class'));
  const invitation = {
    type: RESOURCE_DEMO_DAY_INVITATIONS,
    id: invitationId,
  };

  if (invitation) {
    try {
      yield formRequest({ ...resRef(invitation), attributes: { registration_finished: true } });
    } catch (err) {
      const { errorJson } = yield preprocessError(err);
      if (errorJson?.errors?.[0]?.detail?.includes('Your selected options are in the past')) {
        yield put(showAlert('Your selected options are in the past, please change your registration.'));
        yield pushLocation(`/demo-day/attendance?invitation=${invitation.id}&class=${classId}`);
      }
    }
  }
}
const getAccTimeKey = (inv) => `accumulatedLivestreamTime${inv.id}`;

export function* startLivestreamActivityTracking(invitation) {
  const watchedTime = parseFloat(localStorage[getAccTimeKey(invitation)] || 0);
  const activityStart = new Date();

  if (!invitation || invitation.strongly_active_at) {
    return;
  }

  window.onbeforeunload = () => {
    localStorage.setItem(getAccTimeKey(invitation), (new Date() - activityStart) + watchedTime);
  };
  const searchObj = parse(window.location.search, { ignoreQueryPrefix: true });
  const minutesToStrongActive = parseInt(searchObj.strongAfter || 30, 10);
  yield fork(
    setStrongActive,
    (minutesToStrongActive * 60 * 1000) - watchedTime,
    resRef(invitation),
    activityStart,
    watchedTime
  );

  if (invitation.attended_livestream) {
    return;
  }

  try {
    yield formRequest({ ...resRef(invitation), attributes: { attended_livestream: true } });
  } catch (err) {
    logError(err);
  }
}

function* setStrongActive(msToWait, activeInvitationRef, activityStart, watchedTime) {
  try {
    if (msToWait > 0) {
      yield delay(msToWait);
    }
    const now = new Date();
    const randomDelay = Math.random() * 3 * 60 * 1000;
    yield delay(randomDelay);
    try {
      yield formRequest({ ...activeInvitationRef, attributes: { strongly_active_at: now.toISOString() } });
    } catch (err) {
      logError(err);
    }
  } finally {
    if (yield cancelled()) {
      localStorage.setItem(getAccTimeKey(activeInvitationRef), (new Date() - activityStart) + watchedTime);
    }
  }
}

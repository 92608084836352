/*
 *
 * ProfileTeamNotes constants
 *
 */

export const LOAD_SCHEMA = 'app/ProfileTeamNotes/LOAD_SCHEMA';
export const SET_FORM_FOR_PROFILE = 'app/ProfileTeamNotes/SET_FORM_FOR_PROFILE';
export const SAVE_TEAM_NOTES = 'app/ProfileTeamNotes/SAVE_TEAM_NOTES';
export const INITIAL_LOAD = 'app/ProfileTeamNotes/INITIAL_LOAD';
export const DELETE_NOTE = 'app/ProfileTeamNotes/DELETE_NOTE';

export const TEAM_NOTES_FORM_NAME = 'teamNotesForm';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import omit from 'lodash/omit';
import classNames from 'classnames';

import AaPropTypes from 'utils/AaPropTypes';


class ScrollDiv extends Component {
  constructor(props) {
    super(props);
    this.renderThumbVer = this.renderThumbVer.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderTrackHor = this.renderTrackHor.bind(this);
  }

  renderThumbVer = ({
    style, darkBg = this.props.darkBg, scrollBarDisplay = this.props.scrollBarDisplay, ...props
  }) => {
    const thumbStyle = {
      backgroundColor: darkBg ? 'rgba(255, 255, 255, 0.4)' : 'rgba(3, 39, 59, 0.3)',
      width: '.4375rem',
      borderRadius: '.21875rem',
      right: '.125rem',
      display: scrollBarDisplay || 'block',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  renderView = (props) => (
    <div
      {...props}
      className={
        classNames('dsa-scrollbars-view', this.props.scrollDivClass, { '_block-scrolling_': this.props.blockScroll })}
    />);

  renderTrackHor = ({ ...props }) => <div {...props} style={{ display: 'none' }} />

  render() {
    const { innerRef, ...props } = this.props;
    return (
      <Scrollbars
        renderThumbVertical={this.renderThumbVer}
        renderTrackHorizontal={this.renderTrackHor}
        renderView={this.renderView}
        autoHide
        autoHideTimeout={1500}
        autoHideDuration={200}
        ref={innerRef}
        {...omit(props, ['darkBg', 'scrollDivClass', 'blockScroll'])}
      />);
  }
}

ScrollDiv.propTypes = {
  darkBg: PropTypes.bool,
  blockScroll: PropTypes.bool,
  scrollDivClass: PropTypes.string,
  innerRef: AaPropTypes.ref,
  scrollBarDisplay: PropTypes.string,
};


export default ScrollDiv;

import { makeSubSelector } from 'utils/selectors';

/**
 * Direct selector to the gdprConfirm state domain
 */
const selectGdprConfirmDomain = (state) => state.get('gdprConfirm');

const selectLoadingConfirmation = makeSubSelector(selectGdprConfirmDomain, ['loadingConfirmation']);
const selectLoadingCancellation = makeSubSelector(selectGdprConfirmDomain, ['loadingCancellation']);
const selectRequestFailed = makeSubSelector(selectGdprConfirmDomain, ['requestFailed']);

export { selectLoadingConfirmation, selectLoadingCancellation, selectRequestFailed };

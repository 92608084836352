import React from 'react';
import PropTypes from 'prop-types';

import { ButtonLink } from 'components/Buttons/ButtonLink';


const FooterButton = (props) => (
  <div className="dsa-footer__nav">
    <ButtonLink noMargin {...props} />
  </div>
);


FooterButton.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default FooterButton;

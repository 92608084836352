/**
 * Closes the AlertBox after 5 seconds
 */
import { put, take, takeLatest } from 'redux-saga/effects';

import { askConfirmation, closeConfirmation, setValidation } from './actions';
import { PRE_CLOSE_CONFIRMATION, CONFIRM_ACTION } from './constants';

/**
 * Close Alert Box 5 seconds after it was opened
 */
/**
 * TODO: Convert args to object and replace in all places
 * e.g. {title, message, yesText = 'Yes', noText = 'No', fieldType, helperText, validator, smallHeading}
 */
export function* askConfirmationSaga(title, message, yesText = 'Yes', noText = 'No', fieldType, helperText, validator, smallHeading, smallHeight) {
  let closeAction;
  let validationErr = true;
  yield put(askConfirmation(title, message, yesText, noText, fieldType, helperText, smallHeading, smallHeight));
  while (validationErr) {
    closeAction = yield take(PRE_CLOSE_CONFIRMATION);
    validationErr = closeAction.isConfirm && validator?.(closeAction.fieldContent);
    if (validationErr) {
      yield put(setValidation(validationErr));
    }
  }
  yield put(closeConfirmation());
  return closeAction.isConfirm && (closeAction.fieldContent || true);
}

export function* confirmAction({
  title, message, yesText, noText, actionToConfirm, actionToCancel,
}) {
  const isConfirm = yield askConfirmationSaga(title, message, yesText, noText, actionToConfirm);
  if (isConfirm && actionToConfirm) {
    yield put(actionToConfirm);
  } else if (!isConfirm && actionToCancel) {
    yield put(actionToCancel);
  }
}

export default function* defaultSaga() {
  yield takeLatest(CONFIRM_ACTION, confirmAction);
}

/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

import { makeSelectAccount, makeSelectHighestUserAccess, makeSelectIsAuthenticated, makeSelectProfile, makeSelectUserIs, selectAccountLoaded, selectUserCapability, selectHasAccount, selectShowOnboardingPage, selectHasOnboardedOverTwoYearsAgo }
  from 'containers/AuthProvider/selectors';
import SignInPage from 'containers/SignIn';
import { parseDateString } from 'utils/dateTime';

import { PERMISSION } from './constants';
import { makeSelectLocation } from './selectors';

const RedirectFromHome = ({
  iAmAdmin, iAmRegistrant, isAuthenticated, myAccount, myProfile, hasAccount, accountLoaded, location,
  iAmDemoActive, iAmOnboardingKickoffSteps, userHighestAccess, showOnboardingPage, founderCapability, hasOnboardedOverTwoYearsAgo,
}) => {
  // sanity checks
  if (hasAccount && (!myAccount || !accountLoaded)) return null;

  const iAmDemoDayUser = userHighestAccess === PERMISSION.demoday;
  const iAmNetworkUser = iAmRegistrant && !iAmAdmin && !iAmDemoDayUser;
  const iAmCorporateOrPartner = [PERMISSION.corporate, PERMISSION.partner].includes(userHighestAccess);

  switch (true) {
    case !isAuthenticated:
      return <SignInPage />;
      // iAmDemoActive means there is an active demo day and it is true for the ff. conditions:
      //  For demo day variation, between demo_date and demo_access_end_date || demoday_active_until
      //  For sneak peek variation, between invitation_at and demo_access_end_date || demoday_active_until
      //  For dry run variation, between dry_run_date until dry_run_date + dry_run_days and between demo_date and demo_access_end_date || demoday_active_until
    case iAmDemoActive:
    case iAmDemoDayUser:
      // upcomingDdInvitation returns 1 most recent DD invitation where demo day is still active
      // upcomingDdInvitation is either Sneak Peek / Dry Run / Demo Day invitation (registered or not)
      const upcomingDdInvitation = myProfile?.upcomingDdInvitation?.();
      const upcomingDemoDay = upcomingDdInvitation?.demo_day?.();
      const upcomingAlchemistClass = upcomingDemoDay?.aa_class?.();

      if (upcomingDdInvitation) {
        if (upcomingDemoDay?.id === myAccount.forDryRun?.()?.id
          || upcomingDemoDay?.id === myAccount?.forClassWebsite?.()?.id
        || upcomingDdInvitation.registration_finished
        ) {
          const isClassAX = upcomingAlchemistClass?.isAlchemistX;
          return <Redirect to={`/demo-day/${isClassAX ? 'ax' : 'aa'}/${upcomingAlchemistClass?.number}/companies`} />;
        }

        // If registration is still open and demo day user has not yet finished their registration, redirect them to DD registration form
        if (upcomingAlchemistClass && new Date() < parseDateString(upcomingAlchemistClass.demo_week_registration_end)) {
          return <Redirect to={`/demo-day/attendance?invitation=${upcomingDdInvitation.id}&class=${upcomingAlchemistClass.id}`} />;
        }
      }

      // When demo day has passed, use myAccount.demoDayClassByType to decide where to redirect them
      const alchemistClasses = myAccount?.demoDayClassByType && Object.values(myAccount.demoDayClassByType());
      const mostRecentAlchemistClass = alchemistClasses?.length > 0 && orderBy(alchemistClasses, 'demo_date_pst_aware', 'desc')?.[0];
      return <Redirect to={`/demo-day/${mostRecentAlchemistClass?.isAlchemistX ? 'ax' : 'aa'}/${mostRecentAlchemistClass?.number}/companies`} />;
    case iAmNetworkUser:
      return (
        <Redirect
          to={{
            pathname: '/register-profile/form',
            search: location.search,
            state: { showVaultForFoundersOnlyMsg: true },
          }}
        />);
    case userHighestAccess === PERMISSION.demoday: // Demo Day users where the demo date has passed
      const aaClasses = Object.values(myAccount?.demoDayClassByType?.()); // always return a max of 2 alchemist classes
      const mostRecentAaClass = aaClasses?.length > 0 && orderBy(aaClasses, 'demo_date_pst_aware', 'desc')?.[0];
      return <Redirect to={`/demo-day/${mostRecentAaClass?.isAlchemistX ? 'ax' : 'aa'}/${mostRecentAaClass?.number}/companies`} />;
    case founderCapability && ((iAmOnboardingKickoffSteps && showOnboardingPage) || hasOnboardedOverTwoYearsAgo):
      return <Redirect to={`/onboarding/${founderCapability.id}`} />;
    case iAmCorporateOrPartner:
      return <Redirect to="/companies" />;
    default:
      return <Redirect to="/people" />;
  }
};

RedirectFromHome.propTypes = {
  iAmAdmin: PropTypes.bool,
  iAmDemoActive: PropTypes.bool,
  iAmRegistrant: PropTypes.bool,
  iAmOnboardingKickoffSteps: PropTypes.bool,
  accountLoaded: PropTypes.bool,
  hasAccount: PropTypes.bool,
  myAccount: PropTypes.object,
  myProfile: PropTypes.object,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  userHighestAccess: PropTypes.string,
  showOnboardingPage: PropTypes.bool,
  founderCapability: PropTypes.object,
  hasOnboardedOverTwoYearsAgo: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  iAmDemoActive: makeSelectUserIs(PERMISSION.demo_active),
  iAmAdmin: makeSelectUserIs(PERMISSION.admin),
  iAmRegistrant: makeSelectUserIs(PERMISSION.registration),
  iAmOnboardingKickoffSteps: makeSelectUserIs(PERMISSION.onboarding_kickoff_steps),
  hasAccount: selectHasAccount,
  myAccount: makeSelectAccount(),
  accountLoaded: selectAccountLoaded,
  myProfile: makeSelectProfile(),
  location: makeSelectLocation(),
  isAuthenticated: makeSelectIsAuthenticated(),
  userHighestAccess: makeSelectHighestUserAccess(),
  showOnboardingPage: selectShowOnboardingPage,
  founderCapability: selectUserCapability,
  hasOnboardedOverTwoYearsAgo: selectHasOnboardedOverTwoYearsAgo,
});

export default connect(mapStateToProps)(RedirectFromHome);

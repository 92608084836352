import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import classNames from 'classnames';


const DefaultButtonsWrapper = ({ children, className }) => (
  <Row className={classNames('dsa-profile_form-buttons_wrapper', className)}>{children}</Row>);

DefaultButtonsWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default DefaultButtonsWrapper;

import { Dropdown } from 'reactstrap';

import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';
import ButtonsHolder from 'components/Buttons/ButtonsHolder';

const DropdownFilters = ClassNameExtendableHoC(Dropdown, 'dsa-dropdown-filters');
const MenuOptions = ClassNameExtendableHoC('div', 'py-5 px-5');
const MenuButtons = ClassNameExtendableHoC(ButtonsHolder, 'px-5 pb-6');
const FiltersSearch = ClassNameExtendableHoC('div', 'py-3');

export { DropdownFilters, MenuOptions, MenuButtons, FiltersSearch };

import React from 'react';
import PropTypes from 'prop-types';


const AlchLogoMark = ({ className = '', isAlchemist = true, width = '24', isStartupMultiClasses }) => {
  const aaOrAxLogo = `/alchemist-${isAlchemist ? '' : 'x-'}logomark.png`;
  const aaAndAxLogo = '/aa-ax-logomark.svg';
  return <img className={className} width={width} src={isStartupMultiClasses ? aaAndAxLogo : aaOrAxLogo} alt={`Alchemist ${isAlchemist ? '' : ' X '}Vault Logo`} />;
};

AlchLogoMark.propTypes = {
  className: PropTypes.string,
  isAlchemist: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isStartupMultiClasses: PropTypes.bool,
};

export default AlchLogoMark;

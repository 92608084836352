import { List } from 'immutable';

export const copyFromTo = (imm, fromPath, toPath) => imm.setIn(toPath, imm.getIn(fromPath));
export const copyFromToFiltered = (imm, fromPath, toPath, filter) =>
  imm.updateIn(toPath, (targetSection) => {
    const unifyOperation = List.isList(targetSection) ? 'concat' : 'merge';
    return targetSection.filterNot(filter)[unifyOperation](imm.getIn(fromPath).filter(filter));
  });

export const resetSection = (current, initial, path) => current.setIn(path, initial.getIn(path));
export const resetSectionFiltered = (current, initial, path, filter) =>
  current.updateIn(path, (targetSection) => targetSection.filterNot(filter).merge(initial.getIn(path).filter(filter)));

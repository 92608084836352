import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownToggle, NavLink } from 'reactstrap';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import classNames from 'classnames';
import { faArrowsCross } from '@fortawesome/pro-regular-svg-icons';

import DropdownSuperClass from 'components/Dropdown/DropdownSuperClass';
import MyProfileImage from 'components/SideBar/MyProfileImage';
import { SideBarItem } from 'components/SideBar/SideBarItem';
import { RESOURCE_PEOPLE } from 'containers/App/constants';
import { refUrl } from 'utils/refs';
import { setDefaultImg, setDefaultCompanyImg } from 'utils/general';


class MyAccountMenu extends DropdownSuperClass {
  render() {
    const {
      myProfile, isFounderPrimaryMembership, primaryCompany, iAmDemoDay, isLocked, logoutAction, authUser, deimpersonate, hasMultiFounderCaps, openFounderPopup, hasMultiActiveClasses,
    } = this.props;
    return (
      <SideBarItem className="nav-link">
        <Dropdown
          className="dsa-dropdown-my-account"
          direction="right"
          isOpen={this.state.isDropdownOpen}
          toggle={this.toggleDropdown}
          trigger="legacy"
        >
          <div>
            <DropdownToggle
              className={classNames('', { active: this.state.isDropdownOpen })}
              tag="button"
            >
              <MyProfileImage myProfile={myProfile} /><span className="dsa-hidden-opacity__transition">My Account</span>
            </DropdownToggle>
          </div>
          <DropdownMenu className="py-5 px-6">
            {!isLocked && (
              <>
                {myProfile &&
                  <NavLink
                    tag={NavLinkReact}
                    to={`/${RESOURCE_PEOPLE}/${myProfile.id}`}
                    onClick={this.closeDropdown}
                  >
                    <img
                      className="dsa-avatar dsa-avatar__popover"
                      src={myProfile.avatarLink('lg')}
                      alt={`${myProfile.name}'s profile`}
                      onError={(e) => setDefaultImg(e)}
                    />
                    My Profile
                  </NavLink>}
                {myProfile && myProfile.company && !iAmDemoDay && (
                  <>
                    <NavLink
                      tag={NavLinkReact}
                      to={refUrl(myProfile.company())}
                      onClick={this.closeDropdown}
                    >
                      <img
                        className="dsa-avatar dsa-avatar__popover"
                        src={myProfile.company().avatarLink('lg')}
                        alt={`${myProfile.company().name}'s profile`}
                        onError={(e) => setDefaultCompanyImg(e)}
                      />
                      My Company
                    </NavLink>
                    {isFounderPrimaryMembership && (
                      <NavLink
                        tag={NavLinkReact}
                        to={`${refUrl(primaryCompany)}/manage-team`}
                        onClick={this.closeDropdown}
                      >
                        <FontAwesomeIcon icon={faUsers} size="lg" fixedWidth className="dsa-avatar__width-xs" />
                        Manage Team
                      </NavLink>)}
                  </>)}
              </>)}
            {hasMultiFounderCaps && hasMultiActiveClasses &&
              <button
                type="button"
                className="nav-link"
                onClick={(e) => {
                  openFounderPopup();
                  return this.closeDropdown(e);
                }}
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faArrowsCross} fixedWidth className="dsa-avatar__width-xs" />
                  Switch Class
                </div>
              </button>
            }
            <button type="button" className="nav-link" onClick={authUser?.impersonator ? deimpersonate : logoutAction}>
              <FontAwesomeIcon icon={faSignOut} fixedWidth className="dsa-avatar__width-xs" />
              {authUser?.impersonator ? 'Stop impersonating' : 'Log Out'}
            </button>
          </DropdownMenu>
        </Dropdown>
      </SideBarItem>
    );
  }
}

MyAccountMenu.propTypes = {
  primaryCompany: PropTypes.object,
  myProfile: PropTypes.object,
  logoutAction: PropTypes.func,
  deimpersonate: PropTypes.func,
  isLocked: PropTypes.bool,
  iAmDemoDay: PropTypes.bool,
  isFounderPrimaryMembership: PropTypes.bool,
  hasMultiFounderCaps: PropTypes.bool,
  openFounderPopup: PropTypes.func,
  hasMultiActiveClasses: PropTypes.bool,
};

export default MyAccountMenu;

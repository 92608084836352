/*
 *
 * LostConnectionOverlay actions
 *
 */

import { CONNECTION_LOST, CONNECTION_RECOVERED } from './constants';

export const connectionLost = () => ({ type: CONNECTION_LOST });
export const connectionRecovered = () => ({ type: CONNECTION_RECOVERED });

import { fromJS } from 'immutable';

import { SET_MODAL_OPEN } from './constants';

const initialState = fromJS({
  isModalOpen: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL_OPEN:
      return state.set('isModalOpen', action.isModalOpen);
    default:
      return state;
  }
}

export default reducer;

import { all, call, put, select } from 'redux-saga/effects';
import { stopSubmit, change } from 'redux-form/immutable';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { toDate } from 'date-fns-tz';

import { updateIncludedFromRequest } from 'actions';
import { RELATIONS, API_BASE, API_COMPANIES_BASE_URL, ATTEND_TYPE, RESOURCE_CAPABILITIES, RESOURCE_PEOPLE } from 'containers/App/constants';
import { handleAuthError } from 'containers/AuthProcess/sagas';
import { isUniquenessError, formRequest } from 'utils/Forms/general';
import { handleCallAndCatches, callAndCatch, saveArrayField, ProcessedError } from 'utils/Forms/sagas';
import { formDataToJApi } from 'utils/formToJsonApi';
import { toJS } from 'utils/general';
import { patch, post } from 'utils/request';
import { putActions, updateObjFromApi, waitSelect } from 'utils/sagas';
import { resRef } from 'utils/refs';
import { selectAaClass, selectInvitation, selectIsUserTzNotSupported, selectTimezone } from 'containers/DemoDay/Registration/selectors';
import { makeSelectLocation, makeSelectObject } from 'containers/App/selectors';
import { BROWSER_NOT_SUPPORTED, getDateTime, getValidatedTz } from 'components/Timezone/utils';

import { SAMPLE_COMPANY_MINIMAL, SAMPLE_MEETING, SAMPLE_MEMBERSHIP, SAMPLE_PROFILE_EMAIL } from '../constants';
import { makeSelectCompaniesByName } from '../selectors';
import { setCompanyByName } from '../actions';
import { savePortfolioInvestmentSaga, saveFormSection } from '../saga';


export function* savePeopleExperienceSectionSaga(action) {
  const errors = yield saveArrayField(action, 'experiences', membershipCall, 'company_membership');

  if (!isEmpty(errors)) {
    yield put(stopSubmit(action.section, errors));
    return false;
  }

  if (action.successAction) {
    yield put(action.successAction);
  }
  return true;
}

function* membershipCall(membership, objRef, idx, action) {
  const membershipToSave = { ...membership };
  try {
    membershipToSave.company = yield handleNewCompany(membership.company);
    yield put(change(action.section, `experiences[${idx}].company`, membershipToSave.company));
  } catch (err) {
    throw new ProcessedError('experiences', 'Error adding new company');
  }
  const data = formDataToJApi(
    { ...SAMPLE_MEMBERSHIP, id: membershipToSave.id },
    { relation: RELATIONS.OTHER, ...membershipToSave, user: toJS(objRef) }
  );
  return yield formRequest(data, 'company.stages_of_interest,company.areas_of_service');
}

export function* profileEmailCall(profileEmail, objRef) {
  const data = formDataToJApi(
    { ...SAMPLE_PROFILE_EMAIL, id: profileEmail.id },
    profileEmail.id ? profileEmail : { user: toJS(objRef), ...profileEmail },
  );
  if (data.attributes.is_primary === false) {
    delete data.attributes.is_primary;
  }
  return yield formRequest(data);
}

export function* meetingsCall(meetingCalendar) {
  const data = formDataToJApi(
    { ...SAMPLE_MEETING, id: meetingCalendar.id },
    meetingCalendar.id ? omit(meetingCalendar, 'user') : meetingCalendar,
  );
  return yield formRequest(data);
}

export function* handleNewCompany(company) {
  if (!company?.name || (!company.isNew && company.id > 0)) {
    return company;
  }
  try {
    const newCompanyRequest = yield call(
      post,
      `${API_COMPANIES_BASE_URL}?_minimal=1`,
      { data: formDataToJApi(SAMPLE_COMPANY_MINIMAL, company) }
    );
    yield put(updateIncludedFromRequest(newCompanyRequest));
    const newMinimalCompany = { ...resRef(newCompanyRequest.data), name: company.name };

    yield put(setCompanyByName(company.name, newMinimalCompany));

    return newMinimalCompany;
  } catch (err) {
    let error = '';
    let foundUniquenessError;
    try {
      const errorJson = yield err.response.json();
      if (errorJson.errors.forEach) {
        errorJson.errors.forEach((currentError) => {
          if (isUniquenessError(currentError)) {
            foundUniquenessError = true;
          }
          error += `${currentError.detail}; `;
        });
      } else if (isUniquenessError(errorJson.errors)) {
        foundUniquenessError = true;
      } else {
        error = 'Server Error';
      }
    } catch (e) {
      error = err.toString();
    }
    if (foundUniquenessError) {
      const companiesByName = yield waitSelect(makeSelectCompaniesByName(), 10);
      if (companiesByName[company.name]) {
        return companiesByName[company.name];
      }
    }
    throw error;
  }
}

export function* savePeoplePortfolioSectionSaga(action) {
  const multiFormMainField = 'erogated_investments';
  const investmentRequests = yield all(action.values[multiFormMainField].map((investment) => callAndCatch(savePortfolioInvestmentSaga, {
    ...investment,
    investing_company: investment.investment_type === 'Fund' ? investment.investing_company : null,
    investor_profile: toJS(action.objRef),
  })));
  const isSuccess = yield handleCallAndCatches(investmentRequests, action, multiFormMainField);
  yield updateObjFromApi(action.objRef, multiFormMainField);
  return isSuccess;
}

export function* loadCompanyUsers({ payload: company }) {
  yield updateObjFromApi(company, 'current_members_rel.user');
}

export function* sendPasswordChangeSaga({ values, section, successAction }) {
  try {
    yield post(`${API_BASE}/authentication/passwd/change`, values);
    yield putActions(successAction);
  } catch (e) {
    yield handleAuthError(e, section, { _error: 'There was an error updating your password' });
  }
}

export function* saveDemoDayAttendanceSectionSaga(action) {
  const location = yield select(makeSelectLocation());
  const aaClass = yield select(selectAaClass);
  const isUserTzNotSupported = yield select(selectIsUserTzNotSupported);
  const invitation = yield select(selectInvitation);
  let normalizedAction = { ...action };
  let fallbackTimezone = null;

  const selectedTimezone = yield select(selectTimezone);

  if (location.pathname.includes('/people/')) {
    // This is executed on save of demo day section in people profile
    const selectedPerson = yield select(makeSelectObject({ type: RESOURCE_PEOPLE, id: location.pathname.split('/people/')?.[1] }));
    fallbackTimezone = getValidatedTz(action.values.registration_timezone, selectedPerson?.location?.().timezone, true);
    fallbackTimezone = getDateTime(new Date(), fallbackTimezone) === BROWSER_NOT_SUPPORTED ? 'UTC' : fallbackTimezone;
    normalizedAction.values.registration_timezone = fallbackTimezone;
  } else if (selectedTimezone?.tzIdentifier && (!isUserTzNotSupported || (isUserTzNotSupported && invitation && !invitation.registration_timezone))) {
    normalizedAction.values.registration_timezone = selectedTimezone.tzIdentifier;
  }

  if (action.values.rec_access_date_usertz && action.values.rec_access_time_usertz) {
    const selectedDateTimeInSelectedTz = toDate(
      `${action.values.rec_access_date_usertz}T${action.values.rec_access_time_usertz}`,
      { timeZone: location.pathname.includes('/people/') ? fallbackTimezone : selectedTimezone?.tzIdentifier }
    );
    const utcDate = toDate(selectedDateTimeInSelectedTz, { timeZone: 'UTC' });

    normalizedAction = {
      ...normalizedAction,
      values: {
        ...normalizedAction.values,
        rec_access_date: utcDate.toISOString().split('T')[0],
        rec_access_time: utcDate.toISOString().split('T')[1].split('.')[0],
      },
    };
  }

  if (aaClass && !aaClass.livestream) {
    normalizedAction.values.registered_to_attend_as = ATTEND_TYPE.remotely_recorded;
  }
  yield saveFormSection(normalizedAction);
}

export function* saveGeneralInfoSection(action) {
  const editedAction = { ...action };
  if (action.values.corporate_cert_level === 0) {
    editedAction.values.corporate_cert_level = null;
  }

  yield saveFormSection(editedAction);
}

export function* savePeopleAccountSectionSaga(action) {
  const capabilitiesRel = action.values.capabilities_rel;

  if (capabilitiesRel) {
    // update every capability inside capabilities_rel
    const errors = yield saveArrayField(action, `${RESOURCE_CAPABILITIES}_rel`, saveCapabilityRel, `${RESOURCE_CAPABILITIES}_rel`, action.objRef);

    if (!isEmpty(errors)) {
      return yield put(stopSubmit(action.section, errors));
    }

    // delete capabilities_rel from action copy to update account resource
    // eslint-disable-next-line no-param-reassign
    delete action.values.capabilities_rel;
  }

  if (action?.values?.connect_me_quota !== null || action?.values?.lms_wp_id) {
    // save account info form
    yield saveFormSection(action);
  } else {
    // close the form
    yield put(action.successAction);
  }
  return true;
}

export function* saveMentorSectionSaga(action) {
  const { meetings } = action.values;
  const newAction = cloneDeep(action);
  newAction.handlePostSpecialFields = function* funcToRun() {
    let errors = null;
    if (meetings) {
      errors = yield saveArrayField(action, 'meetings', meetingsCall);
    }
    return errors;
  };
  if (newAction.values.meetings) {
    delete newAction.values.meetings;
  }
  yield saveFormSection(newAction);
}

export function* saveFormSectionWithEmails(action) {
  const profileEmails = action.values.profile_emails;
  const newAction = cloneDeep(action);
  newAction.handlePostSpecialFields = function* funcToRun() {
    let errors = null;
    if (profileEmails) {
      errors = yield saveArrayField(action, 'profile_emails', profileEmailCall);
    }
    return errors;
  };
  if (newAction.values.profile_emails) {
    delete newAction.values.profile_emails;
  }
  yield saveFormSection(newAction);
}

function* saveCapabilityRel(rel) {
  return yield call(patch, `${API_BASE}/${RESOURCE_CAPABILITIES}/${rel.id}`, {
    data: {
      ...resRef(rel),
      attributes: {
        active: rel.active,
        inactive_reason: rel.inactive_reason,
        inactive_reason_other: rel.inactive_reason_other,
      },
    },
  });
}

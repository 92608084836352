import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ className = '', children }) => (
  <div className={`dsa-one-pager__footer ${className}`}>
    {children}
  </div>
);


Footer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifiSlash } from '@fortawesome/pro-solid-svg-icons/faWifiSlash';

import injectReducer from 'utils/injectReducer';

import { selectIsConnected } from './selectors';
import reducer from './reducer';


const LostConnectionOverlay = ({ isConnected }) => isConnected
  ? null
  : (
    <div className="dsa-disconnection-overlay">
      <h1><FontAwesomeIcon icon={faWifiSlash} className="me-5" /> Connection Failure</h1>
    </div>);

LostConnectionOverlay.propTypes = {
  isConnected: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isConnected: selectIsConnected,
});
const withReducer = injectReducer({ key: 'isConnected', reducer });

export default compose(
  connect(mapStateToProps),
  withReducer
)(LostConnectionOverlay);

import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Generic/Tooltip';

import ToggleButton from './ToggleButton';


class ToggleButtonTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.outerRef = React.createRef();
  }

  render() {
    const { delay = 30, tooltipText, ...props } = this.props;
    return (
      <>
        <div className="dsa-tooltip__ref-div" ref={this.outerRef}>
          <ToggleButton {...props} />
        </div>
        {tooltipText && (
          <Tooltip
            className="dsa-tooltip-menu-button"
            placement="bottom"
            targetRef={this.outerRef}
            delay={delay}
          >{tooltipText}
          </Tooltip>)}
      </>
    );
  }
}


ToggleButtonTooltip.propTypes = {
  delay: PropTypes.number,
  tooltipText: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToggleButtonTooltip;

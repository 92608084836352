import { fromJS } from 'immutable';

import { SET_VERIFIABLE } from './constants';

const initialState = fromJS({ verifiableRef: false });

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VERIFIABLE:
      return state.set('verifiableRef', action.verifiableRef);
    default:
      return state;
  }
}

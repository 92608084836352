import { createSelector } from 'reselect';

import { makeSelectQueryParam } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';

import reducer from './reducer';

/**
 * Default selector used by OAuthLanding
 */
const selectStateParam = makeSelectQueryParam('state');
const localInitialState = reducer(undefined, {});
const selectOAuthLanding = (state) => state ? state.get('oauthlanding', localInitialState) : localInitialState;

const selectLoginError = makeSubSelector(selectOAuthLanding, ['loginErrorCode']);

const selectFlow = createSelector(selectStateParam, (stateParam) => stateParam?.split('--')[1]);
const selectUnauthHash = createSelector(selectStateParam, (stateParam) => stateParam?.split('--')[2]);
const selectDDInviteFlag = createSelector(selectStateParam, (stateParam) => stateParam?.split('--')[3]);

export {
  selectFlow,
  selectUnauthHash,
  selectLoginError,
  selectDDInviteFlag,
};

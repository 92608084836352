import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';


class SideBarHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapse: true };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(() => ({ collapse: !this.state.collapse }));
  }

  render() {
    const { children, label } = this.props;
    return (
      <div>
        <button className="dsa-site-sidebar_item _heading_" onClick={this.toggle}>
          {label}
        </button>
        <Collapse isOpen={this.state.collapse}>
          {children}
        </Collapse>
      </div>);
  }
}

SideBarHeading.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
};

export default SideBarHeading;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ListSection from 'components/Lists/ListSection';
import { RESOURCE_PEOPLE } from 'containers/App/constants';

import { openPeopleListCreateModal, deletePeopleLists, markEventList as markEventListAction, loadNextPageEventLists } from '../actions';
import EventListsButtons from './EventListsButtons';
import PeopleListCard from '../PeopleListCard';
import { selectMarkedEventLists, selectEventLists, selectEventListsLoading, selectEventListsHasMore, selectEventListsLoadingNextPage } from '../selectors';
import { LIST_TYPES } from '../constants';
import EventListsButtonsArchive from './EventListsButtons.archive';


const EventLists = ({ lists, markEventList, markedEventLists, isArchive, loadNextPage, loading, hasMore, loadingNextPage }) => {
  const archivedLists = lists?.filter((list) => list.archived);

  const visibleLists = isArchive
    ? archivedLists
    : lists?.filter((list) => !list.archived);

  return (
    <ListSection
      title={isArchive ? 'Archived: Event Lists' : 'Event Lists'}
      buttonsComponent={isArchive ? EventListsButtonsArchive : EventListsButtons}
      containerId={LIST_TYPES.event}
      listsNumber={lists?.length}
      badgeText={isArchive
        ? 'The lists will deleted permanently after 2 years of creation date.'
        : 'The lists will be moved to the archive after 1 year of the creation date.'}
      isArchive={isArchive}
      buttonsProps={{
        linkTo: `/${RESOURCE_PEOPLE}/lists/archived/event`,
        showArchiveLink: archivedLists?.length > 0,
      }}
      loadNextPage={loadNextPage}
      loading={loading}
      hasMore={hasMore}
      loadingNextPage={loadingNextPage}
    >
      {visibleLists?.map((peopleList) => (
        <PeopleListCard
          peopleList={peopleList}
          key={peopleList.id}
          markObject={markEventList}
          markedObjects={markedEventLists}
        />))}
    </ListSection>
  );
};


EventLists.propTypes = {
  lists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  markEventList: PropTypes.func,
  markedEventLists: PropTypes.array,
  isArchive: PropTypes.bool,
  loadNextPage: PropTypes.func,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadingNextPage: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (personList) => dispatch(openPeopleListCreateModal(personList)),
  deleteList: (personList) => dispatch(deletePeopleLists(personList)),
  markEventList: (personListRel, unselect) => dispatch(markEventListAction(personListRel, unselect)),
  loadNextPage: () => dispatch(loadNextPageEventLists()),
});

const mapStateToProps = createStructuredSelector({
  lists: selectEventLists,
  markedEventLists: selectMarkedEventLists,
  loading: selectEventListsLoading,
  hasMore: selectEventListsHasMore,
  loadingNextPage: selectEventListsLoadingNextPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(EventLists);

import { makeSubSelector } from 'utils/selectors';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});
const selectAiMessageDomain = (state) => state ? state.get('aiMessage', localInitialState) : localInitialState;

const selectIsLoading = makeSubSelector(selectAiMessageDomain, ['isLoading']);
const selectChatHistory = makeSubSelector(selectAiMessageDomain, ['chatHistory']);


export { selectIsLoading, selectChatHistory };

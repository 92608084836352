import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ButtonLinkSec } from 'components/Buttons/ButtonLinkSec';
import { Text } from 'components/Text/Body';

import { openDocModal } from './DocsModal/actions';
import { DOC_TYPE } from './DocsModal/constants';


const TermsParagraph = ({ text = 'clicking ', signInText = 'Sign Up / Sign In', ...props }) => (
  <div className="mt-7">
    <Text>
      By {text}{signInText}, you agree to our{' '}
      <ButtonLinkSec inlineText invertUnderline onClick={() => props.openDocModal(DOC_TYPE.privacyPolicy)}>
        Privacy Policy
      </ButtonLinkSec> and{' '}
      <ButtonLinkSec inlineText invertUnderline onClick={() => props.openDocModal(DOC_TYPE.termsGdpr)}>
        Terms and Conditions
      </ButtonLinkSec>.
    </Text>
  </div>);

TermsParagraph.propTypes = {
  openDocModal: PropTypes.func,
  text: PropTypes.string,
  signInText: PropTypes.string,
};

const mapDispatchToProps = { openDocModal };

export default connect(null, mapDispatchToProps)(TermsParagraph);

import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ButtonBase from './ButtonBase';

const ButtonTer = DefaultPropsHoC(ButtonBase, { color: 'tertiary' });
const ButtonTerSm = DefaultPropsHoC(ButtonBase, { color: 'tertiary', size: 'sm' });
const ButtonTerLg = DefaultPropsHoC(ButtonBase, { color: 'tertiary', size: 'lg' });


export {
  ButtonTer,
  ButtonTerSm,
  ButtonTerLg,
};

import * as Sentry from '@sentry/browser';
import { STOP_SUBMIT } from 'redux-form/lib/actionTypes';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { initialize, reset } from 'redux-form';
import { stopSubmit } from 'redux-form/immutable';

import { SEND_RESTORE_ACCESS_EMAIL,
  INITIAL_LOAD,
  SAVE_COMPANY_STEP,
  SAVE_PERSONAL_MORE_STEP,
  SAVE_ROLES_STEP,
  STEP_COMPANIES,
  STEP_PERSONAL,
  STEP_PERSONAL_MORE,
  STEP_ROLES,
  STEP_SUCCESS,
  FINISH_REGISTRATION, STEP_TWO_SHORT_FORM, SAVE_STEP_TWO, GET_NEW_PRIMARY_COMPANY, LOAD_USER_BY_REF } from 'containers/RegisterProfile/constants';
import { updateIncluded, updateIncludedFromRequest } from 'actions';
import { showAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { API_COMPANIES_BASE_URL, API_PEOPLE_BASE_URL } from 'containers/App/constants';
import { makeSelectQueryParam } from 'containers/App/selectors';
import { makeSelectProfile, makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { markDemoDayRegistrationFinished } from 'containers/DemoDay/sagas';
import { markIfsRegistrationFinished } from 'containers/InvestorFeedbackSummit/sagas';
import { SAMPLE_COMPANY, SAMPLE_PERSON, SAMPLE_PERSON_FOR_ROLES, STEP_TWO_SAMPLE_PERSON_FOR_ROLES } from 'containers/Forms/constants';
import {
  meetingsCall,
  savePeopleExperienceSectionSaga,
  savePeoplePortfolioSectionSaga,
} from 'containers/Forms/People/saga';
import { handleTagsFields, createNewLocation, createNewTags } from 'containers/Forms/saga';
import { handleError as genericHandleError, preprocessError, saveArrayField } from 'utils/Forms/sagas';
import { formDataToJApi } from 'utils/formToJsonApi';
import { logError, logToSentry } from 'utils/log';
import { extractData } from 'utils/jsonApiExtract';
import { refApiUrl, resRef } from 'utils/refs';
import { patch, post, request } from 'utils/request';
import { updateObjFromApi, waitSelect, pushLocation } from 'utils/sagas';


import { setShowErrorSummary, setIsCheckingUser, setRegisteringUser, setStep, setNewPrimaryCompanyExp }
  from './actions';
import {
  makeSelectEditedCompany,
  makeSelectRegisteringUser,
  makeSelectShouldShowCompanyStep,
  makeSelectShouldShowRolesStep, selectSpecialFlow, selectNewPrimaryCompany,
} from './selectors';


const includedRels = ['tags', 'location', 'roles', 'company_membership.company', 'company.areas_of_service',
  'company.stages_of_interest', 'areas_of_interest', 'press_topics', 'profile_emails', 'not_areas_of_interest',
  'areas_of_service', 'erogated_investments.investment_round.company.investment_rounds',
  'erogated_investments.investing_company', 'meetings'];

function* handleError(err, section) {
  const { errorJson, serverError } = yield preprocessError(err);
  if (window.sentryDSN && Sentry) {
    const lastServerError = errorJson || serverError;
    logToSentry(
      err,
      Sentry.Severity.Warning,
      lastServerError ? { lastServerError: errorJson || serverError } : null,
      { registrationForm: section, httpCode: err.response && err.response.status }
    );
  }
  yield put(setShowErrorSummary(true));
  yield genericHandleError(errorJson ? { errorJson } : err, section);
}

function* registrationInitialLoad() {
  const ref = yield select(makeSelectQueryParam('ref'));
  const linkedinCode = yield select(makeSelectQueryParam('code'));
  const isAdmin = yield select(makeSelectUserIs('admin'));

  if (isAdmin && ref) {
    yield loadUserByRef(ref);
  } else if (linkedinCode) {
    yield loadUserFromRefAndLinkedInCode();
  } else {
    yield loadAuthUser();
  }
}

function* loadAuthUser() {
  const specialFlow = yield select(selectSpecialFlow);
  const startingStep = yield select(makeSelectQueryParam('start'));

  const myProfile = yield waitSelect(makeSelectProfile());

  yield updateObjFromApi(
    myProfile,
    specialFlow === 'demo-day' ? [...includedRels, 'demo_day_users_rel'] : includedRels,
    '&_step=prepopulation'
  );
  yield put(setRegisteringUser(resRef(myProfile)));
  yield put(reset(STEP_PERSONAL));
  if (startingStep) {
    const validStep = [STEP_PERSONAL, STEP_PERSONAL_MORE, STEP_ROLES, STEP_COMPANIES, STEP_TWO_SHORT_FORM].includes(startingStep) ? startingStep : STEP_PERSONAL;
    yield put(setStep(validStep));
  }
  if (specialFlow === 'demo-day' && myProfile && !myProfile.needsRegistration) {
    yield pushLocation(`/demo-day/edit-profile/${myProfile.id}`);
    return;
  }
  yield put(setIsCheckingUser(false));
}

function* loadUserByRef(ref) {
  let linkedinAuthResp = yield call(
    request,
    `${API_PEOPLE_BASE_URL}/linkedin_token?unauth_hash=${ref}`
  );

  const personRequest = yield call(request, `${refApiUrl(linkedinAuthResp.data)}?include=${includedRels.join(',')}`);
  const { inclusions, items: [profileRef] } = extractData(personRequest);
  yield put(updateIncluded(inclusions));

  if (!personRequest.data.attributes.firstname) {
    try {
      linkedinAuthResp = yield call(request, `${refApiUrl(profileRef)}/linkedin`);
      yield put(updateIncludedFromRequest(linkedinAuthResp));
    } catch (e) {
      yield put(showAlert('Failed to fetch user\'s LinkedIn data'));
    }
  }

  yield put(setRegisteringUser(profileRef));
  yield put(reset(STEP_PERSONAL));
  // if (!inclusions?.people?.[profileRef.id]?.meta?.register_date) {
  //   yield put(setShowImportSuccess(true));
  // } else {
  //   yield put(setShowImportSuccess(false));
  // }
  yield put(setIsCheckingUser(false));
}

function* loadUserFromRefAndLinkedInCode() {
  // const landingQParams = sessionStorage.getItem('landingQueryParams')
  //   && parse(sessionStorage.getItem('landingQueryParams'), { ignoreQueryPrefix: true });

  const profile = yield waitSelect(makeSelectProfile());
  try {
    const personRequest = yield call(request, `${API_PEOPLE_BASE_URL}/linkedin?include=${includedRels.join(',')}`);
    yield put(updateIncludedFromRequest(personRequest));
  } catch (e) {
    logError(e);
    // if (!(profile.firstname && profile.lastname)) {
    //   yield put(setShowImportSuccess(false));
    //   yield showImportErrorPage(true);
    // }
  }
  // yield put(setShowImportSuccess(!(landingQParams && landingQParams.isUpdate)));
  yield put(setRegisteringUser(resRef(profile)));
  yield put(reset(STEP_PERSONAL));

  yield put(setIsCheckingUser(false));
}

// function* showImportErrorPage(allowToGoOn) {
//   yield put(setShowImportError(true, allowToGoOn));
//   if (!allowToGoOn && window && window.Intercom) window.Intercom('show');
// }

function* savePersonalMoreStepSaga(action) {
  const formValues = { ...action.values };
  const memberships = formValues.experiences;
  delete formValues.experiences;

  // const editedObject = yield select(makeSelectObjectRaw(editedObjectRef));
  const baseRequestURL = `${API_PEOPLE_BASE_URL}/${action.objRef.id}`;

  const primaryCompanyBeforeSave = yield select(makeSelectEditedCompany());

  try {
    formValues.tags = yield createNewTags(formValues.tags);
    const dataToSubmit = formDataToJApi({ ...SAMPLE_PERSON, id: action.objRef.id }, formValues);
    const sentRels = keys(dataToSubmit.relationships);

    const saveRequest = yield call(
      patch,
      `${baseRequestURL}?_step=registration_personal_more&_registration=1${sentRels.length ? `&include=${sentRels.join()}` : ''}`,
      { data: dataToSubmit }
    );
    yield put(updateIncludedFromRequest(saveRequest));

    if (memberships && memberships.length) {
      const sagaResult = yield savePeopleExperienceSectionSaga({
        values: { experiences: memberships }, objRef: action.objRef, section: action.section,
      });
      if (!sagaResult) {
        return null;
      }
    }

    const newPrimaryCompany = yield select(makeSelectEditedCompany());
    if (primaryCompanyBeforeSave && newPrimaryCompany && newPrimaryCompany.id !== primaryCompanyBeforeSave.id) {
      yield put(initialize(STEP_COMPANIES, newPrimaryCompany));
      yield put(reset(STEP_COMPANIES));
    }

    const shouldShowRolesStep = yield select(makeSelectShouldShowRolesStep());
    yield put(setStep(shouldShowRolesStep ? STEP_ROLES : STEP_COMPANIES));
  } catch (err) {
    yield handleError(err, STEP_PERSONAL_MORE);
  }
  return null;
}

function* validateInvestmentsAndMeetings(action, erogatedInvestments, meetings) {
  if (erogatedInvestments && erogatedInvestments.filter((inv) => inv.company_invested_in).length) {
    const savePeoplePortfolioSuccess = yield savePeoplePortfolioSectionSaga({
      ...action,
      values: { erogated_investments: erogatedInvestments },
    });
    if (!savePeoplePortfolioSuccess) {
      yield put(setShowErrorSummary(true));
      return;
    }
  }
  if (meetings) {
    const errors = yield saveArrayField(action, 'meetings', meetingsCall);
    if (!isEmpty(errors)) {
      yield put(stopSubmit(action.section, errors));
      yield put(setShowErrorSummary(true));
    }
  }
}

function* saveRolesStepSaga(action) {
  let formValues = { ...action.values };
  formValues.location = yield createNewLocation(formValues.location);
  const { erogated_investments: erogatedInvestments, meetings } = formValues;
  delete formValues.erogated_investments;
  delete formValues.meetings;
  const baseRequestURL = `${API_PEOPLE_BASE_URL}/${action.objRef.id}`;

  try {
    formValues = yield handleTagsFields(formValues);
    // ToDo: to check Should I use The loaded user himself? make sure it doesn't include other rels
    const dataToSubmit = formDataToJApi({ ...SAMPLE_PERSON_FOR_ROLES, id: action.objRef.id }, formValues);
    const sentRels = keys(dataToSubmit.relationships);
    const saveRequest = yield call(
      patch,
      `${baseRequestURL}?_step=roles&_registration=1${sentRels.length ? `&include=${sentRels.join()}` : ''}`,
      { data: dataToSubmit }
    );
    yield put(updateIncludedFromRequest(saveRequest));

    yield validateInvestmentsAndMeetings(action, erogatedInvestments, meetings);

    const shouldShowCompanyStep = yield select(makeSelectShouldShowCompanyStep());
    yield put(stopSubmit(action.section));
    if (shouldShowCompanyStep) {
      yield put(setStep(STEP_COMPANIES));
    } else {
      yield finishRegistration();
    }
  } catch (err) {
    yield handleError(err, STEP_ROLES);
  }
}

function* saveCompanyStepSaga(action) {
  const formValues = action.values;
  const objRef = action.values.id && action.values.type ? resRef(action.values) : action.objRef;
  const baseRequestURL = `${API_COMPANIES_BASE_URL}/${objRef.id}?_registration=1`;

  try {
    formValues.areas_of_service = yield createNewTags(formValues.areas_of_service);
    const saveRequest = yield call(
      patch,
      baseRequestURL,
      { data: formDataToJApi(objRef ? { ...SAMPLE_COMPANY, id: objRef.id } : SAMPLE_COMPANY, formValues) }
    );
    yield put(updateIncludedFromRequest(saveRequest));
    yield finishRegistration();
  } catch (err) {
    yield handleError(err, STEP_COMPANIES);
  }
}

function* saveStepTwoShortForm(action) {
  const primaryCompanyFromUser = yield select(makeSelectEditedCompany());
  const newPrimaryCompany = yield select(selectNewPrimaryCompany);
  const primaryCompany = newPrimaryCompany || primaryCompanyFromUser;

  const formValues = { ...action.values };

  formValues.location = yield createNewLocation(formValues.location);
  const { erogated_investments: erogatedInvestments, meetings } = formValues;
  const memberships = formValues.experiences;
  delete formValues.erogated_investments;
  delete formValues.meetings;
  delete formValues.experiences;

  // Separate values between user and company
  const {
    // eslint-disable-next-line babel/camelcase
    fund_type, stages_of_interest, fund_size, fund_vintage_year, fund_averagecheck, fund_maxcheck, fund_mincheck, fund_investmentfrequency, fund_addvalue, fund_preferlead, areas_of_service, service_addvalue, service_alchemistoffer, service_offer_email_or_link, ...formUserValues
  } = formValues;
  let userValues = { ...formUserValues };

  const formCompanyValues = {
    fund_type, stages_of_interest, fund_size, fund_vintage_year, fund_averagecheck, fund_maxcheck, fund_mincheck, fund_investmentfrequency, fund_addvalue, fund_preferlead, areas_of_service, service_addvalue, service_alchemistoffer, service_offer_email_or_link,
  };

  try {
    // validating and saving memberships/experiences, erogatedInvestments
    if (memberships && memberships.length) {
      const sagaResult = yield savePeopleExperienceSectionSaga({
        values: { experiences: memberships }, objRef: action.objRef, section: action.section,
      });
      if (!sagaResult) {
        return null;
      }
    }
    yield validateInvestmentsAndMeetings(action, erogatedInvestments, meetings);

    // saving user form request
    userValues = yield handleTagsFields(userValues);
    const dataToSubmit = formDataToJApi({ ...STEP_TWO_SAMPLE_PERSON_FOR_ROLES, id: action.objRef.id }, userValues);
    const sentRels = keys(dataToSubmit.relationships);
    const requestURL = `${API_PEOPLE_BASE_URL}/${action.objRef.id}?_step=twostep2&_registration=1${sentRels.length ? `&include=${sentRels.join()}` : ''}`;

    const saveRequest = yield call(patch, requestURL, { data: dataToSubmit });
    yield put(updateIncludedFromRequest(saveRequest));

    if (primaryCompany) {
      // saving company request
      formCompanyValues.areas_of_service = yield createNewTags(formCompanyValues.areas_of_service);
      const companyRequestURL = `${API_COMPANIES_BASE_URL}/${primaryCompany.id}?_registration=1`;
      const saveCompanyRequest = yield call(
        patch,
        companyRequestURL,
        { data: formDataToJApi({ ...SAMPLE_COMPANY, id: primaryCompany.id }, formCompanyValues) }
      );
      yield put(updateIncludedFromRequest(saveCompanyRequest));
    }

    yield finishRegistration();
    yield put(setNewPrimaryCompanyExp(null));
  } catch (err) {
    yield handleError(err, STEP_TWO_SHORT_FORM);
  }
  return null;
}

function* finishRegistration() {
  const registrant = yield select(makeSelectRegisteringUser());
  try {
    const finishRegistrationRequest = yield call(
      patch,
      `${API_PEOPLE_BASE_URL}/${registrant.id}?_step=finish_registration&_registration=1&include=account`,
      { data: resRef(registrant) }
    );
    yield put(updateIncludedFromRequest(finishRegistrationRequest));
    const specialFlow = yield select(selectSpecialFlow);
    try {
      if (specialFlow?.match(/^demo-day/)) {
        yield markDemoDayRegistrationFinished();
      } else if (specialFlow?.match(/^ifs/)) {
        yield markIfsRegistrationFinished();
      }
    } catch (e) {
      yield showAlert(
        'An error was found marking your registration as finished.  Please try again later and contact the Alchemist Team if the problem persists',
        COLOR.danger
      );
    }

    yield put(setStep(STEP_SUCCESS));
  } catch (err) {
    logError(err);
  }
}

function* showErrorSummary() {
  yield put(setShowErrorSummary(true));
}

function* sendRestoreAccessEmailSaga({ email }) {
  try {
    yield call(post, `${API_PEOPLE_BASE_URL}/send_register_link`, { email });
    yield put(showAlert('Success! Email has been sent.'));
    localStorage.removeItem('token');
  } catch (e) {
    logError('sendRestoreAccessEmailSaga', e);
    yield put(showAlert('Some issue happened, please contact support', COLOR.danger));
  }
}

function* getNewPrimaryCompanyExp(action) {
  try {
    const requestURL = `${API_COMPANIES_BASE_URL}/${action.companyId}?include=stages_of_interest`;
    const companyRequest = yield call(request, requestURL);
    const { inclusions: companies } = extractData(companyRequest);
    const company = {
      ...companies?.companies?.[action?.companyId].attributes,
      id: companies?.companies?.[action?.companyId].id,
      stages_of_interest: companies?.stages_of_interest ? Object.values(companies?.stages_of_interest).map((stage) => {
        const { attributes, ...rest } = stage;
        return {
          ...rest,
          title: stage.attributes.title,
          resRef: resRef(stage),
        };
      }) : [],
    };
    yield put(setNewPrimaryCompanyExp(company));
    yield put(updateIncludedFromRequest(companyRequest));
  } catch (e) {
    logError('getNewPrimaryCompanyExp', e);
  }
}

function* loadUserUsingRef() {
  const ref = yield select(makeSelectQueryParam('ref'));
  yield loadUserByRef(ref);
}

export default function* defaultSaga() {
  // yield takeLatest(INITIAL_LOAD, loadUserFromRefAndLinkedInCode);
  yield takeLatest(INITIAL_LOAD, registrationInitialLoad);
  yield takeLatest(SAVE_PERSONAL_MORE_STEP, savePersonalMoreStepSaga);
  yield takeLatest(SAVE_COMPANY_STEP, saveCompanyStepSaga);
  yield takeLatest(SAVE_ROLES_STEP, saveRolesStepSaga);
  yield takeLatest(SAVE_STEP_TWO, saveStepTwoShortForm);
  yield takeLatest(FINISH_REGISTRATION, finishRegistration);
  yield takeLatest(
    ({ type, payload, error }) => type === STOP_SUBMIT
      && error
      && [STEP_COMPANIES, STEP_PERSONAL, STEP_PERSONAL_MORE, STEP_ROLES, STEP_TWO_SHORT_FORM].includes(payload.form),
    showErrorSummary
  );
  yield takeLatest(SEND_RESTORE_ACCESS_EMAIL, sendRestoreAccessEmailSaga);
  yield takeLatest(GET_NEW_PRIMARY_COMPANY, getNewPrimaryCompanyExp);
  yield takeLatest(LOAD_USER_BY_REF, loadUserUsingRef);
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import ListsWrapper from '../ListsWrapper';
import EventLists from '../Sections/EventLists';
import { loadEventLists, clearMarkedEventLists } from '../actions';
import { selectEventLists } from '../selectors';
import { EVENT_LISTS } from '../constants';

const ArchivedEventLists = ({ onStart, clearMarkedEvent, lists }) => {
  useEffect(() => {
    // fetch lists when user reloads page
    if (!lists || (lists && lists.length === 0)) {
      onStart();
    }

    return () => {
      clearMarkedEvent();
    };
  }, []);

  return (
    <ListsWrapper listsType={EVENT_LISTS} isArchive>
      <EventLists isArchive />
    </ListsWrapper>
  );
};

ArchivedEventLists.propTypes = {
  onStart: PropTypes.func,
  clearMarkedEvent: PropTypes.func,
  lists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  onStart: () => dispatch(loadEventLists()),
  clearMarkedEvent: () => dispatch(clearMarkedEventLists()),
});

const mapStateToProps = createStructuredSelector({
  lists: selectEventLists,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedEventLists);

import { fromJS } from 'immutable';

import { makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';

const selectAdminConnectModalDomain = (state) => state.get('adminConnectModal') || fromJS({});

const makeSelectIsModalOpen = () => makeSubSelector(selectAdminConnectModalDomain, ['isModalOpen']);
const selectSelectedFounder = makeSubSelector(selectAdminConnectModalDomain, ['selectedFounder']);
const makeSelectSelectedFounder = () => makeSelectObjectFromRefSelector(selectSelectedFounder);
const makeSelectConnectionsSchema = () => makeSubSelector(selectAdminConnectModalDomain, ['schema']);
const makeSelectSender = () => makeSubSelector(selectAdminConnectModalDomain, ['sender']);
const makeSelectSubject = () => makeSubSelector(selectAdminConnectModalDomain, ['subject']);
const makeSelectOutro = () => makeSubSelector(selectAdminConnectModalDomain, ['outro']);

export {
  makeSelectIsModalOpen,
  makeSelectSelectedFounder,
  makeSelectConnectionsSchema,
  makeSelectSender,
  makeSelectSubject,
  makeSelectOutro,
};

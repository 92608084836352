import { EventTypes } from 'redux-segment';

import { IDENTIFY, TRACK, TRACK_EXT_LINK, TRACK_LOGIN, TRACK_PLAYED_VIDEO } from './constants';

export function identify(tokenAccount, account, profile, company) {
  const connectionStatsPart = account.connection_stats
    && {
      total_sent_messages: account.connection_stats.sent,
      total_connected_messages: account.connection_stats.connected,
      conversion_messages: account.connection_stats.ratio,
      'Connect_Me-Accepted': account.connection_stats.connect_me_accept,
      'Connect_Me-Sent': account.connection_stats.connect_me_sent,
      'Connect_Me-Conv_Rate': account.connection_stats.connect_me_conv,
      'Request_Meeting-Accepted': account.connection_stats.request_meeting_accept,
      'Request_Meeting-Sent': account.connection_stats.request_meeting_sent,
      'Request_Meeting-Conv_Rate': account.connection_stats.request_meeting_conv,
      last_message: account.connection_stats.last,
    };
  const eventPayload = {
    userId: profile.id === 1 ? '01' : profile.id,
    traits: {
      email: profile.email,
      name: profile.nicename,
      phone: profile.phone,
      created_at: profile.created_at,
      primary_type: account.variety || 'network',
      ...connectionStatsPart,
    },
    options: { Intercom: { user_hash: account.verification_hmac } },
  };
  if (company) {
    eventPayload.traits.class_number = company.aclass?.()?.number;
    if (company.aclass) {
      eventPayload.traits.class = {
        full_name: company.aclass().internalFormattedName,
        class_type: company.aclass().class_type,
        title: company.aclass().title,
      };
    }
    const parentCompany = company.parent_company && company.parent_company();
    eventPayload.traits.company = {
      id: company.id,
      name: company.name,
      custom_attributes: {
        parent_company_id: parentCompany?.id,
        parent_company_name: parentCompany?.name,
        shutdown_date: company.shutdowndate || company.acquireddate,
      },
    };
  }
  if (account.point_of_contact_for) {
    eventPayload.traits.poc = isPointOfContactOfCompany(account, company);
  }
  return {
    type: IDENTIFY,
    account: tokenAccount,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload,
      },
    },
  };
}


export function track(eventType, properties, sendToIntercom) {
  return {
    type: TRACK,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          properties,
          event: eventType,
          options: { integrations: { All: true, Intercom: !!sendToIntercom } },
        },
      },
    },
  };
}

export const trackExtLink = (href, text) => ({ type: TRACK_EXT_LINK, href, text });
export const trackLogin = (flow, oauthProvider) => ({ type: TRACK_LOGIN, flow, oauthProvider });
export const trackPlayedVideo = (resource) => ({ type: TRACK_PLAYED_VIDEO, resource });

function isPointOfContactOfCompany(account, company) {
  return !!account.point_of_contact_for().find((c) => c.id === company.id);
}

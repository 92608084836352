import { fromJS } from 'immutable';

import { LOGIN_ERROR } from './constants';

const initialLoadedContentState = fromJS({});


function oAuthLandingReducer(state = initialLoadedContentState, action) {
  switch (action.type) {
    case LOGIN_ERROR:
      return state.set('loginErrorCode', action.errorCode);
    default:
      return state;
  }
}

export default oAuthLandingReducer;

import { createSelector } from 'reselect';

import { makeSubSelector } from 'utils/selectors';

import { LEGAL_DOCS } from './constants';

/**
 * Direct selector to the gdprFooter state domain
 */
const selectGdprFooterDomain = (state) => state.get('gdprDocsModal');

/**
 * Specific selectors
 */
const selectDocType = makeSubSelector(selectGdprFooterDomain, ['docType']);
const selectDoc = createSelector(selectDocType, (docType) => docType && LEGAL_DOCS[docType]);

export { selectDoc };

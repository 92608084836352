
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ConsumeSectionContextHOC } from 'components/Profile/SectionContext';
import { isChildrenEmpty } from 'utils/react';

const ProfileSection = ({
  children, className, verticalDivider, section = '', isEditable, sec,
}) => !isChildrenEmpty(children)
  ? (
    <div
      className={classNames(`dsa-profile__section ${section}`, className, [{ _editable_: isEditable, _sec_: sec }])}
      data-testid={section && `section__${section}`}
    >
      {children}
      {verticalDivider && <div className="dsa-vertical-divider" />}
    </div>)
  : null;

ProfileSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  section: PropTypes.string,
  verticalDivider: PropTypes.bool,
  isEditable: PropTypes.bool,
  sec: PropTypes.bool,
};

export default ConsumeSectionContextHOC(ProfileSection);

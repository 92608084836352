export const SET_PREVIEWED_SHARED_LIST = 'alchemist/Forms/SharedCompanies/SET_PREVIEWED_SHARED_LIST';
export const TOGGLE_SHARED_LIST_PREVIEW = 'alchemist/Forms/SharedCompanies/TOGGLE_SHARED_LIST_PREVIEW';
export const ADD_COMPANY_SHARED_LIST = 'alchemist/Forms/SharedCompanies/ADD_COMPANY_SHARED_LIST';

export const SHARED_COMPANIES_LIST_FORM_NAME = 'sharedCompaniesList';

export const MESSAGE_TYPE_NICE_MAPPER = { one2one: '1-on-1s (1:1)', CN: 'Connects (CN)' };
export const STATUS_NICE_MAPPER = {
  draft: 'Draft',
  list_sent: 'Sent',
  submitting: 'Submitting Picks',
  pre_invite: 'Founder Invite',
  finished: 'Finished',
  [null]: 'Any',
};

export const SHARED_LIST_SUBJECT = {
  one2one: 'Alchemist Companies 1:1 Meeting - {{Day, Mon #th}} from {{#:## AM/PM}} - {{#:## AM/PM}}',
  CN: 'Follow-up - Alchemist / {{Company/Group/Person Name}}',
};

export const SHARED_LIST_MESSAGE_TEMPLATE_EDITABLE = {
  one2one: `
    <p>Thank you for filling it out [[firstname]]!</p>
    <br>
    <p>Based on your input, our algorithm thought these companies might be good fits. Let us know if you'd like to meet with any of these and we'll invite them in for your 1:1s in <strong><em>{{Month}}</em></strong>.</p>
    <br>
    <p>RELEVANT ALCHEMIST COMPANIES:</p>
    <br>`,
  CN: `
    <p>[[firstname]]&nbsp;-</p>
    <br>
    <p>It was great speaking with you today. I hope you found our conversation productive.</p>
    <p>If you haven't yet, please fill out this form to ensure you get individually looped into Alchemist and relevant events and connections.</p>
    <br>
    <p>INTERESTS</p>
    <br>
    <p>I wanted to capture the areas where there was interest for further follow up.</p>
    <p>For the sake of flexibility, I will be following up with a separate email on each of these areas of interest.</p>
    <p>We do this to delineate each separate area of interest, and in case you need to loop in different members of your team for different interests. Please know there may be some redundancy across the different emails.</p>
    <p>Some of the areas of interest we noted were <strong><em>{{Acct Exec to remove any areas of interest that are not relevant and also this {{}}'d insert}}</em></strong>:</p>
    <ul>
      <li>An interest in finding current accelerator companies relevant to your specific domains of interest</li>
      <li>An interest to plug in individually as a mentor to Alchemist companies</li>
      <li>An interest to plug in individually as an angel investor in Alchemist companies</li>
      <li>An interest to work for one of our Alchemist portfolio companies -- or other companies in the ecosystem - <a href="https://influencerseries.wufoo.com/forms/talent-spec/">Please fill this form.</a></li>
      <li>An interest to work for the Alchemist Accelerator in an unpaid fellowship capacity - <a href="https://influencerseries.wufoo.com/forms/alchemist-fellows-application/">Please fill this form.</a></li>
      <li>An interest to explore joining the Technology Innovation Council of corporate executives that engage Alchemist for advisory services to source innovation</li>
      <li>An interest to explore further AlchemistX, our initiative for accelerating internal innovation efforts for spin-in's or spin-out's</li>
      <li>An interest to explore being a backer of Alchemist -- and specifically, these tracks within the Accelerator: <strong><em>{{Acct Exec to replace with relevant track - IoT, Fintech, Security, etc.}}</em></strong></li>
      <li>An interest to explore further being a Limited Partner / Investor in our Seed / Early Stage Fund</li>
      <li>An interest in exposing your product / services to our Accelerator companies</li>
      <li>An interest in facilitating exposure to acquiring / partnering with relevant Alchemist portfolio companies</li>
      <li>An interest in partnering with Alchemist</li>
    </ul>
    <p>Please let me know if I missed an area of interest.</p>
    <p>If the above points capture well the lists of interest, please hold tight. You will be receiving separate emails from me on each of the items above. Please email back if you have not heard from me on any of the points above 48 hours from now.</p>
    <p>Thank you for chatting today, it was a pleasure!</p>
    <br>
    <p>RELEVANT ALCHEMIST COMPANIES:</p>
    <br>`,
};

export const SHARED_LIST_FOUNDER_MESSAGE_TEMPLATE_EDITABLE = `
<p>We've reserved a time slot on</p>
<br>
<p><strong><em>{{Rep Name}} of {{VC Firm}}</em></strong>'s calendar on <strong><em>{{Meeting Date}}</em></strong></p>
<p>from <strong><em>{{Start Time}}-{{End Time}}</em></strong> at their offices in <strong><em>{{Meeting City}}</em></strong>for 25 min 1:1 meets with their partners.</p>
<br>
<p>If you'd like to meet, please sign up <strong><em>here {{update with correct spreadsheet for this session}}</em></strong>.</p>
`;

/*
 * PeopleLists List Actions
 */

import { generateFiltersActions, generateFiltersUiActions, generateLoadedContentActions } from 'utils/filter/actions';
import { resRef } from 'utils/refs';
import { LOAD_LISTS_FOR_MODAL } from 'containers/AuthProvider/constants';

import {
  DELETE_PEOPLE_LISTS,
  LOAD_ALL_LISTS,
  SHARED_WITH_ME_LISTS,
  OWN_LISTS,
  PUBLIC_LISTS,
  REMOVE_ACCESS_FROM_SHARED_WITH_ME_LISTS,
  COPY_SHARED_WITH_ME_LISTS,
  COPY_PUBLIC_LISTS,
  CLOSE_PEOPLE_LIST_CREATE_MODAL,
  OPEN_PEOPLE_LIST_CREATE_MODAL,
  CLOSE_PEOPLE_LIST_MERGE_MODAL,
  OPEN_PEOPLE_LIST_MERGE_MODAL,
  SET_MERGE_TARGET,
  MAKE_MERGE,
  MERGE_DONE,
  COPY_DONE,
  EVENT_LISTS,
  EXPAND_LIST_CONTAINER,
  ARCHIVE_LISTS,
  RESTORE_LISTS,
  COPY_EVENT_LISTS,
} from './constants';

const deletePeopleLists = (listType = '') => ({ type: DELETE_PEOPLE_LISTS, listType });
const removeAccessFromSharedWithMeLists = () => ({ type: REMOVE_ACCESS_FROM_SHARED_WITH_ME_LISTS });
const copySharedWithMeLists = () => ({ type: COPY_SHARED_WITH_ME_LISTS });
const copyPublicLists = () => ({ type: COPY_PUBLIC_LISTS });
const copyEventLists = () => ({ type: COPY_EVENT_LISTS });
const expandListContainer = (containerId) => ({ type: EXPAND_LIST_CONTAINER, containerId });

const {
  markObject: markOwnList,
  clearMarks: clearMarkedOwnLists,
  loadContent: loadOwnLists,
  contentLoaded: ownListsLoaded,
  contentLoadingError: ownListsLoadingError,
  initialLoad: initialLoadAllLists,
  // setHasMore,
  setTotalCount: setOwnListsTotal,
  loadNextPage: loadNextPageOwnLists,
} = generateLoadedContentActions(OWN_LISTS);

const {
  markObject: markSharedWithMeList,
  clearMarks: clearMarkedSharedWithMeLists,
  loadContent: loadSharedWithMeLists,
  contentLoaded: sharedWithMeListsLoaded,
  contentLoadingError: sharedWithMeListsLoadingError,
  // initialLoad,
  // setHasMore,
  setTotalCount: setSharedWithMeListsTotal,
  loadNextPage: loadNextPageSharedLists,
} = generateLoadedContentActions(SHARED_WITH_ME_LISTS);

const {
  markObject: markPublicList,
  clearMarks: clearMarkedPublicLists,
  loadContent: loadPublicLists,
  contentLoaded: publicListsLoaded,
  contentLoadingError: publicListsLoadingError,
  // initialLoad,
  // setHasMore,
  setTotalCount: setPublicListsTotal,
  loadNextPage: loadNextPagePublicLists,
} = generateLoadedContentActions(PUBLIC_LISTS);

const {
  markObject: markEventList,
  clearMarks: clearMarkedEventLists,
  loadContent: loadEventLists,
  contentLoaded: eventListsLoaded,
  contentLoadingError: eventListsLoadingError,
  // initialLoad,
  // setHasMore,
  setTotalCount: setEventListsTotal,
  loadNextPage: loadNextPageEventLists,
} = generateLoadedContentActions(EVENT_LISTS);

const {
  setSearch,
  // setCategory,
  setFiltersAndSort,
  setMaxPage,
} = generateFiltersActions('PeopleLists');

const { setIsSearchFocused } = generateFiltersUiActions('PeopleLists');

const loadAllLists = () => ({ type: LOAD_ALL_LISTS });

const openPeopleListCreateModal = (isEventList = false) => ({ type: OPEN_PEOPLE_LIST_CREATE_MODAL, isEventList });
const closePeopleListCreateModal = () => ({ type: CLOSE_PEOPLE_LIST_CREATE_MODAL });
const openPeopleListMergeModal = () => ({ type: OPEN_PEOPLE_LIST_MERGE_MODAL });
const closePeopleListMergeModal = () => ({ type: CLOSE_PEOPLE_LIST_MERGE_MODAL });
const setMergeTarget = (list) => ({ type: SET_MERGE_TARGET, listRef: list && resRef(list) });
const makeMerge = () => ({ type: MAKE_MERGE });
const mergeDone = () => ({ type: MERGE_DONE });
const copyDone = () => ({ type: COPY_DONE });
const loadListsForModalAction = () => ({ type: LOAD_LISTS_FOR_MODAL });
const archivePeopleLists = () => ({ type: ARCHIVE_LISTS });
const restorePeopleLists = (listType) => ({ type: RESTORE_LISTS, listType });

export {
  deletePeopleLists,
  markOwnList,
  clearMarkedOwnLists,
  loadOwnLists,
  ownListsLoaded,
  ownListsLoadingError,
  setOwnListsTotal,
  markSharedWithMeList,
  clearMarkedSharedWithMeLists,
  loadSharedWithMeLists,
  sharedWithMeListsLoaded,
  sharedWithMeListsLoadingError,
  setSharedWithMeListsTotal,
  markEventList,
  clearMarkedEventLists,
  loadEventLists,
  eventListsLoaded,
  eventListsLoadingError,
  setEventListsTotal,
  markPublicList,
  clearMarkedPublicLists,
  loadPublicLists,
  publicListsLoaded,
  publicListsLoadingError,
  setPublicListsTotal,
  setSearch,
  setFiltersAndSort,
  loadAllLists,
  removeAccessFromSharedWithMeLists,
  copySharedWithMeLists,
  copyPublicLists,
  setIsSearchFocused,
  initialLoadAllLists,
  openPeopleListCreateModal,
  closePeopleListCreateModal,
  openPeopleListMergeModal,
  closePeopleListMergeModal,
  setMergeTarget,
  makeMerge,
  mergeDone,
  copyDone,
  expandListContainer,
  loadListsForModalAction,
  archivePeopleLists,
  restorePeopleLists,
  copyEventLists,
  loadNextPageOwnLists,
  loadNextPageSharedLists,
  loadNextPagePublicLists,
  loadNextPageEventLists,
  setMaxPage,
};

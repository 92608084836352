import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const SearchHolder = ({ children, className = '' }) => (
  <div className={classNames('dsa-search__holder', className)}>
    {children}
  </div>
);

SearchHolder.propTypes = { children: PropTypes.any, className: PropTypes.string };

export default SearchHolder;

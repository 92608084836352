/*
 * PeopleListManageModal
 *
 * This is the first thing users see of our App, at the '/people' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import DsaModal from 'components/Modals/DsaModal';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import EditPeopleList from 'containers/Forms/PeopleList/EditPeopleList';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './saga';
import { selectModalSection, selectPeopleList } from './selectors';
import { closeSharePeopleListModal, setSection as setSectionAction } from './actions';
import ShareSection from './ShareSection';
import { EDIT_SECTION, SHARE_SECTION } from './constants';


class PeopleListManageModal extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    this.inputRef = React.createRef();
  }

  render() {
    const {
      closeModal, section, peopleList, setSection, isDesktop,
    } = this.props;

    return (
      <DsaModal isOpen={!!section} toggle={closeModal} backdrop="static" keyboard={false}>
        <ModalHeader tag="h1">{peopleList.title}</ModalHeader>
        <CloseButtonWrapper closeAction={closeModal} />
        {isDesktop &&
          <ModalMenu>
            <Nav tabs className="dsa-nav-main">
              <NavItem>
                <NavLink active={section === EDIT_SECTION} onClick={() => setSection(EDIT_SECTION)}>Name</NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={section === SHARE_SECTION} onClick={() => setSection(SHARE_SECTION)}>Access Settings</NavLink>
              </NavItem>
            </Nav>
          </ModalMenu>}
        {section === SHARE_SECTION && <ShareSection />}
        {section === EDIT_SECTION && <EditPeopleList peopleList={peopleList} />}
      </DsaModal>
    );
  }
}

PeopleListManageModal.propTypes = {
  section: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  closeModal: PropTypes.func,
  setSection: PropTypes.func,
  isDesktop: PropTypes.bool,
  peopleList: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

// ToDo: remove unnecessary 'export' in 'export function mapDispatchToProps' in the rest of the codebase
function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(closeSharePeopleListModal(false)),
    setSection: (section) => dispatch(setSectionAction(section)),
  };
}

const mapStateToProps = createStructuredSelector({
  peopleList: selectPeopleList,
  section: selectModalSection,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'sharePeopleListModal', reducer });
const withSaga = injectSaga({ key: 'sharePeopleListModal', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  ResponsiveHoC,
)(PeopleListManageModal);

export const ModalMenu = styled.div`
  padding: .5625rem 1.75rem 0;
  
  && .nav-tabs .nav-item {
    min-width: initial;
    margin-right: 1.3125rem;
  }
  
  .nav-link {
    padding: .4375rem 1.3125rem;  
  }
`;

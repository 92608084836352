import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { faTimer } from '@fortawesome/pro-solid-svg-icons';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import { faMessageQuestion, faThumbsUp, faUserTie } from '@fortawesome/pro-regular-svg-icons';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { selectLatestIfsInvitation } from 'containers/AuthProvider/selectors';
import H1 from 'components/Text/Headings/H1';
import H2 from 'components/Text/Headings/H2';
import LoadingSpinner from 'components/LoadingSpinner';
import { ButtonLinkSec } from 'components/Buttons/ButtonLinkSec';

import { Card, Content, Header, StyledOnePagerContent, CardWithBg, FormatText } from './styles';
import { LS_KEY_FEEDBACK_SESSIONS_1_DURATION, LS_KEY_INSTRUCTIONS_TEXT, LS_KEY_INSTRUCTIONS_URL, LS_KEY_LATEST_IFS_DATE } from '../constants';
import InstructionsVideoModal from './InstructionsVideoModal';


const RECOMMENDED_MEET_PARTS_NUMBER = 4;

const IfsWelcomePage = ({ latestIfsInvitation }) => {
  const { feedbackSessionDuration } = useParams();
  const [instructionsVideoIsOpen, setInstructionsVideoIsOpen] = useState(false);
  const latestIfs = latestIfsInvitation?.participant_set?.()?.ifs?.();
  const feedbackSesions1Duration = feedbackSessionDuration || latestIfs?.feedback_session_1_duration || localStorage.getItem(LS_KEY_FEEDBACK_SESSIONS_1_DURATION) || 0;
  const partDuration = Math.floor(feedbackSesions1Duration / RECOMMENDED_MEET_PARTS_NUMBER);
  const timeRemainder = feedbackSesions1Duration % RECOMMENDED_MEET_PARTS_NUMBER;
  const ifsDate = localStorage.getItem(LS_KEY_LATEST_IFS_DATE);
  const ifsDescription = localStorage.getItem(LS_KEY_INSTRUCTIONS_TEXT);
  const ifsUrl = localStorage.getItem(LS_KEY_INSTRUCTIONS_URL);

  const displayDuration = (feedbackSesions1Duration || feedbackSesions1Duration === 0)
    ? `(${partDuration} mins)`
    : <LoadingSpinner size="2x" />;

  return (
    <StyledOnePagerContent className="px-0" hasFooter={false}>
      <Header>
        <div className="center-content-y-x mb-7">
          <img src="/alchemist-logo.png" alt="Alchemist Accelerator Logo" />
        </div>

        <H1 className="mb-5" mobileSize="medHeading">
          Investor Feedback Summit (IFS) Event Guide
        </H1>
        <p>
          {ifsDate
            ? `Instructions for the IFS on ${ifsDate}`
            : 'Thank you for coming to the Alchemist Investor Feedback Summit!'}
        </p>
      </Header>
      <Content className={classNames('d-flex align-items-start')}>
        <Card className="mr-7">
          {(ifsDescription || ifsUrl) && <H2 className="mb-4" mobileSize="medHeading">IFS Instructions</H2>}
          {ifsDescription && <FormatText className="mb-7">{ifsDescription}</FormatText>}
          {ifsUrl &&
            <ButtonLinkSec className="invert-underline m-0 dsa-font-md" onClick={() => setInstructionsVideoIsOpen(true)}>
              <FontAwesomeIcon icon={faPlayCircle} className="mr-3" color="#03273B" />
              Watch IFS Instructions Video
            </ButtonLinkSec>}
          <CardWithBg className={classNames({ 'mt-0': !ifsDescription && !ifsUrl })}>
            <p className="mb-6">
              You will have meetings today with founders who have been working hard on product development. Please know they are not in fundraising mode.
            </p>
            <p className="mb-6">The primary intention is to give feedback - please be profuse with your feedback!</p>
            <p>At the end of each session, <span className="dsa-font-semibold">please leave feedback to founders and Alchemist.</span></p>
          </CardWithBg>
        </Card>

        <Card>
          <H2 className="mb-4" mobileSize="medHeading">Format</H2>
          <FormatText className="mb-7">To maximize interaction and feedback, we recommend this format:</FormatText>
          <ul className="pl-0 text-left">
            <li className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="dsa-list-avatar d-flex justify-content-center align-items-center me-5">
                  <FontAwesomeIcon icon={faUsers} size="lg" />
                </div>
                <div>Founders will pitch uninterrupted.</div>
              </div>
              <div className="dsa-ifs-time">{displayDuration}</div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="dsa-list-avatar d-flex justify-content-center align-items-center me-5">
                  <FontAwesomeIcon icon={faUserTie} size="lg" />
                </div>
                <div>You ask the founder questions about the business.</div>
              </div>
              <div className="dsa-ifs-time">{displayDuration}</div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="dsa-list-avatar d-flex justify-content-center align-items-center me-5">
                  <FontAwesomeIcon icon={faMessageQuestion} size="lg" />
                </div>
                <div>Founders will ask you questions.</div>
              </div>
              <div className="dsa-ifs-time">{displayDuration}</div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="dsa-list-avatar d-flex justify-content-center align-items-center me-5">
                  <FontAwesomeIcon icon={faThumbsUp} size="lg" />
                </div>
                <div>Provide written feedback to the startup while waiting for the next company.</div>
              </div>
              <div className="dsa-ifs-time">{displayDuration}</div>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="dsa-list-avatar d-flex justify-content-center align-items-center me-5">
                  <FontAwesomeIcon icon={faTimer} size="lg" />
                </div>
                <div>Time to switch to next meeting</div>
              </div>
              <div className="dsa-ifs-time">
                {(feedbackSesions1Duration || feedbackSesions1Duration === 0)
                  ? `(${timeRemainder} mins)`
                  : <LoadingSpinner size="2x" />}
              </div>
            </li>
          </ul>
        </Card>
      </Content>
      <InstructionsVideoModal
        isOpen={instructionsVideoIsOpen}
        closeModal={() => setInstructionsVideoIsOpen(false)}
        videoUrl={ifsUrl}
      />
    </StyledOnePagerContent>
  );
};

IfsWelcomePage.propTypes = {
  latestIfsInvitation: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  latestIfsInvitation: selectLatestIfsInvitation,
});

export default connect(mapStateToProps)(IfsWelcomePage);

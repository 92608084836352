import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';


const MobileTopBar = ({ onClick, isSidebarOpened, logoUrl }) => (
  <div className="dsa-site-mobile-topbar">
    <NavLink className="dsa-brand" to={logoUrl} tag={NavLinkReact}>
      <img src="/alchemist-logomark.png" className="d-inline-block align-top" alt="Alchemist Vault Logo" />
    </NavLink>
    <button onClick={onClick} className="close dsa-action-icon" type="button" aria-label="Close">
      {isSidebarOpened
        ? <FontAwesomeIcon icon={faTimes} />
        : <FontAwesomeIcon icon={faBars} />}
    </button>
  </div>
);

MobileTopBar.propTypes = {
  onClick: PropTypes.func,
  isSidebarOpened: PropTypes.bool,
  logoUrl: PropTypes.string,
};

export default MobileTopBar;

/**
 * Gets the people
 */
import { delay, call, put, select, takeLatest, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { stringify } from 'qs';

import { updateIncluded, updateIncludedFromRequest } from 'actions';
import { showAlert } from 'containers/AlertBox/actions';
import { API_COMPANIES_BASE_URL, API_PEOPLE_BASE_URL, RESOURCE_COMPANIES } from 'containers/App/constants';
import { makeSelectLocation, makeSelectObject } from 'containers/App/selectors';
import { SAMPLE_PEOPLE_LIST_PERMISSION } from 'containers/Forms/constants';
import { logError } from 'utils/log';
import { formDataToJApi } from 'utils/formToJsonApi';
import { requestFunctionForObject } from 'utils/Forms/general';
import { generateFiltersString } from 'utils/filter/filter';
import { request } from 'utils/request';
import { resRef, refApiUrl } from 'utils/refs';
import { extractData } from 'utils/jsonApiExtract';
import { updateObjFromApi } from 'utils/sagas';
// import { pluralize } from 'utils/general';

import {
  makeSelectFilter,
  makeSelectLoading,
  makeSelectLoadingNextPage,
  makeSelectMaxPage,
  selectPeopleList,
  selectSharesRaw,
} from './selectors';
import {
  SHARE_PEOPLE_LIST_MODAL_INITIAL_LOAD,
  SHARE_PEOPLE_LIST_MODAL_LOAD_CONTENT,
  SHARE_PEOPLE_LIST_MODAL_SET_SEARCH,
  SHARE_PEOPLE_LIST_MODAL_LOAD_NEXT_PAGE,
  ADD_COMPANY_SHARE, SEND_SHARES, OPEN_FOR_LIST,
} from './constants';
import {
  setSearch, contentLoaded, contentLoadingError, loadContent, setMaxPage, loadNextPageSuccess, setHasMore, addShares,
  setTotalCount, closeSharePeopleListModal, setSharesProcessing, setSharesProcessed, setPeopleCompanyLoading,
} from './actions';
import { preprocessAppliedFiltersForCompanies } from './Filters/filters';
import { makeShare } from './reducer';
import { clearMarkedOwnLists } from '../PeopleLists/actions';
import { preprocessAppliedFilters as preprocessAppliedFiltersForPeople } from '../Search/saga';


/**
 * People request/response handler
 */
function* getPeople(isFetchNextPage) {
  // If a new selection is being loaded and next page is requested, wait for the new selection to finish loading
  // and vice-versa
  let isLoading = isFetchNextPage ? yield select(makeSelectLoading()) : yield select(makeSelectLoadingNextPage());

  while (isLoading) {
    yield delay(100);
    isLoading = isFetchNextPage ? yield select(makeSelectLoading()) : yield select(makeSelectLoadingNextPage());
  }

  // Select filters from store
  const filterImmutable = yield select(makeSelectFilter());

  let companyRefs = [];
  if (!isFetchNextPage) {
    companyRefs = yield fetchCompanies(filterImmutable);
  }

  const nextPage = isFetchNextPage ? (yield select(makeSelectMaxPage())) + 1 : 0;
  const filtersImm = yield preprocessAppliedFiltersForPeople(filterImmutable.get('appliedFilters'));

  const filterString = generateFiltersString(
    filtersImm,
    null,
    nextPage,
    '&'
  );

  const requestURL = `${API_PEOPLE_BASE_URL}/with_user_lists_cap?include=company&fields[companies]=id,name,logo&fields[people]=id,nicename,current_position,profileimg${filterString}&_lazy=1`;

  try {
    // Call our request helper (see 'utils/request')
    const peopleRequest = yield call(request, requestURL);
    const { inclusions, items: people } = extractData(peopleRequest);

    yield put(setHasMore(!!peopleRequest.links.next));
    yield put(setMaxPage(nextPage));

    yield put(updateIncluded(inclusions));
    yield isFetchNextPage ? put(loadNextPageSuccess(people)) : put(contentLoaded([...companyRefs, ...people]));
    if (!isFetchNextPage) {
      yield put(setTotalCount(get(peopleRequest, 'meta.results.available') || 0));
    }
  } catch (err) {
    logError(err);
    yield put(contentLoadingError(err));
  }
}

function* fetchCompanies(filterImmutable) {
  const filterString = generateFiltersString(
    preprocessAppliedFiltersForCompanies(filterImmutable.get('appliedFilters')).set('is_alchemist:eq', true),
    null,
    null,
    '&'
  );
  const requestURL = `${API_COMPANIES_BASE_URL}?include=current_members_rel.user.account&page[current_members_rel:size]=0&page[size]=3${filterString}`;

  try {
    // Call our request helper (see 'utils/request')
    const companiesRequest = yield call(request, requestURL);
    const { inclusions, items: companyRefs } = extractData(companiesRequest);

    yield put(updateIncluded(inclusions));

    return companyRefs;
  } catch (err) {
    logError(err);
    yield put(contentLoadingError(err));
  }
  return [];
}

/**
 * Only trigger the search when the user stops to breath
 */
function* debounceSearch() {
  yield delay(300);
  yield put(loadContent());
}

/**
 * Load the filters from the url search parameters
 */
function* initialLoadSaga() {
  yield put(setSearch(''));
}

function* addCompanyShareSaga({ company }) {
  yield put(setPeopleCompanyLoading(true));
  const updatedCompany = yield select(makeSelectObject(company));
  const usersWithAccount = updatedCompany.teamMembers && updatedCompany.teamMembers().filter((user) => user.account);

  if (usersWithAccount && usersWithAccount.length) {
    yield put(addShares(usersWithAccount.map(resRef).map(makeShare)));
    const qParamsStr = stringify({
      include: 'company',
      fields: {
        [RESOURCE_COMPANIES]: 'name',
      },
      filter: { 'id:in': `[${usersWithAccount.map((user) => user.id).join(',')}]` },
    });
    const peopleReq = yield call(request, `${API_PEOPLE_BASE_URL}?${qParamsStr}`);
    yield put(updateIncludedFromRequest(peopleReq));
  }
  yield put(setPeopleCompanyLoading(false));
}

function* shareListSaga() {
  const sharesRaw = yield select(selectSharesRaw);
  const peopleList = yield select(selectPeopleList);
  const deletedShares = peopleList && peopleList.sharing_permissions
    ? peopleList.sharing_permissions()
      .filter((share) => !sharesRaw.find((shareRaw) => shareRaw.id === share.id))
      .map((s) => ({ ...s, isDeleted: true }))
    : [];
  const shares = [...sharesRaw, ...deletedShares];

  try {
    yield put(setSharesProcessing());
    yield all(shares.map((share) => call(
      requestFunctionForObject(share),
      refApiUrl(share),
      {
        data: formDataToJApi(
          { ...SAMPLE_PEOPLE_LIST_PERMISSION, id: share.id },
          { read: true, user_list: resRef(peopleList), ...share }
        ),
      }
    )));

    yield updateObjFromApi(peopleList, 'sharing_permissions.user');

    // yield put(setPeopleListsModal(false));
    // yield put(showAlert(`Success! Access settings of ${shares.length} ${pluralize(shares.length, 'user')} were changed for the list "${peopleList.title}".`));
    yield put(showAlert(`Success! Access settings for the list "${peopleList.title}" were changed.`));
    yield put(closeSharePeopleListModal());

    const location = yield select(makeSelectLocation());
    if (location.pathname === '/people/lists') {
      yield put(clearMarkedOwnLists());
    }
    // yield put(clearMarks());
  } catch (err) {
    logError(err);
  } finally {
    yield put(setSharesProcessed());
  }
}

function* prepareModal({ peopleListRef }) {
  yield put(setPeopleCompanyLoading(true));
  yield updateObjFromApi(peopleListRef, 'sharing_permissions.user');
  const peopleList = yield select(selectPeopleList);
  if (peopleList.sharing_permissions) {
    yield put(addShares(peopleList.sharing_permissions()
      .map(({
        user, update, id, type,
      }) => ({
        user: resRef(user()), update, id, type,
      }))));
  }
  yield put(setPeopleCompanyLoading(false));
}
// export function* checkSelectedObject() {
//   const selectedObject = yield select(makeSelectSelectedPerson());
//   if (!selectedObject) return;
//
//   const objects = yield select(makeSelectPeople());
//   yield put(setSelectedObject(objects.find((obj) => selectedObject.toJS().id === obj.id) || false));
// }

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_CONTENT actions and calls getPeople when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SHARE_PEOPLE_LIST_MODAL_INITIAL_LOAD, initialLoadSaga);
  yield takeLatest(SHARE_PEOPLE_LIST_MODAL_LOAD_CONTENT, getPeople, false);
  yield takeLatest(SHARE_PEOPLE_LIST_MODAL_LOAD_NEXT_PAGE, getPeople, true);
  yield takeLatest(ADD_COMPANY_SHARE, addCompanyShareSaga);
  yield takeLatest(SEND_SHARES, shareListSaga);
  yield takeLatest(OPEN_FOR_LIST, prepareModal);

  // yield takeLatest(SHARE_PEOPLE_LIST_MODAL_LOAD_CONTENT_SUCCESS, checkSelectedObject);
  yield takeLatest(SHARE_PEOPLE_LIST_MODAL_SET_SEARCH, debounceSearch);
}

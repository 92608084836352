/*
 * TeamNotes actions
 */
import { SAVE_FORM_SECTION } from 'containers/Forms/constants';
import { makeFormActionFunction } from 'utils/Forms/actions';

import { DELETE_NOTE, INITIAL_LOAD, SET_FORM_FOR_PROFILE } from './constants';

export const initialLoad = () => ({ type: INITIAL_LOAD });
export const setFormForProfile = (profileId, isOpen) => ({ type: SET_FORM_FOR_PROFILE, profileId, isOpen });
export const deleteNote = (teamNoteRef) => ({ type: DELETE_NOTE, teamNoteRef });

export const saveTeamNotesFor = (profile) =>
  (values, ...args) => ({ ...makeFormActionFunction(SAVE_FORM_SECTION)('', ...args), values: { ...values, profile } });

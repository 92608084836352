/*
 *
 * AlertBox constants
 *
 */

export const CLOSE_CONFIRMATION = 'app/ConfirmModal/CLOSE_CONFIRMATION';
export const PRE_CLOSE_CONFIRMATION = 'app/ConfirmModal/PRE_CLOSE_CONFIRMATION';
export const ASK_CONFIRMATION = 'app/ConfirmModal/ASK_CONFIRMATION';
export const CONFIRM_ACTION = 'app/ConfirmModal/CONFIRM_ACTION';
export const SET_FIELD_CONTENT = 'app/ConfirmModal/SET_FIELD_CONTENT';
export const SET_VALIDATION = 'app/ConfirmModal/SET_VALIDATION';

export const NUMBER_FIELD = 'number';
export const RICH_TEXT_FIELD = 'richText';
export const DD_SHARE_FIELD = 'dd_share';
export const DD_MEET_FIELDS = 'dd_meet';
export const FOUNDER_FEEDBACK_FIELDS = 'founder_feedback';

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import * as Sentry from '@sentry/browser';
// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { parse } from 'qs';

import 'sanitize.css/sanitize.css';
// Import root app
import App from 'containers/App';
import { ResponsiveProvider } from 'components/Responsive/react-responsive-context';
import AuthProvider from 'containers/AuthProvider';
import errorToWarnAdapt from 'utils/errorToWarnAdapt';

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-logo.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-logo-white.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-logo-blue-white.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-logomark.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemistx-logo.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemistx-logo-big.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-x-logomark.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-logo-vertical.png';
import '!file-loader?name=[name].[ext]!./images/brand/alchemist-horizontal.svg';
import '!file-loader?name=[name].[ext]!./images/brand/aa-ax-logomark.svg';
import '!file-loader?name=[name].[ext]!./images/corporate_innovator/gold_level.svg';
import '!file-loader?name=[name].[ext]!./images/corporate_innovator/bronze_level.svg';
import '!file-loader?name=[name].[ext]!./images/corporate_innovator/silver_level.svg';
import '!file-loader?name=[name].[ext]!./images/background/one_pager_bg.png';
import '!file-loader?name=[name].[ext]!./images/anonymous.png';
import '!file-loader?name=[name].[ext]!./images/default_user.jpg';
import '!file-loader?name=[name].[ext]!./images/default_company.png';
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/favicon-staging-1.ico';
import '!file-loader?name=[name].[ext]!./images/favicon-staging-2.ico';
import '!file-loader?name=[name].[ext]!./images/icon-72x72.png';
import '!file-loader?name=[name].[ext]!./images/icon-96x96.png';
import '!file-loader?name=[name].[ext]!./images/icon-128x128.png';
import '!file-loader?name=[name].[ext]!./images/icon-144x144.png';
import '!file-loader?name=[name].[ext]!./images/icon-152x152.png';
import '!file-loader?name=[name].[ext]!./images/icon-192x192.png';
import '!file-loader?name=[name].[ext]!./images/icon-384x384.png';
import '!file-loader?name=[name].[ext]!./images/icon-512x512.png';
import '!file-loader?name=[name].[ext]!./manifest.webmanifest';
import '!file-loader?name=[name].[ext]!./robots.txt';
import '!file-loader?name=[name].[ext]!./sitemap.xml';
import '!file-loader?name=[name].[ext]!./images/startup-status-icon.svg';
/* eslint-enable import/no-unresolved, import/extensions */

import { store, history } from './configureStore';
// Import CSS reset and Global Styles
import './global-styles';
import { setTokenInLocalStorage } from './utils/auth';
import { browserCompatibilityAlert } from './utils/browserDetection';

import './intercomSettings';

if (window.sentryDSN) {
  Sentry.init({ dsn: window.sentryDSN, release: window.COMMITHASH });
}

// Pick the token
const tokenFromUrl = parse(window.location.search, { ignoreQueryPrefix: true }).secret;
if (tokenFromUrl) {
  setTokenInLocalStorage(tokenFromUrl);
}

const MOUNT_NODE = document.getElementById('alchemist-app');

errorToWarnAdapt();

browserCompatibilityAlert();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ResponsiveProvider>
        <ConnectedRouter history={history}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ConnectedRouter>
      </ResponsiveProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

/*
 * Admin constants
 */

export const LOAD_SITE_SETTINGS = 'app/Admin/LOAD_SITE_SETTINGS';
export const LOAD_SITE_SETTINGS_SUCCESS = 'app/Admin/LOAD_SITE_SETTINGS_SUCCESS';
export const LOAD_SITE_SETTINGS_ERROR = 'app/Admin/LOAD_SITE_SETTINGS_ERROR';

export const MAX_LENGTH_SUBJECT = 100;
export const MAX_LENGTH_PREVIEW_TEXT = 80;

import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import DsaModal from 'components/Modals/DsaModal';
import EditPeopleList from 'containers/Forms/PeopleList/EditPeopleList';

import { makeSelectCreateModalIsEventList, makeSelectCreateModalIsOpen } from './selectors';
import { closePeopleListCreateModal } from './actions';


const PeopleListCreateModal = ({ closeModal, isOpen, isEventList }) => (
  <DsaModal isOpen={isOpen} toggle={closeModal}>
    <ModalHeader tag="h1">New { isEventList && 'Event ' }List</ModalHeader>
    <CloseButtonWrapper closeAction={closeModal} />
    <EditPeopleList isEventList={isEventList} />
  </DsaModal>
);

PeopleListCreateModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  isEventList: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: (field, value) => dispatch(closePeopleListCreateModal(field, value)),
});

const mapStateToProps = createStructuredSelector({
  isOpen: makeSelectCreateModalIsOpen(),
  isEventList: makeSelectCreateModalIsEventList(),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleListCreateModal);

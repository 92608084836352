import PropTypes from 'prop-types';
import { parse } from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { compose } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import classNames from 'classnames';

import OnePager from 'components/OnePager/OnePager';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';

import { LOAD_CONNECTION, SEND_FEEDBACK } from './constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectConnection, selectErrorConnStatus, selectFeedbackSentErr, selectIsConnAlreadyAccepted } from './selectors';


class ConnectionDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = { feedback: '' };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const connectionCode = queryParams.code;
    const connectionId = queryParams.id;
    this.connectionId = connectionId;
    this.connectionCode = connectionCode;
  }

  render() {
    const submit = () => this.props.saveFeedback(this.connectionId, this.connectionCode, this.state.feedback);
    const to = this.props.connection && this.props.connection.user && this.props.connection.user();

    let title = !this.props.connection ? 'Do you want to decline the connection?' : `Thanks for your response${to ? `, ${to.firstname}` : ''}!`;
    if (this.props.feedbackSent) {
      title = `Thanks${to ? `, ${to.firstname}` : ''}!`;
    } else if (this.props.isConnAlreadyAccepted) {
      title = 'You have already accepted this request.';
    } else if (this.props.errorConnStatus) {
      title = 'We are sorry, something went wrong.';
    }

    return (
      <OnePager title={title}>
        {!this.props.connection && !this.props.errorConnStatus && !this.props.isConnAlreadyAccepted &&
          <ButtonPrim onClick={() => this.props.loadConnectionInfo(this.connectionId, this.connectionCode)}>Decline</ButtonPrim>
        }
        {this.props.feedbackSent && <p>We received your feedback and will make note for future requests.</p>}
        {!this.props.feedbackSent && this.props.errorConnStatus
          ? (
            <p>
              Can you please forward the email to <a className="dsa-mailto" href="mailto:support@alchemistaccelerator.com" target="_blank">support@alchemistaccelerator.com</a> and request for declining it?
            </p>
          ) : this.props.connection && !this.props.feedbackSent && !this.props.isConnAlreadyAccepted && (
            <div>
              <p>We appreciate your honesty. We want to make sure we are improving and need your help.</p>
              <p><b>Would you mind telling us why you replied ’No, thanks’?</b></p>
              <textarea
                className={classNames('form-control', {
                  'dsa-form-error': this.props.feedbackSentErr && this.state.feedback === '',
                })}
                value={this.state.feedback}
                onChange={(e) => { this.setState({ feedback: e.target.value }); }}
                placeholder="Write your feedback. i.e They are a bit early as we look for at least 100K MRR"
              >
              </textarea>
              <Button color="primary" onClick={submit}>Submit Feedback</Button>
            </div>
          )
        }
        <div className="mt-7">
          <a className="dsa-link-button link" href="http://alchemistaccelerator.com/portfolio/">
            Check out the Alchemist Portfolio
          </a>
        </div>
      </OnePager>
    );
  }
}

ConnectionDecline.propTypes = {
  location: PropTypes.any,
  connection: PropTypes.any,
  feedbackSent: PropTypes.any,
  loadConnectionInfo: PropTypes.any,
  saveFeedback: PropTypes.any,
  errorConnStatus: PropTypes.bool,
  isConnAlreadyAccepted: PropTypes.bool,
  feedbackSentErr: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};


export function mapDispatchToProps(dispatch) {
  return {
    loadConnectionInfo: (connectionId, connectionCode) => dispatch({
      type: LOAD_CONNECTION, connectionId, connectionCode, status: false,
    }),
    saveFeedback: (connectionId, connectionCode, feedback) => dispatch({
      type: SEND_FEEDBACK, connectionId, connectionCode, feedback,
    }),
  };
}

const mapStateToProps = createStructuredSelector({
  connection: makeSelectConnection(),
  feedbackSent: createSelector((state) => state.get('peopleConnection'), (state) => state && state.get('feedbackSent')),
  errorConnStatus: selectErrorConnStatus,
  isConnAlreadyAccepted: selectIsConnAlreadyAccepted,
  feedbackSentErr: selectFeedbackSentErr,
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'peopleConnection', reducer });
const withSaga = injectSaga({ key: 'peopleConnection', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ConnectionDecline);

import { find } from 'lodash';


// In startup header for admin, startup will reset to `Will not participating in DD` after livestream ends
const getCurrentStartupAclassRel = (editedCompany) => editedCompany?.aclass_demodays_rel && find(editedCompany.aclass_demodays_rel(), (rel) => {
  // Use `demo_date` if future class does not have livestream
  if (!rel.aclass().livestream_end_tzaware) {
    return new Date() < new Date(rel.aclass().demo_date_pst_aware);
  }
  return new Date() < new Date(rel.aclass().livestream_end_tzaware);
});

export {
  getCurrentStartupAclassRel,
};

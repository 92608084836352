import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { NavLink as NavLinkReact } from 'react-router-dom';


const SideBarItem = ({ to, label }) => (
  <li className="dsa-site-sidebar_item _nav_">
    <NavLink tag={NavLinkReact} to={to}>{label}</NavLink>
  </li>
);

SideBarItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

export default SideBarItem;

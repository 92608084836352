import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { parse } from 'qs';

import { DAEMON } from 'utils/constants';
import injectSaga from 'utils/injectSaga';
import OnePager from 'components/OnePager/OnePager';
import { makeSelectLocation } from 'containers/App/selectors';
import SignInLinkedInButton from 'components/Buttons/Specific/SignInLinkedInButton';
import SignInEmailButton from 'components/Buttons/Specific/SignInEmailButton';
import { FLOW_TYPE } from 'containers/AuthProcess/constants';
import { Text } from 'components/Text/Body';
import GdprFooter from 'containers/Gdpr/Footer';

import saga from './saga';
import { markMeAsFloater } from './actions';


const MarkFloater = ({ location, markFloater }) => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  const isThereInvitationAndCode = !['', 'undefined'].includes(queryParams?.invitation) && !['', 'undefined'].includes(queryParams?.code);

  useEffect(() => {
    if (isThereInvitationAndCode) {
      markFloater();
    }
  }, []);

  return (
    <OnePager title={isThereInvitationAndCode ? 'We have marked you as an IFS floater for the upcoming IFS.' : 'Something went wrong.'} medHeading footer={<GdprFooter />}>
      {isThereInvitationAndCode &&
        <Text className="pb-7 mb-7">
          You can update your IFS availability by logging in.
        </Text>
      }
      <SignInLinkedInButton unauthHash={queryParams?.code} flow={FLOW_TYPE.ifs} />
      <SignInEmailButton flow={FLOW_TYPE.ifs} />
    </OnePager>
  );
};

MarkFloater.propTypes = {
  location: PropTypes.object,
  markFloater: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
});

const mapDispatchToProps = (dispatch) => ({
  markFloater: () => dispatch(markMeAsFloater()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'markFloaterPage', mode: DAEMON, saga });

export default compose(
  withSaga,
  withConnect,
)(MarkFloater);


import React from 'react';
import PropTypes from 'prop-types';

import ScrollDiv from 'components/Generic/ScrollDiv';
import MainContent from 'components/MainContent';
import SideBarSec from 'designSystemDocs/SideBar';


const DesignSystemMain = ({ children }) => (
  <MainContent className="flex-row">
    <SideBarSec />
    <ScrollDiv>
      {children}
    </ScrollDiv>
  </MainContent>
);

DesignSystemMain.propTypes = {
  children: PropTypes.any,
};

export default DesignSystemMain;

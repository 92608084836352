import { logError } from './log';

export const fetchBeBranch = (setBeBranch) => {
  fetch('/BE_BRANCH', { cache: 'no-cache' })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('offline');
      }

      return response.text();
    })
    .then((respText) => {
      if (respText.match(/<html/)) {
        throw new Error('File Missing');
      }
      return respText.trim();
    })
    .then(setBeBranch)
    .catch((error) => {
      logError('failed to check BE_BRANCH', error);
      setBeBranch('Missing Info');
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import LoadingPage from 'components/LoadingSpinner/LoadingPage';
import OnePager from 'components/OnePager/OnePager';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import reducer from './reducer';
import saga from './saga';
import { makeSelectTypeLabel, makeSelectVerifiedCompany, makeSelectVerifyingProfile } from './selectors';
import { loadVerifiable } from './actions';


class VerifiableAccept extends React.Component {
  componentDidMount() {
    const { location } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const verifiableCode = queryParams.code;
    this.registrationRef = queryParams.ref;
    this.props.loadVerifiableInfo(this.props.match.params.verifiableType, verifiableCode);
  }

  render() {
    const { verifyingProfile, verifiedCompany, typeLabel } = this.props;
    if (!verifyingProfile || !verifiedCompany) return <LoadingPage text="Loading page..." />;

    return (
      <OnePager title={`Thanks for verifying, ${verifyingProfile.firstname}`}>
        <p>You’re now confirmed as an {typeLabel} at {verifiedCompany.name}.</p>
        {(this.registrationRef
          && (
            <>
              <p>
                Also, if you’d like to receive requests from founders about investment or working with other Alchemist
                companies, please register a profile using the button below.
              </p>
              <div>
                <Link to={`/register-profile?ref=${this.registrationRef}`} className="dsa-link-button primary">
                  Register a Profile
                </Link>
              </div>
            </>))}
        {!this.registrationRef
          && (
            <a className="dsa-link-button link" href="http://alchemistaccelerator.com/portfolio/">
              Check out the Alchemist Portfolio
            </a>)}
      </OnePager>
    );
  }
}

VerifiableAccept.propTypes = {
  location: PropTypes.any,
  loadVerifiableInfo: PropTypes.any,
  match: PropTypes.object,
  verifiedCompany: PropTypes.object,
  verifyingProfile: PropTypes.object,
  typeLabel: PropTypes.string,
};


export function mapDispatchToProps(dispatch) {
  return {
    loadVerifiableInfo: (verifiableType, verifiableCode) => dispatch(loadVerifiable(verifiableType, verifiableCode, true)),
  };
}

const mapStateToProps = createStructuredSelector({
  verifiedCompany: makeSelectVerifiedCompany(),
  verifyingProfile: makeSelectVerifyingProfile(),
  typeLabel: makeSelectTypeLabel(),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'peopleVerifiable', reducer });
const withSaga = injectSaga({ key: 'peopleVerifiable', saga });
export default compose(
  withReducer,
  withSaga,
  withConnect,
)(VerifiableAccept);

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import classNames from 'classnames';


const LinkedInIcon = ({ linkedinLink, target = '_blank', size = 'sm' }) => (
  <a href={linkedinLink} target={target} className={classNames('dsa-social-icon', `_${size}_`)} key="linkedInLink">
    <FontAwesomeIcon icon={faLinkedin} />
  </a>
);

LinkedInIcon.propTypes = { linkedinLink: PropTypes.string, target: PropTypes.string, size: PropTypes.string };

export default LinkedInIcon;

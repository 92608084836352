import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SearchInput from 'components/Search/SearchInput';
import SearchHolder from 'components/Search/SearchHolder';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

import { setIsSearchFocused, setSearch } from './actions';
import { makeSelectSearch, selectIsSearchFocused, selectArchivedOwnListsTotalCount, selectAllUnarchivedListsTotalCount, selectArchivedEventListsTotalCount } from './selectors';
import { OWN_LISTS } from './constants';


const PeopleListsSearch = ({ isMobileTablet, totalCount, isArchive, listsType, archivedOwnListsCount, archivedEventListsCount, ...props }) => {
  const getSearchResultsCount = () => {
    if (!isArchive) {
      return totalCount;
    }
    if (isArchive) {
      return listsType === OWN_LISTS ? archivedOwnListsCount : archivedEventListsCount;
    }
    return 0;
  };

  return (
    <SearchHolder className={isMobileTablet ? 'pt-6 pb-5' : ''}>
      <SearchInput hideResetButton totalCount={getSearchResultsCount()} {...props} />
    </SearchHolder>
  );
};

PeopleListsSearch.propTypes = {
  isMobileTablet: PropTypes.bool,
  isArchive: PropTypes.bool,
  listsType: PropTypes.string,
  totalCount: PropTypes.number,
  archivedOwnListsCount: PropTypes.number,
  archivedEventListsCount: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  totalCount: selectAllUnarchivedListsTotalCount,
  search: makeSelectSearch(),
  isSearchFocused: selectIsSearchFocused,
  archivedOwnListsCount: selectArchivedOwnListsTotalCount,
  archivedEventListsCount: selectArchivedEventListsTotalCount,
});

const mapDispatchToProps = (dispatch) => ({
  setIsSearchFocused: (isFocused) => dispatch(setIsSearchFocused(isFocused)),
  onSearch: (evt) => dispatch(setSearch(evt && evt.target.value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(PeopleListsSearch));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fixInt } from 'utils/fieldProcessors';
import LoadingSingleTer from 'components/LoadingSpinner/LoadingSingleTer';


const ResultsCount = ({
  isLoading, value, hideCount, totalCount, noPadding, isBoldNumber, preNumberText,
}) => {
  const NumberTag = isBoldNumber ? 'strong' : 'span';
  return (
    <div className={classNames('dsa-search_results-number', { 'has-value': value, 'px-0': hideCount || noPadding })}>
      {isLoading && <LoadingSingleTer className="d-inline" />}
      {!hideCount && <span>{preNumberText ? `${preNumberText} ` : ''}<NumberTag>{fixInt(totalCount, '') || 0}</NumberTag> Results</span>}
    </div>);
};

ResultsCount.propTypes = {
  isLoading: PropTypes.bool,
  noPadding: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideCount: PropTypes.bool,
  isBoldNumber: PropTypes.bool,
  totalCount: PropTypes.number,
  preNumberText: PropTypes.string,
};

export default ResultsCount;

/**
 *
 * OAuthLanding
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/pro-solid-svg-icons/faWindowClose';

import LinkTo from 'components/BasicElements/LinkTo';
import LoadingSpinner from 'components/LoadingSpinner';
import OnePager from 'components/OnePager/OnePager';
import { makeSelectQueryParam } from 'containers/App/selectors';
import { DAEMON } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { getCredentials as getCredentialsAction } from './actions';
import saga from './saga';
import reducer from './reducer';
import { selectFlow, selectLoginError } from './selectors';
import { USER_NOT_FOUND_CODE } from './constants';


const OAuthLanding = ({
  error, getCredentials, loginError, flow,
}) => {
  if (error) {
    return (
      <OnePager
        title={(
          <>
            <FontAwesomeIcon icon={faWindowClose} className="me-5" />Sign In with LinkedIn was cancelled by the user.
          </>
        )}
      />
    );
  }
  if (loginError && loginError === USER_NOT_FOUND_CODE) {
    return (
      <OnePager
        title={(
          <>
            <FontAwesomeIcon icon={faWindowClose} className="me-5" />
            We couldn´t find a profile matching your LinkedIn account.<br />
            Please go to <LinkTo to={`/sign-in?flow=${flow}`}>the login form</LinkTo> to login with email and password.
          </>
        )}
      />
    );
  }
  React.useEffect(() => { getCredentials(); }, []);
  return <LoadingSpinner />;
};

OAuthLanding.propTypes = {
  error: PropTypes.string,
  loginError: PropTypes.string,
  flow: PropTypes.string,
  getCredentials: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  error: makeSelectQueryParam('error'),
  loginError: selectLoginError,
  flow: selectFlow,
});

const mapDispatchToProps = {
  getCredentials: getCredentialsAction,
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'oauthlanding', saga, mode: DAEMON });
const withReducer = injectReducer({ key: 'oauthlanding', reducer });

export default compose(withConnect, withSaga, withReducer)(OAuthLanding);

/*
 * PeoplePage
 *
 * This is the first thing users see of our App, at the '/people' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BasicListContent from 'components/BasicList/BasicListContent';
import BasicListResults from 'components/BasicList/BasicListResults';
import SelectList from 'components/BasicList/SelectList';
import SelectListContainer from 'components/BasicList/SelectListContainer';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import SearchInput from 'components/Search/SearchInput';
import { RESOURCE_COMPANIES } from 'containers/App/constants';
import { resRef } from 'utils/refs';

import PeopleOrCompanyResultRow from './PeopleOrCompanyResultRow';
import {
  makeSelectHasMore, makeSelectLoading, makeSelectLoadingNextPage, makeSelectPeople, makeSelectTotalCount,
  makeSelectIsSearchFocused, makeSelectSearch, selectShares, selectSharesHaveChanged, selectSharesProcessing, selectPeopleCompanyLoading,
} from './selectors';
import {
  initialLoad,
  loadNextPage,
  setSearch,
  setIsSearchFocused as setIsSearchFocusedAction,
  addShare as addShareAction,
  addCompanyShare as addCompanyShareAction,
  sendShares as sendSharesAction, closeSharePeopleListModal,
} from './actions';
import SharesList from './SharesList';
import { SearchContextProvider } from '../../../components/Search/SearchContext';


class ShareSection extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    this.props.onStart();
    this.inputRef = React.createRef();
  }

  render() {
    const {
      isSearchFocused, setIsSearchFocused, addShare, addCompanyShare, sendShares, hasChanges, closeModal, totalCount, loading, sharesAreProcessing, isPeopleCompanyLoading,
    } = this.props;

    return (
      <SearchContextProvider value={{ search: this.props.searchText }}>
        <ModalBody>
          <div className="dsa-select-search__holder">
            <SearchInput
              inputRef={this.inputRef}
              onSearch={this.props.onSearch}
              totalCount={totalCount}
              isSearchFocused={isSearchFocused}
              setIsSearchFocused={setIsSearchFocused}
              hideResultsForNull
              placeholder="Search people on the Vault"
              className="_medium_"
            />
            <SelectListContainer
              className={`${isSearchFocused ? 'd-flex' : 'd-none'} ${(!loading && (totalCount ? `items-count-${totalCount}` : 'no-items')) || 'loading-items'}`}
              role="button"
              tabIndex="-1"
              onMouseDown={() => setTimeout(() => { this.inputRef.current.focus(); }, 5)}
            >
              <SelectList>
                <BasicListContent>
                  <BasicListResults
                    component={PeopleOrCompanyResultRow}
                    items={this.props.people}
                    onClickRow={(item) => {
                      setTimeout(() => { this.inputRef.current.blur(); }, 15);
                      (item.type === RESOURCE_COMPANIES ? addCompanyShare : addShare)(item);
                    }}
                    loadNextPage={this.props.loadNextPage}
                    loadingNextPage={this.props.loadingNextPage}
                    loading={loading}
                    hasMore={this.props.hasMore}
                  />
                </BasicListContent>
              </SelectList>
            </SelectListContainer>
          </div>
          <SharesList />
        </ModalBody>
        <ModalFooter>
          <ButtonPrim
            disabled={!hasChanges || isPeopleCompanyLoading}
            onClick={sendShares}
            className="disabled-while-loading"
            isSending={sharesAreProcessing}
          >
            Save Changes
          </ButtonPrim>
          <ButtonSec onClick={closeModal} type="button">{!hasChanges ? '' : 'Discard & '}Close</ButtonSec>
        </ModalFooter>
      </SearchContextProvider>
    );
  }
}

ShareSection.propTypes = {
  people: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  hasMore: PropTypes.bool,
  totalCount: PropTypes.number,
  loadingNextPage: PropTypes.bool,
  loading: PropTypes.bool,
  onStart: PropTypes.func,
  onSearch: PropTypes.func,
  addShare: PropTypes.func,
  sendShares: PropTypes.func,
  loadNextPage: PropTypes.func,
  isSearchFocused: PropTypes.bool,
  hasChanges: PropTypes.bool,
  searchText: PropTypes.string,
  setIsSearchFocused: PropTypes.func,
  addCompanyShare: PropTypes.func,
  closeModal: PropTypes.func,
  sharesAreProcessing: PropTypes.bool,
  isPeopleCompanyLoading: PropTypes.bool,
};

// ToDo: remove unnecessary 'export' in 'export function mapDispatchToProps' in the rest of the codebase
function mapDispatchToProps(dispatch) {
  return {
    onStart: () => dispatch(initialLoad()),
    onSearch: (evt) => dispatch(setSearch(evt && evt.target.value)),
    addShare: (person) => dispatch(addShareAction(resRef(person))),
    addCompanyShare: (company) => dispatch(addCompanyShareAction(resRef(company))),
    loadNextPage: () => dispatch(loadNextPage()),
    setIsSearchFocused: (isSearchFocused) => dispatch(setIsSearchFocusedAction(isSearchFocused)),
    sendShares: () => dispatch(sendSharesAction()),
    closeModal: () => dispatch(closeSharePeopleListModal()),
  };
}

const mapStateToProps = createStructuredSelector({
  people: makeSelectPeople(),
  searchText: makeSelectSearch(),
  isSearchFocused: makeSelectIsSearchFocused(),
  hasMore: makeSelectHasMore(),
  loadingNextPage: makeSelectLoadingNextPage(),
  loading: makeSelectLoading(),
  shares: selectShares,
  totalCount: makeSelectTotalCount(),
  hasChanges: selectSharesHaveChanged,
  sharesAreProcessing: selectSharesProcessing,
  isPeopleCompanyLoading: selectPeopleCompanyLoading,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ShareSection);

/*
 * SharedLists reducer
 */
import { fromJS } from 'immutable';

import { SET_PREVIEWED_SHARED_LIST, TOGGLE_SHARED_LIST_PREVIEW } from './constants';

const initialState = fromJS({ previewedSharedList: false, isShownSharedListPreview: false });

function sharedListsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PREVIEWED_SHARED_LIST:
      return state.set('previewedSharedList', action.previewedSharedList);
    case TOGGLE_SHARED_LIST_PREVIEW:
      return state.set('isShownSharedListPreview', action.isShown);
    default:
      return state;
  }
}

export default sharedListsReducer;

/*
 * Admin constants
 */

import { LOAD_CONTENT, LOAD_NEXT_PAGE } from 'utils/filter/constants';
import { formatStr } from 'utils/general';

export const ADMIN_IFS_PAGE_IDENTIFIER = 'Admin/IFS';
export const LOAD_IFS_EMAILS = 'app/Admin/IFS/LOAD_IFS_EMAILS';
export const LOAD_IFS_EMAILS_SUCCESS = 'app/Admin/IFS/LOAD_IFS_EMAILS_SUCCESS';
export const LOAD_IFS_EMAILS_ERROR = 'app/Admin/IFS/LOAD_IFS_EMAILS_ERROR';
export const RESET_IFS_EMAILS = 'app/Admin/IFS/RESET_IFS_EMAILS';
export const SELECT_IFS_PARTICIPANT_SET_EVENT = 'app/Admin/IFS/SELECT_IFS_PARTICIPANT_SET_EVENT';
export const SELECT_PEOPLE_LIST = 'app/Admin/IFS/SELECT_PEOPLE_LIST';
export const SYNC_PEOPLE_LIST = 'app/Admin/IFS/SYNC_PEOPLE_LIST';
export const SYNC_PEOPLE_LIST_SUCCESS = 'app/Admin/IFS/SYNC_PEOPLE_LIST_SUCCESS';
export const SELECT_TEMPLATE = 'app/Admin/IFS/SELECT_TEMPLATE';
export const FORM_SENT = 'app/Admin/IFS/FORM_SENT';
export const IMPORT_FILES = 'app/Admin/IFS/IMPORT_FILES';
export const CSV_MODAL_STEP = 'app/Admin/IFS/CSV_MODAL_STEP';
export const SET_PREVIEW = 'app/Admin/IFS/SET_PREVIEW';
export const CONFIRM_PREVIEW_SEND = 'app/Admin/IFS/CONFIRM_PREVIEW_SEND';
export const SENDING_MAIL_ERROR = 'app/Admin/IFS/SENDING_MAIL_ERROR';
export const SET_ELIGIBLE_AVAILABLE_TOTAL_COUNT = 'app/Admin/IFS/SET_ELIGIBLE_AVAILABLE_TOTAL_COUNT';
export const SET_BE_PLACEHOLDERS = 'app/Admin/IFS/SET_BE_PLACEHOLDERS';
export const SET_ARE_TEMPLATES_SAVING = 'app/Admin/IFS/SET_ARE_TEMPLATES_SAVING';
export const ADD_SAVED_TEMPLATE = 'app/Admin/IFS/ADD_SAVED_TEMPLATE';
export const SET_SAVED_TEMPLATES = 'app/Admin/IFS/SET_SAVED_TEMPLATES';
export const SAVE_TEMPLATES = 'app/Admin/IFS/SAVE_TEMPLATES';

export const SET_IFS_PREVIEW_TEXTS_IS_LOADING = 'app/Admin/DemoDay/SET_IFS_PREVIEW_TEXTS_IS_LOADING';
export const UPDATE_IFS_PREVIEW_TEXTS = 'app/Admin/DemoDay/UPDATE_IFS_PREVIEW_TEXTS';

export const CHANGE_PSET_TAB = 'app/Admin/IFS/CHANGE_PSET_TAB';
export const CONFIRM_PSET_TAB_SAVE = 'app/Admin/IFS/CONFIRM_PSET_TAB_SAVE';

export const SEND_ENDPOINT_ADAPTER = 'STD_ENDPOINT/send';
export const PREVIEW_ENDPOINT_ADAPTER = 'STD_ENDPOINT/preview';

export const PARTICIPANT_TYPE = {
  backers: 'backers',
  gold_circle: 'gold_circle',
  angels: 'angels',
  floaters_investor: 'floaters_investor',
  demoing_class: 'demoing_class',
  new_class: 'new_class',
  alumni: 'alumni',
  floaters_founder: 'floaters_founder',
};

export const PARTICIPANT_NICE_MAPPER = {
  [PARTICIPANT_TYPE.backers]: 'CVC',
  [PARTICIPANT_TYPE.gold_circle]: 'Gold Circle',
  [PARTICIPANT_TYPE.angels]: 'Angels',
  [PARTICIPANT_TYPE.floaters_investor]: 'Floaters Investor',
  [PARTICIPANT_TYPE.demoing_class]: 'Demoing Class Founders',
  [PARTICIPANT_TYPE.new_class]: 'New Class Founders',
  [PARTICIPANT_TYPE.alumni]: 'Alumni',
  [PARTICIPANT_TYPE.floaters_founder]: 'Floaters Founder',
};

export const PARTICIPANT_MAPPER_EMAILS = {
  [PARTICIPANT_TYPE.backers]: 'Investors: CVC',
  [PARTICIPANT_TYPE.gold_circle]: 'Investors: Gold Circle',
  [PARTICIPANT_TYPE.angels]: 'Investors: Angels',
  [PARTICIPANT_TYPE.floaters_investor]: 'Investors: Floaters',
  [PARTICIPANT_TYPE.demoing_class]: 'Founders: Demoing Class',
  [PARTICIPANT_TYPE.new_class]: 'Founders: New Class',
  [PARTICIPANT_TYPE.alumni]: 'Founders: Alumni',
  [PARTICIPANT_TYPE.floaters_founder]: 'Founders: Floaters',
};

export const PARTICIPANT_MAPPER_TABS = {
  [PARTICIPANT_TYPE.backers]: 'CVC',
  [PARTICIPANT_TYPE.gold_circle]: 'Gold Circle',
  [PARTICIPANT_TYPE.angels]: 'Angels',
  [PARTICIPANT_TYPE.demoing_class]: 'Demoing Class',
  [PARTICIPANT_TYPE.new_class]: 'New Class',
  [PARTICIPANT_TYPE.alumni]: 'Alumni',
  [PARTICIPANT_TYPE.floaters_investor]: 'Floaters',
  [PARTICIPANT_TYPE.floaters_founder]: 'Floaters',
};

export const TEMPLATE_TYPE = {
  backers_invite: 'backers_invite',
  backers_register_extension: 'backers_register_extension',
  backers_register_last_day: 'backers_register_last_day',
  backers_confirm_attendance: 'backers_confirm_attendance',
  backers_last_reminder: 'backers_last_reminder',
  backers_partials_reminder: 'backers_partials_reminder',
  backers_successful_reg: 'backers_successful_reg',
  backers_agenda_after_matches: 'backers_agenda_after_matches',
  backers_agenda_after_matches_2: 'backers_agenda_after_matches_2',
  backers_nonscheduled: 'backers_nonscheduled',
  backers_nofeedback_sessions: 'backers_nofeedback_sessions',
  backers_thank_you: 'backers_thank_you',

  gold_circle_invite: 'gold_circle_invite',
  gold_circle_register_extension: 'gold_circle_register_extension',
  gold_circle_register_last_day: 'gold_circle_register_last_day',
  gold_circle_confirm_attendance: 'gold_circle_confirm_attendance',
  gold_circle_last_reminder: 'gold_circle_last_reminder',
  gold_circle_partials_reminder: 'gold_circle_partials_reminder',
  gold_circle_successful_reg: 'gold_circle_successful_reg',
  gold_circle_agenda_after_matches: 'gold_circle_agenda_after_matches',
  gold_circle_agenda_after_matches_2: 'gold_circle_agenda_after_matches_2',
  gold_circle_nonscheduled: 'gold_circle_nonscheduled',
  gold_circle_nofeedback_sessions: 'gold_circle_nofeedback_sessions',
  gold_circle_thank_you: 'gold_circle_thank_you',

  angels_invite: 'angels_invite',
  angels_register_extension: 'angels_register_extension',
  angels_register_last_day: 'angels_register_last_day',
  angels_confirm_attendance: 'angels_confirm_attendance',
  angels_last_reminder: 'angels_last_reminder',
  angels_partials_reminder: 'angels_partials_reminder',
  angels_successful_reg: 'angels_successful_reg',
  angels_agenda_after_matches: 'angels_agenda_after_matches',
  angels_agenda_after_matches_2: 'angels_agenda_after_matches_2',
  angels_nonscheduled: 'angels_nonscheduled',
  angels_nofeedback_sessions: 'angels_nofeedback_sessions',
  angels_thank_you: 'angels_thank_you',

  floaters_investor_day_of: 'floaters_investor_day_of',
  floaters_investor_successful_reg: 'floaters_investor_successful_reg',
  floaters_investor_thank_you: 'floaters_investor_thank_you',

  demoing_class_invite: 'demoing_class_invite',
  demoing_class_register_extension: 'demoing_class_register_extension',
  demoing_class_register_last_day: 'demoing_class_register_last_day',
  demoing_class_partials_reminder: 'demoing_class_partials_reminder',
  demoing_class_successful_reg: 'demoing_class_successful_reg',
  demoing_class_agenda_after_matches: 'demoing_class_agenda_after_matches',
  demoing_class_agenda_after_matches_2: 'demoing_class_agenda_after_matches_2',
  demoing_class_feedback: 'demoing_class_feedback',
  demoing_class_confirm_intensions: 'demoing_class_confirm_intensions',
  demoing_class_last_reminder: 'demoing_class_last_reminder',

  new_class_invite: 'new_class_invite',
  new_class_register_extension: 'new_class_register_extension',
  new_class_register_last_day: 'new_class_register_last_day',
  new_class_partials_reminder: 'new_class_partials_reminder',
  new_class_successful_reg: 'new_class_successful_reg',
  new_class_agenda_after_matches: 'new_class_agenda_after_matches',
  new_class_agenda_after_matches_2: 'new_class_agenda_after_matches_2',
  new_class_feedback: 'new_class_feedback',
  new_class_last_reminder: 'new_class_last_reminder',

  alumni_invite: 'alumni_invite',
  alumni_register_extension: 'alumni_register_extension',
  alumni_register_last_day: 'alumni_register_last_day',
  alumni_partials_reminder: 'alumni_partials_reminder',
  alumni_successful_reg: 'alumni_successful_reg',
  alumni_agenda_after_matches: 'alumni_agenda_after_matches',
  alumni_agenda_after_matches_2: 'alumni_agenda_after_matches_2',
  alumni_feedback: 'alumni_feedback',
  alumni_last_reminder: 'alumni_last_reminder',

  floaters_founder_day_of: 'floaters_founder_day_of',
  floaters_founder_successful_reg: 'floaters_founder_successful_reg',
  floaters_founder_feedback: 'floaters_founder_feedback',
};
export const IFS_INVITATIONS_LOAD_CONTENT = formatStr(LOAD_CONTENT, ADMIN_IFS_PAGE_IDENTIFIER);
export const IFS_INVITATIONS_LOAD_NEXT_PAGE = formatStr(LOAD_NEXT_PAGE, ADMIN_IFS_PAGE_IDENTIFIER);

// const DEMODAY_SIGNIN_URL = `${window.location.origin}/demo-day/[[class_id]]/sign-in`;

export const TMPL_NICE_MAPPER = {
  [TEMPLATE_TYPE.backers_invite]: 'Invite',
  [TEMPLATE_TYPE.backers_register_extension]: 'Extended deadline to register',
  [TEMPLATE_TYPE.backers_register_last_day]: 'Last day to register',
  [TEMPLATE_TYPE.backers_confirm_attendance]: 'Attendance & availability confirmation',
  [TEMPLATE_TYPE.backers_last_reminder]: 'Last Event Reminder',
  [TEMPLATE_TYPE.backers_partials_reminder]: 'Partial reminder',
  [TEMPLATE_TYPE.backers_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.backers_agenda_after_matches]: 'Agenda after matches 1',
  [TEMPLATE_TYPE.backers_agenda_after_matches_2]: 'Agenda after matches 2',
  [TEMPLATE_TYPE.backers_nonscheduled]: 'Non-Scheduled investors',
  [TEMPLATE_TYPE.backers_nofeedback_sessions]: 'Link for non-founder meetings',
  [TEMPLATE_TYPE.backers_thank_you]: 'Thank you',
  [TEMPLATE_TYPE.gold_circle_invite]: 'Invite',
  [TEMPLATE_TYPE.gold_circle_register_extension]: 'Extended deadline to register',
  [TEMPLATE_TYPE.gold_circle_register_last_day]: 'Last day to register',
  [TEMPLATE_TYPE.gold_circle_confirm_attendance]: 'Attendance & availability confirmation',
  [TEMPLATE_TYPE.gold_circle_last_reminder]: 'Last Event Reminder',
  [TEMPLATE_TYPE.gold_circle_partials_reminder]: 'Partial reminder',
  [TEMPLATE_TYPE.gold_circle_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.gold_circle_agenda_after_matches]: 'Agenda after matches 1',
  [TEMPLATE_TYPE.gold_circle_agenda_after_matches_2]: 'Agenda after matches 2',
  [TEMPLATE_TYPE.gold_circle_nonscheduled]: 'Non-Scheduled investors',
  [TEMPLATE_TYPE.gold_circle_nofeedback_sessions]: 'Link for non-founder meetings',
  [TEMPLATE_TYPE.gold_circle_thank_you]: 'Thank you',
  [TEMPLATE_TYPE.angels_invite]: 'Invite',
  [TEMPLATE_TYPE.angels_register_extension]: 'Extended deadline to register',
  [TEMPLATE_TYPE.angels_register_last_day]: 'Last day to register',
  [TEMPLATE_TYPE.angels_confirm_attendance]: 'Attendance & availability confirmation',
  [TEMPLATE_TYPE.angels_last_reminder]: 'Last Event Reminder',
  [TEMPLATE_TYPE.angels_partials_reminder]: 'Partial reminder',
  [TEMPLATE_TYPE.angels_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.angels_agenda_after_matches]: 'Agenda after matches 1',
  [TEMPLATE_TYPE.angels_agenda_after_matches_2]: 'Agenda after matches 2',
  [TEMPLATE_TYPE.angels_nonscheduled]: 'Non-Scheduled investors',
  [TEMPLATE_TYPE.angels_nofeedback_sessions]: 'Link for non-founder meetings',
  [TEMPLATE_TYPE.angels_thank_you]: 'Thank you',
  [TEMPLATE_TYPE.floaters_investor_day_of]: 'IFS Day',
  [TEMPLATE_TYPE.floaters_investor_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.floaters_investor_thank_you]: 'Thank You',
  [TEMPLATE_TYPE.demoing_class_invite]: 'Invite',
  [TEMPLATE_TYPE.demoing_class_register_extension]: 'Extended deadline to register',
  [TEMPLATE_TYPE.demoing_class_register_last_day]: 'Last day to register',
  [TEMPLATE_TYPE.demoing_class_partials_reminder]: 'Partial reminder',
  [TEMPLATE_TYPE.demoing_class_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.demoing_class_agenda_after_matches]: 'Agenda after matches 1',
  [TEMPLATE_TYPE.demoing_class_agenda_after_matches_2]: 'Agenda after matches 2',
  [TEMPLATE_TYPE.demoing_floaters_agenda]: 'Floaters IFS Day',
  [TEMPLATE_TYPE.demoing_class_feedback]: 'Founder Feedback',
  [TEMPLATE_TYPE.demoing_class_confirm_intensions]: 'Founder Confirm Intensions',
  [TEMPLATE_TYPE.demoing_class_last_reminder]: 'Last Event Reminder',
  [TEMPLATE_TYPE.new_class_invite]: 'Invite',
  [TEMPLATE_TYPE.new_class_register_extension]: 'Extended deadline to register',
  [TEMPLATE_TYPE.new_class_register_last_day]: 'Last day to register',
  [TEMPLATE_TYPE.new_class_partials_reminder]: 'Partial reminder',
  [TEMPLATE_TYPE.new_class_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.new_class_agenda_after_matches]: 'Agenda after matches 1',
  [TEMPLATE_TYPE.new_class_agenda_after_matches_2]: 'Agenda after matches 2',
  [TEMPLATE_TYPE.new_class_feedback]: 'Founder Feedback',
  [TEMPLATE_TYPE.new_class_last_reminder]: 'Last Event Reminder',
  [TEMPLATE_TYPE.alumni_invite]: 'Invite',
  [TEMPLATE_TYPE.alumni_register_extension]: 'Extended deadline to register',
  [TEMPLATE_TYPE.alumni_register_last_day]: 'Last day to register',
  [TEMPLATE_TYPE.alumni_partials_reminder]: 'Partial reminder',
  [TEMPLATE_TYPE.alumni_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.alumni_agenda_after_matches]: 'Agenda after matches 1',
  [TEMPLATE_TYPE.alumni_agenda_after_matches_2]: 'Agenda after matches 2',
  [TEMPLATE_TYPE.alumni_feedback]: 'Founder Feedback',
  [TEMPLATE_TYPE.alumni_last_reminder]: 'Last Event Reminder',
  [TEMPLATE_TYPE.floaters_founder_day_of]: 'IFS Day',
  [TEMPLATE_TYPE.floaters_founder_successful_reg]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.floaters_founder_feedback]: 'Feedback',
};

export const getFormNameForTemplate = (ifsTemplate) => `ifsTemplate${ifsTemplate?.id}`;
export const isIFSTemplate = (formName) => formName.match(/^ifsTemplate\d+$/);

export const PARTICIPANT_TYPE_ORDER = [PARTICIPANT_TYPE.alumni, PARTICIPANT_TYPE.new_class, PARTICIPANT_TYPE.demoing_class, PARTICIPANT_TYPE.floaters_founder, PARTICIPANT_TYPE.angels, PARTICIPANT_TYPE.backers, PARTICIPANT_TYPE.gold_circle, PARTICIPANT_TYPE.floaters_investor];
export const FOUNDER_PARTICIPANT_TYPE = [PARTICIPANT_TYPE.demoing_class, PARTICIPANT_TYPE.new_class, PARTICIPANT_TYPE.alumni, PARTICIPANT_TYPE.floaters_founder];
export const INVESTOR_PARTICIPANT_TYPE = [PARTICIPANT_TYPE.backers, PARTICIPANT_TYPE.gold_circle, PARTICIPANT_TYPE.angels, PARTICIPANT_TYPE.floaters_investor];

import React from 'react';
import PropTypes from 'prop-types';

import { getSignInUpString } from 'utils/general';

import ButtonsHolder from '../ButtonsHolder';
import { ButtonSec } from '../ButtonSec';


const SignInEmailButton = ({
  flow, className = 'my-6', isSignUp, ddInviteFlag, buttonText = '', ButtonComponent, size,
}) => {
  const Button = ButtonComponent || ButtonSec;
  const finalButtonText = buttonText || <>Sign {getSignInUpString(isSignUp)} with Email</>;
  return (
    <ButtonsHolder className={className}>
      <Button invertUnderline size={size} to={`/sign-${isSignUp ? 'up' : 'in'}?flow=${flow}${ddInviteFlag ? `&for=${ddInviteFlag}` : ''}`}>
        {finalButtonText}
      </Button>
    </ButtonsHolder>
  );
};

SignInEmailButton.propTypes = {
  flow: PropTypes.string,
  className: PropTypes.string,
  isSignUp: PropTypes.string,
  ddInviteFlag: PropTypes.string,
  buttonText: PropTypes.string,
  ButtonComponent: PropTypes.node,
  size: PropTypes.string,
};

export default SignInEmailButton;


import { put, select, takeLatest } from 'redux-saga/effects';
import { find } from 'lodash';

import { API_CONNECTIONS_BASE_URL, MSG_TYPE } from 'containers/App/constants';
import { makeSelectSignatures } from 'containers/AuthProvider/selectors';
import { logError } from 'utils/log';
import { request } from 'utils/request';

import { setMessage } from '../PersonModal/actions';
import { MESSAGE_SENT_ACTION } from '../PersonModal/constants';
import { SET_SENDER } from './constants';
import { makeSelectModalPerson } from '../PersonModal/selectors';
import { setOutroWithUpdatedSender } from './actions';
import { makeSelectSelectedFounder } from './selectors';

function* resetState(action) {
  if (action.messageType === MSG_TYPE.ADMIN_CONNECT && action.success) {
    yield put(setMessage(''));
  }
}

function* updateSender(action) {
  const founderOrLp = yield select(makeSelectSelectedFounder());
  const toUser = yield select(makeSelectModalPerson());
  const signatures = yield select(makeSelectSignatures());
  const senderEmail = action.sender.slice(action.sender.indexOf('<') + 1, action.sender.length - 1);
  const senderSignature = find(signatures, (s) => s.email === senderEmail);

  try {
    const msgReq = yield request(`${API_CONNECTIONS_BASE_URL}/meta/from/${founderOrLp?.account?.().id}/to/${toUser?.id}/${senderSignature?.system_key}?include=sender,subject`);
    yield put(setOutroWithUpdatedSender(msgReq?.meta.mail_templates.INTRO.outro));
  } catch (err) {
    logError(err);
  }
}

export default function* defaultSaga() {
  yield takeLatest(MESSAGE_SENT_ACTION, resetState);
  yield takeLatest(SET_SENDER, updateSender);
}

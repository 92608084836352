/*
 * People List Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import { generateLoadedContentActions, generateFiltersActions, generateFiltersUiActions } from 'utils/filter/actions';

import {
  CLOSE_MODAL,
  OPEN_FOR_LIST,
  SET_SHARE,
  ADD_SHARE,
  ADD_COMPANY_SHARE,
  ADD_SHARES,
  REMOVE_SHARE,
  SEND_SHARES,
  SET_SECTION,
  SET_SHARES_PROCESSING,
  SET_SHARES_PROCESSED,
  SET_PEOPLE_COMPANY_LOADING,
} from './constants';

const closeSharePeopleListModal = () => ({ type: CLOSE_MODAL });
const openModalForList = (peopleListRef, section) => ({ type: OPEN_FOR_LIST, peopleListRef, section });
const setShare = (idx, update) => ({ type: SET_SHARE, idx, update });
const addCompanyShare = (company) => ({ type: ADD_COMPANY_SHARE, company });
const addShare = (person) => ({ type: ADD_SHARE, person });
const addShares = (shares) => ({ type: ADD_SHARES, shares });
const sendShares = () => ({ type: SEND_SHARES });
const removeShare = (idx) => ({ type: REMOVE_SHARE, idx });
const setSection = (section) => ({ type: SET_SECTION, section });
const setSharesProcessing = () => ({ type: SET_SHARES_PROCESSING });
const setSharesProcessed = () => ({ type: SET_SHARES_PROCESSED });
const setPeopleCompanyLoading = (isPeopleCompanyLoading) => ({ type: SET_PEOPLE_COMPANY_LOADING, isPeopleCompanyLoading });

const {
  clearMarks,
  loadNextPage,
  loadNextPageSuccess,
  loadContent,
  contentLoaded,
  contentLoadingError,
  initialLoad,
  setHasMore,
  setTotalCount,
} = generateLoadedContentActions('PeopleListManageModal');

const { setSearch, setMaxPage } = generateFiltersActions('PeopleListManageModal');

const { setIsSearchFocused } = generateFiltersUiActions('PeopleListManageModal');

export {
  clearMarks,
  loadNextPage,
  loadNextPageSuccess,
  loadContent,
  contentLoaded,
  contentLoadingError,
  initialLoad,
  setSearch,
  setMaxPage,
  setHasMore,
  setTotalCount,
  setIsSearchFocused,
  closeSharePeopleListModal,
  openModalForList,
  setShare,
  addShare,
  addCompanyShare,
  addShares,
  sendShares,
  removeShare,
  setSection,
  setSharesProcessing,
  setSharesProcessed,
  setPeopleCompanyLoading,
};

/**
 *
 * GdprDocsModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import DocModal from 'components/Modals/DocModal';
import { HeadingLarge } from 'components/Text/Headings';
import injectReducer from 'utils/injectReducer';

import { selectDoc } from './selectors';
import reducer from './reducer';
import { openDocModal } from './actions';

const GdprDocsModal = (props) => (
  <DocModal toggle={() => props.openDocModal(null)} isOpen={!!props.doc}>
    <HeadingLarge>GDPR {'>'} {props.doc && props.doc.title}</HeadingLarge>
    {props.doc && props.doc.document}
  </DocModal>);

GdprDocsModal.propTypes = {
  openDocModal: PropTypes.func.isRequired,
  doc: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  doc: selectDoc,
});

const mapDispatchToProps = {
  openDocModal,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'gdprDocsModal', reducer });

export default compose(withReducer, withConnect)(GdprDocsModal);

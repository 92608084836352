/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from 'redux-immutable';
import { fromJS, List } from 'immutable';
import { reducer as formReducer } from 'redux-form/immutable';

export const UPDATE_INCLUDED = 'alchemist/Global/UPDATE_INCLUDED';
export const REQUEST_RELOAD = 'alchemist/Global/REQUEST_RELOAD';
export const RELOAD_FINISHED = 'alchemist/Global/RELOAD_FINISHED';
export const REMOVE_FROM_INCLUDED = 'alchemist/Global/REMOVE_FROM_INCLUDED';
export const SET_IS_AUTH_ROUTE = 'alchemist/Global/SET_IS_AUTH_ROUTE';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */


// Initial included state
const includedInitialState = fromJS({});

/**
 * Update the stored data fetched from the server
 */
function included(state = includedInitialState, action) {
  switch (action.type) {
    case UPDATE_INCLUDED:
      return state.mergeWith(merger(0, 3), { ...action.included });
    case REQUEST_RELOAD:
      return state.setIn([action.objRef.type, action.objRef.id.toString(), 'meta', 'isLoading'], true);
    case RELOAD_FINISHED:
      return state.setIn([action.objRef.type, action.objRef.id.toString(), 'meta', 'isLoading'], false);
    case REMOVE_FROM_INCLUDED:
      return state.removeIn([action.resource, action.id.toString()]);
    default:
      return state;
  }
}

const merger = (depth, maxDepth) => (a, b) => {
  if (a && a.mergeWith && !List.isList(a) && !List.isList(b) && depth < maxDepth) {
    return a.mergeWith(merger(depth + 1, maxDepth), b);
  }

  return b;
};

const authRouteInitialState = fromJS({
  isUserOnAuthRoute: false,
});

/**
  * this is needed to mark if user is at the auth route for tokenExpirationMiddleware.
  * when user visit auth route, we set isUserOnAuthRoute to true (please see AuthRouteWithErrorBoundary).
  * if the flag is true and token is expired, middleware force user to relogin.
*/
function authRouteReducer(state = authRouteInitialState, action) {
  switch (action.type) {
    case SET_IS_AUTH_ROUTE:
      return state.set('isUserOnAuthRoute', action.isUserOnAuthRoute);
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers, history) {
  return combineReducers({
    form: formReducer,
    router: connectRouter(history),
    included,
    authRoute: authRouteReducer,
    ...injectedReducers,
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';


const H1 = ({
  children, className, mobileSize, isMobileTablet,
}) => (
  <h1 className={classNames('dsa-heading', className, isMobileTablet && mobileSize ? `_${mobileSize}_` : '_large_')}>
    {children}
  </h1>);


H1.propTypes = {
  children: PropTypes.any,
  mobileSize: PropTypes.string,
  className: PropTypes.string,
  isMobileTablet: PropTypes.bool,
};

export default ResponsiveHoC(H1);

/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SET_SELECTED_COMPANY = 'alchemist/Company/Single/SET_SELECTED_COMPANY';
export const CORRECT_SELECTED_COMPANY_ID = 'alchemist/Company/Single/CORRECT_SELECTED_COMPANY_ID';
export const SET_COMPANY_TEAM_LOADING = 'alchemist/Company/Single/SET_COMPANY_TEAM_LOADING';
export const SELECTED_COMPANY_NOT_FOUND = 'alchemist/People/Single/SELECTED_COMPANY_NOT_FOUND';

export const STARTUP_STATUS = {
  active: 'Active',
  tuition_not_paid: 'Tuition not Paid',
  shutdown: 'Shutdown',
  acquired: 'Acquired',
};

export const INACTIVE_REASONS = {
  tuition_not_paid: 'Tuition not Paid',
  shutdown: 'Shutdown',
  acquired: 'Acquired',
};

export const INACTIVE_REASON_MAPPER = {
  [INACTIVE_REASONS.shutdown]: 'Startup has shutdown',
  [INACTIVE_REASONS.acquired]: 'Startup has been acquired',
};

import { call, put, takeLatest } from 'redux-saga/effects';

import { updateIncluded } from 'actions';
import { API_BASE } from 'containers/App/constants';
import { extractData } from 'utils/jsonApiExtract';
import { logError } from 'utils/log';
import { request } from 'utils/request';

import { siteSettingsLoaded, siteSettingsLoadingError } from './actions';
import { LOAD_SIDEBAR_SETTINGS, SIDEBAR_SETTINGS_GROUP } from './constants';

export function* loadSidebarLinks() {
  try {
    const siteSettingsRequest = yield call(
      request,
      `${API_BASE}/sitesettings?filter[settings_group:eq]=${SIDEBAR_SETTINGS_GROUP}`
    );
    const { inclusions, items: siteSettings } = extractData(siteSettingsRequest);
    yield put(updateIncluded(inclusions));
    yield put(siteSettingsLoaded(SIDEBAR_SETTINGS_GROUP, siteSettings));
  } catch (err) {
    logError(err);
    yield put(siteSettingsLoadingError(err));
  }
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_SIDEBAR_SETTINGS, loadSidebarLinks);
}

/*
 *
 * RegisterProfile reducer
 *
 */

import { fromJS } from 'immutable';

import {
  SET_UNAUTH_HASH, SET_IS_CHECKING_USER, SET_STEP, SET_REGISTERING_USER, STEP_PERSONAL, SET_LINKEDIN_VALUES,
  DELETE_LINKEDIN_VALUE,
  // SET_SHOW_IMPORT_SUCCESS, SET_SHOW_IMPORT_ERROR,
  SET_SHOW_ERROR_SUMMARY,
  GO_BACK_TO_FIRST_STEP, SET_NEW_PRIMARY_COMPANY,
} from './constants';

const initialState = fromJS({
  registeringUser: null,
  isCheckingUser: true,
  mustShowImportSuccess: true,
  mustShowImportError: false,
  canGoOnAfterImportError: false,
  mustShowErrorSummary: 0,
  step: STEP_PERSONAL,
  unauthHash: false,
  importedValues: {},
  primaryCompanyExp: null,
});

function registerProfileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REGISTERING_USER:
      return state.set('registeringUser', action.registeringUser);
    case SET_IS_CHECKING_USER:
      return state.set('isCheckingUser', action.isCheckingUser);
    case SET_STEP:
      return state.set('step', action.step);
    case GO_BACK_TO_FIRST_STEP:
      return state.set('step', initialState.get('step'));
    case SET_UNAUTH_HASH:
      return state.set('unauthHash', action.schema);
    case SET_LINKEDIN_VALUES:
      return state.mergeIn(['importedValues'], action.importedValues);
    case DELETE_LINKEDIN_VALUE:
      return state.deleteIn(['importedValues', action.key]);
    case SET_NEW_PRIMARY_COMPANY:
      return state.set('primaryCompanyExp', action.company);
    // case SET_SHOW_IMPORT_SUCCESS:
    //   return state.set('mustShowImportSuccess', action.mustShow);
    // case SET_SHOW_IMPORT_ERROR:
    //   return state.set('mustShowImportError', action.mustShow).set('canGoOnAfterImportError', action.allowToGoOn);
    case SET_SHOW_ERROR_SUMMARY:
      return state.update('mustShowErrorSummary', (currentMustShow) => action.mustShow ? currentMustShow + 1 : 0);
    default:
      return state;
  }
}

export default registerProfileReducer;

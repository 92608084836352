import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody, ModalHeader } from 'reactstrap';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import DsaModal from 'components/Modals/DsaModal';
import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import VimeoVideo from 'containers/BasicConnectedElements/VimeoVideo';


const InstructionsVideoModal = ({ isOpen, closeModal, videoUrl }) => (
  <DsaModal
    className="dsa-onboarding-preview__modal"
    size="lg"
    isOpen={isOpen}
    toggle={closeModal}
  >
    <ModalHeader className="text-capitalize" tag="h1">
      IFS Instructions Video
    </ModalHeader>
    <CloseButtonWrapper closeAction={closeModal} />
    <ModalBody className="py-7">
      <VimeoVideo className="dsa-profile__video" videoUrl={videoUrl} />
    </ModalBody>
  </DsaModal>
);

InstructionsVideoModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  videoUrl: PropTypes.string,
};

export default ResponsiveHoC(InstructionsVideoModal);

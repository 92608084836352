import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';


const ToggleButton = ({ toggleAction, toggleBool, size }) => (
  <ButtonStyled size={size} onClick={toggleAction} type="button" aria-label="Close">
    <FontAwesomeIcon icon={toggleBool ? faPencilAlt : faEye} />
  </ButtonStyled>
);

ToggleButton.propTypes = {
  toggleAction: PropTypes.func,
  toggleBool: PropTypes.bool,
  size: PropTypes.string,
};

export default ToggleButton;


const ButtonStyled = styled.button`
  font-size: ${(props) => (props.size === 'sm' ? '.75rem' : '1.401rem')};
  padding: .282rem .875rem;
  color: #03273B;
  
  &:hover {
    cursor: pointer;
    background-color: #03273B;
    color: white;
  }
`;

import { SET_SELECTED_COMPANY, SET_COMPANY_TEAM_LOADING, SELECTED_COMPANY_NOT_FOUND, CORRECT_SELECTED_COMPANY_ID }
  from './constants';

const setSelectedCompany = (company) => ({ type: SET_SELECTED_COMPANY, company });
const correctSelectedCompanyId = (companyId) => ({ type: CORRECT_SELECTED_COMPANY_ID, companyId });
const setCompanyTeamLoading = (isLoading) => ({ type: SET_COMPANY_TEAM_LOADING, isLoading });
const selectedCompanyNotFound = () => ({ type: SELECTED_COMPANY_NOT_FOUND });

export { setSelectedCompany, setCompanyTeamLoading, selectedCompanyNotFound, correctSelectedCompanyId };


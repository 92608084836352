export function preprocessSort(sort) {
  let processedSort = sort;
  if (sort.has('message_acceptance_rate')) {
    processedSort = processedSort.set('messages_to_me_count', sort.get('message_acceptance_rate'));
  }
  if (sort.has('average_rating')) {
    processedSort = processedSort.set('comment_count', -1);
  }
  return processedSort.set('created_at', -1);
}

import React from 'react';
import { ConsentManager, openConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';

import { ButtonTer } from 'components/Buttons/ButtonTer';

import Hack from './Hack';

const CustomConsentManager = () => {
  if (!window.segment_code) {
    return null;
  }
  const bannerContent = (
    <div className="font-size-base">
      We use cookies to personalize and to analyze our traffic. By using this website, you agree to the use of cookies.
      <ButtonTer className="ms-7 text-decoration-none" onClick={openConsentManager}>Set Cookies</ButtonTer>
    </div>);
  const bannerSubContent = '';
  const preferencesDialogTitle = <Hack />;
  const preferencesDialogContent = 'We use cookies to provide better user experience and analyze traffic. We do NOT ' +
    'use your information for advertising and marketing.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent = 'Your preferences have not been saved. By continuing to use our website, you՚re ' +
    'agreeing to our Website Data Collection Policy.';
  return (
    <div className="consent-manager-wrapper">
      <ConsentManager
        writeKey={window.segment_code}
        shouldRequireConsent={inEU}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
      />
    </div>);
};

export default CustomConsentManager;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MailTo from 'components/BasicElements/MailTo';
import SignInEmailButton from 'components/Buttons/Specific/SignInEmailButton';
import SignInLinkedInButton from 'components/Buttons/Specific/SignInLinkedInButton';
import OnePager from 'components/OnePager/OnePager';
import { Text } from 'components/Text/Body';
import { makeSelectQueryParam } from 'containers/App/selectors';
import { FLOW_TYPE } from 'containers/AuthProcess/constants';
import GdprFooter from 'containers/Gdpr/Footer';
import TermsParagraph from 'containers/Gdpr/TermsParagraph';


const VaultIntro = ({ unauthHash }) => (
  <OnePager title="Welcome to the Alchemist Vault!" footer={<GdprFooter />}>
    <Text className="mb-7">The Vault is an invaluable tool developed to provide you access to the Alchemist network of over 30K
      investors, mentors, corporates, etc. as well as resources such as legal docs, sales agreements, email
      templates to help build your company.
    </Text>
    <Text className="mb-7">To start, you will need to complete several tasks as part of our onboarding process. But first, please
      Sign Up with LinkedIn or Email using one of the buttons below. With LinkedIn, make sure you use your primary LinkedIn email when signing
      in to avoid any future issues.
    </Text>
    <Text className="mb-7">
      For any technical questions during the onboarding process, please use the chat button in the bottom
      right corner or email us at <MailTo to="support@alchemistaccelerator.com" />.
    </Text>
    <SignInLinkedInButton flow={FLOW_TYPE.onboarding} unauthHash={unauthHash} isSignUp isSignString="Up" />
    <SignInEmailButton flow={FLOW_TYPE.onboarding} isSignUp />
    <TermsParagraph signInText="Sign Up" />
  </OnePager>
);

VaultIntro.propTypes = {
  unauthHash: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  unauthHash: makeSelectQueryParam('code'),
});

export default connect(mapStateToProps)(VaultIntro);

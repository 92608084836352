import { createSelector } from 'reselect';

import { makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';
import { asHour12, getLongDate, getTimezoneAbbreviation, parseDateString } from 'utils/dateTime';

const selectDemoDayRegistrationDomain = (state) => state.get('demoDayRegistration');

const selectInvitationRef = makeSubSelectorToJS(selectDemoDayRegistrationDomain, ['invitationRef']);
const selectInvitation = makeSelectObjectFromRefSelector(selectInvitationRef);
const selectDemoDay = createSelector(
  selectInvitation,
  (invitation) => invitation && invitation.demo_day && invitation.demo_day()
);
const selectAaClass = createSelector(selectDemoDay, (demoDay) => demoDay && demoDay.aa_class && demoDay.aa_class());

const selectQueryParams = makeSubSelectorToJS(selectDemoDayRegistrationDomain, ['queryParams']);
const selectIsTimezoneShownOnMobile = makeSubSelector(selectDemoDayRegistrationDomain, ['isTimezoneListShownOnMobile']);
const selectTimezone = makeSubSelector(selectDemoDayRegistrationDomain, ['timezone']);
const selectIsUserTzNotSupported = makeSubSelector(selectDemoDayRegistrationDomain, ['isUserTzNotSupported']);

const selectDdAttendanceLink = createSelector(
  selectQueryParams,
  (queryParams) => `/demo-day/attendance?flow=demo-day${queryParams?.invitation && queryParams?.class ? `&invitation=${queryParams.invitation}&class=${queryParams.class}` : ''}`
);

const selectDdEventStartDate = createSelector(
  selectAaClass,
  (aaClass) => {
    const eventStartTzDate = aaClass?.livestream && aaClass.livestream_start_tzaware
      ? parseDateString(aaClass.livestream_start_tzaware)
      : parseDateString(aaClass?.demo_date_pst_aware);
    return aaClass && eventStartTzDate ? `${getLongDate(eventStartTzDate)} ${getTimezoneAbbreviation(eventStartTzDate)}` : `{{ Make sure you have ${aaClass?.livestream && !aaClass.livestream_start_tzaware ? 'demo date and agenda details' : 'demo date'}. }}`;
  }
);

const selectDemoDayRangeDate = createSelector(
  selectAaClass,
  (aaClass) => {
    const streamStartTzDate = parseDateString(aaClass?.livestream_start_tzaware);
    const eventStartTzDate = aaClass?.livestream && streamStartTzDate ? streamStartTzDate : parseDateString(aaClass?.demo_date_pst_aware);
    const weekRegEndTzDate = parseDateString(aaClass?.demo_week_registration_end);
    return aaClass && eventStartTzDate && weekRegEndTzDate
      ? `${getLongDate(eventStartTzDate)} ${asHour12(eventStartTzDate)} - ${getLongDate(weekRegEndTzDate)} ${asHour12(weekRegEndTzDate)} ${getTimezoneAbbreviation(weekRegEndTzDate)}`
      : `{{ Make sure you have ${aaClass?.livestream && !aaClass.livestream_start_tzaware ? 'demo date and agenda details' : 'demo date'}. }}`;
  }
);

const selectDdAgendaHtml = createSelector(
  selectAaClass,
  (aaClass) => aaClass?.dd_event_agenda
    && `<ul>${aaClass.dd_event_agenda().map((eventAgenda) => `<li>${eventAgenda.fullHtmlDescriptionTzAware}</li>`).join('')}</ul>`
);

const selectReplacedDdEventDetails = createSelector(
  selectAaClass,
  selectDdEventStartDate,
  selectDemoDayRangeDate,
  selectDdAgendaHtml,
  (aaClass, eventStartDate, ddRangeDate, ddAgendaHtml) => {
    let details = aaClass?.demoday_event_details;
    details = details?.replace(/{% event_start_date %}/g, eventStartDate);
    details = details?.replace(/{% range_demo_day_date %}/g, ddRangeDate);
    details = details?.replace(/{% dd_event_agenda %}/g, ddAgendaHtml);
    return details;
  }
);

export { selectInvitation, selectDemoDay, selectAaClass, selectQueryParams, selectDdAttendanceLink, selectReplacedDdEventDetails, selectTimezone, selectIsTimezoneShownOnMobile, selectIsUserTzNotSupported };

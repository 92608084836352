import styled from 'styled-components';


const ShareListTable = styled.div`
  min-height: 39px;
  margin: 21px 0 21px 0;
  font-size: 12px;
  color: #62778C;
  
  table {
    width: 100%;
  }
  
  thead tr {
    display: flex;
  }
  
  tbody {
    display: block;
    max-height: 211px;
    overflow-y: auto;
    width: 100%;
  }
`;

const ShortTd = styled.td`
  width: 35px;
  padding: 0;
  color: #03273B;
  border-left: 1px solid #e6e6e6;
  align-self: center;

  thead & {
    border-left-color: transparent;
  }

  button {
    height: 34px;
    width: 34px;
    padding: 7px;
  }
`;

const TextTdName = styled.td`
  width: 33%;
  color: #03273B;
  font-weight: 500;
  padding-left: 14px;
  align-self: center;
  
  thead & {
    color: #62778C;
  }
`;

const TableRow = styled.tr`
  height: 35px;
  border: 1px solid #e6e6e6;
  
  thead & {
    border: none;
    color: #62778C;
    font-weight: 500;
  }
`;

const TextTd = styled.td`
  width: 33%;
  align-self: center;
`;

const TextMessage = styled.div`
  font-size: 14px;
  color: #03273B;
`;


export {
  ShareListTable,
  ShortTd,
  TextTdName,
  TableRow,
  TextTd,
  TextMessage,
};

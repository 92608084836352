import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ButtonBase from './ButtonBase';

const ButtonSec = DefaultPropsHoC(ButtonBase, { color: 'primary', outline: true });
const ButtonSecSm = DefaultPropsHoC(ButtonBase, { color: 'primary', outline: true, size: 'sm' });
// const ButtonSecSm = DefaultPropsHoC(ButtonBase, { color: 'secondary', outline: true, size: 'sm' });
const ButtonSecLg = DefaultPropsHoC(ButtonBase, { color: 'primary', outline: true, size: 'lg' });


export {
  ButtonSec,
  ButtonSecSm,
  ButtonSecLg,
};

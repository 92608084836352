import React from 'react';
import { PropTypes } from 'prop-types';

import MailTo from 'components/BasicElements/MailTo';
import SignInEmailButton from 'components/Buttons/Specific/SignInEmailButton';
import SignInLinkedInButton from 'components/Buttons/Specific/SignInLinkedInButton';
import OnePager from 'components/OnePager/OnePager';
import { FLOW_TYPE } from 'containers/AuthProcess/constants';


const VaultIntroInvestors = ({ flow = FLOW_TYPE.standard }) => (
  <OnePager title="Welcome to the Alchemist Vault!">
    <p>
      The Vault is an invaluable tool developed to connect Alchemist Startups with investors, mentors, and corporates
      within the Alchemist network.
    </p>
    <p>Sign In with LinkedIn using the button below.</p>
    <p>
      If you have any technical questions, please use the chat button in the bottom right corner. Otherwise, you can
      always email <MailTo to="support@alchemistaccelerator.com" />.
    </p>
    <SignInLinkedInButton isSignUp flow={flow} />
    <SignInEmailButton isSignUp flow={flow} />
  </OnePager>
);

VaultIntroInvestors.propTypes = {
  flow: PropTypes.string,
};

export default VaultIntroInvestors;

import React from 'react';
import PropTypes from 'prop-types';

import ScrollDiv from 'components/Generic/ScrollDiv';

const SideBarSection = (props) => (
  <ScrollDiv tagName="ul" darkBg className="dsa-site-sidebar__section">
    {props.children}
  </ScrollDiv>
);

SideBarSection.propTypes = {
  children: PropTypes.any,
};

export default SideBarSection;

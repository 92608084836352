import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { hasAccessOtherThanThese } from 'utils/fieldProcessors';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

import MainContentHeader from '../../components/MainContent/MainContentHeader';
import { makeSelectAccount, makeSelectAuthPrincipals, makeSelectUserIs } from '../AuthProvider/selectors';
import { PERMISSION, RESOURCE_CONNECTIONS, RESOURCE_PEOPLE_LISTS } from '../App/constants';

const PeopleMainContentHeader = ({ children, myAccount, myRoles, isAdmin, isDesktop }) => (
  <MainContentHeader
    scrollable={!isDesktop}
    headerLinks={[
      { label: 'Network', to: '/people', exact: true },
      hasAccessOtherThanThese(myRoles, [PERMISSION.corporate, PERMISSION.partner]) && {
        label: 'Lists',
        to: '/people/lists',
        notification: myAccount
            && myAccount.hasPendingNotificationsFor(RESOURCE_PEOPLE_LISTS) && 'New list(s) shared with you',
      },
      {
        label: 'Messages',
        to: '/people/sent-messages',
        notification: myAccount
            && myAccount.hasPendingNotificationsFor(RESOURCE_CONNECTIONS) && 'Messages statuses updated',
      },
      isAdmin && {
        label: 'Class Coaches',
        to: '/people/class-coaches',
        exact: true,
        isAdmin: true,
      },
    ]}
  >{children}
  </MainContentHeader>
);

PeopleMainContentHeader.propTypes = {
  children: PropTypes.any,
  myAccount: PropTypes.object,
  isAdmin: PropTypes.bool,
  myRoles: PropTypes.array,
  isDesktop: PropTypes.bool,
};
const mapStateToProps = createStructuredSelector({
  myAccount: makeSelectAccount(),
  myRoles: makeSelectAuthPrincipals(),
  isAdmin: makeSelectUserIs(PERMISSION.admin),
});

export default connect(mapStateToProps)(ResponsiveHoC(PeopleMainContentHeader));

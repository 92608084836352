import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ButtonBase from './ButtonBase';

const ButtonPrim = DefaultPropsHoC(ButtonBase, { color: 'primary' });
const ButtonPrimSm = DefaultPropsHoC(ButtonBase, { color: 'primary', size: 'sm' });
const ButtonPrimLg = DefaultPropsHoC(ButtonBase, { color: 'primary', size: 'lg' });
const ButtonPrimMultiLine = DefaultPropsHoC(ButtonBase, { color: 'primary', multiLine: true });
const ButtonPrimFlex = DefaultPropsHoC(ButtonBase, { color: 'primary', isFlexButton: true, noMargin: true });


export {
  ButtonPrim,
  ButtonPrimSm,
  ButtonPrimFlex,
  ButtonPrimLg,
  ButtonPrimMultiLine,
};

import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledAccordion } from 'reactstrap';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

const PeopleListsContent = ({ children, isMobileTablet }) => isMobileTablet ?
  (
    <UncontrolledAccordion stayOpen className="dsa-people-lists">
      {children}
    </UncontrolledAccordion>
  ) : (
    <div className="dsa-people-lists">
      {children}
    </div>
  );

PeopleListsContent.propTypes = {
  children: PropTypes.any,
  isMobileTablet: PropTypes.bool,
};


export default ResponsiveHoC(PeopleListsContent);

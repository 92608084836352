import { makeFormActionFunction } from 'utils/Forms/actions';

import { INITIAL_LOAD, SET_INVITATION, ATTENDANCE_SENT, SET_CONFIRM_MODAL_OPEN, FOUNDER_ATTENDANCE_SENT, LOAD_COMPANY, MARK_COMPANY, LOAD_REG_COMPANIES, SET_REG_COMPANIES, REORDER_MARKED_COMPANIES, SET_TIMEZONE, TOGGLE_TIMEZONE_ON_MOBILE, SUBMIT_IFS_REG_FORM } from './constants';

export const initialLoad = () => ({ type: INITIAL_LOAD });
export const setInvitation = (invitationRef) => ({ type: SET_INVITATION, invitationRef });
export const attendanceSent = () => ({ type: ATTENDANCE_SENT });
export const founderAttendanceSent = () => ({ type: FOUNDER_ATTENDANCE_SENT });
export const setIsConfirmModalOpen = (isConfirmModalOpen) => ({ type: SET_CONFIRM_MODAL_OPEN, isConfirmModalOpen });
export const loadCompany = (companyRef) => ({ type: LOAD_COMPANY, companyRef });
export const loadRegistrantCompanies = (ifsId) => ({ type: LOAD_REG_COMPANIES, ifsId });
export const setRegistrantCompanies = (companyRefs) => ({ type: SET_REG_COMPANIES, companyRefs });
export const markCompany = (company, unselect) => ({ type: MARK_COMPANY, company, unselect });
export const reorderMarkedCompanies = (companyRefs) => ({ type: REORDER_MARKED_COMPANIES, companyRefs });
export const setTimezone = (timezone) => ({ type: SET_TIMEZONE, timezone });
export const toggleTimezoneOnMobile = () => ({ type: TOGGLE_TIMEZONE_ON_MOBILE });

export const submitIfsRegForm = makeFormActionFunction(SUBMIT_IFS_REG_FORM);

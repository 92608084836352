import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { makeSelectProfile, selectClassFromLinkedinSignIn } from 'containers/AuthProvider/selectors';
import { CLASS_TYPE } from 'containers/App/constants';
import { ALCHEMIST_DD_LOGIN_LS_KEY } from 'containers/AuthProvider/constants';

import { PAGE_ID_TO_URL } from './constants';
import { makeSelectAlchemistClass } from './RegistrationStart/selectors';


const DemoDayCurrentPage = ({ myProfile, match: { params: { pageId } }, aaClassFromEmailSignIn, aaClassFromLinkedinSignIn }) => {
  let aaClass = myProfile?.upcomingDdInvitation?.();
  if (aaClassFromEmailSignIn?.number) {
    aaClass = aaClassFromEmailSignIn;
  } else if (aaClassFromLinkedinSignIn?.number) {
    aaClass = aaClassFromLinkedinSignIn;
  }

  const isAlchemistX = aaClass?.class_type === CLASS_TYPE.alchemistx;
  const ddRoute = isAlchemistX ? 'ax' : 'aa';
  const ddLoginRoute = localStorage.getItem(ALCHEMIST_DD_LOGIN_LS_KEY);

  // what is ddLoginRoute:
  // in case when UNAUTH user visit demoday route (e.g. demo-day/ax/:id/companies), redirect him/her to demoday login page (SignInPage component)
  // and save initial route in localStorage.
  // after login redirect to this route.
  if (ddLoginRoute) {
    return (
      <Switch>
        <Redirect exact from="/demo-day/current/companies" to={ddLoginRoute} />
      </Switch>
    );
  }

  return (
    <Switch>
      {aaClass?.number
        ? <Redirect exact from="/demo-day/current/companies" to={`/demo-day/${ddRoute}/${aaClass.number}/${PAGE_ID_TO_URL[pageId]}`} />
        : <Redirect to="/" />}
    </Switch>
  );
};

DemoDayCurrentPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ pageId: PropTypes.string }) }),
  myProfile: PropTypes.object,
  aaClassFromEmailSignIn: PropTypes.object,
  aaClassFromLinkedinSignIn: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  myProfile: makeSelectProfile(),
  aaClassFromEmailSignIn: makeSelectAlchemistClass(),
  aaClassFromLinkedinSignIn: selectClassFromLinkedinSignIn,
});

export default connect(mapStateToProps)(DemoDayCurrentPage);

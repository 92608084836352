import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useIsRequiredFromSchema, useLabelFromMapper } from 'containers/Forms/hooks';
import TooltipPrim from 'components/Tooltips/TooltipPrim';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { VARIETY } from 'containers/App/constants';


const FormLabel = ({
  label: labelProp, required, type, isHide, className, meta, width, small, isBold, fieldName, isLabelFirst, labelAdditionalComponent, isAdminField, iAmAdmin,
}) => {
  const requiredInSchema = useIsRequiredFromSchema(fieldName);
  const isRequired = required || requiredInSchema;
  const labelFromMapper = useLabelFromMapper(fieldName);
  const label = labelProp === undefined ? labelFromMapper : labelProp;

  const classes = classNames(
    className,
    type,
    width && `dsa-width__${width}`,
    'dsa-form-label',
    {
      '_small-heading_': small,
      'dsa-required': isRequired || isBold,
      'd-none': isHide,
      _error_: meta && (meta.dirty || meta.touched) && meta.error,
    }
  );

  return (
    <Label className={classes}>
      {isRequired && !isLabelFirst &&
        <span className="dsa-form-asterisk">
          <TooltipPrim
            className="d-inline"
            text={isAdminField ? 'Required for admins' : 'Required for non-admins'}
            tooltipClassName="tooltip-asterisk"
            placement="top-start"
            alwaysNeeded={iAmAdmin}
          >
            *
          </TooltipPrim>
        </span>
      }
      {label}
      {isRequired && isLabelFirst &&
        <span className="dsa-form-asterisk _after_ ms-2">
          <TooltipPrim
            className="d-inline"
            text={isAdminField ? 'Required for admins' : 'Required for non-admins'}
            tooltipClassName="tooltip-asterisk"
            placement="top-start"
            alwaysNeeded={iAmAdmin}
          >
            *
          </TooltipPrim>
        </span>
      }
      {labelAdditionalComponent && labelAdditionalComponent}
    </Label>
  );
};

FormLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  small: PropTypes.bool,
  isBold: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  isHide: PropTypes.bool,
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  isLabelFirst: PropTypes.bool,
  labelAdditionalComponent: PropTypes.element,
  isAdminField: PropTypes.bool,
  iAmAdmin: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  iAmAdmin: makeSelectUserIs(VARIETY.admin),
});

export default connect(mapStateToProps)(FormLabel);

export const DELETE_ADVISOR_OR_POC = 'alchemist/Forms/Company/DELETE_ADVISOR_OR_POC';
export const SUBMIT_COMPANY_HEADER_FORM = 'alchemist/Forms/Company/SUBMIT_COMPANY_HEADER_FORM';
export const SUBMIT_COMPANY_STATUS_FORM = 'alchemist/Forms/Company/SUBMIT_COMPANY_STATUS_FORM';

export const WILL_NOT_PARTICIPATE_DD_OPT = {
  label: 'Will not participate in future Demo Day',
  value: 'will_not_participate',
};

// $alchemist-blue in _style-constants.scss
export const DEFAULT_PRIMARY_ALCHEMIST_COLOR_HEX = '#03273B';
export const DEFAULT_PRIMARY_ALCHEMIST_COLOR = '03273B';

// $teal-link in _style-constants.scss
export const DEFAULT_LINK_COLOR_HEX = '#099BB8';
export const DEFAULT_LINK_COLOR = '099BB8';

export const THEME_TYPES = {
  alchemist: 1,
  custom: 2,
};

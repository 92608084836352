import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { ButtonGreyPlain } from '../ButtonLinkSec';

const ArchivedListsButton = ({ className, to }) => (
  <ButtonGreyPlain to={to || '/people'} className={classNames(className || '')}>
    <FontAwesomeIcon icon={faFolderOpen} className="me-3" />
    Open Archived lists
  </ButtonGreyPlain>
);

ArchivedListsButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
};

export default ArchivedListsButton;

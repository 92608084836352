import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownToggle, NavLink } from 'reactstrap';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIntercom } from '@fortawesome/free-brands-svg-icons/faIntercom';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import classNames from 'classnames';

import DropdownSuperClass from 'components/Dropdown/DropdownSuperClass';
import { SideBarItem } from 'components/SideBar/SideBarItem';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import ContactUsButton from 'containers/Gdpr/ContactUsButton';


class HelpMenu extends DropdownSuperClass {
  render() {
    return (
      this.props.isDesktop
        ? (
          <div className="dsa-help-menu">
            <SideBarItem className="nav-link p-0">
              <Dropdown
                className="dsa-dropdown-my-account"
                direction="right"
                isOpen={this.state.isDropdownOpen}
                toggle={this.toggleDropdown}
                trigger="legacy"
              >
                <DropdownToggle
                  className={classNames('', { active: this.state.isDropdownOpen })}
                  tag="button"
                >
                  <FontAwesomeIcon icon={faQuestionCircle} size="lg" fixedWidth />
                  <span className="dsa-hidden-opacity__transition">Help</span>
                </DropdownToggle>
                <DropdownMenu className="py-5 px-6 _no-hover_ position-fixed">
                  <ContactUsButton buttonTag={NavLink} onClickExtra={this.closeDropdown} />
                  <NavLink tag={NavLinkReact} to="/gdpr" onClick={this.closeDropdown}>GDPR</NavLink>
                </DropdownMenu>
              </Dropdown>
            </SideBarItem>
          </div>)
        : (
          <>
            <li className="dsa-site-sidebar_item">
              <ContactUsButton buttonTag={NavLink} onClickExtra={this.closeDropdown}>
                <FontAwesomeIcon icon={faIntercom} size="lg" fixedWidth />
              </ContactUsButton>
            </li>
            <li className="dsa-site-sidebar_item">
              <NavLink tag={NavLinkReact} to="/gdpr" onClick={this.closeDropdown}>
                <FontAwesomeIcon icon={faLock} size="lg" fixedWidth />GDPR
              </NavLink>
            </li>
          </>)
    );
  }
}

HelpMenu.propTypes = {
  isDesktop: PropTypes.bool,
};

export default ResponsiveHoC(HelpMenu);

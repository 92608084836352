import { DD_PAGE_ID } from '../DemoDay/constants';

export const FLOW_TYPE = {
  register: 'register',
  demoDayInvitation: 'demoDayInvitation',
  demoDayLivestream: 'demoDayLivestream',
  demoDayClassWebsite: 'demoDayClassWebsite',
  demoDayDryRun: 'demoDayDryRun',
  standard: 'standard',
  onboarding: 'onboarding',
  lmsLogin: 'lmsLogin',
  ifs: 'ifs',
  demoDaySignIn: 'demoDaySignIn',
};

export const FLOW_TYPE_TO_URL = {
  [FLOW_TYPE.register]: '/register-profile/form',
  [FLOW_TYPE.demoDayInvitation]: '/demo-day/attendance',
  [FLOW_TYPE.demoDayLivestream]: `/demo-day/current/${DD_PAGE_ID.livestream}`,
  [FLOW_TYPE.demoDayClassWebsite]: `/demo-day/current/${DD_PAGE_ID.companies}`,
  [FLOW_TYPE.demoDayDryRun]: `/demo-day/current/${DD_PAGE_ID.companies}`,
  [FLOW_TYPE.demoDaySignIn]: `/demo-day/current/${DD_PAGE_ID.companies}`,
  [FLOW_TYPE.standard]: '/',
  [FLOW_TYPE.onboarding]: '/onboarding',
  [FLOW_TYPE.lmsLogin]: '/?rest_route=/simple-jwt-login/v1/autologin',
  [FLOW_TYPE.ifs]: '/ifs/attendance',
};
export const FLOW_TYPE_TO_FORM_TITLE = {
  [FLOW_TYPE.register]: 'to create your Alchemist profile',
  [FLOW_TYPE.demoDayInvitation]: 'to register to the Demo Day',
  [FLOW_TYPE.demoDayLivestream]: 'to view the Demo Day Livestream',
  [FLOW_TYPE.demoDayClassWebsite]: 'to get a Sneak Peek of the Demoing Class Companies',
  [FLOW_TYPE.demoDayDryRun]: 'to participate in the Demoing Class Dry Run',
  [FLOW_TYPE.demoDaySignIn]: 'to the Demo Day',
  [FLOW_TYPE.onboarding]: 'for Founder Onboarding',
  [FLOW_TYPE.standard]: '',
  [FLOW_TYPE.lmsLogin]: 'for the LMS system',
  [FLOW_TYPE.ifs]: 'for the Investor Feedback Summit',
};

export const DEMODAY_INVITE = 'demodayinvite';

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ButtonsHolder from 'components/Buttons/ButtonsHolder';
import OnePager from 'components/OnePager/OnePager';
import { Text } from 'components/Text/Body';
import { sendRestoreAccessEmail } from 'containers/RegisterProfile/actions';
import reducer from 'containers/RegisterProfile/reducer';
import saga from 'containers/RegisterProfile/saga';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

// eslint-disable-next-line react/prop-types
const RegisterProfile = ({ match: { params: { email } }, sendRestoreMail }) => {
  React.useEffect(() => sendRestoreMail(email), []);
  return (
    <OnePager title="Get Access to Existing Profile!" medHeading>
      <Text lh="double">Please check your <b>{email}</b> inbox for an email with further instructions.
        If you have any questions, please use the Intercom bubble in the right bottom corner to contact us.
      </Text>
      <ButtonsHolder className="py-5">
        Didn’t receive the email?
        <button className="dsa-button btn-link dsa-anchor-link" onClick={() => sendRestoreMail(email)}>
          Send again
        </button>
      </ButtonsHolder>
    </OnePager>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendRestoreMail: (email) => dispatch(sendRestoreAccessEmail(email)),
});

const withConnect = connect(null, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'registerProfile',
  reducer,
});
const withSaga = injectSaga({
  key: 'registerProfile',
  saga,
});

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(RegisterProfile);

import { stringify } from 'qs';
import isObject from 'lodash/isObject';
import { cloneDeep, uniqBy } from 'lodash';

import { FILTER_OPERATORS } from 'containers/People/Search/Filters/constants';

import { toJS } from '../general';
import { AND_OP, EQ_OP, NE_OP, SEARCH_NOT_OP, SEARCH_OP } from './constants';
import { isReferable, resRef } from '../refs';
import { SORTING_A_TO_Z_MAPPER, SORTING_MAPPER } from '../../containers/People/Search/constants';

export function generateFiltersLocalString(filtersImmutable) {
  const filters = {};
  const filter = filtersImmutable.get('appliedFilters').filter((x) => !!x && x.size !== 0);
  if (filter.size > 0) {
    filters.filter = filter.toJS();
  }

  if (filtersImmutable.get('sort').size > 0) {
    filters.sort = filtersImmutable.get('sort').toJS();
  }

  const filtersString = stringify(filters);
  return filtersString ? `?${filtersString}` : '';
}

export function generateFiltersString(filtersImmutable, sort, page, firstChar, advFiltersOperator, fopOverride, _count, pageSize) {
  const filters = {};
  const filter = filtersImmutable
    && filtersImmutable.filter((x) => x === null || x.size !== 0).map((x) => x === null ? 'None' : x);

  if (filter && filter.size > 0) {
    filters.filter = filter.toJS();
  }
  if (sort && sort.size > 0) {
    // Turns { 'k1': 1, 'k2': -1} into ['k1', '-k2'] (reduce step) and then into 'k1,-k2'
    filters.sort = sort.reduce((reduction, v, k) => [...reduction, makeSortString(k, v)], []).join(',');
    if (filters.filter && Object.keys(filters.filter)[0].endsWith(':similar')) {
      const firstField = Object.keys(filters.filter)[0].replace(':similar', '').split(',')[0];
      filters.sort = `-similar[${firstField}]=${Object.values(filters.filter)[0]},${filters.sort}`;
    }
  }
  if (fopOverride) {
    filters.fop = fopOverride;
  } else if (advFiltersOperator) {
    filters.fop = { '_0:and:_1': AND_OP, _1: advFiltersOperator };
  }

  if (page) {
    filters.page = { number: page + 1 };
  }

  if (pageSize) {
    filters.page = { ...(filters.page || {}), size: pageSize };
  }

  if (_count) {
    // eslint-disable-next-line no-underscore-dangle
    filters._count = _count;
  }

  const filtersString = stringify(filters, { indices: false });
  return filtersString ? `${firstChar}${filtersString}` : '';
}

export const makeSortString = (sortKey, order) => `${order === -1 ? '-' : ''}${sortKey}`;

/**
 * An operator prepended by a '+' can be used to switch the ',' in the field to '+'
 * (switches from or to and in that filter)
 */
export function processAdvancedFilters(advancedFiltersImm, filtersImm) {
  if (!advancedFiltersImm.size) {
    return filtersImm;
  }
  let newFiltersImm = filtersImm;
  advancedFiltersImm.forEach((vImmutable) => {
    // if ever an array is a possible value, it'd have to be prestringified, to avoid confusion with multiple filters
    // const value = Array.isArray(v.value) ? JSON.stringify(v.value) : v.value;
    const v = toJS(vImmutable);
    let groupedField = v.field.match(/^_\d+:/) ? v.field : `_1:${v.field}`;
    const { 1: targetField, 2: operator } = groupedField.match(/^_\d+:([\w,.+]+):(.+)$/);
    if (operator[0] === '+') {
      groupedField = groupedField.replace(':+', ':').replace(',', '+');
    }

    const currentValue = newFiltersImm.get(groupedField);
    let valueToSet = v.value;

    // Support for results from /autocomplete ({ [field]: 'STR', count: number })
    // ToDo: look at this in some time
    if (valueToSet && isObject(valueToSet) && targetField in valueToSet) {
      valueToSet = valueToSet[targetField];
      // Support for object refs
    } else if (isReferable(valueToSet)) {
      valueToSet = JSON.stringify(resRef(valueToSet));
    }
    if (typeof currentValue !== 'undefined') {
      newFiltersImm = newFiltersImm.set(
        groupedField,
        Array.isArray(currentValue) ? [...currentValue, valueToSet] : [currentValue, valueToSet]
      );
    } else {
      newFiltersImm = newFiltersImm.set(groupedField, valueToSet);
    }
  });
  // newFiltersImm = newFiltersImm.map((v, k) => k.match(/:in$/) ? JSON.stringify(castToArray(v)) : v);
  return newFiltersImm;
}

export const groupMatch = (group) => (k) => k.match(`^_${group}:`);
export const groupMatchFilter = (group) => (v) => groupMatch(group)(toJS(v).field);
export const keyMatch = (key, group) => (k) => k.match(`^${group || group === 0 ? `_${group}:` : ''}${key}:`);
export const keyMatchFilter = (key, group) => (v) => keyMatch(key, group)(toJS(v).field);
export const extractColumn = (filterItem) => filterItem.field && filterItem.field.split(':')[0];
export const extractColumnAfterGroup = (filterItem) => filterItem.field && filterItem.field.split(':')[1];
export const extractGroupAndColumn = (filterItem) => filterItem.field && `${filterItem.field.split(':')[0]}:${filterItem.field.split(':')[1]}`;
export const addSuperField = (superField) => (field) => `${superField}.${field}`;
export const isFilterIncludesGroup = (appliedFilter) => appliedFilter.field.match(/:/g)?.length > 1 && appliedFilter.field.startsWith('_');

export const peopleSearchLabeler = (sortObj) => SORTING_A_TO_Z_MAPPER[sortObj.sortKey]
  ? `${SORTING_A_TO_Z_MAPPER[sortObj.sortKey]} ${sortObj.order === -1 ? 'Z to A' : 'A to Z'}`
  : SORTING_MAPPER[makeSortString(sortObj.sortKey, sortObj.order)];

const generateRegex = (operator) => new RegExp(`:${operator}$`);

export const checkOptionOperator = (filter, checkedOperator = EQ_OP, uncheckedOperator = NE_OP) => filter.field.match(generateRegex(checkedOperator)) || filter.field.match(generateRegex(uncheckedOperator));
export const isSearch = (filter) => checkOptionOperator(filter, SEARCH_OP, SEARCH_NOT_OP);
export const isEqOrNe = (filter) => checkOptionOperator(filter);

const findCurrentFilterOptionOperator = ({ individualOptionsOperators, filterGroup, value }) => individualOptionsOperators[`_${filterGroup}`]?.find((item) => item.filterValue === value)?.operator;

export const onFilterSelect = ({ value, individualOptionsOperators, filterGroup, filterAction, filterKey, operator, checkedOperator = EQ_OP, uncheckedOperator = NE_OP }) => {
  const currentOptionOperator = findCurrentFilterOptionOperator({ individualOptionsOperators, filterGroup, value });
  const currentOperator = currentOptionOperator === checkedOperator || !currentOptionOperator ? checkedOperator : uncheckedOperator;
  const operatorToUse = operator === FILTER_OPERATORS.ENCLOSED ? operator : currentOperator;
  filterAction(`_${filterGroup}:${filterKey}:${operatorToUse}`, value);
};

export const onFilterSelectContains = ({ value, individualOptionsOperators, filterGroup, filterAction, filterKey, checkedOperator = SEARCH_OP, uncheckedOperator = SEARCH_NOT_OP }) => {
  const currentOptionOperator = findCurrentFilterOptionOperator({ individualOptionsOperators, filterGroup, value });
  // last argument 'true' is needed to mark click on 'contains' option (isContains)
  filterAction(`_${filterGroup}:${filterKey}:${currentOptionOperator === checkedOperator || !currentOptionOperator ? checkedOperator : uncheckedOperator}`, value, true);
};

export const moveOptions = (options, appliedValues, field) => {
  if (options) {
    if (appliedValues) {
      const optionsToShow = cloneDeep(options);
      options.forEach((opt) => {
        if (appliedValues.includes(opt[field])) {
          optionsToShow.unshift(opt);
        }
      });
      return uniqBy(optionsToShow, field);
    }
    return options;
  }
  return [];
};

export const moveOptionsSimple = (options, appliedValues) => {
  if (!options.length) return [];
  const remainingOptions = options.filter((option) => !appliedValues.includes(option));
  return [...appliedValues, ...remainingOptions];
};

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { NavLink } from 'reactstrap';


const SideBarBrand = ({ to, label }) => (
  <div className="dsa-site-sidebar__header pb-5">
    <NavLink tag={NavLinkReact} className="dsa-brand" to={to}>
      <span className="dsa-brand-name">{label}</span>
    </NavLink>
  </div>
);

SideBarBrand.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

export default SideBarBrand;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';


const ResetButton = ({ onClick }) => (
  <button className="dsa-info-icon__button me-3" onClick={onClick}><FontAwesomeIcon icon={faTimesCircle} type='button' /></button>
);

ResetButton.propTypes = {
  onClick: PropTypes.func,
};

export default ResetButton;

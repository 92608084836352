/*
 *
 * PersonModal constants
 *
 */

export const OPEN_PERSON_MODAL = 'alchemist/People/PersonModal/OPEN_PERSON_MODAL';
export const SET_PERSON_MODAL_SECTION = 'alchemist/People/PersonModal/SET_PERSON_MODAL_SECTION';
export const CLOSE_MODAL = 'alchemist/People/PersonModal/CLOSE_MODAL';
export const SET_MESSAGE = 'alchemist/People/PersonModal/SET_MESSAGE';
export const SET_MESSAGE_REF = 'alchemist/People/PersonModal/SET_MESSAGE_REF';
export const SET_TOPICS = 'alchemist/People/PersonModal/SET_TOPICS';
export const SEND_MESSAGE = 'alchemist/People/PersonModal/SEND_MESSAGE';
export const SEND_FOLLOW_UP = 'alchemist/People/PersonModal/SEND_FOLLOW_UP';
export const MESSAGE_SENT_ACTION = 'alchemist/People/PersonModal/MESSAGE_SENT';
export const FOLLOW_UP_SENT_ACTION = 'alchemist/People/PersonModal/FOLLOW_UP_SENT_ACTION';
export const SET_MESSAGE_PARTS = 'alchemist/People/PersonModal/SET_MESSAGE_PARTS';
export const VIEW_QUOTA_DETAILS = 'alchemist/People/PersonModal/VIEW_QUOTA_DETAILS';

export const SEND_REVIEW = 'alchemist/People/PersonModal/SEND_REVIEW';
export const REVIEW_SENT = 'alchemist/People/PersonModal/REVIEW_SENT';
export const SET_REVIEW = 'alchemist/People/PersonModal/SET_REVIEW';
export const SET_REVIEW_ANONYMOUS = 'alchemist/People/PersonModal/SET_REVIEW_ANONYMOUS';
export const SET_REVIEW_RATING = 'alchemist/People/PersonModal/SET_REVIEW_RATING';
export const SET_COMMENT_TEXT = 'alchemist/People/PersonModal/SET_COMMENT_TEXT';

export const SAVE_LIST_CHANGES = 'alchemist/People/PersonModal/SAVE_LIST_CHANGES';
export const SET_LIST_CHANGE = 'alchemist/People/PersonModal/SET_LIST_CHANGE';
export const RESET_LIST_CHANGES = 'alchemist/People/PersonModal/RESET_LIST_CHANGES';
export const REMOVE_LIST_FROM_LISTS_CHANGES = 'alchemist/People/PersonModal/REMOVE_LIST_FROM_LISTS_CHANGES';

export const MESSAGE_DRAFT = 'MESSAGE_DRAFT';
export const MESSAGE_PREVIEW = 'MESSAGE_PREVIEW';
export const MESSAGE_SENT = 'MESSAGE_SENT';
export const ADD_REVIEW = 'ADD_REVIEW';
export const ADD_TO_LIST = 'ADD_TO_LIST';
export const REPORT_AN_ISSUE = 'REPORT_AN_ISSUE';
export const ADMIN_CONNECT_DRAFT = 'ADMIN_CONNECT_DRAFT';
export const ADMIN_CONNECT_SENT = 'ADMIN_CONNECT_SENT';

export const OPEN_THREAD_MESSAGE = 'alchemist/People/PersonModal/alchemist/People/PersonModal/OPEN_PERSON_MODAL';
export const SET_THREAD_MESSAGE_LOADING = 'alchemist/People/PersonModal/alchemist/People/PersonModal/SET_THREAD_MESSAGE_LOADING';
export const SET_THREAD_MESSAGE_LOADED = 'alchemist/People/PersonModal/alchemist/People/PersonModal/SET_THREAD_MESSAGE_LOADED';

export const SENTINEL_DATE_VALUE = '0001-01-01T00:00:00';
export const SENTINEL_DATE_TOOLTIP_TEXT = 'Displaying approximate dates due to unavailability of exact ones';

import styled from 'styled-components';

import OnePagerContent from 'components/OnePager/OnePagerContent';
import OnePagerHeader from 'components/OnePager/OnePagerHeader';

const MOBILE_PADDING_M = 32;
const MOBILE_PADDING_SM = 16;

const StyledOnePagerContent = styled(OnePagerContent)`
  .dsa-scrollbars-view {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ul li, p:not(.dsa-text-body) {
    color: #395564;
    font-size: 1rem;
  }
`;

const Header = styled(OnePagerHeader)`
  background-color: #F0F4F8;
  text-align: center;
  padding-left: 21px;
  padding-right: 21px;

  .dsa-heading {
    font-weight: 700;
  }

  @media (min-width: 992px) {
    .dsa-heading._large_ {
      font-size: 40px;
      line-height: 40px;
  
      + p {
        font-size: 20px;
        margin: 0;
      }
    }
  }
`;

const Content = styled('div')`
  flex-direction: column;
  align-items: center;
  padding: ${MOBILE_PADDING_SM}px ${MOBILE_PADDING_SM}px 0 ${MOBILE_PADDING_SM}px;

  @media (min-width: 992px) {
    flex-direction: row;
    padding: 48px 86px;
  }
`;

const Card = styled('div')`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50%;

  .dsa-heading {
    font-weight: 600;
  }

  p {
    max-width: 460px;

    &.dsa-text-body {
      font-weight: 600;
      width: 100%;
      text-align: left; 
    }
  }

  ul {
    list-style-type: none;

    li {
      border: 1px solid #F0F4F8;
      box-shadow: 4px 4px 12px rgba(3, 39, 59, 0.08);
      border-radius: 8px;
      flex-direction: row;
      padding: ${MOBILE_PADDING_SM}px;
      margin-bottom: ${MOBILE_PADDING_SM}px;
      font-size: 14px;
    }
  }

  .dsa-list-avatar {
    background-color: #F0F4F8;
    width: 48px;
    height: 48px;
    flex-shrink: 0;

    svg {
      margin: 0;
    }
  }

  .dsa-ifs-time {
    color: #8EA2B6;
    flex-shrink: 0;
    margin-left: 14px;
    font-weight: 600;
  }

  @media (min-width: 576px) {
    ul li {
      align-items: center;
      padding: 24px;
      border-radius: ${MOBILE_PADDING_SM}px;

      > div {
        align-items: center;
      }
    }

    h2._md-new_ {
      font-size: 24px;
    }
  }
`;

const CardWithBg = styled(Card)`
  width: 100%;
  background-color: #F1F4F8;
  font-weight: 400;
  padding: ${MOBILE_PADDING_M}px;
  margin-bottom: ${MOBILE_PADDING_M}px;
  border-radius: 16px;
  text-align: center;
  margin-top: 64px;
  font-size: 20px;

  @media (min-width: 992px) {
    margin-bottom: 0;
    padding: 88px;
  }
`;

const FormatText = styled('div')`
    color: #62778C;
    text-align: center;
    font-size: 16px;
`;

export {
  StyledOnePagerContent,
  Header,
  Content,
  Card,
  CardWithBg,
  FormatText,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

const ModalSimple = ({
  className, children, size, isMobileTablet, fullscreen, onCloseIconClick, showCloseButton, ...props
}) => (
  <Modal
    className={
      classNames(
        'dsa-simple-modal modal-dialog-centered',
        className,
        size && `modal-${size}`,
        { 'is-mobile-tablet': isMobileTablet, _fullscreen_: fullscreen }
      )}
    {...omit(props, ['screenWidth', 'screenSize', 'isMobile', 'isTablet', 'isDesktop'])}
  >
    <>
      {showCloseButton &&
        <div className="dsa-action-buttons dsa-modal__close">
          <button onClick={onCloseIconClick} className="close" type="button" aria-label="Close">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>}
      {children}
    </>
  </Modal>
);

ModalSimple.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  isMobileTablet: PropTypes.bool,
  children: PropTypes.any,
  fullscreen: PropTypes.bool,
  onCloseIconClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

export default ResponsiveHoC(ModalSimple);

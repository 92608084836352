/*
 *
 * RegisterProfile actions
 *
 */

import {
  INITIAL_LOAD, SET_STEP, FINISH_REGISTRATION,
  SET_REGISTERING_USER, SET_UNAUTH_HASH, SET_IS_CHECKING_USER,
  SAVE_PERSONAL_STEP, SAVE_PERSONAL_MORE_STEP, SAVE_COMPANY_STEP, SAVE_ROLES_STEP,
  ADD_NEW_COMPANIES_IDS,
  SET_LINKEDIN_VALUES, DELETE_LINKEDIN_VALUE,
  // SET_SHOW_IMPORT_SUCCESS, SET_SHOW_IMPORT_ERROR,
  SET_SHOW_ERROR_SUMMARY, GO_BACK_TO_FIRST_STEP,
  SEND_RESTORE_ACCESS_EMAIL, SAVE_STEP_TWO, GET_NEW_PRIMARY_COMPANY, SET_NEW_PRIMARY_COMPANY,
  LOAD_USER_BY_REF,
} from './constants';

// ToDO: finish linkedin clean-up
export const initialLoad = () => ({ type: INITIAL_LOAD });
export const finishRegistration = () => ({ type: FINISH_REGISTRATION });
export const setImportedValues = (importedValues) => ({ type: SET_LINKEDIN_VALUES, importedValues });
export const deleteLinkedInValue = (key) => ({ type: DELETE_LINKEDIN_VALUE, key });
// export const setShowImportSuccess = (mustShow) => ({ type: SET_SHOW_IMPORT_SUCCESS, mustShow });
export const setShowErrorSummary = (mustShow) => ({ type: SET_SHOW_ERROR_SUMMARY, mustShow });

// export const setShowImportError = (mustShow, allowToGoOn) => ({ type: SET_SHOW_IMPORT_ERROR, mustShow, allowToGoOn });
export const setRegisteringUser = (registeringUser) => ({ type: SET_REGISTERING_USER, registeringUser });
export const setStep = (step) => ({ type: SET_STEP, step });
export const goBackToFirstStep = () => ({ type: GO_BACK_TO_FIRST_STEP });
export const setUnauthHash = (unauthHash) => ({ type: SET_UNAUTH_HASH, unauthHash });
export const setIsCheckingUser = (isCheckingUser) => ({ type: SET_IS_CHECKING_USER, isCheckingUser });
export const addNewCompaniesIds = (newCompaniesIds) => ({ type: ADD_NEW_COMPANIES_IDS, newCompaniesIds });
export const getNewPrimaryCompanyExp = (companyId) => ({ type: GET_NEW_PRIMARY_COMPANY, companyId });
export const setNewPrimaryCompanyExp = (company) => ({ type: SET_NEW_PRIMARY_COMPANY, company });
export const loadUserByRef = () => ({ type: LOAD_USER_BY_REF });

export const savePersonalStep = (values, section, objRef, successAction) => ({
  type: SAVE_PERSONAL_STEP, values, section, objRef, successAction,
});
export const savePersonalMoreStep = (values, section, objRef, successAction) => ({
  type: SAVE_PERSONAL_MORE_STEP, values, section, objRef, successAction,
});
export const saveCompanyStep = (values, section, objRef, successAction) => ({
  type: SAVE_COMPANY_STEP, values, section, objRef, successAction,
});
export const saveRolesStep = (values, section, objRef, successAction) => ({
  type: SAVE_ROLES_STEP, values, section, objRef, successAction,
});

export const saveStepTwoShortForm = (values, section, objRef, successAction) => ({
  type: SAVE_STEP_TWO, values, section, objRef, successAction,
});

export const sendRestoreAccessEmail = (email) => ({ type: SEND_RESTORE_ACCESS_EMAIL, email });

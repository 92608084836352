import React from 'react';
import PropTypes from 'prop-types';

import OnePagerMain from 'components/OnePager/OnePagerMain';

import LoadingTextBase from './LoadingTextBase';


const LoadingPage = ({ text, className }) => (
  <OnePagerMain className={`dsa-background-sec center-content-x-y ${className}`}>
    <LoadingTextBase text={text} />
  </OnePagerMain>
);

LoadingPage.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default LoadingPage;

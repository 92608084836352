import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'reactstrap';

import LinkTo from 'components/BasicElements/LinkTo';
import { ButtonGreyPlain } from 'components/Buttons/ButtonLinkSec';
import { TitleMd } from 'components/Text/Titles';

const ResourceCard = ({
  resource: {
    to, href, target, title, links, description, onClick,
  },
}) => (
  <Col className="dsa-program__card col-lg-4 col-md-6 col-12">
    <div className="d-flex border-bottom justify-content-between py-3">
      <TitleMd className="">{title}</TitleMd>
      <ButtonGreyPlain onClick={onClick} href={href} to={to} target={target}>View All</ButtonGreyPlain>
    </div>
    <div className="d-flex flex-column pt-5 _color-sec_">
      {description || (
        links && links.map((item) => (
          <LinkTo key={item.title} inv darkerBlue className="pb-4" href={item.href} to={item.to} target={item.target}>
            {item.title}
          </LinkTo>)))}
    </div>
  </Col>
);

ResourceCard.propTypes = {
  resource: PropTypes.object,
};

export default ResourceCard;

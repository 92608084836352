import { createSelector } from 'reselect';
import pick from 'lodash/pick';
import { isValid, hasSubmitFailed, getFormSyncErrors, getFormSubmitErrors } from 'redux-form/immutable';

import { makeSelectObjectFromRefSelector, makeSelectQueryParam } from 'containers/App/selectors';
import { getFormRegisteredFields, makeSelectIsFormSubmitting, makeSelectFormFieldPositions } from 'containers/Forms/selectors';
import { hasRoleOneOf } from 'utils/fieldProcessors';
import { toJS } from 'utils/general';
import { makeSubSelector } from 'utils/selectors';

import reducer from './reducer';
import { STEP_COMPANIES, STEP_PERSONAL, STEP_PERSONAL_MORE, STEP_ROLES } from './constants';
import { makeSelectProfile } from '../AuthProvider/selectors';

/**
 * Direct selector to the registerProfile state domain
 */
const selectRegisterProfileDomain = (state) => state.get('registerProfile', reducer(undefined, {}));

/**
 * Other specific selectors
 */

const makeSelectImportedValues = (field) => createSelector(
  selectRegisterProfileDomain,
  (substate) => toJS(substate.getIn(['importedValues', field]))
);

// const selectMustShowImportSuccess = makeSubSelector(selectRegisterProfileDomain, ['mustShowImportSuccess']);
// const selectMustShowImportError = makeSubSelector(selectRegisterProfileDomain, ['mustShowImportError']);
const selectCanGoOnAfterImportError = makeSubSelector(selectRegisterProfileDomain, ['canGoOnAfterImportError']);
const selectMustShowErrorSummary = makeSubSelector(selectRegisterProfileDomain, ['mustShowErrorSummary']);
const selectNewPrimaryCompany = makeSubSelector(selectRegisterProfileDomain, ['primaryCompanyExp']);


const makeSelectRegisteringUserRef = () => createSelector(
  selectRegisterProfileDomain,
  (substate) => substate.get('registeringUser')
);

const makeSelectRegisteringUser = () => makeSelectObjectFromRefSelector(makeSelectRegisteringUserRef());
const selectIsImpersonate = createSelector(
  makeSelectRegisteringUser(),
  makeSelectProfile(),
  (registeringUser, profile) => profile && registeringUser && registeringUser.id !== profile.id
);

const makeSelectEditedCompany = () => createSelector(
  makeSelectRegisteringUser(),
  (registeringUser) => registeringUser
    && registeringUser.primaryMembership && registeringUser.primaryMembership().company()
);

const makeSelectCurrentStep = () => createSelector(
  selectRegisterProfileDomain,
  (substate) => substate.get('step')
);

const makeSelectIsCurrentStepValid = () => createSelector(
  makeSelectCurrentStep(),
  (state) => state,
  (formName, state) => isValid(formName)(state),
);

const makeSelectIsCurrentStepSubmitting = () => createSelector(
  makeSelectCurrentStep(),
  (state) => state,
  (currentStep, state) => makeSelectIsFormSubmitting(currentStep)(state)
);

const makeSelectHasSubmitFailedInCurrentStep = () => createSelector(
  makeSelectCurrentStep(),
  (state) => state,
  (formName, state) => hasSubmitFailed(formName)(state),
);

const makeSelectCurrentStepErrors = () => createSelector(
  makeSelectCurrentStep(),
  (state) => state,
  (formName, state) => {
    const formRegisteredFields = getFormRegisteredFields(formName)(state);
    if (!formRegisteredFields) return {};

    return pick({
      ...toJS(getFormSyncErrors(formName)(state) || {}),
      ...toJS(getFormSubmitErrors(formName)(state) || {}),
    }, Object.keys(formRegisteredFields));
  },
);

const selectCurrentStepFieldsPos = createSelector(
  makeSelectCurrentStep(),
  (state) => state,
  (formName, state) => makeSelectFormFieldPositions(formName)(state)
);

const makeSelectShouldShowCompanyStep = () => createSelector(
  makeSelectEditedCompany(),
  makeSelectRegisteringUser(),
  (company, registeringUser) => company
    && hasRoleOneOf(registeringUser, ['investor', 'service_provider', 'strategic_investor'])
);

const selectShouldShowContentInRolesStep = createSelector(
  makeSelectRegisteringUser(),
  (registeringUser) => registeringUser
    && (hasRoleOneOf(registeringUser, ['investor', 'mentor', 'press', 'angel', 'consultant', 'lecturer', 'acquirer', 'strategic_investor']) || registeringUser.fmp)
);

const selectIsEmptyStepRole = createSelector(
  selectShouldShowContentInRolesStep,
  makeSelectCurrentStep(),
  (shouldShowContentInRolesStep, currentStep) => !shouldShowContentInRolesStep && currentStep === STEP_ROLES
);

const makeSelectShouldShowRolesStep = () => createSelector(
  selectShouldShowContentInRolesStep,
  makeSelectShouldShowCompanyStep(),
  (shouldShowContentInRolesStep, shouldShowCompanyStep) => shouldShowContentInRolesStep || !shouldShowCompanyStep
);

// ToDo: Fix this
const STEPS_ORDER = [STEP_PERSONAL, STEP_PERSONAL_MORE, STEP_ROLES, STEP_COMPANIES];
const makeSelectPreviousStep = () => createSelector(
  makeSelectCurrentStep(),
  makeSelectShouldShowRolesStep(),
  (step, shouldShowRoleStep) => {
    if (step === STEP_COMPANIES && !shouldShowRoleStep) return STEP_PERSONAL_MORE;

    const currentStepOrder = STEPS_ORDER.indexOf(step);
    return currentStepOrder > 0 ? STEPS_ORDER[currentStepOrder - 1] : false;
  }
);

const makeSelectUnauthHash = () => createSelector(
  selectRegisterProfileDomain,
  (substate) => substate.get('unauthHash')
);

const selectSpecialFlow = makeSelectQueryParam('flow');
const selectFormQueryParam = makeSelectQueryParam('fmat');

const makeSelectIsCheckingUser = () => createSelector(
  selectRegisterProfileDomain,
  (substate) => substate.get('isCheckingUser')
);

export {
  selectRegisterProfileDomain,
  makeSelectCurrentStep,
  makeSelectPreviousStep,
  makeSelectIsCheckingUser,
  makeSelectRegisteringUserRef,
  makeSelectRegisteringUser,
  makeSelectEditedCompany,
  makeSelectUnauthHash,
  makeSelectIsCurrentStepValid,
  makeSelectHasSubmitFailedInCurrentStep,
  selectShouldShowContentInRolesStep,
  selectIsEmptyStepRole,
  makeSelectShouldShowRolesStep,
  makeSelectShouldShowCompanyStep,
  makeSelectImportedValues,
  // selectMustShowImportSuccess,
  // selectMustShowImportError,
  selectMustShowErrorSummary,
  makeSelectCurrentStepErrors,
  makeSelectIsCurrentStepSubmitting,
  selectCanGoOnAfterImportError,
  selectCurrentStepFieldsPos,
  selectSpecialFlow,
  selectIsImpersonate,
  selectFormQueryParam,
  selectNewPrimaryCompany,
};

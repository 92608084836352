import React, { useState, useEffect } from 'react';
import { ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { VARIETY } from 'containers/App/constants';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';

import ModalSimple from './ModalSimple';

const AdminMobileAlertModal = ({ isMobileTablet, userIsAdmin }) => {
  const [modalIsVisible, setModalIsVisible] = useState(isMobileTablet && userIsAdmin);

  useEffect(() => {
    if (isMobileTablet && userIsAdmin) {
      setModalIsVisible(true);
    }
  }, [isMobileTablet, userIsAdmin]);

  return (
    <ModalSimple
      isOpen={modalIsVisible}
      toggle={() => setModalIsVisible(false)}
      zIndex={3130}
    >
      <>
        <div className="dsa-action-buttons dsa-modal__close">
          <button onClick={() => setModalIsVisible(false)} className="close" type="button" aria-label="Close">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <ModalBody>
          <p className="text-center">All Admin features are not available in Mobile version</p>
        </ModalBody>
      </>
    </ModalSimple>
  );
};

AdminMobileAlertModal.propTypes = {
  isMobileTablet: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  userIsAdmin: makeSelectUserIs(VARIETY.admin),
});

export default connect(mapStateToProps)(ResponsiveHoC(AdminMobileAlertModal));

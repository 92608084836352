import React from 'react';

// import { AvatarLg } from 'components/Avatars/Avatar';
import DocPager from 'components/DocPager/DocPager';
import LiVerticalDivider from 'components/SideBar/LiVerticalDivider';
import { HeadingLarge, HeadingMedium } from 'components/Text/Headings';
import { TextMdNew } from 'components/Text/Body';
import DesignSystemMain from 'designSystemDocs/Sections/DesignSystemMain';


const Avatars = () => (
  <DesignSystemMain>
    <DocPager>
      <HeadingLarge>Avatars</HeadingLarge>
      <TextMdNew>An avatar is an image that represents a user (founders, faculty members, mentors, angels, investors, press, etc) or a company (startups, funds, corporations, service providers, etc). </TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>Avatar Large</HeadingMedium>
      <TextMdNew>91px x 91px</TextMdNew>
      <img className="dsa-avatar-img _lg_" src="/upload/gu0tgs-pa.png" alt="Avatar Large" />
      { /* <AvatarLg profile={dummyProfile} /> */ }
    </DocPager>
  </DesignSystemMain>
);

export default Avatars;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { HIDE_BOX } from 'utils/Forms/general';
import { toJS } from 'utils/general';


const ErrorBox = ({ error, rounded, className }) => {
  if (error === HIDE_BOX) {
    return null;
  }
  let errorContent;
  if (typeof error === 'object') {
    if (React.isValidElement(error)) {
      errorContent = error;
    } else if (Array.isArray(toJS(error)) && toJS(error)?.length > 0) {
      errorContent = error.map((e) => <div>{e}</div>);
    } else {
      errorContent = JSON.stringify(error);
    }
  } else {
    errorContent = (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        components={{ root: 'p' }}
      >{error}
      </ReactMarkdown>
    );
  }
  return (
    <div className={classNames('dsa-form__error-message', className, { rounded })}>
      {errorContent}
    </div>);
};

ErrorBox.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
};

export default ErrorBox;

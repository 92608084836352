import { createSelector } from 'reselect';

import { makeSelectObjectArrayFromRefArray, makeSelectObjectFromRefSelector } from 'containers/App/selectors';

import { makeSubSelector, makeSubSelectorToJS } from '../selectors';
import { groupMatch, keyMatch } from './filter';

const makePageSelectorFromSelectorOrIdentifier = (pageSelectorOrIdentifier) => typeof pageSelectorOrIdentifier === 'string'
  ? makeSubSelector((s) => s, [pageSelectorOrIdentifier])
  : pageSelectorOrIdentifier;

export function generateFiltersUiSelectors(pageSelectorOrIdentifier, loadedContentCustomSelector) {
  const pageSelector = makePageSelectorFromSelectorOrIdentifier(pageSelectorOrIdentifier);
  const filtersUiSelector = loadedContentCustomSelector || makeSubSelector(pageSelector, ['filtersUi']);
  const selectOpenSection = makeSubSelector(filtersUiSelector, ['openSection']);

  return {
    makeSelectIsAdvFiltersOpen: () => makeSubSelector(filtersUiSelector, ['isAdvFiltersOpen']),
    selectOpenSection,
    makeSelectIsSectionOpen: (section) => createSelector(selectOpenSection, (openSection) => section === openSection),
    makeSelectSelectedAdvFilter: () => makeSubSelector(filtersUiSelector, ['selectedAdvFilter']),
    makeSelectIsSearchFocused: () => makeSubSelector(filtersUiSelector, ['isSearchFocused']),
  };
}
export function generateLoadedContentSelectors(pageSelectorOrIdentifier, loadedContentCustomSelector) {
  const pageSelector = makePageSelectorFromSelectorOrIdentifier(pageSelectorOrIdentifier);
  const loadedContentSelector = loadedContentCustomSelector || makeSubSelector(pageSelector, ['loadedContent']);
  const makeSelectMarkedObjectsRefs = () => makeSubSelectorToJS(loadedContentSelector, ['markedObjects']);
  const selectObjectsRef = makeSubSelector(loadedContentSelector, ['loadedObjects']);
  const selectSelectedObjectRef = makeSubSelector(loadedContentSelector, ['selectedObject']);



  return {
    selectRouteParams: makeSubSelectorToJS(loadedContentSelector, ['routeParams']),
    makeSelectLoading: () => makeSubSelector(loadedContentSelector, ['loading']),
    makeSelectLoadingNextPage: () => makeSubSelector(loadedContentSelector, ['loadingNextPage']),
    selectObjectsRef,
    selectSelectedObjectRef,
    makeSelectMarkedObjectsRefs,
    makeSelectHasMore: () => makeSubSelector(loadedContentSelector, ['hasMore']),
    makeSelectTotalCount: () => makeSubSelector(loadedContentSelector, ['totalCount']),
    makeSelectObjects: () => makeSelectObjectArrayFromRefArray(selectObjectsRef),
    makeSelectSelectedObject: () => makeSelectObjectFromRefSelector(selectSelectedObjectRef),
    makeSelectMarkedObjects: () => makeSelectObjectArrayFromRefArray(makeSelectMarkedObjectsRefs()),
    makeSelectMaxPageContent: () => makeSubSelector(loadedContentSelector, ['maxPage']),
  };
}

const makeSelectFiltersForGroup = (filtersSelector, group) => createSelector(
  filtersSelector,
  (tempFilters) => tempFilters.filter((v) => groupMatch(group)(v.field))
);

const makeSelectFiltersForKey = (filtersSelector, key, group) => createSelector(
  filtersSelector,
  (tempFilters) => tempFilters.filter((v) => keyMatch(key, group)(v.field))
);

export function generateFiltersSelectors(pageSelectorOrIdentifier, filtersCustomSelector) {
  const pageSelector = makePageSelectorFromSelectorOrIdentifier(pageSelectorOrIdentifier);
  const filtersSelector = filtersCustomSelector || makeSubSelector(pageSelector, ['filter']);
  const makeSelectAppliedFilters = () => makeSubSelector(filtersSelector, ['appliedFilters']);
  const makeSelectTempFiltersForGroup = (group) => makeSelectFiltersForGroup(
    makeSubSelectorToJS(filtersSelector, ['tempFilters', 'filters']),
    group
  );
  const makeSelectAdvFiltersForGroup = (group) => makeSelectFiltersForGroup(
    makeSubSelectorToJS(makeSelectAppliedFilters(), ['advancedFilters', 'filters']),
    group
  );
  const makeSelectTempFiltersForKey = (key, group) => makeSelectFiltersForKey(
    makeSubSelectorToJS(filtersSelector, ['tempFilters', 'filters']),
    key,
    group
  );
  const makeSelectAdvFiltersForKey = (key, group) => makeSelectFiltersForKey(
    makeSubSelectorToJS(makeSelectAppliedFilters(), ['advancedFilters', 'filters']),
    key,
    group
  );
  const makeSelectSort = () => makeSubSelector(filtersSelector, ['sort']);
  const extractValues = (filters) => filters && filters.map((filter) => filter.value);

  return {
    makeSelectFilter: () => filtersSelector,
    makeSelectMaxPage: () => makeSubSelector(filtersSelector, ['maxPage']),
    selectTmpAdvFiltersOperator: makeSubSelector(filtersSelector, ['tempFilters', 'fop']),
    selectAdvFiltersOperator: makeSubSelector(filtersSelector, ['appliedFilters', 'advancedFilters', 'fop']),
    selectTmpSearchOperator: makeSubSelector(filtersSelector, ['tempFilters', 'searchOp']),
    selectSearchOperator: makeSubSelector(filtersSelector, ['appliedFilters', 'searchOp']),
    makeSelectSearch: () => makeSubSelector(filtersSelector, ['appliedFilters', 'search']),
    makeSelectAppliedFilters,
    makeSelectSort,
    selectSortObj: createSelector(makeSelectSort(), (sortSetting) => (sortSetting
        && [...sortSetting.entries()].map(([sortKey, order]) => ({ sortKey, order }))[0])
        || {}),
    makeSelectTempFilters: () => makeSubSelectorToJS(filtersSelector, ['tempFilters', 'filters']),
    makeSelectAdvancedFilters: () => makeSubSelectorToJS(makeSelectAppliedFilters(), ['advancedFilters', 'filters']),
    makeSelectSelectedCategory: () => makeSubSelectorToJS(makeSelectAppliedFilters(), ['category']),
    makeSelectAdvFiltersForGroup,
    makeSelectTempFiltersForGroup,
    makeSelectAdvFilterValuesForGroup: (group) => createSelector(makeSelectAdvFiltersForGroup(group), extractValues),
    makeSelectTempFilterValuesForGroup: (group) => createSelector(makeSelectTempFiltersForGroup(group), extractValues),
    makeSelectAdvFiltersForKey,
    makeSelectTempFiltersForKey,
    makeSelectAdvFilterValuesForKey: (key, group) => createSelector(makeSelectAdvFiltersForKey(key, group), extractValues),
    makeSelectTempFilterValuesForKey: (key, group) => createSelector(makeSelectTempFiltersForKey(key, group), extractValues),
    makeSelectFilterGroupsOperators: () => makeSubSelectorToJS(makeSelectAppliedFilters(), ['advancedFilters', 'filterGroupsOperators']),
    makeSelectIndividualOptionsOperators: () => makeSubSelectorToJS(makeSelectAppliedFilters(), ['advancedFilters', 'individualOptionsOperators']),
  };
}

/*
 * HomeReducer
 */
import { fromJS } from 'immutable';

import { REGISTER_FIELD_POSITION, SET_COMPANY_BY_NAME, SET_FORM_SCHEMA, SET_USER_BY_EMAIL } from './constants';

const initialLoadedContentState = fromJS({
  formSchemas: {},
  usersByEmail: {},
  companiesByName: {},
  fieldPositions: {},
});

function peopleForms(state = initialLoadedContentState, action) {
  switch (action.type) {
    case SET_FORM_SCHEMA:
      return state.setIn(['formSchemas', action.resourceName], action.schema);
    case SET_USER_BY_EMAIL:
      return state.setIn(['usersByEmail', action.email], action.user);
    case SET_COMPANY_BY_NAME:
      return state.setIn(['companiesByName', action.name], action.company);
    case REGISTER_FIELD_POSITION:
      return state.setIn(['fieldPositions', action.form, action.field], action.position);
    default:
      return state;
  }
}

export default peopleForms;

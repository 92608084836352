import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Label } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { PERMISSION } from 'containers/App/constants';

import FormHelperPopover from '../FormHelperPopover';
import ErrorPart from '../ErrorPart';
import FieldClass from './FieldClass';

const SimpleFieldMiddleHoc = (WrappedInput, defaultProps = {}) => class extends FieldClass {
    static defaultProps = {
      colSize: '', className: '', type: '', ...defaultProps,
    };

    render() {
      const {
        helperPopoverText, popoverPlacement = 'top-end', popoverWidth,
        colSize, className, meta, errorProcessor, isAdminField, iAmAdmin, ...props
      } = this.props;

      if (!iAmAdmin && (isAdminField || className.includes('dsa-admin-field'))) {
        return null;
      }

      const errorHighlighter = meta.error === 'ONLY_HIGHLIGHT' ? 'dsa-form__error-highlight' : '';
      const isCheckbox = props.type === 'checkbox';
      const classNameStr = classNames(
        props.type,
        colSize,
        className,
        errorHighlighter,
        {
          _error_: (meta.dirty || meta.touched) && meta.error,
          'd-flex align-items-center': isCheckbox,
          'dsa-admin-field': isAdminField && (props.input.value || ![null, ''].includes(props.input.value)),
        }
      );
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

      return (
        <div className={classNameStr} ref={this.inputRef} data-testid={props.input && `field__${props.input.name}`}>
          <WrappedInput {...props} meta={meta} isAdminField={isAdminField} />
          {!isIE11 && meta.active && helperPopoverText && !!this.inputRef && (
            <FormHelperPopover
              helperText={helperPopoverText}
              target={this.inputRef.current}
              placement={popoverPlacement}
              popoverWidth={popoverWidth}
            />)}
          {isCheckbox && <Label className="mb-0">{this.props.label}</Label>}
          {!errorHighlighter && <ErrorPart meta={meta} errorProcessor={errorProcessor} />}
        </div>
      );
    }
};

const SimpleFieldHoc = (Component, defaultProps) => {
  const mapStateToProps = createStructuredSelector({
    iAmAdmin: makeSelectUserIs(PERMISSION.admin),
  });

  return connect(mapStateToProps)(SimpleFieldMiddleHoc(Component, defaultProps));
};

export default SimpleFieldHoc;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import authProviderReducer from 'containers/AuthProvider/reducer';
import { makeSelectLocation } from 'containers/App/selectors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { changeTheme } from 'utils/theme';
import { reloginPath } from 'utils/auth';

import { initialCheckToken } from './actions';
import saga from './saga';
import { makeSelectIsAuthenticated, makeSelectIsCheckingAuth, makeSelectUser, selectUserCapability } from './selectors';

export class AuthProvider extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    if (!this.props.location.pathname.match(/^\/oauthlanding/)) {
      this.props.initialCheckToken();
      changeTheme();
    }
  }

  render() {
    const { user, location, children, userCapability } = this.props;
    const isOauthLanding = location.pathname.match(/^\/oauthlanding/);
    if (!user && localStorage.token && !isOauthLanding) {
      return null;
    }

    if (this.props.isChecking && !isOauthLanding) {
      return null;
    }

    if (this.props.isAuthenticated && user && location.pathname.startsWith('/welcome')) {
      // force user to log in again
      localStorage.removeItem('token');
      window.location.href = reloginPath(null, `${location.pathname}${location.search}`);
      return null;
    }

    const isOnboarding = user?.roles?.includes('onboarding');
    const isNetworkOnboarding = user?.roles?.includes('network_onboarding');
    if (this.props.isAuthenticated && user
      && (isOnboarding || isNetworkOnboarding)
      && !(location.pathname.includes('onboarding') || location.pathname.startsWith('/lms'))
      && userCapability) {
      return <Redirect to={`/onboarding/${userCapability?.id}`} />;
    }

    return React.Children.only(children);
  }
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialCheckToken: PropTypes.func,
  user: PropTypes.any,
  isChecking: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.any,
  userCapability: PropTypes.object,
};


const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  isChecking: makeSelectIsCheckingAuth(),
  isAuthenticated: makeSelectIsAuthenticated(),
  location: makeSelectLocation(),
  userCapability: selectUserCapability,
});

const mapDispatchToProps = { initialCheckToken };

const withReducer = injectReducer({ key: 'auth', reducer: authProviderReducer });
const withSaga = injectSaga({ key: 'auth', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withReducer, withSaga)(AuthProvider);

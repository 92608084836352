import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';
import { makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { RESOURCE_CONNECTIONS } from 'containers/App/constants';

/**
 * Direct selector to the personModal state domain
 */
const selectPersonModalDomain = (state) => state.get('personModal') || fromJS({});

/**
 * Other specific selectors
 */

const makeSelectIsOpen = () => makeSubSelector(selectPersonModalDomain, ['isOpen']);


const selectModalPersonRef = makeSubSelector(selectPersonModalDomain, ['modalPerson']);
const makeSelectModalPerson = () => makeSelectObjectFromRefSelector(selectModalPersonRef);

const makeSelectModalLeftSideSection = () => makeSubSelector(selectPersonModalDomain, ['leftSideSection']);
const makeSelectPersonModalInternal = () => makeSubSelector(selectPersonModalDomain, ['personModalInternal']);

/** MESSAGES */
const selectMessageSection = makeSubSelector(makeSelectPersonModalInternal(), ['messageSection']);
const makeSelectMessageText = () => makeSubSelector(selectMessageSection, ['message']);
const selectExistingConnectionRef = makeSubSelector(selectMessageSection, ['messageRef']);
const selectExistingConnection = makeSelectObjectFromRefSelector(selectExistingConnectionRef);
const makeSelectTopics = () => makeSubSelector(selectMessageSection, ['topics']);
const makeSelectMessageParts = () => makeSubSelector(selectMessageSection, ['messageParts']);
const makeSelectModalMessageType = () => makeSubSelector(selectMessageSection, ['messageType']);
const makeSelectModalMessageIsIncoming = () => makeSubSelector(selectMessageSection, ['isIncomingMessage']);

const makeSelectThreadMessageIsLoading = () => makeSubSelector(selectMessageSection, ['isThreadMessageLoading']);
const makeSelectActiveThreadMessage = () => makeSubSelector(selectMessageSection, ['activeThreadMessage']);
const makeSelectThreadMessageFromIncluded = (threadMessageId) => makeSelectObjectFromRefSelector(() => ({ type: RESOURCE_CONNECTIONS, id: threadMessageId }));

const makeSelectIsSending = () => makeSubSelector(makeSelectPersonModalInternal(), ['isSending']);
/** REVIEWS */
const makeSelectReview = () => makeSubSelectorToJS(makeSelectPersonModalInternal(), ['review']);

/** ADD TO LIST */
const makeSelectListsChangesImmutable = () => makeSubSelector(makeSelectPersonModalInternal(), ['listsChanges']);
const makeSelectListsChanges = () => createSelector(
  makeSelectPersonModalInternal(),
  (personModalInternalState) => personModalInternalState.get('listsChanges').toJS(),
);
const makeSelectAddNewList = () => createSelector(
  makeSelectPersonModalInternal(),
  (personModalInternalState) => personModalInternalState.get('addNewList').toJS(),
);

export {
  makeSelectModalLeftSideSection,
  makeSelectModalPerson,
  makeSelectIsOpen,
  makeSelectMessageText,
  makeSelectTopics,
  makeSelectMessageParts,
  selectExistingConnection,
  makeSelectIsSending,
  makeSelectReview,
  makeSelectListsChanges,
  makeSelectListsChangesImmutable,
  makeSelectAddNewList,
  makeSelectModalMessageType,
  makeSelectModalMessageIsIncoming,
  makeSelectThreadMessageFromIncluded,
  makeSelectThreadMessageIsLoading,
  makeSelectActiveThreadMessage,
};

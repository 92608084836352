// Individual exports for testing
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { parse, stringify } from 'qs';

import { trackLogin } from 'containers/Analytics/actions';
import { API_PEOPLE_BASE_URL, RESOURCE_ALCHEMIST_CLASSES } from 'containers/App/constants';
import { makeSelectQueryParam } from 'containers/App/selectors';
import { DEMODAY_INVITE, FLOW_TYPE } from 'containers/AuthProcess/constants';
import { loginFlow } from 'containers/AuthProvider/saga';
import { request } from 'utils/request';
import { updateObjFromApi } from 'utils/sagas';
import { setClassFromLinkedinSignin } from 'containers/AuthProvider/actions';

import { GET_CREDENTIALS } from './constants';
import { selectFlow, selectUnauthHash, selectDDInviteFlag } from './selectors';
import { loginError } from './actions';

const FLOW_TO_LOGIN_ENDPOINT = {
  [FLOW_TYPE.register]: `${API_PEOPLE_BASE_URL}/linkedin_token`,
  [FLOW_TYPE.demoDayInvitation]: `${API_PEOPLE_BASE_URL}/dd_special_auth`,
  [FLOW_TYPE.demoDaySignIn]: `${API_PEOPLE_BASE_URL}/dd_special_auth`,
  [FLOW_TYPE.demoDayClassWebsite]: `${API_PEOPLE_BASE_URL}/dd_special_auth`,
  [FLOW_TYPE.demoDayDryRun]: `${API_PEOPLE_BASE_URL}/dd_special_auth`,
  [FLOW_TYPE.demoDayLivestream]: `${API_PEOPLE_BASE_URL}/unified_login`,
  [FLOW_TYPE.standard]: `${API_PEOPLE_BASE_URL}/unified_login`,
  [FLOW_TYPE.lmsLogin]: `${API_PEOPLE_BASE_URL}/unified_login`,
  [FLOW_TYPE.onboarding]: `${API_PEOPLE_BASE_URL}/dd_special_auth?flow=onboarding`,
  [FLOW_TYPE.ifs]: `${API_PEOPLE_BASE_URL}/dd_special_auth`,
};

function* getCredentials() {
  delete localStorage.token;
  const flow = yield select(selectFlow);
  const ddInviteFlag = yield select(selectDDInviteFlag);
  const unauthHash = yield select(selectUnauthHash);
  const code = yield select(makeSelectQueryParam('code'));
  const userReqParams = { unauth_hash: unauthHash, code };

  const landingQParams = sessionStorage.getItem('landingQueryParams') && parse(sessionStorage.getItem('landingQueryParams'), { ignoreQueryPrefix: true });
  if (landingQParams.class) {
    const aaClassFromSignInLinkRef = { type: RESOURCE_ALCHEMIST_CLASSES, id: landingQParams.class };
    yield put(setClassFromLinkedinSignin(aaClassFromSignInLinkRef));
    yield updateObjFromApi(aaClassFromSignInLinkRef);
  }

  let loginEndpoint = FLOW_TO_LOGIN_ENDPOINT[flow];

  if (ddInviteFlag && ddInviteFlag.toLowerCase() === DEMODAY_INVITE) {
    loginEndpoint = `${loginEndpoint}${loginEndpoint.includes('?') ? '&' : '?'}for=${ddInviteFlag}`;
  }

  try {
    yield call(request, `${loginEndpoint}${loginEndpoint.includes('?') ? '&' : '?'}${stringify(userReqParams)}`);
    yield put(trackLogin(flow, 'linkedin'));
    yield loginFlow(flow, code);
  } catch (err) {
    const errorJson = yield err?.response?.json?.();
    if (errorJson) {
      yield put(loginError(errorJson?.errors?.code));
    }
  }
}


export default function* defaultSaga() {
  yield takeLatest(GET_CREDENTIALS, getCredentials);
}

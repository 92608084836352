import React from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';


const FormHelperPopover = ({
  target, helperText, placement, popoverWidth,
}) => (
  <Popover
    className={`dsa-info-icon-popover ${popoverWidth}`}
    placement={placement}
    isOpen
    target={target}
  >
    <PopoverBody>{helperText}</PopoverBody>
  </Popover>
);

FormHelperPopover.propTypes = {
  helperText: PropTypes.string,
  placement: PropTypes.string,
  popoverWidth: PropTypes.string,
  target: PropTypes.object,
};

FormHelperPopover.defaultProps = { placement: 'right', popoverWidth: '' };

export default FormHelperPopover;

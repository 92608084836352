import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AuthRoutePure from 'containers/AuthProvider/AuthRoute';
import { ErrorBoundary } from 'components/React/FailSafe';
import { setIsAuthRoute as setIsAuthRouteAction } from 'actions';

const AuthRouteWithErrorBoundary = ({ component: Component, setIsAuthRoute, ...rest }) => {
  useEffect(() => {
    setIsAuthRoute(true);
    return () => setIsAuthRoute(false);
  }, []);

  return (
    <AuthRoutePure
      render={
        (routeProps) => (
          <ErrorBoundary>
            <Component {...routeProps} />
          </ErrorBoundary>
        )}
      {...rest}
    />
  );
};

AuthRouteWithErrorBoundary.propTypes = {
  component: PropTypes.any,
  setIsAuthRoute: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setIsAuthRoute: (isUserOnAuthRoute) => dispatch(setIsAuthRouteAction(isUserOnAuthRoute)),
});

export default connect(null, mapDispatchToProps)(AuthRouteWithErrorBoundary);

import React from 'react';
import PropTypes from 'prop-types';
import { Field as FieldReduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';

import { findFieldMapping, isRequiredInSchema } from 'utils/Forms/general';
import PLACEHOLDER_MAPPER from 'utils/Forms/placeholderMapper.json';
import LABEL_MAPPER from 'utils/Forms/labelMapper.json';
import HELPER_MAPPER from 'utils/Forms/helperTextMapper.json';
import { RenderField } from 'components/Form/Input/RenderField';

import { SchemaContext } from '.';
import { registerFieldPosition as registerFieldPositionAction } from './actions';

const Field = ({ registerFieldPos, ...props }) => (
  <SchemaContext.Consumer>
    {({
      schema, formName, resourceType, subSchemas,
    }) => (
      <FieldReduxForm
        registerFieldPosition={registerFieldPos}
        placeholder={findFieldMapping(PLACEHOLDER_MAPPER, props.name, formName, resourceType)}
        helperPopoverText={props.showHelperPopover ? findFieldMapping(HELPER_MAPPER, props.name, formName, resourceType) : null}
        schemaNode={schema && schema.properties && schema.properties[props.name]}
        formName={formName}
        {...props}
        required={props.required === undefined
          ? isRequiredInSchema(props.name, subSchemas, schema)
          : props.required}
        label={props.label === undefined
          ? findFieldMapping(LABEL_MAPPER, props.name, formName, resourceType)
          : props.label}
      />)}
  </SchemaContext.Consumer>);

Field.propTypes = {
  component: PropTypes.any,
  registerFieldPos: PropTypes.func,
  showHelperPopover: PropTypes.bool,
  ...FieldReduxForm.propTypes,
};

Field.defaultProps = {
  component: RenderField,
  showHelperPopover: true,
};

const mapDispatchToProps = { registerFieldPos: registerFieldPositionAction };

export default connect(null, mapDispatchToProps)(Field);

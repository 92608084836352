import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import MainContent from 'components/MainContent';
import PeopleMainContentHeader from 'containers/People/PeopleMainContentHeader';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { PERMISSION, RESOURCE_PEOPLE } from 'containers/App/constants';
import injectReducer from 'utils/injectReducer';
import PeopleListManageModal from 'containers/People/PeopleListManageModal';
import injectSaga from 'utils/injectSaga';
import BackButton from 'components/Buttons/BackButton';

import PeopleListCreateModal from './PeopleListCreateModal';
import PeopleListMergeModal from './MergeModal/PeopleListMergeModal';
import PeopleListsContent from './PeopleListsContent';
import reducer from './reducer';
import saga from './saga';
import PeopleListsSearch from './PeopleListsSearch';


const ListsWrapper = ({ isArchive, children, listsType, isFounder, isAdmin }) => (
  <MainContent>
    <PeopleMainContentHeader />
    <PeopleListsSearch placeholder={(isAdmin || isFounder) ? 'Search by List name or people name' : 'Search lists'} isArchive={isArchive} listsType={listsType} />
    <PeopleListsContent>
      {isArchive &&
        <BackButton
          text="Back to the lists"
          to={`/${RESOURCE_PEOPLE}/lists`}
          className="mt-4 mb-3"
        />}
      {children}
    </PeopleListsContent>
    <PeopleListManageModal />
    <PeopleListCreateModal />
    <PeopleListMergeModal />
  </MainContent>
);

ListsWrapper.propTypes = {
  children: PropTypes.any,
  isArchive: PropTypes.bool,
  listsType: PropTypes.string,
  isFounder: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isAdmin: makeSelectUserIs(PERMISSION.admin),
  isFounder: makeSelectUserIs(PERMISSION.founder),
});

const withConnect = connect(mapStateToProps);
const withReducer = injectReducer({ key: 'peopleListsPage', reducer });
const withSaga = injectSaga({ key: 'peopleListsPage', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ListsWrapper);

import jstz from 'jstimezonedetect';

import { canonicalTimezone } from 'utils/canonicalTimezone';
import { logError } from 'utils/log';
import { timezoneAbbreviationFromDB } from 'utils/timezoneAbbreviationFromDB';

export const BROWSER_NOT_SUPPORTED = 'Browser does not recognize this time zone';

const timezonesFromDb = Object.keys(timezoneAbbreviationFromDB).filter((tzIdentifier) => !tzIdentifier.includes('Etc'));
export const ALL_TIMEZONES = Array.from(new Set([...canonicalTimezone, ...timezonesFromDb])).filter((t) => t.includes('/') || ['Cuba', 'Egypt', 'Eire', 'Greenwich', 'Hongkong', 'Iceland', 'Iran', 'Israel', 'Jamaica', 'Japan', 'Kwajalein', 'Libya', 'Navajo', 'Poland', 'Portugal', 'Singapore', 'Turkey', 'Zulu', 'UTC'].includes(t));
export const LOS_ANGELES = 'America/Los_Angeles';

// If withDay is true, it returns "Wednesday, May 22, 2024 at 01:00 AM"
// If withDay is false, it returns "May 22, 2024 at 01:00 AM"
export const getDateTime = (dateObj, tzIdentifier, withDay = false, passedOptions = {}) => {
  try {
    let dateTimeFormatter;
    if (window.Intl?.DateTimeFormat) {
      dateTimeFormatter = new Intl.DateTimeFormat(
        'en',
        {
          timeZone: tzIdentifier,
          ...(withDay ? { weekday: 'long' } : {}),
          ...(Object.keys(passedOptions).length > 0
            ? passedOptions
            : {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            }
          ),
        }
      );
    } else {
      dateTimeFormatter = { format: (date) => date.toJSON() };
    }
    return dateTimeFormatter.format(new Date(dateObj));
  } catch (err) {
    logError(err);
    return BROWSER_NOT_SUPPORTED;
  }
};


export const getOnlyTime = (dateObj, tzIdentifier, isHour12 = true) => {
  try {
    let dateTimeFormatter;
    if (window.Intl?.DateTimeFormat) {
      dateTimeFormatter = new Intl.DateTimeFormat(
        'en',
        { timeZone: tzIdentifier, hour: 'numeric', minute: '2-digit', hour12: isHour12 }
      );
    } else {
      dateTimeFormatter = { format: (date) => date.toJSON() };
    }
    return dateTimeFormatter.format(new Date(dateObj));
  } catch (err) {
    logError(err);
    return BROWSER_NOT_SUPPORTED;
  }
};

export const getYyyyMmDdInSelectedTz = (dateOption, tzIdentifier) => {
  const monthDayYear = getDateTime(dateOption, tzIdentifier, false, { year: 'numeric', month: '2-digit', day: '2-digit' });

  if (monthDayYear === BROWSER_NOT_SUPPORTED) {
    return BROWSER_NOT_SUPPORTED;
  }

  const [month, day, year] = monthDayYear.split('/');
  return `${year}-${month}-${day}`;
};

// eslint-disable-next-line arrow-body-style
export const getValidatedTz = (tzFromInvitation, tzFromProfile, isEdit = false) => {
  return (tzFromInvitation && ALL_TIMEZONES.includes(tzFromInvitation) ? tzFromInvitation : false)
  || (!isEdit && ALL_TIMEZONES.includes(jstz.determine().name()) ? jstz.determine().name() : false)
  || (tzFromProfile && ALL_TIMEZONES.includes(tzFromProfile) ? tzFromProfile : false)
  || LOS_ANGELES;
};

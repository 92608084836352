// Individual exports for testing
import { put, takeLatest } from 'redux-saga/effects';

import { reloadFinished } from 'actions';
import { REQUEST_RELOAD } from 'reducers';
import { updateObjFromApi } from 'utils/sagas';


function* reloadObj({ objRef, include }) {
  yield updateObjFromApi(objRef, include);
  yield put(reloadFinished(objRef));
}
export default function* defaultSaga() {
  yield takeLatest(REQUEST_RELOAD, reloadObj);
}

/*
 *
 * RegisterProfile constants
 *
 */

import { makeValueLabelArray } from 'utils/Forms/general';

export const INITIAL_LOAD = 'app/RegisterProfile/INITIAL_LOAD';
export const SET_REGISTERING_USER = 'app/RegisterProfile/SET_REGISTERING_USER';
export const SET_STEP = 'app/RegisterProfile/SET_STEP';
export const GO_BACK_TO_FIRST_STEP = 'app/RegisterProfile/GO_BACK_TO_FIRST_STEP';
export const SET_UNAUTH_HASH = 'app/RegisterProfile/SET_UNAUTH_HASH';
export const SET_IS_CHECKING_USER = 'app/RegisterProfile/SET_IS_CHECKING_USER';
export const SAVE_PERSONAL_STEP = 'app/RegisterProfile/SAVE_PERSONAL_STEP';
export const SAVE_PERSONAL_MORE_STEP = 'app/RegisterProfile/SAVE_PERSONAL_MORE_STEP';
export const SAVE_COMPANY_STEP = 'app/RegisterProfile/SAVE_COMPANY_STEP';
export const SAVE_ROLES_STEP = 'app/RegisterProfile/SAVE_ROLES_STEP';
export const SAVE_STEP_TWO = 'app/RegisterProfile/SAVE_STEP_TWO';
export const ADD_NEW_COMPANIES_IDS = 'app/RegisterProfile/ADD_NEW_COMPANIES_IDS';
export const SET_LINKEDIN_VALUES = 'app/RegisterProfile/SET_LINKEDIN_VALUES';
// export const SET_SHOW_IMPORT_SUCCESS = 'app/RegisterProfile/SET_SHOW_IMPORT_SUCCESS';
export const DELETE_LINKEDIN_VALUE = 'app/RegisterProfile/DELETE_LINKEDIN_VALUE';
export const FINISH_REGISTRATION = 'app/RegisterProfile/FINISH_REGISTRATION';
// export const SET_SHOW_IMPORT_ERROR = 'app/RegisterProfile/SET_SHOW_IMPORT_ERROR';
export const SET_SHOW_ERROR_SUMMARY = 'app/RegisterProfile/SET_SHOW_ERROR_SUMMARY';
export const GET_NEW_PRIMARY_COMPANY = 'app/RegisterProfile/GET_NEW_PRIMARY_COMPANY';
export const SET_NEW_PRIMARY_COMPANY = 'app/RegisterProfile/SET_NEW_PRIMARY_COMPANY';
export const LOAD_USER_BY_REF = 'app/RegisterProfile/LOAD_USER_BY_REF';

export const STEP_PERSONAL = 'registrationStepPersonal';
export const STEP_PERSONAL_MORE = 'registrationStepPersonalMore';
export const STEP_COMPANIES = 'registrationStepCompanies';
export const STEP_ROLES = 'registrationStepRoles';
export const STEP_SUCCESS = 'registrationStepSuccess';
export const SHORT_FORMAT = '2sf';
export const STEP_TWO_SHORT_FORM = 'registrationStepTwoShortForm';

export const SEND_RESTORE_ACCESS_EMAIL = 'registrationSendRestoreAccessEmail';

export const ROLES_GROUPING = [
  'mentor',
  { groupName: 'Investors:', items: ['angel', 'investor', 'strategic_investor'] },
  'acquirer',
  { groupName: 'Corporate Representatives:', items: ['customer', 'corporate', 'industry_analyst'] },
  'channel_partner',
  'press',
  'service_provider',
  'lecturer',
  'consultant',
];

const ACCREDITED_INVESTOR_MAPPER = {
  not_accredited: 'I\'m NOT an accredited investor',
  income_200k: 'Yes, my income is $200K+ (or $300k+ with spouse)',
  networth_1m: 'Yes, my networth is $1M+ (with or without spouse)',
  trust_5m: 'Yes, invests through trust with total assets of $5M+',
  delegate: 'Yes, I represent a VC, fund or family office that is accredited',
  series_7_62_65: 'Yes, I hold a Series 7, 62, or 65 license',
  other: 'Yes, I have other ways of accreditation',
};

const ALL_ACCREDITED_INV_OPTIONS = makeValueLabelArray(
  Object.keys(ACCREDITED_INVESTOR_MAPPER),
  (option) => ACCREDITED_INVESTOR_MAPPER[option]
);

export const GROUPED_ACCREDITED_INV_OPTIONS = [
  {
    label: 'Unaccredited',
    options: ALL_ACCREDITED_INV_OPTIONS.filter((opt) => opt.value === 'not_accredited'),
  },
  {
    label: 'Accredited',
    options: ALL_ACCREDITED_INV_OPTIONS.filter((opt) => opt.value !== 'not_accredited'),
  },
];

export const SUBMIT_BTN_TEXT = 'Confirm & Finish';

/**
 *
 * ContactUsButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const ContactUsButton = ({ buttonTag: ButtonTag, onClickExtra, children }) => {
  const [hasIntercom, setHasIntercom] = React.useState(false);
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!!window.Intercom !== hasIntercom) {
        setHasIntercom(!!window.Intercom);
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);
  const actionProps = hasIntercom && window.Intercom
    ? { onClick: () => { window.Intercom('show'); if (onClickExtra) onClickExtra(); } }
    : { href: 'mailto:support@alchemistaccelerator.com', onClick: onClickExtra, target: '_blank' };

  return <ButtonTag {...actionProps}>{children}Contact Us</ButtonTag>;
};

ContactUsButton.propTypes = {
  children: PropTypes.any,
  buttonTag: PropTypes.any,
  onClickExtra: PropTypes.func,
};

export default ContactUsButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import classNames from 'classnames';
import styled from 'styled-components';
import omit from 'lodash/omit';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';


class DsaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalHeight: 0 };
    this.onWindowResize = () => this.setState(this.actualInnerHeight());
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize, false);
    const vh = window.innerHeight - 56;
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(() => ({ modalHeight: vh }));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  actualInnerHeight() {
    const vh = window.innerHeight - 56;
    return { modalHeight: vh };
  }

  render() {
    const {
      className, isMobile, isMobileTablet, size = 'md', smallHeight, ...props
    } = this.props;

    return (
      <StyledModal
        centered
        className={classNames('dsa-simple-modal', `modal-${size}`, className, { 'is-mobile-tablet': isMobileTablet, 'small-height': isMobile && smallHeight })}
        zIndex={isMobile ? 3140 : null}
        {...omit(props, ['screenWidth', 'screenSize', 'isDesktop', 'isTablet'])}
        style={isMobile
          ? { height: this.state.modalHeight, marginTop: 49, minHeight: 'initial' }
          : null}
      />
    );
  }
}

DsaModal.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  isMobile: PropTypes.bool,
  isMobileTablet: PropTypes.bool,
  smallHeight: PropTypes.bool,
};

export default ResponsiveHoC(DsaModal);


const StyledModal = styled(Modal)`
  && .modal-content {
    padding: 0;

    h1 {
      margin-bottom: 0;
      line-height: 1.75;
    }
  }

  .modal-content {
    .col {
      padding: 0;
    }

    .dsa-action-buttons {
      top: 1.3125rem;
      right: 1.3125rem;
    }
  }
`;

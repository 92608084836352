import React from 'react';
import PropTypes from 'prop-types';

import AlchLogoMark from 'containers/Companies/Search/AlchLogoMark';


const RoundedAlchemistLogo = ({ className = '', isAlchemist = true, isStartupMultiClasses }) => (
  <div className="dsa-rounded-logo flex-shrink-0">
    <AlchLogoMark className={className} isAlchemist={isAlchemist} width={22} isStartupMultiClasses={isStartupMultiClasses} />
  </div>
);

RoundedAlchemistLogo.propTypes = {
  className: PropTypes.string,
  isAlchemist: PropTypes.bool,
  isStartupMultiClasses: PropTypes.bool,
};

export default RoundedAlchemistLogo;

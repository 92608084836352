import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { updateIncludedFromRequest } from 'actions';
import { PERMISSION, RESOURCE_PEOPLE } from 'containers/App/constants';
import { makeSelectHighestUserAccess, makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { allIfsEventAgenda } from 'containers/InvestorFeedbackSummit/Registration/saga';
import { initialLoad as loadTeamNotes } from 'containers/People/ProfileTeamNotes/actions';
import { logError } from 'utils/log';
import { request } from 'utils/request';
import { refApiUrl } from 'utils/refs';
import { checkRouteMatch } from 'utils/general';
import { refreshCurrentRoute } from 'utils/sagas';

import { getSingleConnectionSaga, loadMyCompanyTeamMembers } from '../Search/saga';
import { selectedPersonNotFound } from './actions';
import { SET_SELECTED_PERSON } from './constants';
import { makeSelectSelectedPersonRef, selectIsSelf, selectShowTeamNotes } from './selectors';


export const basicIncludes = ['tags', 'location', 'company_membership.company', 'roles', 'company.aclass'];
export const extraIncludes = ['areas_of_service', 'press_topics', 'erogated_investments.investment_round.company.tags',
  'erogated_investments.investment_round.company.investment_rounds', 'erogated_investments.investing_company',
  'company.stages_of_interest', 'not_areas_of_interest', 'areas_of_interest', 'angel_prefer_geographies'];
export const ifsIncludes = ['ifs_invitations.selected_events', ...allIfsEventAgenda.map((aType) => `ifs_invitations.${aType}`)];

function* getPerson() {
  const userHighestAccess = yield select(makeSelectHighestUserAccess());
  const personRef = yield select(makeSelectSelectedPersonRef());
  const isSelf = yield select(selectIsSelf);
  if (!personRef.id || !personRef.id.match(/^\d+$/)) {
    yield put(selectedPersonNotFound());
    return;
  }

  let include = [...basicIncludes, ...extraIncludes];
  if (!isSelf) {
    include.push('comments.author.profile');
  }

  if (userHighestAccess === PERMISSION.corporate) {
    include = include.filter((incl) => !['erogated_investments.investment_round.company.tags',
      'erogated_investments.investment_round.company.investment_rounds', 'erogated_investments.investing_company'].includes(incl));
  }

  const isAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
  if (isAdmin) {
    include.push('account.capabilities_rel.membership', 'account.capabilities_rel.dd_invitation', 'demo_day_users_rel.demo_day.aa_class.event_agenda', 'demo_day_users_rel.selected_events', ...ifsIncludes, 'ifs_invitations.areas_of_interest', 'ifs_invitations.selected_companies', 'ifs_invitations.selected_investors', 'coach_classes', 'company.tags.text');
  }
  if (isAdmin || isSelf) {
    include.push('profile_emails', 'meetings');
  }

  const requestURL = `${refApiUrl(personRef)}?include=${Array.from(new Set(include)).join()}`;
  const showTeamNotes = yield select(selectShowTeamNotes);

  try {
    // Call our request helper (see 'utils/request')
    const peopleRequest = yield call(request, requestURL);
    if (showTeamNotes) {
      yield put(loadTeamNotes());
    }
    yield put(updateIncludedFromRequest(peopleRequest));
    yield getSingleConnectionSaga(personRef.id);
    yield loadMyCompanyTeamMembers();
  } catch (err) {
    if (err.response.status === 404) {
      yield put(selectedPersonNotFound());
    }
    logError(err);
    // yield put(contentLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  yield takeLatest(SET_SELECTED_PERSON, getPerson);
  yield takeLatest(LOCATION_CHANGE, refreshCurrentRoute, { callbackSaga: getPerson, condition: (location) => checkRouteMatch(`/${RESOURCE_PEOPLE}/:personId`, location) });
}

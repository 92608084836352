/*
 *
 * AlertBox actions
 *
 */

import {
  CLOSE_CONFIRMATION,
  ASK_CONFIRMATION,
  CONFIRM_ACTION,
  SET_FIELD_CONTENT,
  PRE_CLOSE_CONFIRMATION,
  SET_VALIDATION,
} from './constants';

const preCloseConfirmation = (isConfirm, fieldContent) => ({ type: PRE_CLOSE_CONFIRMATION, isConfirm, fieldContent });
const closeConfirmation = (isConfirm, fieldContent) => ({ type: CLOSE_CONFIRMATION, isConfirm, fieldContent });
const askConfirmation = (title, message, yesText, noText, fieldType, helperText, smallHeading, smallHeight) => ({
  type: ASK_CONFIRMATION, title, message, yesText, noText, fieldType, helperText, smallHeading, smallHeight,
});
const confirmAction = (title, message, yesText, noText, actionToConfirm, actionToCancel) => ({
  type: CONFIRM_ACTION, title, message, yesText, noText, actionToConfirm, actionToCancel,
});
const setFieldContent = (fieldContent) => ({ type: SET_FIELD_CONTENT, fieldContent });
const setValidation = (validationErr) => ({ type: SET_VALIDATION, validationErr });

export {
  preCloseConfirmation,
  closeConfirmation,
  askConfirmation,
  confirmAction,
  setFieldContent,
  setValidation,
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CloseButton from 'components/Buttons/CloseButton';
import ToggleButtonTooltip from 'components/Buttons/ToggleButtonTooltip';
import {
  ShareListTable,
  ShortTd,
  TableRow,
  TextMessage,
  TextTd,
  TextTdName,
} from 'components/Lists/SharesListStyles';
import LoadingContent from 'components/LoadingSpinner/LoadingContent';

import { setShare as setShareAction, removeShare as removeShareAction } from './actions';
import { selectPeopleCompanyLoading, selectShares } from './selectors';


const SharesList = ({ shares, setShare, removeShare, isPeopleCompanyLoading }) => {
  if (isPeopleCompanyLoading) {
    return <ShareListTable><LoadingContent text="Loading members..." /></ShareListTable>;
  }
  return (
    <ShareListTable>
      {shares && shares.length
        ? (
          <table>
            <tbody>
              {shares.map((share, idx) => (
                <TableRow key={share.user.id}>
                  <TextTdName>{share.user.nicename}</TextTdName>
                  <TextTd>{share.user.company && share.user.company().name}</TextTd>
                  <TextTd>{share.user.current_position}</TextTd>
                  <ShortTd>
                    <ToggleButtonTooltip
                      tooltipText={share.update ? 'Can Edit List' : 'Only View Access'}
                      toggleAction={() => setShare(idx, !share.update)}
                      size="sm"
                      toggleBool={share.update}
                    />
                  </ShortTd>
                  <ShortTd><CloseButton closeAction={() => removeShare(idx)} size="sm" /></ShortTd>
                </TableRow>
              ))}
            </tbody>
          </table>)
        : <TextMessage>Not shared with anyone yet.</TextMessage>
      }
    </ShareListTable>
  );
};

SharesList.propTypes = {
  shares: PropTypes.array,
  setShare: PropTypes.func,
  removeShare: PropTypes.func,
  isPeopleCompanyLoading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  shares: selectShares,
  isPeopleCompanyLoading: selectPeopleCompanyLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setShare: (idx, update) => dispatch(setShareAction(idx, update)),
  removeShare: (idx) => dispatch(removeShareAction(idx)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharesList);

import React from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FORM_NOTE_TEXT_MAPPER } from 'containers/App/constants';
import { adaptErrors, generateFullValuesPreprocessor } from 'utils/Forms/general';
import { generateSchema } from 'utils/Forms/schemaManipulation';
import { DAEMON } from 'utils/constants';
import { isShallowEqual, toJS } from 'utils/general';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { deepMaterializeObject, deepNullifyFunctions } from 'utils/jsonApiExtract';
import { logError, logTable } from 'utils/log';
import { resRef } from 'utils/refs';

import FormWrapper from './FormWrapper';
import reducer from './reducer';
import saga from './saga';
import { loadFormSchema, saveFormSection } from './actions';
import { makeSelectFormSchemas } from './selectors';

export const SchemaContext = React.createContext();

class FormBase extends React.PureComponent {
  // props that have to change for generateValidateSchema to be run from componentDidUpdate
  propsToCompareForUpdate = ['schema', 'fields', 'subSchemas'];

  constructor(props) {
    super(props);
    const {
      dispatch,
      formName,
      fields,
      extraFields = [],
      materializeFields = [],
      multiFormMainField,
      valuesPreprocessor,
      saveUnchangedAction,
      resourceType,
      enableReinitialize,
      noFeValidation,
      removeInnerButtonsWrapper,
      switchButtonsPlaces,
      saveUnchangedFieldsArray,
    } = props;
    // ToDo: Use property registered fields and add what necessary to an extraFields prop if needed
    if (fields) fields.push('id');
    const initialValuesOnConstruct = this.getInitialValues();
    const fullValuesPreprocessor = generateFullValuesPreprocessor(valuesPreprocessor, multiFormMainField);

    const onlyChanged = (values) => {
      const initialValues = enableReinitialize ? this.getInitialValues() : initialValuesOnConstruct;
      return pickBy(
        values,
        (val, key) => !isEqual(deepMaterializeObject(initialValues[key], 0), deepMaterializeObject(val, 0))
          || key === 'type' || key === 'id' || saveUnchangedFieldsArray?.includes(key)
      );
    };

    this.generateValidateSchema();

    const validate = (values, formProps) => {
      if (noFeValidation) {
        return null;
      }

      let valuesInJS = fullValuesPreprocessor(values);

      if (multiFormMainField) {
        if (!valuesInJS[multiFormMainField]) return null;

        const errors = [];
        valuesInJS[multiFormMainField].forEach((formValue, idx) => {
          const toJSInner = mapValues(formValue, (value) => isFunction(value) ? value() : value);
          const isValid = this.validateSchema(toJSInner);
          if (!isValid) errors[idx] = adaptErrors(this.validateSchema.errors, formName, resourceType);
        });
        if (!errors.length) return null;
        return { [multiFormMainField]: errors };
      }

      valuesInJS = mapValues(valuesInJS, (value) => isFunction(value) ? value() : value);
      const isValid = this.validateSchema(valuesInJS);
      const extraErrors = this.props.extraValidation && this.props.extraValidation(valuesInJS);
      if (!isValid || extraErrors) {
        if (!isValid) logTable(this.validateSchema.errors);
        if (extraErrors) {
          logTable(extraErrors);
        }
        const ajvAdaptedErrors = this.validateSchema.errors && adaptErrors(
          this.validateSchema.errors,
          formName,
          resourceType,
          (formProps && toJS(formProps.registeredFields)) || {}
        );
        return merge(ajvAdaptedErrors, extraErrors);
      }
      return null;
    };

    const save = (values, _, form, extraOptions) => (
      // redux-form requires to return Promise from submit handler to make 'processing' prop work
      new Promise(() => {
        const preprocessedValues = fullValuesPreprocessor(values); // converts Map to a new obj
        const changedValues = this.props.sendUnchangedFields ? preprocessedValues : onlyChanged(preprocessedValues);
        const registeredFields = form.registeredFields ? Object.keys(form.registeredFields.toJS()) : [];
        const actionToDispatch = (form.dirty === false || isEmpty(omit(changedValues, ['id', 'type']))) && saveUnchangedAction
          ? saveUnchangedAction
          // using makeFormActionFunction in the end
          : this.props.saveAction(
            pick(changedValues, ['id', 'type', ...registeredFields, ...extraFields]),
            this.props.formName, // Shouldn't really change, but I see no reason nt to use the latest value
            this.props.editedObject ? resRef(this.props.editedObject) : null,
            this.props.successAction,
            resourceType,
            (extraOptions && extraOptions.formAction) || this.props.apiEndpoint,
            this.props.sendRefFromUrlOnSubmit,
          );
        // If changedValues is empty and there is a saveUnchangedAction, dispatch that
        dispatch(actionToDispatch);
      })
    );

    const FormWrapperReduxForm = reduxForm({
      form: formName,
      validate,
      closeForm: this.props.closeForm,
      className: this.props.className,
      enableReinitialize,
      onSubmit: save,
      removeInnerButtonsWrapper,
      switchButtonsPlaces,
      destroyOnUnmount: this.props.destroyOnUnmount !== undefined ? this.props.destroyOnUnmount : true,
    })(FormWrapper);

    const mapStateToProps = createStructuredSelector({
      initialValues: () => {
        const initialValues = this.getInitialValues();
        const onlyFormFieldsObject = this.props.fields
          ? pick(initialValues, multiFormMainField || this.props.fields)
          : initialValues;
        // ToDo: find usages of the prop materializeFields and instead materialize initialValues/editedObject
        const materializeFunction = (value, key) => (
          isFunction(value) && materializeFields.includes(key) ? value() : value
        );
        const lastMaterializedInitialValues = mapValues(onlyFormFieldsObject, materializeFunction);
        // deepNullifyFunctions - makes values (including nested obj values) null if it's a function
        return enableReinitialize ? deepNullifyFunctions(lastMaterializedInitialValues) : lastMaterializedInitialValues;
      },
    });

    const mapDispatchToProps = () => ({ save });

    this.FormWrapperConnected = connect(mapStateToProps, mapDispatchToProps)(FormWrapperReduxForm);
    this.updateSchemaContextProps();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(pick(prevProps, this.propsToCompareForUpdate), pick(this.props, this.propsToCompareForUpdate))) {
      this.generateValidateSchema();
    }
    this.updateSchemaContextProps();
  }

  getInitialValues() {
    return this.props.initialValuesOverride || this.props.editedObject || {};
  }

  updateSchemaContextProps() {
    const schemaContextProps = pick(this.props, 'schema', 'formName', 'resourceType', 'subSchemas');
    if (!this.schemaContextProps || !isShallowEqual(schemaContextProps, this.schemaContextProps)) {
      this.schemaContextProps = schemaContextProps;
    }
  }

  generateValidateSchema() {
    // Add in componentDidUpdate other props that might need to be used
    const {
      fields, schema, subSchemas, noFeValidation,
    } = this.props;
    if (noFeValidation) {
      return;
    }

    try {
      // returns ajv json schema validator function that returns a boolean if it's valid or not
      this.validateSchema = generateSchema(fields, schema, subSchemas);
    } catch (e) {
      logError(e);
      this.validateSchema = () => false;
      this.validateSchema.errors = [{ dataPath: '_error', message: 'No schema, cannot validate' }];
    }
  }

  render() {
    const FormWrapped = this.FormWrapperConnected;
    return (
      <SchemaContext.Provider value={this.schemaContextProps}>
        <FormWrapped
          SaveButtonComponent={this.props.SaveButtonComponent}
          CloseButtonComponent={this.props.CloseButtonComponent}
          ButtonsWrapper={this.props.ButtonsWrapper}
          className={this.props.className}
          passedChildren={this.props.children}
          errorsOnTop={this.props.errorsOnTop}
          canSaveUnchanged={this.props.canSaveUnchanged || !!this.props.saveUnchangedAction}
          removeInnerSaveButton={this.props.removeInnerSaveButton}
          showErrorNextSaveButton={this.props.showErrorNextSaveButton}
          formNoteText={FORM_NOTE_TEXT_MAPPER[this.props.formName]}
        />
      </SchemaContext.Provider>
    );
  }
}

FormBase.propTypes = {
  closeForm: PropTypes.func,
  formName: PropTypes.string,
  resourceType: PropTypes.string,
  // ToDo: should multiFormMainField be removed in favor of subschemas?
  multiFormMainField: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string),
  extraFields: PropTypes.arrayOf(PropTypes.string),
  schema: PropTypes.object,
  subSchemas: PropTypes.object,
  editedObject: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  initialValuesOverride: PropTypes.object,
  materializeFields: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.any,
  className: PropTypes.string,
  saveAction: PropTypes.func,
  extraValidation: PropTypes.func,
  successAction: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.func]),
  saveUnchangedAction: PropTypes.object,
  valuesPreprocessor: PropTypes.func,
  dispatch: PropTypes.func,
  removeInnerSaveButton: PropTypes.bool,
  errorsOnTop: PropTypes.bool,
  showErrorNextSaveButton: PropTypes.bool,
  sendUnchangedFields: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  canSaveUnchanged: PropTypes.bool,
  noFeValidation: PropTypes.bool,
  SaveButtonComponent: PropTypes.any,
  CloseButtonComponent: PropTypes.any,
  ButtonsWrapper: PropTypes.any,
  apiEndpoint: PropTypes.string,
  removeInnerButtonsWrapper: PropTypes.bool,
  switchButtonsPlaces: PropTypes.bool,
  sendRefFromUrlOnSubmit: PropTypes.bool,
  saveUnchangedFieldsArray: PropTypes.array,
  destroyOnUnmount: PropTypes.bool,
};

FormBase.defaultProps = {
  saveAction: saveFormSection,
};

const mapStateToProps = createStructuredSelector({
  schemas: makeSelectFormSchemas(),
});

const mapDispatchToProps = (dispatch) => ({
  loadSchema: (resourceName, name, extraParams) => dispatch(loadFormSchema(resourceName, name, extraParams)),
  dispatch,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'alchemistForms', reducer });
const withSaga = injectSaga({ key: 'alchemistForms', saga, mode: DAEMON });

export const withFormWrapper = compose(withReducer, withSaga, withConnect);

export default connect((dispatch) => ({ dispatch }))(FormBase);

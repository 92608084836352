import React from 'react';
import PropTypes from 'prop-types';

const SiteMainWrapper = (props) => (
  <div className="dsa-site-main-wrapper">
    {props.children}
  </div>
);

SiteMainWrapper.propTypes = {
  children: PropTypes.any,
};

export default SiteMainWrapper;

import React from 'react';
import PropTypes from 'prop-types';

import { setDefaultImg } from 'utils/general';

const MyProfileImage = ({ myProfile }) => (
  <img
    className="dsa-avatar dsa-avatar__my-profile"
    src={myProfile?.avatarLink('md') || '/default_user.jpg'}
    alt={`${myProfile && myProfile.nicename}'s Account`}
    onError={(e) => setDefaultImg(e)}
  />
);

MyProfileImage.propTypes = {
  myProfile: PropTypes.object,
};

export default MyProfileImage;

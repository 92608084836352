/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEFAULT_LOCALE = 'en';

// Resource types
export const RESOURCE_PEOPLE = 'people';
export const RESOURCE_TEAM_NOTES = 'team_notes';
export const RESOURCE_ROLES = 'roles';
export const RESOURCE_ACCOUNTS = 'accounts';
export const RESOURCE_CAPABILITIES = 'capabilities';
export const RESOURCE_ACCOUNTS_HINTS = 'account_hints';
export const RESOURCE_COMPANIES = 'companies';
export const RESOURCE_MEMBERSHIP = 'membership';
export const RESOURCE_INVESTMENT = 'investments';
export const RESOURCE_INVESTMENT_ROUNDS = 'investment_rounds';
export const RESOURCE_RESOURCES = 'alchemist_resources';
export const RESOURCE_RESOURCES_VIDEOS = 'alchemist_resources_videos';
export const RESOURCE_REVIEWS = 'reviews';
export const RESOURCE_CONNECTIONS = 'connections';
export const RESOURCE_CALENDARS = 'calendars';
export const RESOURCE_FOUNDER_FEEDBACK = 'founder_feedback';
export const RESOURCE_ALCHEMIST_FEEDBACK = 'alchemist_feedback';
export const RESOURCE_PEOPLE_LISTS = 'peoplelists';
export const RESOURCE_PEOPLE_LISTS_PERMISSIONS = 'peoplelists_permissions';
export const RESOURCE_PUBLIC_PEOPLE_LISTS = 'public_peoplelists';
export const RESOURCE_SHARED_COMPANIES_LISTS = 'sharedlists';
export const RESOURCE_TAG = 'tags';
export const RESOURCE_TAG_COMPANIES = 'tags/alchemist_companies';
export const RESOURCE_ALCHEMIST_CLASSES = 'alchemist_classes';
export const RESOURCE_PROFILE_REPORTS = 'profile_reports';
export const RESOURCE_LOCATIONS = 'locations';
export const RESOURCE_REGIONS = 'regions';
export const RESOURCE_PROGRAM = 'alchemist_program';
export const RESOURCE_BASE_DEMO_DAYS = 'basedemodays';
export const RESOURCE_DEMO_DAYS = 'demodays';
export const RESOURCE_CLASS_WEBSITES = 'class_websites';
export const RESOURCE_DRY_RUNS = 'dry_runs';
export const RESOURCE_CUSTOMER_SUMMITS = 'customer_summits';
export const RESOURCE_DEMO_DAY_INVITATIONS = 'demoday_users';
export const RESOURCE_DEMO_DAY_ACTIONS = 'demoday_mail_templates';
export const RESOURCE_GDPR_REQUESTS = 'gdpr_requests';
export const RESOURCE_PROFILE_EMAILS = 'profile_emails';
export const RESOURCE_EVENT_AGENDAS = 'event_agendas';
export const RESOURCE_DASHBOARD = 'dashboard';
export const RESOURCE_IFS = 'ifs';
export const RESOURCE_IFS_PARTICIPANT_SETS = 'ifs_participant_sets';
export const RESOURCE_IFS_INVITATIONS = 'ifs_invitations';
export const RESOURCE_IFS_TEMPLATES = 'ifs_mail_templates';
export const RESOURCE_IFS_MATCHES = 'ifs_manual_matches';
export const RESOURCE_IFS_MEETS = 'ifs_meets';
export const RESOURCE_IFS_MEETS_HISTORY = 'ifs_meets_history';
export const RESOURCE_IFS_PLANS = 'ifs_plans';
export const RESOURCE_PERKS = 'perks';
export const RESOURCE_PERK_CATEGORIES = 'perk_categories';
export const RESOURCE_PERK_REVIEWS = 'perk_reviews';
export const RESOURCE_DEMO_DAY_COMPANIES = 'demoday_companies';
export const RESOURCE_SIGNATURES = 'signatures';
export const RESOURCE_ONBOARDING_STEPS = 'onboarding_steps';
export const CAP_COMP_ONBOARDING_STEPS = 'cap_comp_onboarding_steps';
export const RESOURCE_NOTIFICATIONS = 'notifications';
export const RESOURCE_ACLASS_COMPANIES = 'aclass_companies';
export const RESOURCE_RECSYS_FEEDBACK = 'recsys_feedback';

export const INVITE_CONTACT_PSEUDO_RESOURCE = 'invites/contact';
export const INVITE_PEER_PSEUDO_RESOURCE = 'invites/peer';
export const REQUEST_INTRO_PUBLIC_PSEUDO_RESOURCE = 'connections/public_company_request_intro';
export const SHARE_PUBLIC_PSEUDO_RESOURCE = 'connections/public_company_share';

// See alchemist/models/user_company.py:19 (alchemist.models.user_company.TypeOfRelation) in BE Repo
export const RELATIONS = {
  FOUNDER: 0,
  EMPLOYEE: 1,
  BOARD_MEMBER: 2,
  ANGEL_INVESTOR: 3,
  ADVISOR: 4,
  STUDENT: 5,
  INSTITUTIONAL_INVESTOR: 6,
  COACH: 7,
  OTHER: 9,
  ALUMNI: 10,
  isTeam(membership) { return [this.FOUNDER, this.EMPLOYEE, this.ALUMNI].includes(membership.relation); },
  isExperience(membership) { return [this.FOUNDER, this.EMPLOYEE, this.OTHER, this.ALUMNI].includes(membership.relation); },
  isCurrentTeam(membership) { return !membership.end_date && this.isTeam(membership); },
};
RELATIONS.MAPPER = {
  [RELATIONS.FOUNDER]: 'Founder',
  [RELATIONS.EMPLOYEE]: 'Employee',
};

// API urls
export const API_BASE = '/api/v1';
export const API_BASE_OPERATIONS = '/api/v1/operations';
export const ATOMIC_RESULTS = 'atomic:results';

export const API_PEOPLE_BASE_URL = `${API_BASE}/${RESOURCE_PEOPLE}`;
export const API_ROLES_BASE_URL = `${API_BASE}/${RESOURCE_ROLES}`;
export const API_ACCOUNTS_BASE_URL = `${API_BASE}/${RESOURCE_ACCOUNTS}`;
export const API_ACCOUNTS_HINTS_BASE_URL = `${API_BASE}/${RESOURCE_ACCOUNTS_HINTS}`;
export const API_COMPANIES_BASE_URL = `${API_BASE}/${RESOURCE_COMPANIES}`;
export const API_MEMBERSHIP_BASE_URL = `${API_BASE}/${RESOURCE_MEMBERSHIP}`;
export const API_RESOURCES_BASE_URL = `${API_BASE}/${RESOURCE_RESOURCES}`;
export const API_REVIEWS_BASE_URL = `${API_BASE}/${RESOURCE_REVIEWS}`;
export const API_CONNECTIONS_BASE_URL = `${API_BASE}/${RESOURCE_CONNECTIONS}`;
export const API_GENERATE_TEMPLATE_URL = `${API_BASE}/${RESOURCE_CONNECTIONS}/generate_msg`;
export const API_PEOPLE_LISTS_BASE_URL = `${API_BASE}/${RESOURCE_PEOPLE_LISTS}`;
export const API_PEOPLE_LISTS_PERMISSIONS_BASE_URL = `${API_BASE}/${RESOURCE_PEOPLE_LISTS_PERMISSIONS}`;
export const API_PUBLIC_PEOPLE_LISTS_BASE_URL = `${API_BASE}/${RESOURCE_PUBLIC_PEOPLE_LISTS}`;
export const API_SHARED_COMPANIES_LISTS_BASE_URL = `${API_BASE}/${RESOURCE_SHARED_COMPANIES_LISTS}`;
export const API_TAGS_BASE_URL = `${API_BASE}/${RESOURCE_TAG}`;
export const API_ALCHEMIST_CLASSES_BASE_URL = `${API_BASE}/${RESOURCE_ALCHEMIST_CLASSES}`;
export const API_LOCATIONS_BASE_URL = `${API_BASE}/${RESOURCE_LOCATIONS}`;
export const API_PROGARAM_SEARCH_BASE_URL = `${API_BASE}/${RESOURCE_PROGRAM}`;
export const API_DEMO_DAY_BASE_URL = `${API_BASE}/${RESOURCE_DEMO_DAYS}`;
export const API_DEMO_DAY_INVITATIONS_BASE_URL = `${API_BASE}/${RESOURCE_DEMO_DAY_INVITATIONS}`;
export const API_GDPR_REQUESTS_BASE_URL = `${API_BASE}/${RESOURCE_GDPR_REQUESTS}`;
export const API_DASHBOARD_STATS_OVERVIEW = `${API_BASE}/stats/overview`;
export const API_DASHBOARD_STATS_DETAILS = `${API_BASE}/stats/details`;
export const API_DASHBOARD_STATS_META = `${API_BASE}/stats/meta`;
export const API_DASHBOARD_STATS_TIMESERIES = `${API_BASE}/stats/timeseries`;
export const API_IFS_BASE_URL = `${API_BASE}/${RESOURCE_IFS}`;
export const API_IFS_PARTICIPANT_SETS_BASE_URL = `${API_BASE}/${RESOURCE_IFS_PARTICIPANT_SETS}`;
export const API_IFS_INVITATIONS_BASE_URL = `${API_BASE}/${RESOURCE_IFS_INVITATIONS}`;
export const API_IFS_MATCHES_BASE_URL = `${API_BASE}/${RESOURCE_IFS_MATCHES}`;
export const API_IFS_MEETS_HISTORY_BASE_URL = `${API_BASE}/${RESOURCE_IFS_MEETS_HISTORY}`;
export const API_IFS_PLANS_BASE_URL = `${API_BASE}/${RESOURCE_IFS_PLANS}`;
export const API_IFS_MEETS_BASE_URL = `${API_BASE}/${RESOURCE_IFS_MEETS}`;
export const API_PERKS_BASE_URL = `${API_BASE}/${RESOURCE_PERKS}`;
export const API_BULK_INVITE_BASE_URL = `${API_BASE}/invites/register_bulk`;
export const API_BULK_INVITE_SEARCH = `${API_BULK_INVITE_BASE_URL}/search`;
export const API_DEMODAY_COMPANIES_BASE_URL = `${API_BASE}/${RESOURCE_DEMO_DAY_COMPANIES}`;
export const API_SIGNATURES_BASE_URL = `${API_BASE}/${RESOURCE_SIGNATURES}`;
export const API_CAP_COMP_ONBOARDING_STEPS_URL = `${API_BASE}/${CAP_COMP_ONBOARDING_STEPS}`;
export const API_ONBOARDING_STEPS_URL = `${API_BASE}/${RESOURCE_ONBOARDING_STEPS}`;
export const API_NOTIFS_BASE_URL = `${API_BASE}/${RESOURCE_NOTIFICATIONS}`;
export const API_CAPABILITIES_BASE_URL = `${API_BASE}/${RESOURCE_CAPABILITIES}`;
export const API_ACLASS_COMPANIES_BASE_URL = `${API_BASE}/${RESOURCE_ACLASS_COMPANIES}`;
export const API_RECSYS_FEEDBACK_BASE_URL = `${API_BASE}/${RESOURCE_RECSYS_FEEDBACK}`;

// Mappers
export const ROLE_MAPPER = {
  network: 'Network',
  faculty: 'Faculty',
  mentor: 'Mentor',
  angel: 'Angel',
  investor: 'VC',
  strategic_investor: 'Strategic Investor',
  acquirer: 'Acquirer',
  corporate: 'Corporate Innovator',
  industry_analyst: 'Industry Analyst',
  channel_partner: 'Channel Partner',
  press: 'Press',
  customer: 'Customer',
  service_provider: 'Service Provider',
  lecturer: 'Lecturer',
  consultant: 'Freelancer / Consultant',
  alumni_mentor: 'Alumni Mentor',
};

export const MESSAGE_TYPE_MAPPER = {
  RM: 'Request Meeting',
  CM: 'Request Intro',
  book_a_slot: 'Book a slot',
  send_an_email: 'Send Email',
  RI_CI: 'Request Introduction',
};

export const MESSAGE_STATE_MAPPER = {
  draft: 'Draft',
  sent: 'Sent',
  opened: 'Opened',
  bounced: 'Bounced',
  accepted: 'Connected',
  declined: 'Declined',
  meeting_verified: 'Meeting Held',
};

export const MESSAGE_STATE_COLOR_MAPPER = {
  draft: 'white',
  sent: 'blue',
  opened: 'blue',
  bounced: 'red',
  accepted: 'green',
  declined: 'gray',
  meeting_verified: 'green',
};

export const MESSAGE_STATE_TO_TEXT_MAPPER = {
  draft: 'View Draft',
  sent: 'Message Sent',
  opened: 'Message Opened',
  bounced: 'Message Bounced',
  accepted: 'Request Accepted',
  declined: 'Request Declined',
  meeting_verified: 'Meeting Held',
};

export const MESSAGE_STATE_TO_DATE_MAPPER = {
  sent: 'sent_at',
  accepted: 'accept_at',
  draft: 'created_at',
};

export const COMPANY_TYPE_MAPPER = {
  startup: 'Startup',
  fund: 'Fund',
  enterprise: 'Corporation',
  service_provider: 'Service Provider',
  smb: 'SMB',
  nonprofit: 'Non-Profit',
  government: 'Government',
  education: 'Education',
};

export const MENTOR_TYPE_FORM_MAPPER = {
  'Regular Mentor': 'Regular Mentor - Dedicated office hours',
  'Directory Listing': 'Directory Listing - By approval',
};

export const ROLES = {
  investor: 'investor',
  angel: 'angel',
  service_provider: 'service_provider',
  press: 'press',
  admin: 'admin',
  mentor: 'mentor',
  network: 'network',
  faculty: 'faculty',
  founder: 'founder',
  corporate: 'corporate',
  consultant: 'consultant',
  lecturer: 'lecturer',
  industry_analyst: 'industry_analyst',
  strategic_investor: 'strategic_investor',
  channel_partner: 'channel_partner',
  acquirer: 'acquirer',
  customer: 'customer',
  alumni_mentor: 'alumni_mentor',
  limited_partner: 'limited_partner',
  partner: 'partner',
  class_coach: 'class_coach',
};
export const VARIETY = {
  demoday: 'demoday',
  founder: 'founder',
  admin: 'admin',
  class_coach: 'class_coach',
};
export const PERMISSION = {
  onboarding: 'onboarding',
  network_onboarding: 'network_onboarding',
  onboarding_kickoff_steps: 'onboarding_kickoff_steps',
  founder: 'founder',
  admin: 'admin',
  demoday: 'demoday',
  registration: 'registration',
  demo_active: 'demo_active',
  lp: 'limited_partner',
  corporate: 'corporate',
  ifs: 'ifs',
  class_coach: 'class_coach',
  partner: 'partner',
};

export const CAPABILITIES = {
  admin: 0,
  founder: 10,
  class_coach: 11,
  partner: 19,
  limited_partner: 20,
  corporate: 25,
  investor: 30,
  demoday: 40,
};

Object.defineProperties(CAPABILITIES, {
  // These properties and functions will not appear in Object.keys(), for in, etc
  onboardingCaps: {
    enumerable: false,
    value: [CAPABILITIES.admin, CAPABILITIES.founder, CAPABILITIES.class_coach],
  },
  hasOnboarding: {
    enumerable: false,
    value: (cap) => CAPABILITIES.onboardingCaps.includes(Number.isInteger(cap) ? cap : CAPABILITIES[cap]),
  },
  networkOnboardingCaps: {
    enumerable: false,
    value: [CAPABILITIES.partner, CAPABILITIES.limited_partner, CAPABILITIES.corporate, CAPABILITIES.investor],
  },
  hasNetworkOnboarding: {
    enumerable: false,
    value: (cap) => CAPABILITIES.networkOnboardingCaps.includes(Number.isInteger(cap) ? cap : CAPABILITIES[cap]),
  },
  hasAnyOnboarding: {
    enumerable: false,
    value: (cap) => Boolean(CAPABILITIES.hasOnboarding(cap) || CAPABILITIES.hasNetworkOnboarding(cap)),
  },
  canUseComments: {
    enumerable: false,
    value: (cap) => [
      CAPABILITIES.admin,
      CAPABILITIES.founder,
      CAPABILITIES.class_coach,
      CAPABILITIES.partner,
      CAPABILITIES.limited_partner,
      CAPABILITIES.corporate,
    ].includes(Number.isInteger(cap) ? cap : CAPABILITIES[cap]),
  },
  canUseLists: {
    enumerable: false,
    value: (cap) => [
      CAPABILITIES.admin,
      CAPABILITIES.founder,
      CAPABILITIES.class_coach,
      CAPABILITIES.limited_partner,
    ].includes(Number.isInteger(cap) ? cap : CAPABILITIES[cap]),
  },
  canUseResources: {
    enumerable: false,
    value: (cap) => [
      CAPABILITIES.admin,
      CAPABILITIES.founder,
      CAPABILITIES.class_coach,
      CAPABILITIES.partner,
    ].includes(Number.isInteger(cap) ? cap : CAPABILITIES[cap]),
  },
});

export const CAPABILITIES_MAPPER = {
  0: 'Admin',
  10: 'Founder',
  11: 'Class Coach',
  19: 'Partner',
  20: 'Limited Partner',
  25: 'Corporate Innovator',
  30: 'Investor',
  40: 'Demoday',
};

export const NETWORK_ONB = 'network_onboarding';
export const MSG_TYPE = {
  REQUEST_MEETING: 'RM', // founder reachout type
  REQUEST_INTRO: 'CM', // founder reachout type
  ADMIN_CONNECT: 'INTRO', // alchemist connect type
  COMPANY_REQ_INTRO: 'CCM', // investor reachout type
  DEMO_CONNECT_IN: 'DCIN', // demo day type
  DEMO_RESERVE: 'DRESERVE', // demo day type
  DEMO_SHARE: 'DSHARE', // demo day type
  DEMO_MEET: 'DMEET', // demo day type
  REQUEST_INTRO_LP: 'RI_LP', // investor reachout type
  PUBLIC_COMPANY_REQ_INTRO: 'PCCM', // public type
  PUBLIC_COMPANY_SHARE: 'PUBLIC_COMPANY_SHARE', // public type
  RI_CI: 'RI_CI', // investor reachout type
};

export const MSG_TYPE_MAPPER = {
  [MSG_TYPE.REQUEST_MEETING]: 'Founder Reachout',
  [MSG_TYPE.REQUEST_INTRO]: 'Founder Reachout',
  [MSG_TYPE.ADMIN_CONNECT]: 'Alchemist Enabled Connect',
  [MSG_TYPE.COMPANY_REQ_INTRO]: 'LP Reachout: Startup',
  [MSG_TYPE.DEMO_CONNECT_IN]: 'Demo Day: Connect',
  [MSG_TYPE.DEMO_RESERVE]: 'Demo Day: Reserve',
  [MSG_TYPE.DEMO_SHARE]: 'Demo Day: Share',
  [MSG_TYPE.DEMO_MEET]: 'Demo Day: Meet',
  [MSG_TYPE.REQUEST_INTRO_LP]: 'LP Reachout',
  [MSG_TYPE.PUBLIC_COMPANY_REQ_INTRO]: 'Alchemist Website: Connect',
  [MSG_TYPE.PUBLIC_COMPANY_SHARE]: 'Alchemist Website: Share',
  [MSG_TYPE.RI_CI]: 'Corporate Innovator Reachout',
};

export const MSG_TABS = {
  connection: 'Connection',
  reminder: 'Auto-Reminder',
  request: 'Introduction Request',
};

export const FORM_NOTE_TEXT_MAPPER = {
  funding: 'After closing a round you will be able to reach out again to the people that you have previously contacted through the Vault.',
  investors: 'Emails are sent to investors and advisors for verification.',
};

export const NO_FAVORITE_ADDED_MSG = 'You have not added any companies to favorite yet. Please click on the icon in the companies preview to add companies to favorites.';

export const LP_OR_ABOVE = [PERMISSION.admin, PERMISSION.founder, PERMISSION.class_coach, PERMISSION.lp];
export const CORP_INNOV_OR_ABOVE = [PERMISSION.admin, PERMISSION.founder, PERMISSION.class_coach, PERMISSION.lp, PERMISSION.corporate];
export const ALL_STD_AUTH = [...LP_OR_ABOVE];
export const DD_OR_ABOVE = [...ALL_STD_AUTH, PERMISSION.demoday, PERMISSION.demo_active, PERMISSION.corporate];

export const CLASS_TYPE = {
  alchemist: 'alchemist',
  alchemistx: 'alchemistx',
};
export const CLASS_TYPE_SLIDER_OPTIONS = {
  leftOption: { label: 'Alchemist (USA)', value: CLASS_TYPE.alchemist },
  rightOption: { label: 'AlchemistX', value: CLASS_TYPE.alchemistx },
};

export const INVESTMENT_ROUNDS = ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Series C', 'Series D',
  'Friends & Family', 'Angel', 'Self', 'Bridge'];

export const DEMO_DAY_TYPE_MAPPER = {
  [RESOURCE_CUSTOMER_SUMMITS]: 'Customer Summit',
  [RESOURCE_DEMO_DAYS]: 'Demo Day',
  [RESOURCE_CLASS_WEBSITES]: 'Sneak Peek',
  [RESOURCE_DRY_RUNS]: 'Dry Run',
};
export const ATTEND_TYPE = Object.freeze({
  remotely: 'remotely',
  in_person: 'in_person',
  remotely_live: 'remotely_live',
  remotely_recorded: 'remotely_recorded',
});
export const REMOTE_ATTEND_TYPES = [ATTEND_TYPE.remotely_recorded, ATTEND_TYPE.remotely_live, ATTEND_TYPE.remotely];
export const ATTENDANCE_TYPE_MAPPER = {
  [ATTEND_TYPE.remotely]: 'Remotely',
  [ATTEND_TYPE.in_person]: 'In-person',
  [ATTEND_TYPE.remotely_live]: 'Watch Live Stream',
  [ATTEND_TYPE.remotely_recorded]: 'Watch Later',
};
export const ATTENDANCE_ROLE_MAPPER = {
  customer: 'Customer',
  alchemist_partner: 'Alchemist Partner',
  angel_investor: 'Angel Investor',
  vc: 'VC',
  founder: 'Founder',
  press: 'Press',
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const ButtonsHolder = ({
  className, expand, mobileInline, children, spaceEvenly,
}) => (
  <div
    className={
      classNames(
        'dsa-button__holder',
        className,
        { '_flex-expand_': expand, '_inline-buttons-mobile_': mobileInline, 'justify-content-evenly': spaceEvenly },
      )
    }
  >
    {children}
  </div>
);

ButtonsHolder.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  expand: PropTypes.bool,
  mobileInline: PropTypes.bool,
  spaceEvenly: PropTypes.bool,
};

export default ButtonsHolder;

import React from 'react';

import DocPager from 'components/DocPager/DocPager';
import ButtonsHolder from 'components/Buttons/ButtonsHolder';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import LiVerticalDivider from 'components/SideBar/LiVerticalDivider';
import { HeadingLarge } from 'components/Text/Headings';
import { TextMdNew } from 'components/Text/Body';
import DesignSystemMain from 'designSystemDocs/Sections/DesignSystemMain';


const Buttons = () => (
  <DesignSystemMain>
    <DocPager>
      <HeadingLarge>Buttons</HeadingLarge>
      <TextMdNew>
        A button is used to trigger an event. The types of buttons used in the Vault are solid color, outlined and a plain text link.
      </TextMdNew>
      <LiVerticalDivider />
      <TextMdNew>
        The base level class .dsa-button is used in to create a button element. Used alone the class .dsa-button creates the default button. The modifier classes are always used in conjunction with the base class.
      </TextMdNew>
      <ButtonsHolder className="mt-7">
        <ButtonPrim>Primary Button</ButtonPrim>
        <ButtonSec>Secondary Button</ButtonSec>
      </ButtonsHolder>
    </DocPager>
  </DesignSystemMain>
);

export default Buttons;

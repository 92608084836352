import 'whatwg-fetch';
import jstz from 'jstimezonedetect';
import inEU, { isEULocale, isInEUTimezone } from '@segment/in-eu';
import { all, call, put, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';

import { setToken } from 'containers/AuthProvider/actions';
import { selectToken } from 'containers/AuthProvider/selectors';
import { offlineReadyRequest } from 'containers/LostConnectionOverlay/saga';
import { API_BASE_OPERATIONS } from 'containers/App/constants';
import { SAMPLE_MAPPER } from 'containers/Forms/constants';
import { makeSelectObjectRaw } from 'containers/App/selectors';
import { FOUNDER_COMPANY } from 'containers/FounderChooseModal/constants';

import { getDecodedToken, reloginPath, setTokenInLocalStorage } from './auth';
import { getCurrentTimestamp } from './dateTime';
import { setLastPage } from './general';
import { resRef } from './refs';
import { formDataToJApi } from './formToJsonApi';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

const sameOriginRegex = new RegExp(`^${window.location.origin}`);
/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function* checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    yield refreshTokenFromHeader(response);
    return response;
  }

  const decodedToken = getDecodedToken();
  if (!window.location.pathname.match(/^\/(signin|register-profile|sign-(up|in)|email-verification)/)
    && [403, 401].includes(response.status)
    && response.url.match(sameOriginRegex)
    && getCurrentTimestamp() > decodedToken?.exp) {
    delete localStorage.token;
    setLastPage();
    window.location = reloginPath(decodedToken);
  }

  const error = new Error(response && response.statusText);
  error.response = response;
  throw error;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function nonSagaCheckStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    if (response.headers.has('new_token')) {
      setTokenInLocalStorage(response.headers.get('new_token'));
    }
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function setAuthHeader(options) {
  const authHeaders = { ...options.headers };

  authHeaders['BROWSER-TZ'] = jstz.determine().name();

  if (localStorage.getItem('token')) authHeaders.authorization = `JWT ${localStorage.getItem('token')}`;
  if (inEU()) {
    authHeaders['IE-EU'] = 'True';
    authHeaders['IE-EU-LOCALE'] = isEULocale() ? 'True' : 'False';
    authHeaders['IE-EU-TZ'] = isInEUTimezone() ? 'True' : 'False';
  }

  if (JSON.parse(localStorage.getItem(FOUNDER_COMPANY))) {
    const companyAclass = JSON.parse(localStorage.getItem(FOUNDER_COMPANY));
    authHeaders['context-company-id'] = companyAclass?.company;
    authHeaders['context-aclass-id'] = companyAclass?.aclass;
  }

  return authHeaders;
}

/**
 * Requests a URL, returning a promise
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function nonSagaRequest(url, options = {}) {
  const headers = setAuthHeader(options);
  requestHooks.onFetch.forEach((hook) => hook());
  return fetch(url, { ...options, headers })
    .then(nonSagaCheckStatus)
    .then(parseJSON);
}

/**
 * Requests a URL, returning a promise
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function* request(url, options = {}) {
  const headers = setAuthHeader(options);
  requestHooks.onFetch.forEach((hook) => hook());
  let response = yield offlineReadyRequest(url, { ...options, headers });
  response = yield checkStatus(response);
  response = yield parseJSON(response);
  return response;
}

export function* postRaw(url, body = null, additionalOptions = {}) {
  const options = {
    method: 'POST',
    body: body ? JSON.stringify(body) : null,
    ...additionalOptions,
  };
  const headers = setAuthHeader(options);
  return yield offlineReadyRequest(url, { ...options, headers });
}

export function* post(...params) {
  let response = yield postRaw(...params);
  response = yield checkStatus(response);
  return yield parseJSON(response);
}

export function patch(url, body, options = {}) {
  return request(url, { ...options, method: 'PATCH', body: body ? JSON.stringify(body) : null });
}

export function deleteReq(url, body, options = {}) {
  return request(url, { ...options, method: 'DELETE', body: body ? JSON.stringify(body) : null });
}

export function* refreshTokenFromHeader(response) {
  if (response.headers.has('new_token')) {
    const newToken = response.headers.get('new_token');
    const currentToken = yield select(selectToken);
    if (currentToken === newToken) {
      return;
    }

    localStorage.token = newToken;
    yield put(setToken(newToken));
    const decodedToken = jwtDecode(newToken);
    if (newToken) {
      localStorage.lastLoginFromForm = decodedToken && decodedToken.from_login_form ? 'true' : '';
    }
  }
}

export const requestHooks = { onFetch: [] };

export const requestStringForObject = (obj) => {
  if (obj && obj.id > 0) return (obj.isDeleted || obj.is_deleted ? 'remove' : 'update');
  return 'add';
};

function* getObject(objRef) {
  return yield select(makeSelectObjectRaw(objRef));
}

export function* atomicReq(resources, useRawResources = true) {
  const rawResources = yield all(resources.map((r) => {
    if (r.type && r.id) {
      return call(getObject, resRef(r));
    }
    return null;
  }));
  const body = {
    'atomic:operations': resources?.map((r) => {
      const operation = requestStringForObject(r);
      let targetResource = {};
      if (r.href) {
        targetResource = { href: r.href };
      } else if (operation === 'add') {
        targetResource = {};
      } else {
        // when operation is updating a resource directly (not updating a relationship of a resource),
        // we can pass the ref like below or you can also not pass ref at all
        targetResource = { ref: resRef(r) };
      }

      let editedObject;
      if (useRawResources && r.type && r.id) {
        editedObject = find(rawResources, (rawR) => rawR?.id === r.id);
      } else {
        editedObject = SAMPLE_MAPPER[r.type];
      }
      return {
        op: operation,
        ...targetResource,
        ...(operation === 'remove'
          ? {}
          : {
            data: r.data
              || pickBy(formDataToJApi(
                { ...editedObject, id: r.id },
                r,
              ), (v) => v !== undefined),
          }
        ),
      };
    }),
  };
  return yield request(API_BASE_OPERATIONS, { method: 'POST', body: body ? JSON.stringify(body) : null });
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ToggleButtonBase from './ToggleButtonBase';


const ToggleSec = ({
  isDesktop, noMargin, size, responsiveCaret, isStyled = true, isPrimary, parentHeight = false, IconComponent, ...props
}) => (
  <ToggleButtonBase
    caret={responsiveCaret && isDesktop}
    IconComponent={IconComponent}
    {...props}
    className={classNames(
      `dsa-button ${props.className}`,
      size && `btn-${size}`,
      'btn',
      {
        'btn-primary': isStyled && isPrimary,
        'btn-outline-primary': isStyled && !isPrimary,
        'no-margin': noMargin,
        'h-100': parentHeight,
      }
    )}
  />
);


ToggleSec.propTypes = {
  isDesktop: PropTypes.bool,
  noMargin: PropTypes.bool,
  responsiveCaret: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  isPrimary: PropTypes.bool,
  isStyled: PropTypes.bool,
  parentHeight: PropTypes.bool,
  IconComponent: PropTypes.any,
};
export const ToggleSecCaret = ResponsiveHoC(DefaultPropsHoC(ToggleSec, { responsiveCaret: true }));
export const ToggleSecCaretSm = ResponsiveHoC(DefaultPropsHoC(ToggleSec, { responsiveCaret: true, size: 'sm' }));
export default ResponsiveHoC(ToggleSec);

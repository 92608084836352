/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import { LOAD_CONTENT_SUCCESS, SET_SELECTED_OBJECT, BROWSE_SELECTED_OBJECT } from 'utils/filter/constants';
import { formatStr } from 'utils/general';

export const PEOPLE_PAGE_IDENTIFIER = 'People';

export const PEOPLE_LOAD_CONTENT_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, PEOPLE_PAGE_IDENTIFIER);
export const PEOPLE_SET_SELECTED_OBJECT = formatStr(SET_SELECTED_OBJECT, PEOPLE_PAGE_IDENTIFIER);
export const PEOPLE_BROWSE_SELECTED_OBJECT = formatStr(BROWSE_SELECTED_OBJECT, PEOPLE_PAGE_IDENTIFIER);


export const SET_INVITE_COMPANY_MODAL = 'alchemist/People/SET_INVITE_COMPANY_MODAL';
export const SET_INVITE_CLASS_COACH_MODAL = 'alchemist/People/SET_INVITE_CLASS_COACH_MODAL';
export const SET_PEOPLE_LISTS_MODAL = 'alchemist/People/SET_PEOPLE_LISTS_MODAL';
export const MARK_PEOPLE_LIST = 'alchemist/People/MARK_PEOPLE_LIST';
export const CLEAR_MARKED_PEOPLE_LISTS = 'alchemist/People/CLEAR_MARKED_PEOPLE_LISTS';
export const ADD_MARKED_PEOPLE_TO_MARKED_LISTS = 'alchemist/People/ADD_MARKED_PEOPLE_TO_MARKED_LISTS';
export const INCREASE_SEARCH_RESULTS_OFFSET = 'alchemist/People/INCREASE_SEARCH_RESULTS_OFFSET';
export const RESET_SEARCH_RESULTS_OFFSET = 'alchemist/People/RESET_SEARCH_RESULTS_OFFSET';

export const SET_MERGE_MODAL = 'alchemist/People/SET_MERGE_MODAL';
export const SELECT_MERGE_TARGET = 'alchemist/People/SELECT_MERGE_TARGET';
export const REQUEST_MERGE = 'alchemist/People/REQUEST_MERGE';
export const MERGE_SUCCESS = 'alchemist/People/MERGE_SUCCESS';
export const MERGE_FAILED = 'alchemist/People/MERGE_FAILED';
export const OPEN_MOBILE_FILTERS_MODAL = 'alchemist/People/OPEN_MOBILE_FILTERS_MODAL';
export const CLOSE_MOBILE_FILTERS_MODAL = 'alchemist/People/CLOSE_MOBILE_FILTERS_MODAL';
export const TOGGLE_ACCORDION_ITEM = 'alchemist/People/TOGGLE_ACCORDION_ITEM';
export const SET_IS_RECOMMENDATION_USEFUL = 'alchemist/People/SET_IS_RECOMMENDATION_USEFUL';

export const TOGGLE_PEOPLE_FILTERS = 'alchemist/Companies/TOGGLE_PEOPLE_FILTERS';

export const RESULTS_TO_ADD_PER_ROUND = 500;
export const RECSYS_RANKING = 'recsys_ranking';

export const SORTING_A_TO_Z_MAPPER = {
  nicename: 'Name',
  title: 'Title',
  'company.name': 'Company',
};

export const SORTING_MAPPER = {
  '-responsiveness': 'Highest Responsiveness',
  '-bayes_average_rating': 'Highest Ranking',
  '-updated_at': 'Most Recent Updates',
  bayes_average_rating: 'Lowest Ranking',
  responsiveness: 'Lowest Responsiveness',
  [RECSYS_RANKING]: 'Recommended',
};
  // 'Profiles with Notes',

// Filter UI Sections
export const ROLES_SECTION = 'ROLES';
export const SORTING_SECTION = 'SORTING';

// SORTING OPTIONS
export const SORT_OPTIONS_FOUNDER = [
  { sortKey: 'bayes_average_rating', order: -1 },
  { sortKey: 'responsiveness', order: -1 },
  { sortKey: 'updated_at', order: -1 },
];

export const SORT_OPTIONS_ADMIN = SORT_OPTIONS_FOUNDER.concat([
  { sortKey: 'bayes_average_rating' },
  { sortKey: 'responsiveness' },
]);

import { createSelector } from 'reselect';

import { makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { RESOURCE_MEMBERSHIP, RESOURCE_INVESTMENT } from 'containers/App/constants';

const selectPeopleVerifiableRef = (state) => state.getIn(['peopleVerifiable', 'verifiableRef']);

const makeSelectVerifiable = () => makeSelectObjectFromRefSelector(selectPeopleVerifiableRef);

const makeSelectVerifiedCompany = () => createSelector(
  makeSelectVerifiable(),
  (verifiable) => (verifiable.type === RESOURCE_MEMBERSHIP && verifiable.company && verifiable.company())
    || (verifiable.type === RESOURCE_INVESTMENT && verifiable.investment_round && verifiable.investment_round().company
      && verifiable.investment_round().company())
);

const makeSelectVerifyingProfile = () => createSelector(
  makeSelectVerifiable(),
  (verifiable) => (verifiable.type === RESOURCE_MEMBERSHIP && verifiable.user && verifiable.user())
    || (verifiable.type === RESOURCE_INVESTMENT && verifiable.investor_profile && verifiable.investor_profile())
);

const makeSelectTypeLabel = () => createSelector(
  makeSelectVerifiable(),
  (verifiable) => (verifiable.type === RESOURCE_MEMBERSHIP && 'Advisor')
    || (verifiable.type === RESOURCE_INVESTMENT && verifiable.investing_company && 'Investor')
    || (verifiable.type === RESOURCE_INVESTMENT && !verifiable.investing_company && 'Angel')
);

export {
  makeSelectVerifiable,
  makeSelectVerifiedCompany,
  makeSelectVerifyingProfile,
  makeSelectTypeLabel,
};

/**
 *
 * GdprFooter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { openConsentManager } from '@segment/consent-manager';
import { connect } from 'react-redux';

import FooterButton from 'components/OnePager/Footer/FooterButton';
import Footer from 'components/OnePager/Footer/OnePagerFooter';

import ContactUsButton from '../ContactUsButton';
import { DOC_TYPE } from '../DocsModal/constants';
import { openDocModal } from '../DocsModal/actions';


const GdprFooter = (props) => (
  <Footer>
    <FooterButton onClick={() => props.openDocModal(DOC_TYPE.termsGdpr)}>Terms and Conditions</FooterButton>
    <FooterButton onClick={() => props.openDocModal(DOC_TYPE.privacyPolicy)}>Privacy Policy</FooterButton>
    {/* <FooterButton onClick={() => props.openDocModal(DOC_TYPE.dataAgreement)}>Data Processing Agreement</FooterButton> */}
    <FooterButton onClick={openConsentManager}>Cookie Policy</FooterButton>
    <FooterButton to="/gdpr/faq">GDPR FAQ</FooterButton>
    <ContactUsButton buttonTag={FooterButton} />
  </Footer>);


GdprFooter.propTypes = { openDocModal: PropTypes.func.isRequired };

const mapDispatchToProps = { openDocModal };

export default connect(null, mapDispatchToProps)(GdprFooter);


import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RadioButton from 'components/Buttons/RadioButton';
import { makeSelectAccount } from 'containers/AuthProvider/selectors';
import { resRef } from 'utils/refs';

import { makeSelectMergeTargetRef, selectMarkedMergeableLists } from '../selectors';
import { setMergeTarget } from '../actions';


const SelectListRadioButton = ({
  list, selectList, selectedList, myAccount,
}) => (
  <div className="dsa-radio-button">
    <RadioButton
      label={list.title}
      value={resRef(list)}
      setValue={selectList}
      currentValue={resRef(selectedList)}
      disabled={!(list.event_list || list.creator || list.creator().id === myAccount.id) || list.myPermission?.update === false}
    />
    {list.myPermission?.update === false && <>&nbsp;(view-only list)</>}
  </div>
);

SelectListRadioButton.propTypes = {
  selectList: PropTypes.func,
  list: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    event_list: PropTypes.bool,
    creator: PropTypes.func,
    myPermission: {
      update: PropTypes.bool,
    },
  }),
  selectedList: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ id: PropTypes.number, type: PropTypes.string }),
  ]),
  myAccount: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ id: PropTypes.number, type: PropTypes.string }),
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  selectList: (list) => dispatch(setMergeTarget(list)),
});

const mapStateToProps = createStructuredSelector({
  myAccount: makeSelectAccount(),
  selectedList: makeSelectMergeTargetRef(),
  markedLists: selectMarkedMergeableLists,
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectListRadioButton);

import { makeSubSelector } from 'utils/selectors';

/**
 * Direct selector to the mobileDesktopSwitcher state domain
 */
const selectSwitcherDomain = (state) => state.get('mobileDesktopSwitcher');

const selectIsDesktopVersion = makeSubSelector(selectSwitcherDomain, ['isDesktopVersion']);

export { selectIsDesktopVersion };

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'components/Generic/Tooltip';


class TooltipBase extends React.Component {
  constructor(props) {
    super(props);
    this.outerRef = React.createRef();
  }

  render() {
    const {
      children,
      hoverContent,
      delay = 10,
      placement = 'top',
      size,
      tooltipClassName,
      contentClassName,
      Component = 'div',
    } = this.props;
    return (
      <>
        <Component className={contentClassName} ref={this.outerRef}>
          {hoverContent}
        </Component>
        {children
          && (
            <Tooltip
              placement={placement}
              autohide={false}
              targetRef={this.outerRef}
              delay={delay}
              className={classNames(tooltipClassName, 'dsa-tooltip-prim', size ? `_${size}_` : '')}
            >{children}
            </Tooltip>)}
      </>
    );
  }
}


TooltipBase.propTypes = {
  children: PropTypes.any,
  hoverContent: PropTypes.any,
  delay: PropTypes.number,
  tooltipClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  placement: PropTypes.string,
  size: PropTypes.string,
  Component: PropTypes.element,
};


export default TooltipBase;

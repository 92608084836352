import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { stringify } from 'qs';

import { MOBILE_WIDTH } from './constants';
import { EQ_OP } from './filter/constants';

export function formatStr(str, ...args) {
  let formattedStr = str;

  for (let i = 0; i < args.length; i++) {
    formattedStr = formattedStr.replace(`{${i}}`, args[i]);
  }
  return formattedStr;
}

export function mergeDeep(orig, obj) {
  const o = orig ? { ...orig } : {};

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const val = obj[key];
      if (val && typeof val === 'object' && typeof o[key] === 'object') {
        const mergeResult = mergeDeep(o[key], val);
        o[key] = Array.isArray(o[key]) ? Object.values(o[key]) : mergeResult;
      } else {
        o[key] = val && typeof val === 'object' ? { ...val } : val;
      }
    }
  });

  return o;
}

/** Convert toJS immutables if the attribute is an immutable. Otherwise return the same argument */
export const toJS = (immutableOrNot) => immutableOrNot && immutableOrNot.toJS ? immutableOrNot.toJS() : immutableOrNot;

export function isShallowEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }
  if (!obj1 || !obj2) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let i = 0; i < keys1.length; i++) {
    if (!(keys1[i] in obj2) || obj1[keys1[i]] !== obj2[keys1[i]]) {
      return false;
    }
  }
  return true;
}

export function parseEmailName(emailStr) {
  const emailMatch = emailStr && emailStr.match(/(.+?) <(.+?)>/);
  return emailMatch ? emailMatch[1] : '';
}

/**
 * e.g. filterObjectFields({a:2, b:3, c:17}, [a, c]) === {a:2, c:17}
 *
 * @param obj the original object
 * @param fields the fields we want to extract
 */
export function filterObjectFields(obj, fields) {
  return fields.reduce((result, field) => ({ ...result, [field]: obj[field] }), {});
}

export function listHasUser(userList, searchUsr) {
  return Boolean(userList.user_ids && userList.user_ids.has(searchUsr.id));
}

export const highlightInHtml = (html, search) => {
  if (!search || !html || !html.toLowerCase().includes(search.toLowerCase())) {
    return html;
  }
  if (!html.includes('<') || !html.includes('>')) {
    return `<p>${html.replace(new RegExp(`${escapeRegExp(search)}`, 'i'), addHighlightSpan)}</p>`;
  }
  return html.replace(
    new RegExp(`>([^<>]*)(${escapeRegExp(search)})([^<>]*)<`, 'i'),
    ($0, $1, $2, $3) => `>${$1}${addHighlightSpan($2)}${$3}<`
  );
};

export const highlightInMd = (html, search) => {
  if (!search || !html || !html.toLowerCase().includes(search.toLowerCase())) {
    return html;
  }

  return html.replace(new RegExp(escapeRegExp(search), 'i'), ($0) => `\`${$0}\``);
};
const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export const replaceEmptyPTagWithBrTag = (htmlString) => htmlString.replace(/<p><\/p>/gi, '<br/>');

const addHighlightSpan = (text) => `<span class="dsa-highlight-search">${text}</span>`;

export function updateLocation(newLocation) {
  if (window.location.pathname !== newLocation) {
    window.history.pushState({}, 'HOLA :)', newLocation);
  }
}

export const matchArrayOfObjectsFields = (arr1, arr2, fields) => arr1.length === arr2.length
  && !arr1.find((el1) => !arr2.find((el2) => isEqual(pick(el1, fields), pick(el2, fields))));

export const pluralize = (amount, word1, wordPlural) => amount > 1 ? wordPlural || `${word1}s` : word1;

export const handlePlural = (amount, word1, wordPlural) => (
  `${amount > 1 ? `${amount} ` : ''}${pluralize(amount, word1, wordPlural)}`);

export const concatenateWordsWithAnd = (words) => {
  if (words.length === 0) return '';

  if (words.length === 1) return words[0];

  const lastWord = words.pop();
  return `${words.join(', ')} and ${lastWord}`;
};

export const trueOr0 = (trueOr0Var) => !!trueOr0Var || trueOr0Var === 0;
export const nullToMinus1 = (v) => trueOr0(v) ? v : -1;

export const castToArray = (arrOrNotArray) => Array.isArray(arrOrNotArray) ? arrOrNotArray : [arrOrNotArray];

export const cycleFromUntil = (from, until) => (i) => i >= until ? from : (i || from) + 1;

export const resetSection = (state, initalState, keyOrPath) => Array.isArray(keyOrPath)
  ? state.setIn(keyOrPath, initalState.getIn(keyOrPath))
  : state.set(keyOrPath, initalState.get(keyOrPath));

export const objfromArray = (arr, keyCallback) => arr.reduce((obj, item) => ({ ...obj, [keyCallback(item)]: item }), {});

export const clamp = (min, max) => (num) => Math.min(Math.max(num, min), max);

/**
 * compare 2 objects or immutables on a defined set of fields
 *
 * @param objOrImmutable1
 * @param objOrImmutable2
 * @param fields
 * @returns {boolean}
 */
export function easyFieldsCompare(objOrImmutable1, objOrImmutable2, fields) {
  const obj1 = toJS(objOrImmutable1);
  const obj2 = toJS(objOrImmutable2);

  for (let i = 0; i < fields.length; i++) {
    if (obj1[fields[i]] !== obj2[fields[i]]) return false;
  }

  return true;
}

export function romanize(num) {
  if (Number.isNaN(num)) {
    return '';
  }
  const digits = String(+num).split('');
  const key = [
    ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'],
    ['', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'],
    ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'],
  ];
  let roman = '';
  for (let i = 0; digits.length && i < key.length; i++) {
    roman = (key[i][+digits.pop()] || '') + roman;
  }
  return Array(+digits.join('') + 1).join('M') + roman;
}
export const makeTwoDigits = (num) => num < 10 ? `0${num}` : num;

export function getGoogleCalLink(text, fromDate, untilDate, details, location, ctz = 'America/Los_Angeles') {
  const qsParams = {
    text,
    dates: `${fromDate}/${untilDate}`,
    action: 'TEMPLATE',
    details,
    location: location || undefined,
    sf: true,
    output: 'xml',
    ctz,
  };

  return `https://www.google.com/calendar/render?${stringify(qsParams, { format: 'RFC1738' })}`;
}

export function truncateInnerHTML(el, passedOriginalHTML) {
  if (!el) {
    return { needsTooltip: false, originalHTML: null };
  }
  if (passedOriginalHTML) {
    // eslint-disable-next-line no-param-reassign
    el.innerHTML = passedOriginalHTML;
  }
  const originalHTML = passedOriginalHTML || el.innerHTML;
  const needsTooltip = el.parentNode.scrollHeight > el.parentNode.offsetHeight;
  const wordArray = el.innerHTML.replace(/<\//g, ' </').split(' ');
  while (el.parentNode.scrollHeight > el.parentNode.offsetHeight) {
    wordArray.pop();
    // eslint-disable-next-line no-param-reassign
    el.innerHTML = `${wordArray.join(' ')} ...`;
  }
  return { needsTooltip, originalHTML };
}

export const getSignInUpString = (isSignUp) => isSignUp ? 'Up / Sign In' : 'In';
export const setLastPage = () => {
  if (!window.location.pathname.match(/^\/sign-?(up|in)$/)) {
    localStorage.last_page = window.location.pathname + window.location.search;
  }
};

export const currentBaseUrl = () => `${window.location.protocol}//${window.location.hostname}${(window.location.port ? `:${window.location.port}` : '')}`;

export const setDefaultImg = (e) => {
  e.target.src = '/default_user.jpg';
  e.target.onerror = null;
};

export const setDefaultCompanyImg = (e) => {
  e.target.src = '/default_company.png';
  e.target.onerror = null;
};

export const getClickableLink = (link) => {
  if (!link) return '';
  return link.startsWith('http://') || link.startsWith('https://') ?
    link
    : `http://${link}`;
};

export const getFourRandomNumbers = () => (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);

/* Source:
 * https://stackoverflow.com/questions/56966672/copy-the-html-table-to-clipboard-in-reactjs
 */
export const copyTable = (tableClassName) => {
  const elTable = document.querySelector(tableClassName);

  let range; let
    sel;

  // Ensure that range and selection are supported by the browsers
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    // unselect any element in the page
    sel.removeAllRanges();

    try {
      range.selectNodeContents(elTable);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable);
      sel.addRange(range);
    }

    document.execCommand('copy');
  }

  sel.removeAllRanges();
};

export const checkLandscapeModeEnabled = () => {
  try {
    const screen = window?.screen;
    const angle = screen?.orientation?.angle;
    const isLandscape = (angle === 90 || angle === -90) && (screen?.height <= MOBILE_WIDTH);
    return isLandscape;
  } catch (e) {
    return false;
  }
};

// Matches `/path_here/`
export const extractIdFromUrl = (url) => {
  const regex = /\/(\d+)\//;
  const match = regex.exec(url);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const removeStyleTags = (htmlString) => {
  const styleTagRegex = /<style\b[^>]*>(.*?)<\/style>/gs;
  const cleanedHtmlString = htmlString.replace(styleTagRegex, '');

  return cleanedHtmlString;
};

/**
 * checks if a given route pattern matches the current location's pathname with slug
 *
 * @param {string} routePattern - route pattern with slug to match (like, '/users/:id')
 * @param {Location} location - current location object
 * @returns {boolean} returns true if the route pattern matches the location, otherwise returns false
 */
export const checkRouteMatch = (routePattern, location) => {
  const pathSegments = location.pathname.split('/');
  const patternSegments = routePattern.split('/');

  if (pathSegments.length !== patternSegments.length) {
    return false;
  }

  for (let i = 0; i < pathSegments.length; i++) {
    const pathSegment = pathSegments[i];
    const patternSegment = patternSegments[i];

    if (patternSegment.startsWith(':')) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (pathSegment !== patternSegment) {
      return false;
    }
  }

  return true;
};

export const getNicenames = (data) => {
  const nicenames = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].nicename) {
      nicenames.push(data[i].nicename);
    }
  }

  return nicenames.join(', ');
};

export const removeSpacesFromString = (inputString = '') => inputString.replace(/\s/g, '');

export const removeEnclosedContent = (inputString = '') => inputString.replace(/<[^>]+>/g, '');

export const moveElementToZeroIndex = (arr = [], element) => {
  if (Array.isArray(arr)) {
    const index = arr?.indexOf(element);
    if (index !== -1) {
      arr.splice(index, 1);
      arr.unshift(element);
    }
  }
  return arr;
};

export const copyUrlToClipboard = () => {
  const url = window.location.href;
  navigator.clipboard.writeText(url);
};

export const countWords = (str) => {
  if (typeof str !== 'string') {
    return 0;
  }

  const string = str.trim();
  if (string === '') {
    return 0;
  }
  return str.split(/\s+/).length;
};

export const removeOpFromString = (str, op = EQ_OP) => str?.replace(new RegExp(`:(~)?${op}`, 'g'), '') || '';
export const removeGroupFromString = (str, group) => str?.replace(new RegExp(`_${group}:`, 'g'), '') || '';

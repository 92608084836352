import { createSelector } from 'reselect';
import { isValid, formValueSelector } from 'redux-form/immutable';

import { toJS } from 'utils/general';
import { makeSubSelectorToJS } from 'utils/selectors';

const selectAlchemistForms = (state) => state ? state.get('alchemistForms') : {};

const getFieldValue = (formName, field) => (state) => toJS(formValueSelector(formName)(state, field));

const makeSelectFormSchemas = () => makeSubSelectorToJS(selectAlchemistForms, ['formSchemas']);
const makeSelectSpecificFormSchema = (schemaName) => makeSubSelectorToJS(selectAlchemistForms, ['formSchemas', schemaName]);

const makeSelectForm = (formName) => (state) => state && state.getIn(['form', formName]);

const subSelectIsFormSubmitting = (formState) => formState && formState.get('submitting');

const selectSubmittingForms = (state) => state && toJS(state.get('form').map(subSelectIsFormSubmitting));
const selectValidForms = (state) => state && toJS(state.get('form').map((_, formName) => isValid(formName)(state)));

const selectIsThereActiveField = (state) => state && !!state.get('form').filter((form) => form.get('active')).size;

const makeSelectIsFormSubmitting = (formName) => createSelector(makeSelectForm(formName), subSelectIsFormSubmitting);

const getFormRegisteredFields = (formName) => createSelector(
  makeSelectForm(formName),
  (formState) => formState && toJS(formState.get('registeredFields'))
);

const makeSelectFormFieldPositions = (form) => makeSubSelectorToJS(
  selectAlchemistForms,
  ['fieldPositions', form]
);

const makeSelectIsSubmitTriggered = (formName) => createSelector(
  makeSelectForm(formName),
  (formState) => formState && toJS(formState.get('triggerSubmit'))
);

const getRegisteredField = (formName, field) => createSelector(
  getFormRegisteredFields(formName),
  (registeredFields) => registeredFields && registeredFields[field]
);

const makeSelectUsersByEmail = () => createSelector(
  selectAlchemistForms,
  (alchemistFormsState) => alchemistFormsState && alchemistFormsState.get('usersByEmail').toJS(),
);

const makeSelectCompaniesByName = () => createSelector(
  selectAlchemistForms,
  (alchemistFormsState) => alchemistFormsState && toJS(alchemistFormsState.get('companiesByName')),
);

const makeSelectFormError = (formName) => createSelector(
  makeSelectForm(formName),
  (formState) => formState && toJS(formState.get('error'))
);

const makeSelectFormSyncErrors = (formName) => createSelector(
  makeSelectForm(formName),
  (formState) => formState && toJS(formState.get('syncErrors'))
);

export {
  makeSelectFormSchemas,
  makeSelectSpecificFormSchema,
  makeSelectUsersByEmail,
  makeSelectCompaniesByName,
  getFormRegisteredFields,
  getRegisteredField,
  getFieldValue,
  makeSelectIsFormSubmitting,
  selectSubmittingForms,
  selectValidForms,
  makeSelectFormFieldPositions,
  selectIsThereActiveField,
  makeSelectIsSubmitTriggered,
  makeSelectFormError,
  makeSelectFormSyncErrors,
};

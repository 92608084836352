import { parseUserAgent } from 'detect-browser';

const supportedBrowsers = [
  'chrome',
  'edge',
  'safari',
  'firefox',
  'ios',
  'crios',
  'fxios',
  'android',
];

const leastSupportedVersion = {
  chrome: 57,
  edge: 12,
  safari: 10,
  firefox: 52,
  ios: 10,
  crios: 70,
  fxios: 60,
  android: 4.4,
};

const mobileTabletiOS = [
  'iOS',
  'Android OS',
  'BlackBerry OS',
  'Windows Mobile',
  'Amazon OS',
];


const getShortVersion = (b) => b.version ? b.version.split('.')[0] : 0;

const detectedCache = {};
const cachedParseUserAgent = (ua) => {
  if (detectedCache[ua]) return detectedCache[ua];
  detectedCache[ua] = parseUserAgent(ua);
  return detectedCache[ua];
};

export const detectBrowser = (ua) => cachedParseUserAgent(ua || navigator.userAgent) || {};

export const isMobileTabletDevice = () => mobileTabletiOS.includes(detectBrowser().os);

export const browserCompatibilityAlert = () => {
  if (sessionStorage.getItem('browserSupportErrorShown')) return;

  const browser = detectBrowser();
  if (!supportedBrowsers.includes(browser.name)) {
    // eslint-disable-next-line no-alert
    alert('Your browser is not supported and all the features might not work properly. Please use the latest version of Chrome, Firefox, Safari, or Microsoft Edge.');
    sessionStorage.setItem('browserSupportErrorShown', 'TRUE');
  } else if (leastSupportedVersion[browser.name] >= getShortVersion(browser)) {
    // eslint-disable-next-line no-alert
    alert('Your browser version is too old for The Vault. Please update your browser and reload the page.');
    sessionStorage.setItem('browserSupportErrorShown', 'TRUE');
  }
};

export const isModernIPad = () => detectBrowser().os === 'Mac OS' && ('ontouchstart' in window || navigator.maxTouchPoints > 2);

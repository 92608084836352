import React from 'react';
import PropTypes from 'prop-types';

import { Stronger, Text } from 'components/Text/Body';


const NoteLegend = ({ children }) => <Text className="_col-sec_"><Stronger>Note:</Stronger> {children}</Text>;

NoteLegend.propTypes = { children: PropTypes.any };

export default NoteLegend;

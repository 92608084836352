import React from 'react';
import findKey from 'lodash/findKey';

import { MOBILE, TABLET, DESKTOP } from 'utils/constants';

import { ResponsiveConsumer } from './react-responsive-context';

export const ResponsiveHoC = (Component) => (props) => (
  <ResponsiveConsumer>
    {(status) => {
      const screenSizeValue = getScreenSize(status);

      return (
        <Component
          screenWidth={status.width}
          screenSize={screenSizeValue}
          isMobile={screenSizeValue === MOBILE}
          isMobileTablet={screenSizeValue < DESKTOP}
          isTablet={screenSizeValue === TABLET}
          isDesktop={screenSizeValue === DESKTOP}
          {...props}
        />
      );
    }}
  </ResponsiveConsumer>);

const getScreenSize = (status) => parseInt(findKey(status), 10);

/*
 *
 * GdprConfirm actions
 *
 */

import { SEND_CONFIRMATION, SEND_CANCELLATION, CONFIRMATION_SENT, CANCELLATION_SENT } from './constants';

export const sendConfirmation = (code) => ({ type: SEND_CONFIRMATION, code });
export const sendCancellation = (code) => ({ type: SEND_CANCELLATION, code });
export const cancellationSent = (requestFailed) => ({ type: CANCELLATION_SENT, requestFailed });
export const confirmationSent = (requestFailed) => ({ type: CONFIRMATION_SENT, requestFailed });

import { createSelector } from 'reselect';

import { selectIncluded, makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import {
  generateFiltersSelectors, generateFiltersUiSelectors, generateLoadedContentSelectors,
} from 'utils/filter/selectors';
import { matchArrayOfObjectsFields } from 'utils/general';
import { deepMaterializeObject, fetchObjectFromIncluded } from 'utils/jsonApiExtract';
import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});

const selectSharePeopleListModal = (state) => state ? state.get('sharePeopleListModal', localInitialState) : localInitialState;

const {
  makeSelectLoading,
  makeSelectLoadingNextPage,
  makeSelectMarkedObjectsRefs: makeSelectMarkedPeopleRefs,
  makeSelectHasMore,
  makeSelectTotalCount,
  makeSelectObjects: makeSelectPeople,
  makeSelectSelectedObject: makeSelectSelectedPerson,
  makeSelectMarkedObjects: makeSelectMarkedPeople,
} = generateLoadedContentSelectors(selectSharePeopleListModal);

const {
  makeSelectFilter,
  makeSelectMaxPage,
  makeSelectSearch,
  makeSelectAppliedFilters,
  makeSelectSelectedCategory,
  makeSelectSort,
} = generateFiltersSelectors(selectSharePeopleListModal);

const { makeSelectIsSearchFocused } = generateFiltersUiSelectors(selectSharePeopleListModal);

const makeSelectMain = () => makeSubSelector(selectSharePeopleListModal, ['main']);

const selectModalSection = makeSubSelector(makeSelectMain(), ['section']);

const selectPeopleListRef = makeSubSelector(makeSelectMain(), ['peopleListRef']);

const selectPeopleList = makeSelectObjectFromRefSelector(selectPeopleListRef);

const selectSharesRaw = makeSubSelectorToJS(makeSelectMain(), ['shares']);

const selectSharesProcessing = makeSubSelector(makeSelectMain(), ['sharesProcessing']);

const selectPeopleCompanyLoading = makeSubSelector(makeSelectMain(), ['isPeopleCompanyLoading']);

const selectShares = createSelector(
  selectSharesRaw,
  selectIncluded,
  (sharesRaw, included) => sharesRaw.map((share) => ({ ...share, user: fetchObjectFromIncluded(share.user, included) }))
);

const selectSharesHaveChanged = createSelector(
  selectSharesRaw,
  selectPeopleList,
  (sharesRaw, peopleList) => !matchArrayOfObjectsFields(
    sharesRaw || [],
    peopleList && peopleList.sharing_permissions ? deepMaterializeObject(peopleList.sharing_permissions()) : [],
    ['user.id', 'update']
  ),
);

export {
  makeSelectPeople,
  makeSelectFilter,
  makeSelectAppliedFilters,
  makeSelectSearch,
  makeSelectSelectedCategory,
  makeSelectSelectedPerson,
  makeSelectMarkedPeopleRefs,
  makeSelectMarkedPeople,
  makeSelectHasMore,
  makeSelectTotalCount,
  makeSelectLoading,
  makeSelectLoadingNextPage,
  makeSelectSort,
  makeSelectMaxPage,
  makeSelectIsSearchFocused,
  selectModalSection,
  selectPeopleList,
  selectSharesRaw,
  selectShares,
  selectSharesHaveChanged,
  selectSharesProcessing,
  selectPeopleCompanyLoading,
};

import React from 'react';
import PropTypes from 'prop-types';

import ScrollDiv from 'components/Generic/ScrollDiv';
import MainContent from 'components/MainContent';


const GdprMain = ({ children }) => (
  <MainContent className="flex-row dsa-force-vh">
    <ScrollDiv>
      {children}
    </ScrollDiv>
  </MainContent>
);

GdprMain.propTypes = { children: PropTypes.any };

export default GdprMain;

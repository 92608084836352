/*
 * PeopleLists/Search
 */
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import isEqual from 'lodash/isEqual';

import {
  generateFilterReducer,
  generateFiltersUiReducer,
  generateLoadedContentReducer,
} from 'utils/filter/reducer';

import {
  OPEN_PEOPLE_LIST_CREATE_MODAL,
  CLOSE_PEOPLE_LIST_CREATE_MODAL,
  OPEN_PEOPLE_LIST_MERGE_MODAL,
  CLOSE_PEOPLE_LIST_MERGE_MODAL,
  SET_MERGE_TARGET,
  SHARED_WITH_ME_LISTS,
  OWN_LISTS,
  PUBLIC_LISTS,
  EVENT_LISTS,
  MAKE_MERGE,
  MERGE_DONE, PEOPLE_LISTS_IDENTIFIER, COPY_SHARED_WITH_ME_LISTS, COPY_DONE, EXPAND_LIST_CONTAINER,
} from './constants';

const initialModalState = fromJS({
  isCreateModalOpen: fromJS({ open: false, isEventList: false }),
  isMergeLoading: false,
  isMergeModalOpen: false,
  mergeTarget: false,
});

function modals(state = initialModalState, action) {
  switch (action.type) {
    case CLOSE_PEOPLE_LIST_CREATE_MODAL:
      return state.set('isCreateModalOpen', fromJS({ open: false, isEventList: false }));
    case OPEN_PEOPLE_LIST_CREATE_MODAL:
      return state.set('isCreateModalOpen', fromJS({ open: true, isEventList: action.isEventList }));
    case CLOSE_PEOPLE_LIST_MERGE_MODAL:
      return state.set('isMergeModalOpen', false).set('mergeTarget', false);
    case OPEN_PEOPLE_LIST_MERGE_MODAL:
      return state.set('isMergeModalOpen', true);
    case SET_MERGE_TARGET:
      return state.set('mergeTarget', action.listRef);
    case MAKE_MERGE:
      return state.set('isMergeLoading', true);
    case MERGE_DONE:
      return state.set('isMergeLoading', false).set('isMergeModalOpen', false).set('mergeTarget', false);
    default:
      return state;
  }
}

const initialListsState = fromJS({
  isCopyLoading: false,
  expandedListsContainer: '',
});

function lists(state = initialListsState, action) {
  switch (action.type) {
    case COPY_SHARED_WITH_ME_LISTS:
      return state.set('isCopyLoading', true);
    case COPY_DONE:
      return state.set('isCopyLoading', false);
    case EXPAND_LIST_CONTAINER:
      return isEqual(state.get('expandedListsContainer'), action.containerId)
        ? state.set('expandedListsContainer', '')
        : state.set('expandedListsContainer', action.containerId);
    default:
      return state;
  }
}

export default combineReducers({
  loadedOwnLists: generateLoadedContentReducer(OWN_LISTS),
  loadedSharedWithMeLists: generateLoadedContentReducer(SHARED_WITH_ME_LISTS),
  loadedPublicLists: generateLoadedContentReducer(PUBLIC_LISTS),
  loadedEventLists: generateLoadedContentReducer(EVENT_LISTS),
  filter: generateFilterReducer(PEOPLE_LISTS_IDENTIFIER),
  filtersUi: generateFiltersUiReducer(PEOPLE_LISTS_IDENTIFIER),
  modals,
  lists,
});


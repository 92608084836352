/*
 * Admin reducer
 */
import { fromJS } from 'immutable';

import { LOAD_SITE_SETTINGS, LOAD_SITE_SETTINGS_SUCCESS, LOAD_SITE_SETTINGS_ERROR } from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  loadedObjects: {},
});

function adminReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SITE_SETTINGS:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['loadedObjects', action.settingsGroup], false);
    case LOAD_SITE_SETTINGS_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['loadedObjects', action.settingsGroup], action.objects);
    case LOAD_SITE_SETTINGS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false);
    default:
      return state;
  }
}

export default adminReducer;

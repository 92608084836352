import { select, put } from 'redux-saga/effects';

import { showAlert } from 'containers/AlertBox/actions';
import { makeSelectAccount } from 'containers/AuthProvider/selectors';
import { formRequest } from 'utils/Forms/general';
import { preprocessError } from 'utils/Forms/sagas';
import { resRef } from 'utils/refs';
import { pushLocation, waitAndSelectFullAccount } from 'utils/sagas';

import { selectIfsInvitation } from './Registration/selectors';

export function* markIfsRegistrationFinished() {
  let myAccount = yield waitAndSelectFullAccount();
  if (!myAccount) {
    myAccount = yield select(makeSelectAccount());
  }

  const invitation = yield select(selectIfsInvitation);
  if (invitation) {
    try {
      yield formRequest({ ...resRef(invitation), attributes: { registration_finished: true } });
    } catch (err) {
      const { errorJson } = yield preprocessError(err);
      if (errorJson?.errors?.[0]?.detail?.includes('Your selected options are in the past')) {
        yield put(showAlert('Your selected options are in the past, please change you registration'));
        yield pushLocation('/ifs/attendance');
      }
    }
  }
}

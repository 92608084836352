import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { UncontrolledTooltip as Tooltip } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';

const CopyButton = ({ buttonClassName, buttonId, tooltipText, showTooltip, text, onCopy, isCircle = false, isText = false }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyIcon = isText ? faCopy : faLink;

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        if (onCopy && typeof onCopy === 'function') {
          onCopy();
        }
        if (!isCopied) {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 5000);
        }
      }}
    >
      <button
        type="button"
        id={buttonId}
        className={classNames(`dsa-btn-circle ${buttonClassName || ''}`, { _copied_: isCopied, '_is-circle_': isCircle })}
      >
        <FontAwesomeIcon icon={isCopied ? faCheck : copyIcon} color={isCopied ? '#FFF' : '#03273B'} size={isCopied ? '1x' : '1x'} className="m-0" />
        {showTooltip && <Tooltip target={buttonId} className="dsa-tooltip-menu-button">{tooltipText}</Tooltip>}
      </button>
    </CopyToClipboard>
  );
};

CopyButton.propTypes = {
  buttonClassName: PropTypes.string,
  buttonId: PropTypes.string,
  tooltipText: PropTypes.string,
  showTooltip: PropTypes.bool,
  text: PropTypes.string,
  onCopy: PropTypes.func,
  isCircle: PropTypes.bool,
  isText: PropTypes.bool,
};

export default CopyButton;

import { all, call, put, select } from 'redux-saga/effects';

import { showAlert } from 'containers/AlertBox/actions';
import { API_PEOPLE_BASE_URL } from 'containers/App/constants';
import { request } from 'utils/request';
import { updateObjFromApi } from 'utils/sagas';

import { makeSelectMarkedPeople, selectMergeTargetRef } from '../selectors';
import { mergeFailed, mergeSuccess, unlistProfile } from '../actions';

export function* requestMerge() {
  const selectedProfiles = yield select(makeSelectMarkedPeople());
  const targetRef = yield select(selectMergeTargetRef);
  try {
    yield all(selectedProfiles
      .filter((profile) => profile.id !== targetRef.id)
      .map((sourceProfile) => call(mergePair, sourceProfile, targetRef)));
    yield updateObjFromApi(targetRef, ['roles', 'company.aclass']);
    yield put(mergeSuccess());
    yield put(showAlert('Success! Profiles have been merged.'));
  } catch (e) {
    yield put(mergeFailed());
  }
}

function* mergePair(source, target) {
  yield request(`${API_PEOPLE_BASE_URL}/merge/${source.id}/${target.id}`);
  yield put(unlistProfile(source));
}

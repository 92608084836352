import { call, put, takeLatest } from 'redux-saga/effects';

import { updateIncluded } from 'actions';
import { showAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { API_BASE, RESOURCE_INVESTMENT, RESOURCE_MEMBERSHIP } from 'containers/App/constants';
import { extractData } from 'utils/jsonApiExtract';
import { patch, request } from 'utils/request';

import { LOAD_VERIFIABLE } from './constants';
import { setVerifiable } from './actions';

function* verifyVerifiable({ verifiableCode, verifiableType, status }) {
  const includes = {
    [RESOURCE_INVESTMENT]: status ? 'investor_profile,investing_company,investment_round.company' : 'investor_profile',
    [RESOURCE_MEMBERSHIP]: status ? 'user,company' : 'user',
  }[verifiableType];

  const uri = `${API_BASE}/${verifiableType}/${verifiableCode}?include=${includes}`;
  try {
    const verifiableObjectRequest = yield call(request, uri);
    const inputData = {
      data: { type: verifiableType, id: verifiableObjectRequest.data.id, attributes: { verified: status } },
    };
    const verifiableResponse = yield call(patch, uri, inputData);

    const { inclusions, items: [verifiableRef] } = extractData(verifiableResponse);

    yield put(updateIncluded(inclusions));
    yield put(setVerifiable(verifiableRef));
  } catch (e) {
    if (e.response && e.response.status === 400) {
      yield put(showAlert('Request Already Accepted!', COLOR.warning));
    } else {
      yield put(showAlert('Something went wrong when accepting the verifiable', COLOR.danger));
      throw e;
    }
  }
}

export default function* verifiableSaga() {
  // "Verify" Sagas
  yield takeLatest(LOAD_VERIFIABLE, verifyVerifiable);
}

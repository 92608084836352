import { fromJS } from 'immutable';
import { select } from 'redux-saga/effects';

import { ROLES } from 'containers/App/constants';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';

export function* preprocessAppliedFilters(appliedFilters, prefix = '') {
  let newFilters = fromJS({});

  if (appliedFilters.get('search')) {
    const isAdmin = yield select(makeSelectUserIs(ROLES.admin));
    const isFounder = yield select(makeSelectUserIs(ROLES.founder));
    const searchFields = ['title', 'description'];
    const additionalFields = ['users.nicename', 'users.title', 'users.bragline', 'users.company.name', 'users.tags.text', 'users.areas_of_interest.text', 'users.investmentcriteria'];
    const adminOnlyFields = ['users.profile_emails.email'];

    if (isAdmin) {
      adminOnlyFields.forEach((adminOnlyField) => searchFields.push(adminOnlyField));
    }

    if (isAdmin || isFounder) {
      additionalFields.forEach((additionalField) => searchFields.push(additionalField));
    }

    newFilters = newFilters.set(
      `${searchFields.map((s) => `${prefix}${s}`).join(',')}:search`,
      appliedFilters.get('search').replace(/%/, '\\%')
    );
  }

  return newFilters;
}

export function preprocessSort(sort, fieldsPrefix = '') {
  return sort.set(`${fieldsPrefix}created_at`, -1).set(`${fieldsPrefix}id`, -1);
}

/**
 *
 * GdprConfirmPage
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';

import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import ButtonsHolder from 'components/Buttons/ButtonsHolder';
import OnePager from 'components/OnePager/OnePager';
import { Text } from 'components/Text/Body';

import { selectLoadingConfirmation, selectRequestFailed } from './selectors';
import { sendConfirmation } from './actions';
import { TEXTS } from './constants';

class ConfirmPage extends Component {
  propTypes = {
    match: PropTypes.object,
    sendConfirmation: PropTypes.func,
    requestFailed: PropTypes.bool,
  };
  componentDidMount() {
    this.props.sendConfirmation(this.props.match.params.code);
  }
  render() {
    const { match: { params: { action, code } }, requestFailed } = this.props;
    const actionTexts = TEXTS[action];

    const helmet = (
      <Helmet>
        <title>Confirm Gdpr Request</title>
        <meta name="description" content="Gdpr Request Confirmation page" />
      </Helmet>);

    if (requestFailed) {
      return (
        <OnePager title="Thank you for the confirmation!">
          {helmet}
          <Text>You had requested to cancel this request previously.</Text>
          <Text>
            If you would still like to delete/deactivate your profile, please visit the following link to request again.
          </Text>
          <ButtonsHolder>
            <ButtonPrim to="/gdpr/manage">Manage Alchemist Profile</ButtonPrim>
          </ButtonsHolder>
        </OnePager>);
    }

    return (
      <OnePager title="Thank you for the confirmation!">
        {helmet}
        {actionTexts && actionTexts.mainTexts.map((mainText) => <Text key={mainText}>{mainText}</Text>)}
        {actionTexts && actionTexts.buttonText &&
          <ButtonsHolder>
            <ButtonPrim to={`/gdpr/respond/${action}/${code}/cancel`}>{actionTexts.buttonText}</ButtonPrim>
          </ButtonsHolder>}
      </OnePager>);
  }
}

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingConfirmation,
  requestFailed: selectRequestFailed,
});
const mapDispatchToProps = { sendConfirmation };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPage);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SideBar from 'components/SideBar';
import {
  selectUpcomingClass, makeSelectIsAuthenticated, makeSelectProfile, makeSelectAuthPrincipals, makeSelectSiteSettings,
  makeSelectAccount, makeSelectUser, makeSelectHighestUserAccess, selectHasMultiFounderCaps, selectHasMultiActiveClasses,
} from 'containers/AuthProvider/selectors';
import { logout, deimpersonate } from 'containers/AuthProvider/actions';
import { setFounderChooseModal } from 'containers/FounderChooseModal/actions';

import { makeSelectLocation } from './selectors';

const mapStateToProps = createStructuredSelector({
  myProfile: makeSelectProfile(),
  myAccount: makeSelectAccount(),
  myRoles: makeSelectAuthPrincipals(),
  authUser: makeSelectUser(),
  isAuthenticated: makeSelectIsAuthenticated(),
  location: makeSelectLocation(),
  upcomingClass: selectUpcomingClass,
  siteSettings: makeSelectSiteSettings(),
  userHighestAccess: makeSelectHighestUserAccess(),
  hasMultiFounderCaps: selectHasMultiFounderCaps,
  hasMultiActiveClasses: selectHasMultiActiveClasses,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  deimpersonate: () => dispatch(deimpersonate()),
  openFounderPopup: () => dispatch(setFounderChooseModal(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

import { createSelector } from 'reselect';
import { getFormSyncErrors, getFormSubmitErrors } from 'redux-form/immutable';

import { makeSelectObject, makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { toJS } from 'utils/general';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});

const selectSharedListFormDomain = (state) => state
  ? state.get('sharedListForm', localInitialState)
  : localInitialState;

const makeSelectPreviewedSharedListRef = () => createSelector(
  selectSharedListFormDomain,
  (substate) => toJS(substate.get('previewedSharedList'))
);

const makeSelectSharedListPreviewIsShown = () => createSelector(
  selectSharedListFormDomain,
  (substate) => substate.get('isShownSharedListPreview')
);

const makeSelectPreviewedSharedList = () => makeSelectObjectFromRefSelector(makeSelectPreviewedSharedListRef());
const makeSelectSharedListByRef = (sharedListRef) => makeSelectObject(sharedListRef);

const makeSelectSyncErrors = () => createSelector(
  (state) => state,
  (state) => ({ ...toJS(getFormSyncErrors('sharedCompaniesList')(state) || {}) }),
);

const makeSelectSubmitErrors = () => createSelector(
  (state) => state,
  (state) => ({ ...toJS(getFormSubmitErrors('sharedCompaniesList')(state) || {}) }),
);

export {
  makeSelectPreviewedSharedList,
  makeSelectSharedListPreviewIsShown,
  makeSelectSharedListByRef,
  makeSelectSyncErrors,
  makeSelectSubmitErrors,
};

/**
 *
 * GdprConfirm
 *
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { compose } from 'redux';

import ConnectedSwitch from 'containers/BasicConnectedElements/ConnectedSwitch';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import reducer from './reducer';
import saga from './saga';
import ConfirmPage from './ConfirmPage';
import CancelPage from './CancelPage';

const GdprConfirm = () => (
  <ConnectedSwitch>
    <Route path="/gdpr/respond/:action/:code/confirm" component={ConfirmPage} />
    <Route path="/gdpr/respond/:action/:code/cancel" exact component={CancelPage} />
  </ConnectedSwitch>);


const withReducer = injectReducer({ key: 'gdprConfirm', reducer });
const withSaga = injectSaga({ key: 'gdprConfirm', saga });

export default compose(withReducer, withSaga)(GdprConfirm);

import React from 'react';
import PropTypes from 'prop-types';


const DropdownItemButton = ({ children, className }) => (
  <div className={`dropdown-item-button ${className}`}>
    {children}
  </div>
);


DropdownItemButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default DropdownItemButton;

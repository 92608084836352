import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import OnePager from 'components/OnePager/OnePager';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { getLongDate } from 'utils/dateTime';

import { SAVE_CANCELATION, SAVE_VERIFY } from './constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectInvitation } from './selectors';


class VerifyOrCancelAttendance extends React.Component {
  componentDidMount() {
    const { location, match } = this.props;
    const { action, session } = match.params;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const invitationId = queryParams.invitation;
    const invitationCode = queryParams.code;

    if (action === 'confirm') {
      this.props.saveVerifyInfo(invitationId, invitationCode);
    } else if (action === 'cancel') {
      this.props.saveCancelInfo(invitationId, invitationCode, session);
    }
  }

  render() {
    const { action, session } = this.props.match.params;
    const to = this.props?.invitation?.user?.();
    const ifs = this.props?.invitation?.participant_set?.().ifs?.();

    if (action === 'confirm') {
      return (
        <OnePager title={`Thanks${to ? `, ${to?.firstname}` : ''}!`}>
          <p>
            You have verified that you will attend Investor Feedback Summit{ifs ? ` on ${getLongDate(ifs?.event_datetime_pst_aware)}` : ''}.<br />
            We look forward to seeing you there!
          </p>
        </OnePager>);
    } else if (action === 'cancel') {
      const msg = {
        all: 'all meets',
        morning: 'the morning session meets',
        afternoon: 'the afternoon session meets',
      };
      return (
        <OnePager title={`Thanks for the notice${to ? `, ${to?.firstname}` : ''}!`}>
          <p>
            You have canceled {msg[session]} that you were scheduled for Investor Feedback Summit{ifs ? ` on ${getLongDate(ifs?.event_datetime_pst_aware)}` : ''}.<br />
          </p>
        </OnePager>);
    }
    return null;
  }
}

VerifyOrCancelAttendance.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  invitation: PropTypes.object,
  saveVerifyInfo: PropTypes.func,
  saveCancelInfo: PropTypes.func,
};


export function mapDispatchToProps(dispatch) {
  return {
    saveVerifyInfo: (invitationId, invitationCode) => dispatch({
      type: SAVE_VERIFY, invitationId, invitationCode, status: true,
    }),
    saveCancelInfo: (invitationId, invitationCode, session) => dispatch({
      type: SAVE_CANCELATION, invitationId, invitationCode, session,
    }),
  };
}

const mapStateToProps = createStructuredSelector({
  invitation: makeSelectInvitation(),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'ifsVerify', reducer });
const withSaga = injectSaga({ key: 'ifsVerify', saga });
export default compose(
  withReducer,
  withSaga,
  withConnect,
)(VerifyOrCancelAttendance);

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import injectReducer from 'utils/injectReducer';

import reducer from './reducer';

const MobileDesktopSwitcherModal = ({ isTablet }) => (
  <>{isTablet ? 'View Desktop' : 'View Mobile'}</>
);

MobileDesktopSwitcherModal.propTypes = {
  isTablet: PropTypes.bool,
};

const withReducer = injectReducer({ key: 'mobileDesktopSwitcher', reducer });

export default compose(withReducer)(MobileDesktopSwitcherModal);

import React from 'react';
import PropTypes from 'prop-types';

import { logToSentry } from 'utils/log';

export class ErrorBoundary extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const processedErrInfo = errorInfo ? errorInfo.componentStack.trim() : JSON.stringify(errorInfo);
    logToSentry(
      error,
      'error',
      { errorInfo: processedErrInfo.substr(0, 100) },
      { errorBoundary: 'FailSafe-componentDidCatch' }
    );
  }

  render() {
    if (this.state.error) {
      return (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            paddingTop: '10%',
          }}
        >
          <h2>Something went wrong</h2>
          <p> You can try to navigate to a different part of the product or reload the page.</p>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export const withErrorBoundary = (ComponentThatMightFail) => (props) => (
  <ErrorBoundary>
    <ComponentThatMightFail {...props} />
  </ErrorBoundary>
);

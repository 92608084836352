import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

const Warning = ({ text, className, ...props }) => (
  <div className={classNames('dsa-warning-box', className)} {...props}>
    <FontAwesomeIcon icon={faExclamationCircle} />
    {text}
  </div>
);

Warning.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Warning;

import React from 'react';
import PropTypes from 'prop-types';
import { DropdownToggle } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ToggleButtonBase = ({
  isCategorySelected, dropdownOpen, label, caret, className, disabled, labelComponent = () => React.Fragment, onMouseEnter, IconComponent, faIcon, hideCaret, onClick,
}) => (
  <DropdownToggle
    disabled={disabled}
    caret={hideCaret ? null : caret}
    className={classNames(className, { _selected_: isCategorySelected, active: dropdownOpen })}
    tag="button"
    type="button"
    onMouseEnter={onMouseEnter}
    {...(onClick ? { onClick } : {})}
  >
    {IconComponent && <IconComponent className="mr-5" width="15" />}
    {faIcon && <FontAwesomeIcon icon={faIcon} className="mr-5" />}
    {label || labelComponent()}
  </DropdownToggle>
);

ToggleButtonBase.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  isCategorySelected: PropTypes.string,
  dropdownOpen: PropTypes.bool,
  caret: PropTypes.bool,
  disabled: PropTypes.bool,
  labelComponent: PropTypes.any,
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
  IconComponent: PropTypes.any,
  hideCaret: PropTypes.bool,
  faIcon: PropTypes.any,
};


export default ToggleButtonBase;

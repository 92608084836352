import DefaultPropsHoC from 'components/Generic/DefaultPropsHoC';

import ButtonBase from './ButtonBase';

const ButtonLinkSec = DefaultPropsHoC(ButtonBase, { color: 'link', linkStyle: 'dsa-anchor-link' });
const ButtonLinkSecSm = DefaultPropsHoC(ButtonBase, { color: 'link', linkStyle: 'dsa-anchor-link', size: 'sm' });
const ButtonLinkSecLg = DefaultPropsHoC(ButtonBase, { color: 'link', linkStyle: 'dsa-anchor-link', size: 'lg' });
const ButtonBlue = DefaultPropsHoC(ButtonLinkSec, { invertUnderline: true, alignVer: true, fontWeightMed: true });
const ButtonBlueSec = DefaultPropsHoC(ButtonLinkSec, { alignVer: true, invertUnderline: true });
const ButtonGreyPlain = DefaultPropsHoC(ButtonLinkSec, {
  alignVer: true, invertUnderline: true, plain: true, grey: true,
});
const ButtonBlueSm = DefaultPropsHoC(ButtonLinkSecSm, { invertUnderline: true, noMargin: true, noPadX: true });
const ButtonHelper = DefaultPropsHoC(ButtonLinkSecSm, { noMargin: true, noPadX: true, className: 'dsa-helper-button' });

const ButtonWhitePlain = DefaultPropsHoC(ButtonLinkSec, { alignVer: true, plain: true, white: true });

export {
  ButtonLinkSec,
  ButtonLinkSecSm,
  ButtonLinkSecLg,
  ButtonBlue,
  ButtonBlueSec,
  ButtonGreyPlain,
  ButtonBlueSm,
  ButtonHelper,
  ButtonWhitePlain,
};

import React from 'react';


class DropdownSuperClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDropdownOpen: false };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleIsDropdownOpen = this.handleIsDropdownOpen.bind(this);
  }

  handleIsDropdownOpen(passedValue) {
    this.setState({ isDropdownOpen: passedValue });
  }

  toggleDropdown() {
    this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }));
  }

  closeDropdown() {
    if (this.state.isDropdownOpen) {
      this.setState(() => ({ isDropdownOpen: false }));
    }
  }

  render() {
    return null;
  }
}

export default DropdownSuperClass;

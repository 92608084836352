import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';

const Text = ClassNameExtendableHoC(
  'p',
  'dsa-text-body',
  ({ lh }) => [
    { '_lh-compact_': lh === 'compact', 'mb-5 _lh-ample_': lh === 'ample', 'mb-6 _lh-double_': lh === 'double' }],
  ['lh']
);
const TextMd = ClassNameExtendableHoC('p', 'dsa-text-body _md_'); // 18px
const TextMdNew = ClassNameExtendableHoC('p', 'dsa-text-body _md-new_'); // 17px
const TextSpan = ClassNameExtendableHoC('span', 'dsa-text-body');
const ParSmall = ClassNameExtendableHoC('p', 'dsa-text-body _small_');
const TextSmall = ClassNameExtendableHoC('span', 'dsa-text-body _small_');
const TextLegend = ClassNameExtendableHoC('span', 'dsa-text-body _legend_');
const Note = ClassNameExtendableHoC('small', 'dsa-text-body _note_');
const Stronger = ClassNameExtendableHoC('strong', '_stronger_');
const StrongCol = ClassNameExtendableHoC('strong', '_col-sec_');
const SpanLw = ClassNameExtendableHoC('span', '_light-weight_');
const SpanI = ClassNameExtendableHoC('span', 'text-ita-opaque');
const TextI = ClassNameExtendableHoC('p', 'dsa-text-body text-ita _light-weight_');


export { Text, TextMd, TextMdNew, TextSpan, TextI, ParSmall, TextSmall, TextLegend, Note, Stronger, StrongCol, SpanLw, SpanI };

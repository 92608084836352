import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';

import TermsParagraph from 'containers/Gdpr/TermsParagraph';
import { Text } from 'components/Text/Body';
import OnePager from 'components/OnePager/OnePager';
import GdprFooter from 'containers/Gdpr/Footer';
import Main from 'components/SiteStructure/Main';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { makeSelectQueryParam } from 'containers/App/selectors';


const DemoDayInterestSuccessPage = ({ forQueryParam }) => (
  <Main noSidebar>
    <Helmet>
      <title>Demo Day Express Interest</title>
      <meta name="description" content="Demo Day Express Interest Success" />
    </Helmet>
    <OnePager
      sectionSize="md"
      title="Thanks for expressing interest to get Alchemist Demo Day invite!"
      medHeading
      footer={<GdprFooter />}
    >
      <Text className="mb-7">The Alchemist Demo Day committee will review your profile and will make a decision to extend the invite. </Text>
      <Text className="mb-7">Registering your profile on Alchemist increases your chances to get an invite for the Demo Day! To register your profile, click <ButtonLink noMargin inlineText anchorTextStyle to={`/register-profile/form?for=${forQueryParam}`}>here</ButtonLink>.</Text>
      <TermsParagraph text="registering your profile" signInText="" />
    </OnePager>
  </Main>
);

DemoDayInterestSuccessPage.propTypes = {
  forQueryParam: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  forQueryParam: makeSelectQueryParam('for'),
});

export default connect(mapStateToProps)(DemoDayInterestSuccessPage);

import { fromJS } from 'immutable';


import { LOAD_SIDEBAR_SETTINGS, LOAD_SIDEBAR_SETTINGS_SUCCESS,
  LOAD_SIDEBAR_SETTINGS_ERROR, TOGGLE_SIDEBAR_MODAL } from './constants';

export const initialState = fromJS({
  errorSettings: false,
  loadedSettingsObjects: {},
  isEditSidebarModalOpen: false,
  sidebarItem: '',
});

export default function editSidebarItemReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SIDEBAR_SETTINGS:
      return state
        .set('errorSettings', false)
        .setIn(['loadedSettingsObjects', action.settingsGroup], false);
    case LOAD_SIDEBAR_SETTINGS_SUCCESS:
      return state.setIn(['loadedSettingsObjects', action.settingsGroup], action.objects);
    case LOAD_SIDEBAR_SETTINGS_ERROR:
      return state.set('errorSettings', action.error);
    case TOGGLE_SIDEBAR_MODAL:
      return state.set('isEditSidebarModalOpen', action.isModalOpen)
        .set('sidebarItem', action.sidebarItem || '');
    default:
      return state;
  }
}

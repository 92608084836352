import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import FormLabel from 'components/Form/FormLabel';
import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import DsaModal from 'components/Modals/DsaModal';
import { getAutoCompleteResFilter } from 'utils/inputHelpers';
import { MSG_TYPE, RESOURCE_PEOPLE } from 'containers/App/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';

import reducer from './reducer';
import { openAdminConnectModal } from './actions';
import { makeSelectIsModalOpen, makeSelectSelectedFounder } from './selectors';
import { openPersonModal } from '../PersonModal/actions';
import { ADMIN_CONNECT_DRAFT } from '../PersonModal/constants';
import saga from './saga';


const AdminConnectModal = ({ founder, openConnectModal, isModalOpen, openEmailModal }) => {
  const [networkProfile, setNetworkProfile] = useState(null);

  return (
    <DsaModal className="mobile-friendly-modal" isOpen={isModalOpen} toggle>
      <ModalHeader tag="h1">Introduce Founder/LP to anyone on Vault</ModalHeader>
      <div className="dsa-action-buttons dsa-modal__close">
        <button onClick={() => openConnectModal(false)} className="close" type="button" aria-label="Close">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <ModalBody>
        <div className="mb-6">
          <FormLabel label="Founder/LP" required />
          <AsyncPaginate
            classNamePrefix="Select"
            className="dsa-select-prim _md_"
            getOptionLabel={(option) => option.nicename}
            getOptionValue={(option) => option.id}
            label="Founder"
            placeholder="Select Founder"
            loadOptions={getAutoCompleteResFilter(RESOURCE_PEOPLE, 'nicename', { extraFilters: { 'roles.title:in': '[\'founder\',\'limited_partner\']' } })}
            value={founder}
            isDisabled
          />
        </div>
        <div>
          <FormLabel label="Network Profile" required />
          <AsyncPaginate
            classNamePrefix="Select"
            className="dsa-select-prim _md_"
            getOptionLabel={(option) => option.nicename}
            getOptionValue={(option) => option.id}
            label="Network Profile"
            placeholder="Search or choose..."
            loadOptions={getAutoCompleteResFilter(RESOURCE_PEOPLE, 'nicename')}
            value={networkProfile}
            onChange={(e) => setNetworkProfile(e)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonPrim
          type="submit"
          onClick={() => {
            openConnectModal(false);
            openEmailModal(networkProfile);
            setNetworkProfile(null);
          }}
          disabled={!(founder && networkProfile)}
        >Compose Message
        </ButtonPrim>
      </ModalFooter>
    </DsaModal>
  );
};

AdminConnectModal.propTypes = {
  isModalOpen: PropTypes.bool,
  openConnectModal: PropTypes.func,
  openEmailModal: PropTypes.func,
  founder: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  isModalOpen: makeSelectIsModalOpen(),
  founder: makeSelectSelectedFounder(),
});

const mapDispatchToProps = (dispatch) => ({
  openConnectModal: (isModalOpen) => dispatch(openAdminConnectModal(isModalOpen)),
  openEmailModal: (networkProfile) => dispatch(openPersonModal(ADMIN_CONNECT_DRAFT, networkProfile, null, MSG_TYPE.ADMIN_CONNECT)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'adminConnectModal', reducer });
const withSaga = injectSaga({ key: 'adminConnectModal', saga, mode: DAEMON });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(AdminConnectModal);

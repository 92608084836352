import { put, select } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form/immutable';

import { trackLogin } from 'containers/Analytics/actions';
import { makeSelectQueryParam } from 'containers/App/selectors';
import { loginFlow } from 'containers/AuthProvider/saga';
import { preprocessError, processJsonApiErrors } from 'utils/Forms/sagas';

import { enableNewPasswordBtn } from './ResetPassword/actions';

export function* setAuthAndRedirect() {
  const redirectToFlow = yield select(makeSelectQueryParam('flow'));
  yield put(trackLogin(redirectToFlow));
  yield loginFlow(redirectToFlow);
  yield put(enableNewPasswordBtn());
}

export function* handleAuthError(e, formName, defaultError) {
  const { errorJson } = yield preprocessError(e);
  if (errorJson) {
    if (errorJson.status === 'error') {
      yield put(stopSubmit(formName, defaultError || { _error: errorJson.error }));
    } else {
      const formError = yield processJsonApiErrors(errorJson);
      yield put(stopSubmit(formName, formError));
    }
  }
}

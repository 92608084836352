import React from 'react';
import PropTypes from 'prop-types';

import { ButtonPrim } from 'components/Buttons/ButtonPrim';


const DefaultSaveButton = ({ disabled, submitting }) => (
  <ButtonPrim
    disabled={disabled}
    isSending={submitting}
    type="submit"
    className="disabled-while-loading"
  >
    Save
  </ButtonPrim>
);

DefaultSaveButton.propTypes = {
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default DefaultSaveButton;

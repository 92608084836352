import { all, call, delay, put, race, select, take, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { parse, stringify } from 'qs';
import { formValueSelector } from 'redux-form/lib/immutable';
import jwtDecode from 'jwt-decode';

import { updateIncluded, updateIncludedFromRequest } from 'actions';
import { API_PEOPLE_BASE_URL, RESOURCE_RESOURCES } from 'containers/App/constants';
import { makeSelectLocation, makeSelectObjectRaw, makeSelectObject } from 'containers/App/selectors';
import { FULL_ACCOUNT_DATA_LOADED } from 'containers/AuthProvider/constants';
import {
  makeSelectAccount,
  makeSelectNotifications,
  makeSelectUser,
  selectAccountLoaded,
  selectToken,
} from 'containers/AuthProvider/selectors';
import { toJS } from 'utils/general';
import { extractData } from 'utils/jsonApiExtract';
import { showAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';

import { patch, request } from './request';
import { refApiUrl, refCompare, resRef, resRefForArrayOrObj } from './refs';
import { logError } from './log';

export function* waitAndSelectAccount() {
  return yield waitSelect(makeSelectAccount());
}

export function* waitAndSelectFullAccount() {
  const tokenAccount = yield waitSelect(makeSelectUser(), 10);
  if (!tokenAccount || tokenAccount.no_account) {
    return null;
  }

  const accountLoaded = yield select(selectAccountLoaded);
  if (!accountLoaded) {
    const { timeout } = yield race({
      accountFullLoaded: take(FULL_ACCOUNT_DATA_LOADED),
      timeout: delay(60000),
    });
    if (timeout) {
      return null;
    }
  }
  return yield select(makeSelectAccount());
}

export function* updateObjFromApi(baseObjRef, include, extraParams = '', reselect) {
  const objRef = resRef(baseObjRef);
  const includeStr = Array.isArray(include) ? Array.from(new Set(include)).join(',') : include;

  if (objRef.type.match(RESOURCE_RESOURCES)) objRef.type = RESOURCE_RESOURCES;

  const requestURL = `${refApiUrl(objRef)}${includeStr ? `?include=${includeStr}` : ''}${extraParams}`;
  const objRequest = yield call(request, requestURL);
  yield put(updateIncludedFromRequest(objRequest));
  if (reselect) {
    return yield select(makeSelectObject(objRef));
  }
  return null;
}

export function* updateRawObj(obj) {
  yield put(updateIncluded({ [obj.type]: { [obj.id]: obj } }));
}

export function* updateObjProperty(objRef, property, value, section = 'attributes') {
  yield put(updateIncluded({ [objRef.type]: { [objRef.id]: { [section]: { [property]: value } } } }));
}

export function* waitSelect(selector, attempts = 50, actionType) {
  for (let i = 0; i < attempts; i++) {
    const waitedObject = yield select(selector);
    if (waitedObject) {
      return waitedObject;
    }
    if (actionType && i === 0) {
      yield take(actionType);
    } else {
      yield delay(100);
    }
  }
  return null;
}

export function* pushLocation(...args) {
  return yield put(push(...args));
}

export function* parseUrlFilters() {
  const location = yield select(makeSelectLocation());
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  if (queryParams.filter && queryParams.filter.advancedFilters && queryParams.filter.advancedFilters.filters) {
    queryParams.filter.advancedFilters.filters = queryParams.filter.advancedFilters.filters.map((filter) => ({ ...filter, value: filter.field.match(/:eq$/) && filter.value === '' ? null : filter.value }));
  }
  if (queryParams.sort) {
    Object.keys(queryParams.sort).forEach((k) => { queryParams.sort[k] = parseFloat(queryParams.sort[k]); });
  }
  return queryParams;
}

export function debounceSaga(debouncedSaga, delayMs = 300) {
  return function* sagaWithDebounce(...args) {
    yield delay(delayMs);
    yield debouncedSaga(...args);
  };
}
export function* putAction(action) {
  yield put(action);
}

export function* injectRelationship(targetResRef, relName, rel) {
  const rawResource = yield select(makeSelectObjectRaw(targetResRef));
  const relAsRefs = resRefForArrayOrObj(rel);

  if (!rawResource.relationships) rawResource.relationships = {};
  if (!rawResource.relationships[relName]) rawResource.relationships[relName] = {};

  const relCurrentValue = rawResource.relationships[relName].data;
  if (Array.isArray(relAsRefs) && relCurrentValue) {
    const relsToAdd = relAsRefs.filter((newRel) => !relCurrentValue.find((oldRel) => refCompare(newRel, oldRel)));
    rawResource.relationships[relName].data = [...relCurrentValue, ...relsToAdd];
  } else {
    rawResource.relationships[relName].data = relAsRefs;
  }

  yield put(updateIncluded({ [rawResource.type]: { [rawResource.id]: rawResource } }));
}
export function* removeFromRelationshipArray(targetResRef, relName, filterCallbackOrRef) {
  const filterCallback = typeof filterCallbackOrRef === 'function'
    ? filterCallbackOrRef
    : (rel) => rel.id !== filterCallbackOrRef.id;
  const rawResource = yield select(makeSelectObjectRaw(targetResRef));

  if (!rawResource.relationships) rawResource.relationships = {};
  if (!rawResource.relationships[relName]) rawResource.relationships[relName] = {};

  const relCurrentValue = rawResource.relationships[relName].data;
  rawResource.relationships[relName].data = relCurrentValue
    && relCurrentValue.filter((currentRel) => filterCallback(currentRel));

  yield put(updateIncluded({ [rawResource.type]: { [rawResource.id]: rawResource } }));
}

export function* markNotifsAsViewed(resType) {
  const myAccount = yield waitAndSelectAccount();
  const userToken = yield select(selectToken);
  const decodedUserToken = jwtDecode(userToken);

  yield waitSelect(makeSelectNotifications());
  // If there are notifs and there is no impersonator, patch the notif/s as viewed
  if (myAccount.notifications && !decodedUserToken?.impersonator) {
    const notificationsForLists = myAccount.getPendingNotificationsFor(resType);

    if (notificationsForLists.length > 0) {
      const notificationRequests = yield all(notificationsForLists.map((notif) => call(patch, refApiUrl(notif), { data: { ...resRef(notif), attributes: { viewed: true } } })));
      yield all(notificationRequests.map((notifReq) => put(updateIncludedFromRequest(notifReq))));
    }
  }
}

export function* putActions(actionsOrAction) {
  if (actionsOrAction) {
    if (Array.isArray(actionsOrAction)) {
      yield all(actionsOrAction.map((action) => put(action)));
    } else {
      yield put(actionsOrAction);
    }
  }
}

export const takeOne = (pattern, saga, ...args) => fork(function* waitForTakeFirst() {
  const action = yield take(pattern);
  yield call(saga, ...args.concat(action));
});

/**
 * refresh current route by putting the specified action if the provided condition is met.
 * if no condition is provided, use default condition of matching the location's pathname
 * with the targetLocation.
 *
 * @param {string} targetLocation - target location pathname to match.
 * @param {any} action - action to dispatch if the condition is met.
 * @param {function|null} condition - optional custom condition function.
 *                                    if provided, it will be used to determine if we need to
 *                                    dispatch action.
 *                                    if not provided, the default condition (pathname match)
 *                                    will be used.
 */

export function* refreshCurrentRoute({ targetLocation, action, condition = null, callbackSaga = null }) {
  const location = yield select(makeSelectLocation());

  function* meetCondition() {
    if (callbackSaga) {
      yield callbackSaga();
      return;
    }

    yield put(action);
  }

  if (condition === null) {
    if (location.pathname === targetLocation) {
      yield meetCondition();
    }
  } else if (typeof condition === 'function' && condition(location)) {
    yield meetCondition();
  }
}

export function* checkEmailSaga({ formName, successAction }) {
  const checkedEmail = yield select((state) => toJS(formValueSelector(formName)(state, 'email')));
  try {
    const getStr = stringify({
      filter: { 'profile_emails.email:ilike': checkedEmail },
      include: 'roles,company',
    });
    const peopleRequest = yield call(request, `${API_PEOPLE_BASE_URL}?${getStr}`);
    const { inclusions, items: [matchedPersonRef] } = extractData(peopleRequest);
    yield put(updateIncluded(inclusions));
    if (matchedPersonRef) {
      const matchedPerson = yield select(makeSelectObject(matchedPersonRef));
      if (matchedPerson.email.toLowerCase() !== checkedEmail.toLowerCase()) {
        yield put(showAlert('User matched by by non-primary email'));
      }
    }
    yield put(successAction(matchedPersonRef || null));
  } catch (err) {
    yield put(showAlert('Error Checking Email. Please contact the Dev team', COLOR.danger));
    logError(err);
    yield put(successAction(null));
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import { openConsentManager } from '@segment/consent-manager';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import MailTo from 'components/BasicElements/MailTo';
import { ButtonLinkSec } from 'components/Buttons/ButtonLinkSec';
import DocPager from 'components/DocPager/DocPager';
import LiVerticalDivider from 'components/SideBar/LiVerticalDivider';
import { TextMdNew } from 'components/Text/Body';
import { HeadingLarge, HeadingMedium } from 'components/Text/Headings';

import { openDocModal } from './DocsModal/actions';
import { DOC_TYPE } from './DocsModal/constants';
import GdprMain from './GdprMain';


const GdprFaq = (props) => (
  <GdprMain>
    <DocPager>
      <HeadingLarge><Link to="/gdpr">GDPR</Link> {'>'} FAQs</HeadingLarge>
      <TextMdNew>Frequently Asked Questions</TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>What information does The Vault collect about me?</HeadingMedium>
      <TextMdNew>
        All the information that we collected from you to onboard you to the Vault is visible in your personal profile.
        When you visit your user profile you can get an overview of the data, edit your profile, delete certain
        information or your complete profile. Deleting your profile is an irrevocable step and will permanently delete
        your personal data from our system.
      </TextMdNew>
      <TextMdNew>
        For information about our cookie policy, third party providers and alike please visit our{' '}
        <ButtonLinkSec inlineText invertUnderline onClick={() => props.openDocModal(DOC_TYPE.privacyPolicy)}>
          Privacy Policy
        </ButtonLinkSec>.
      </TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>What do you need my data for?</HeadingMedium>
      <TextMdNew>
        The data we collect in our sign up form is exclusively intended to create the best possible user experience for
        all Vault members. The Vault is engined to connect high-caliber founders with industry experts, investors, mentors
        and many more. Through the information you chose to provide in your profile you enable others to find you on the
        Vault (based on your alchemist role, areas of expertise, location, …). Which information you want to put on your
        profile is up to you. You may change or delete single items or your entire profile at any time.
      </TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>How can I delete my user profile?</HeadingMedium>
      <TextMdNew>
        To delete your user profile simply go to your profile and click on the “Delete profile” button in the top menu.
        When you complete the process all personal information will be permanently deleted from our system.
      </TextMdNew>
      <TextMdNew>Note that deleting your profile is an irrevocable step.</TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>How can I withdraw from my cookie policy consent?</HeadingMedium>
      <TextMdNew>
        To withdraw from your consent to our cookie policy please click
        {' '}<ButtonLinkSec inlineText invertUnderline onClick={openConsentManager}>here</ButtonLinkSec>.
      </TextMdNew>
      <TextMdNew>
        If you want to learn more about our cookie policy please click{' '}
        <ButtonLinkSec inlineText invertUnderline onClick={() => props.openDocModal(DOC_TYPE.privacyPolicy)}>
          here
        </ButtonLinkSec>.
      </TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>Some of the data in my profile is incorrect or not up to date. How can I fix this?</HeadingMedium>
      <TextMdNew>
        If you want to edit your user profile please go to profile and click on the “edit” button in the top menu.
      </TextMdNew>
      <LiVerticalDivider />
      <HeadingMedium>My concern is not addressed in these FAQs. How can I get in touch with you?</HeadingMedium>
      <TextMdNew>
        If you have additional GDPR related questions or requests, we are happy to assist you. Please email us at
        {' '}<MailTo to="support@alchemistaccelerator.com" /> and we will be in touch shortly to help with your request.
      </TextMdNew>
    </DocPager>
  </GdprMain>
);


GdprFaq.propTypes = { openDocModal: PropTypes.func.isRequired };

const mapDispatchToProps = { openDocModal };

export default connect(null, mapDispatchToProps)(GdprFaq);

import React from 'react';
import PropTypes from 'prop-types';


const OnePagerMain = (props) => (
  <div className={`dsa-one-pager ${props.className}`}>
    {props.children}
  </div>);


OnePagerMain.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

OnePagerMain.defaultProps = {
  className: '',
};

export default OnePagerMain;

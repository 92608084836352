import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import ListsWrapper from '../ListsWrapper';
import OwnLists from '../Sections/OwnLists';
import { loadOwnLists, clearMarkedOwnLists } from '../actions';
import { selectOwnLists } from '../selectors';
import { OWN_LISTS } from '../constants';

const ArchivedOwnLists = ({ onStart, clearMarkedOwn, lists }) => {
  useEffect(() => {
    // fetch lists when user reloads page
    if (!lists || (lists && lists.length === 0)) {
      onStart();
    }

    return () => {
      clearMarkedOwn();
    };
  }, []);

  return (
    <ListsWrapper listsType={OWN_LISTS} isArchive>
      <OwnLists isArchive />
    </ListsWrapper>
  );
};

ArchivedOwnLists.propTypes = {
  onStart: PropTypes.func,
  clearMarkedOwn: PropTypes.func,
  lists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  onStart: () => dispatch(loadOwnLists()),
  clearMarkedOwn: () => dispatch(clearMarkedOwnLists()),
});

const mapStateToProps = createStructuredSelector({
  lists: selectOwnLists,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedOwnLists);

/*
 * Profile
 */


import React from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { connect } from 'react-redux';

import { trackPlayedVideo as trackPlayedVideoAction } from 'containers/Analytics/actions';
import { detectBrowser, isModernIPad } from 'utils/browserDetection';
import { resRef } from 'utils/refs';

const requiresMuteForAutoplay = () => detectBrowser().os === 'iOS' || isModernIPad();

const extractResourceInfo = (res) => ({
  ...resRef(res),
  name: res.name || res.title,
  class: res.aclass?.().internalFormattedName,
});

const VimeoVideo = ({
  trackPlayedVideo, resource, videoUrl, className, autoplay = false, setError,
}) => {
  const playerWrapper = React.useRef(null);
  const player = React.useRef(null);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (playerWrapper.current && videoUrl) {
      const playAction = () => trackPlayedVideo(extractResourceInfo(resource));
      const options = { url: videoUrl, autoplay, muted: autoplay && requiresMuteForAutoplay() };
      if (!player.current) {
        player.current = {
          player: new Player(playerWrapper.current, options),
          loadedUrl: videoUrl,
        };
      } else if (player.current.loadedUrl !== videoUrl) {
        player.current.player.destroy();
        player.current = {
          player: new Player(playerWrapper.current, options),
          loadedUrl: videoUrl,
        };
      }

      player.current.player.ready().catch(() => {
        if (setError) {
          setError(true);
        }
      });
      player.current.player.on('play', playAction);
      return () => { player.current.player.off('play', playAction); };
    }
  }, [playerWrapper.current, videoUrl]);

  return <div className={className} ref={playerWrapper} />;
};

VimeoVideo.propTypes = {
  resource: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
  videoUrl: PropTypes.string,
  trackPlayedVideo: PropTypes.func,
  autoplay: PropTypes.bool,
  setError: PropTypes.func,
};

export default connect(null, { trackPlayedVideo: trackPlayedVideoAction })(VimeoVideo);


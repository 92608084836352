import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { updateIncludedFromRequest } from 'actions';
import { API_COMPANIES_BASE_URL, PERMISSION, RESOURCE_COMPANIES } from 'containers/App/constants';
import { makeSelectIsCheckingAuth, selectHasAccount, makeSelectUserIs, makeSelectAuthPrincipals }
  from 'containers/AuthProvider/selectors';
import { logError } from 'utils/log';
import { request } from 'utils/request';
import { refreshCurrentRoute, updateObjFromApi, waitSelect } from 'utils/sagas';
import { hasAccessOtherThan } from 'utils/fieldProcessors';
import { checkRouteMatch } from 'utils/general';

import { SET_SELECTED_COMPANY } from './constants';
import { makeSelectCompanyRef } from './selectors';
import { setCompanyTeamLoading, selectedCompanyNotFound, correctSelectedCompanyId } from './actions';

export function* getCompany() {
  const companyRef = yield select(makeSelectCompanyRef());
  const myRoles = yield select(makeSelectAuthPrincipals());
  const hasAccessOtherThanCI = hasAccessOtherThan(myRoles, PERMISSION.corporate);
  const iAmAdmin = yield select(makeSelectUserIs(PERMISSION.admin));

  let userHasAccount = yield select(selectHasAccount);
  if (!userHasAccount) {
    const isChecking = yield select(makeSelectIsCheckingAuth());
    if (isChecking) {
      userHasAccount = yield waitSelect(selectHasAccount, 10);
    }
  }
  const isPrivateId = companyRef.id?.match(/^\d{1,8}$/);
  // TODO: A company public slug can be anything, and this will cause issues with company names having just numbers
  const isPublicId = !isPrivateId;
  if (!(isPrivateId && userHasAccount) && !isPublicId) {
    yield put(selectedCompanyNotFound());
    return;
  }

  let include = ['aclass', 'acquired_by', 'current_members_rel.user', 'notablecustomers', 'tags', 'location', 'users_rel.user'];

  if (userHasAccount) {
    include = include.concat(['areas_of_service', 'cohorts.companies.tags', 'fund_prefer_geographies']);

    if (iAmAdmin) {
      include.push('aclass_demodays_rel.aclass', 'point_of_contact', 'aclasses_rel.aclass');
    }

    if (hasAccessOtherThanCI) {
      include = include.concat(['investment_rounds.investments.investor_profile', 'investment_rounds.investments.investing_company', 'erogated_investments.investor_profile', 'erogated_investments.investment_round.company.tags', 'erogated_investments.investment_round.company.investment_rounds', 'stages_of_interest']);
    }
  }

  const requestURL = `${API_COMPANIES_BASE_URL}/${companyRef.id}?include=${Array.from(new Set(include)).join()}`;

  try {
    // Call our request helper (see 'utils/request')
    const companyRequest = yield call(request, requestURL);
    yield put(updateIncludedFromRequest(companyRequest));
    if (isPublicId) {
      yield put(correctSelectedCompanyId(companyRequest.data.id));
    }
    yield loadCompanyMembers(isPublicId && companyRef.id);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield put(selectedCompanyNotFound());
    }
    logError(err);
    // yield put(contentLoadingError(err));
  }
}

function* loadCompanyMembers(publicId) {
  yield put(setCompanyTeamLoading(true));
  const companyRef = yield select(makeSelectCompanyRef());
  const isAdmin = yield select(makeSelectUserIs('admin'));

  yield updateObjFromApi(
    publicId ? { ...companyRef, id: publicId } : companyRef,
    isAdmin ? 'current_members_rel.user.account.profile,point_of_contact_account.profile' : 'current_members_rel.user'
  );
  yield put(setCompanyTeamLoading(false));
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  yield takeLatest(SET_SELECTED_COMPANY, getCompany);
  yield takeLatest(LOCATION_CHANGE, refreshCurrentRoute, { callbackSaga: getCompany, condition: (location) => checkRouteMatch(`/${RESOURCE_COMPANIES}/:companyId`, location) });
}

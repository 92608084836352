import { createSelector } from 'reselect';

import { makeSelectObjectArrayFromRefArray } from 'containers/App/selectors';
import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';
import { generateFiltersSelectors } from 'utils/filter/selectors';

import reducer from './reducer';

const localInitialState = reducer(undefined, {});

const selectPeopleListsPage = (state) => state ? state.get('peopleListsPage') || localInitialState : localInitialState;

/** Filter Selectors */
const { makeSelectFilter, makeSelectSearch } = generateFiltersSelectors(selectPeopleListsPage);

/** Content sections selectors */
const makeSelectOwnListsContent = () => makeSubSelector(selectPeopleListsPage, ['loadedOwnLists']);
const makeSelectSharedWithMeListsContent = () => makeSubSelector(selectPeopleListsPage, ['loadedSharedWithMeLists']);
const makeSelectPublicListsContent = () => makeSubSelector(selectPeopleListsPage, ['loadedPublicLists']);
const makeSelectEventListsContent = () => makeSubSelector(selectPeopleListsPage, ['loadedEventLists']);

/** Content sub-selectors generators */
const makeSelectorListsRefs = (makeSelectListsContent) => makeSubSelectorToJS(makeSelectListsContent(), ['loadedObjects']);
const makeSelectorTotalCount = (makeSelectListsContent) => makeSubSelector(makeSelectListsContent(), ['totalCount']);
const makeSelectorMarkedListsRefs = (makeSelectListsContent) => makeSubSelectorToJS(makeSelectListsContent(), ['markedObjects']);
const makeSelectorListsLoading = (makeSelectListsContent) => makeSubSelectorToJS(makeSelectListsContent(), ['loading']);
const makeSelectorListsHasMore = (makeSelectListsContent) => makeSubSelectorToJS(makeSelectListsContent(), ['hasMore']);
const makeSelectorListsLoadingNextPage = (makeSelectListsContent) => makeSubSelectorToJS(makeSelectListsContent(), ['loadingNextPage']);

/** Lists are loading selectors */
const selectOwnListsLoading = makeSelectorListsLoading(makeSelectOwnListsContent);
const selectSharedListsLoading = makeSelectorListsLoading(makeSelectSharedWithMeListsContent);
const selectEventListsLoading = makeSelectorListsLoading(makeSelectEventListsContent);

/** Lists has more selectors */
const selectOwnListsHasMore = makeSelectorListsHasMore(makeSelectOwnListsContent);
const selectSharedListsHasMore = makeSelectorListsHasMore(makeSelectSharedWithMeListsContent);
const selectEventListsHasMore = makeSelectorListsHasMore(makeSelectEventListsContent);

/** Lists are loading next page selectors */
const selectOwnListsLoadingNextPage = makeSelectorListsLoadingNextPage(makeSelectOwnListsContent);
const selectSharedListsLoadingNextPage = makeSelectorListsLoadingNextPage(makeSelectSharedWithMeListsContent);
const selectEventListsLoadingNextPage = makeSelectorListsLoadingNextPage(makeSelectEventListsContent);

/** Loaded Lists Refs selectors */
const selectOwnListsRefs = makeSelectorListsRefs(makeSelectOwnListsContent);
const selectSharedWithMeListsRelsRefs = makeSelectorListsRefs(makeSelectSharedWithMeListsContent);
const selectPublicListsRefs = makeSelectorListsRefs(makeSelectPublicListsContent);
const selectEventListsRefs = makeSelectorListsRefs(makeSelectEventListsContent);

/** Marked Lists Refs selectors */
const selectMarkedOwnListsRefs = makeSelectorMarkedListsRefs(makeSelectOwnListsContent);
const selectMarkedSharedWithMeListsRelsRefs = makeSelectorMarkedListsRefs(makeSelectSharedWithMeListsContent);
const selectMarkedPublicListsRefs = makeSelectorMarkedListsRefs(makeSelectPublicListsContent);
const selectMarkedEventListsRefs = makeSelectorMarkedListsRefs(makeSelectEventListsContent);

/** Total count selectors */
const selectPublicListsTotalCount = makeSelectorTotalCount(makeSelectPublicListsContent);
const selectOwnListsTotalCount = makeSelectorTotalCount(makeSelectOwnListsContent);
const selectSharedWithMeListsTotalCount = makeSelectorTotalCount(makeSelectSharedWithMeListsContent);
const selectEventListsTotalCount = makeSelectorTotalCount(makeSelectEventListsContent);

const selectAllListsTotalCount = createSelector(
  selectOwnListsTotalCount,
  selectPublicListsTotalCount,
  selectSharedWithMeListsTotalCount,
  selectEventListsTotalCount,
  (ownTotalCount, publicTotalCount, sharedWithMeTotalCount, eventListsCount) => ownTotalCount + publicTotalCount + sharedWithMeTotalCount + eventListsCount
);

const listFromRelWithCanEdit = (myPermission) => myPermission.user_list
  && { ...myPermission.user_list(), isSharedWithMe: true, myPermission };

/** Lists objects Selectors */
const selectOwnLists = makeSelectObjectArrayFromRefArray(selectOwnListsRefs);
const selectSharedWithMeListsRels = makeSelectObjectArrayFromRefArray(selectSharedWithMeListsRelsRefs);
const makeSelectSharedWithMeLists = () => createSelector(
  selectSharedWithMeListsRels,
  (sharedWithMeListsRels) => sharedWithMeListsRels && sharedWithMeListsRels.map(listFromRelWithCanEdit)
);
const selectPublicLists = makeSelectObjectArrayFromRefArray(selectPublicListsRefs);
const selectEventLists = makeSelectObjectArrayFromRefArray(selectEventListsRefs);

/** Search results count selectors */
const selectArchivedOwnListsTotalCount = createSelector(
  selectOwnLists,
  (ownLists) => ownLists?.filter((list) => list.archived)?.length || 0
);

const selectArchivedEventListsTotalCount = createSelector(
  selectEventLists,
  (eventLists) => eventLists?.filter((list) => list.archived)?.length || 0
);

const selectAllUnarchivedListsTotalCount = createSelector(
  selectOwnLists,
  selectSharedWithMeListsRels,
  selectPublicLists,
  selectEventLists,
  (ownLists, sharedWithMeLists, publicLists, eventLists) => [...ownLists, ...sharedWithMeLists, ...publicLists, ...eventLists]?.filter((list) => !list.archived)?.length || 0
);

/** Marked Lists objects Selectors */
const selectMarkedOwnLists = makeSelectObjectArrayFromRefArray(selectMarkedOwnListsRefs);
const selectMarkedSharedWithMeListsRels = makeSelectObjectArrayFromRefArray(selectMarkedSharedWithMeListsRelsRefs);
const selectMarkedSharedWithMeLists = createSelector(
  selectMarkedSharedWithMeListsRels,
  (markedSharedWithMeListsRels) => markedSharedWithMeListsRels.map(listFromRelWithCanEdit)
);
const selectMarkedPublicLists = makeSelectObjectArrayFromRefArray(selectMarkedPublicListsRefs);
const selectMarkedEventLists = makeSelectObjectArrayFromRefArray(selectMarkedEventListsRefs);

const selectMarkedMergeableLists = createSelector(
  selectMarkedOwnLists,
  selectMarkedSharedWithMeLists,
  selectMarkedPublicLists,
  selectMarkedEventLists,
  (markedOwnLists, markedSharedWithMeLists, markedPublicLists, markedEventLists) => [...markedOwnLists, ...markedSharedWithMeLists, ...markedPublicLists, ...markedEventLists]
);

/** Merge button logic selector */
const makeSelectMergeEnabled = () => createSelector(
  selectMarkedOwnListsRefs,
  selectMarkedSharedWithMeLists,
  selectMarkedPublicListsRefs,
  selectMarkedEventLists,
  (ownLists, sharedLists, publicLists, eventLists) => ownLists.length >= 1
    && ownLists.length + sharedLists.length + publicLists.length + eventLists.length > 1
    && ownLists.length + sharedLists.length + publicLists.length + eventLists.length < 4,
);

/** Merge button logic selector */
const makeSelectEventListMergeEnabled = () => createSelector(
  selectMarkedOwnListsRefs,
  selectMarkedSharedWithMeLists,
  selectMarkedPublicListsRefs,
  selectMarkedEventLists,
  (ownLists, sharedLists, publicLists, eventLists) => eventLists.length >= 1
    && ownLists.length + sharedLists.length + publicLists.length + eventLists.length > 1
    && ownLists.length + sharedLists.length + publicLists.length + eventLists.length < 4,
);

const selectIsSearchFocused = makeSubSelector(selectPeopleListsPage, ['filtersUi', 'isSearchFocused']);

// Modals selectors
const makeSelectCreateModalIsOpen = () => makeSubSelector(selectPeopleListsPage, ['modals', 'isCreateModalOpen', 'open']);
const makeSelectCreateModalIsEventList = () => makeSubSelector(selectPeopleListsPage, ['modals', 'isCreateModalOpen', 'isEventList']);
const makeSelectMergeModalIsOpen = () => makeSubSelector(selectPeopleListsPage, ['modals', 'isMergeModalOpen']);
const makeSelectMergeTargetRef = () => makeSubSelector(selectPeopleListsPage, ['modals', 'mergeTarget']);
const makeSelectMergeIsLoading = () => makeSubSelector(selectPeopleListsPage, ['modals', 'isMergeLoading']);

const makeSelectCopyIsLoading = () => makeSubSelector(selectPeopleListsPage, ['lists', 'isCopyLoading']);

/** Collapsible list container selector */
const makeSelectExpandedListContainer = () => makeSubSelector(selectPeopleListsPage, ['lists', 'expandedListsContainer']);

export {
  selectOwnLists,
  selectSharedWithMeListsRels,
  makeSelectSharedWithMeLists,
  selectPublicLists,
  selectEventLists,
  selectMarkedOwnLists,
  selectMarkedPublicLists,
  selectMarkedEventLists,
  selectMarkedSharedWithMeListsRels,
  selectMarkedSharedWithMeLists,
  selectMarkedMergeableLists,
  makeSelectMergeEnabled,
  makeSelectEventListMergeEnabled,
  makeSelectFilter,
  makeSelectSearch,
  selectAllListsTotalCount,
  selectIsSearchFocused,
  // makeSelectOwnListsOnlyActionsEnabled,
  makeSelectCreateModalIsOpen,
  makeSelectCreateModalIsEventList,
  makeSelectMergeModalIsOpen,
  makeSelectMergeTargetRef,
  makeSelectMergeIsLoading,
  makeSelectOwnListsContent,
  makeSelectSharedWithMeListsContent,
  makeSelectPublicListsContent,
  makeSelectEventListsContent,
  selectPeopleListsPage,
  makeSelectCopyIsLoading,
  makeSelectExpandedListContainer,
  selectArchivedOwnListsTotalCount,
  selectArchivedEventListsTotalCount,
  selectAllUnarchivedListsTotalCount,
  selectOwnListsLoading,
  selectOwnListsHasMore,
  selectOwnListsLoadingNextPage,
  selectSharedListsLoading,
  selectSharedListsHasMore,
  selectSharedListsLoadingNextPage,
  selectEventListsLoading,
  selectEventListsHasMore,
  selectEventListsLoadingNextPage,
};

import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import DsaModal from 'components/Modals/DsaModal';
import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import { showAlert } from 'containers/AlertBox/actions';
import { makeSelectAlchemistClasses } from 'containers/Admin/ClassesSection/selectors';
import FormBase, { withFormWrapper } from 'containers/Forms';
import { saveSettings } from 'containers/Forms/actions';
import { API_BASE, PERMISSION } from 'containers/App/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import Field from 'containers/Forms/Field';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { UrlField } from 'components/Form/Input/UrlInput';

import { toggleEditSidebarModal, loadSiteSettings } from './actions';
import { SIDEBAR_SETTINGS_GROUP } from './constants';
import reducer from './reducer';
import saga from './saga';
import { makeSelectSiteSettingsSidebarLinks, selectEditedSidebarItem, selectIsEditSidebarModalOpen } from './selectors';


class EditSidebarItemModal extends React.Component {
  componentDidMount() {
    if (this.props.isAdmin) {
      this.props.loadSchema(`sitesettings/${SIDEBAR_SETTINGS_GROUP}`);
    }
  }

  render() {
    const {
      schemas, initialValues, isModalOpen, toggleSidebarModal, editedSidebarItem,
    } = this.props;

    if (!schemas || !schemas[`sitesettings/${SIDEBAR_SETTINGS_GROUP}`]) return null;

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {isModalOpen &&
          <DsaModal isOpen={isModalOpen}>
            <ModalHeader className="text-capitalize" tag="h1">Edit {initialValues[`${editedSidebarItem}_title`]}</ModalHeader>
            <CloseButtonWrapper closeAction={() => toggleSidebarModal(false)} />
            <ModalBody className="pt-3">
              <FormBase
                formName={SIDEBAR_SETTINGS_GROUP}
                section={SIDEBAR_SETTINGS_GROUP}
                schema={schemas[`sitesettings/${SIDEBAR_SETTINGS_GROUP}`]}
                initialValuesOverride={initialValues}
                saveAction={saveSettings}
                successAction={[loadSiteSettings(), toggleEditSidebarModal(false), showAlert('Success! Your changes were saved.')]}
                apiEndpoint={`${API_BASE}/sitesetting/${SIDEBAR_SETTINGS_GROUP}`}
                extraValidation={(fields) => {
                  if (fields?.[`${editedSidebarItem}_title`]?.length > 11) {
                    return {
                      [`${editedSidebarItem}_title`]: {
                        _error: 'Title is too long. Maximum length is 11.',
                      },
                    };
                  }
                  return null;
                }}
              >
                <Field name={`${editedSidebarItem}_title`} colSize="col-100" />
                <Field component={UrlField} name={editedSidebarItem} colSize="col-100" />
              </FormBase>
            </ModalBody>
          </DsaModal>
        }
      </>
    );
  }
}

EditSidebarItemModal.propTypes = {
  loadSchema: PropTypes.func,
  schemas: PropTypes.object,
  initialValues: PropTypes.object,
  toggleSidebarModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  editedSidebarItem: PropTypes.string,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  alchemistClasses: makeSelectAlchemistClasses(),
  initialValues: makeSelectSiteSettingsSidebarLinks(),
  isModalOpen: selectIsEditSidebarModalOpen,
  editedSidebarItem: selectEditedSidebarItem,
  isAdmin: makeSelectUserIs(PERMISSION.admin),
});

const mapDispatchToProps = (dispatch) => ({
  toggleSidebarModal: (isModalOpen) => dispatch(toggleEditSidebarModal(isModalOpen)),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'editSidebarItemModal', reducer });
const withSaga = injectSaga({ key: 'editSidebarItemModal', saga });

export default compose(
  withConnect,
  withReducer,
  withSaga,
  withFormWrapper
)(EditSidebarItemModal);


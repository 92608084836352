/*
 * HomeReducer
 */
import { fromJS } from 'immutable';

import { SELECTED_COMPANY_NOT_FOUND, SET_SELECTED_COMPANY, SET_COMPANY_TEAM_LOADING, CORRECT_SELECTED_COMPANY_ID }
  from './constants';

const initialLoadedContentState = fromJS({ company: false, companyTeamIsLoading: true, notFound: false });

function companySinglePage(state = initialLoadedContentState, action) {
  switch (action.type) {
    case SET_COMPANY_TEAM_LOADING:
      return state.set('companyTeamIsLoading', action.isLoading);
    case SELECTED_COMPANY_NOT_FOUND:
      return state.set('notFound', true);
    case SET_SELECTED_COMPANY:
      return state.set('company', action.company).set('notFound', false);
    case CORRECT_SELECTED_COMPANY_ID:
      return state.update('company', (oldCompanyRef) => oldCompanyRef && { ...oldCompanyRef, id: action.companyId });
    default:
      return state;
  }
}

export default companySinglePage;

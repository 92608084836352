import cloneDeepWith from 'lodash/cloneDeepWith';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import { makeSelectUser } from 'containers/AuthProvider/selectors';

const sentryOmit = ['included', 'peoplePage.loadedContent.loadedObjects', 'profileimg', 'initial', 'registeredFields', 'fields'];
const breadCrumbsExclude = ['@@redux-form/REGISTER_FIELD', '@@redux-form/CHANGE', 'alchemist/Analytics/TRACK'];

const sentryOmitBy = (value) => {
  if (isObject(value) && !value.length) {
    return mapValues(omit(value, sentryOmit), (v) => cloneDeepWith(v, sentryOmitBy));
  }
  if (!value) {
    return undefined;
  }
  if (value.length) {
    return value.slice && value.slice(0, 50);
  }
  return undefined;
};

export const createOurSentryMiddleware = () => window.sentryDSN && createSentryMiddleware(Sentry, {
  getUserContext: makeSelectUser(),
  filterBreadcrumbActions: (action) => !breadCrumbsExclude.includes(action.type),
  stateTransformer: (state) => cloneDeepWith(state.toJS(), sentryOmitBy),
});

import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Generic/Tooltip';

class TooltipSimple extends React.Component {
  constructor(props) {
    super(props);
    this.outerRef = React.createRef();
    this.state = {
      isTooltipShown: !!this.props.tooltipText,
    };
  }

  render() {
    const {
      delay = 100, tooltipText, className, children, Component = 'div', placement = 'top', isBlocked,
      tooltipClassName = '',
    } = this.props;
    const { isTooltipShown } = this.state;

    return (
      <>
        <Component
          className={className}
          ref={this.outerRef}
          onClick={() => {
            // hide tooltip when DD Connect button is clicked too fast then reset
            if (tooltipText === 'Compose a connection request') {
              this.setState({
                isTooltipShown: false,
              });
              setTimeout(() => {
                this.setState({
                  isTooltipShown: !!this.props.tooltipText,
                });
              }, 1000);
            }
          }}
        >
          {children}
        </Component>
        {tooltipText && isTooltipShown && (
          <Tooltip
            className={`dsa-tooltip-menu-button ${tooltipClassName}`}
            placement={placement}
            targetRef={this.outerRef}
            delay={delay}
            isBlocked={isBlocked}
          >{tooltipText}
          </Tooltip>)}
      </>
    );
  }
}


TooltipSimple.propTypes = {
  delay: PropTypes.number,
  tooltipText: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.any,
  Component: PropTypes.any,
  isBlocked: PropTypes.bool,
  tooltipClassName: PropTypes.string,
};

export default TooltipSimple;

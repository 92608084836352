/**
 *
 * GdprCancelPage
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';

import MailTo from 'components/BasicElements/MailTo';
import OnePager from 'components/OnePager/OnePager';
import { Text } from 'components/Text/Body';

import { selectLoadingCancellation, selectRequestFailed } from './selectors';
import { sendCancellation } from './actions';
import { TEXTS } from './constants';

class CancelPage extends Component {
  propTypes = {
    match: PropTypes.object,
    loading: PropTypes.bool,
    requestFailed: PropTypes.bool,
    sendCancellation: PropTypes.func,
  };

  componentDidMount() {
    if (!this.props.loading) {
      this.props.sendCancellation(this.props.match.params.code);
    }
  }

  render() {
    const { requestFailed, match: { params: { action } } } = this.props;
    const actionTexts = TEXTS[action];
    const helmet = (
      <Helmet>
        <title>Cancel Gdpr Request</title>
        <meta name="description" content="Gdpr Request cancellation page" />
      </Helmet>);

    if (requestFailed) {
      return (
        <OnePager title="Sorry!">
          {helmet}
          <Text>You can no longer cancel this action.</Text>
          <Text>
            Please reach out to Alchemist team via Intercom bubble in the bottom right corner or contact support{' '}
            at <MailTo to="support@alchemistaccelerator.com" />.
          </Text>
        </OnePager>);
    }

    return (
      <OnePager title="We have received your request.">
        {helmet}
        <Text>Your profile will not be {actionTexts && actionTexts.participle}.</Text>
      </OnePager>);
  }
}

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingCancellation,
  requestFailed: selectRequestFailed,
});
const mapDispatchToProps = { sendCancellation };

export default connect(mapStateToProps, mapDispatchToProps)(CancelPage);

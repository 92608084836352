import { CLASS_TYPE } from 'containers/App/constants';
import { LOAD_CONTENT_SUCCESS, MARK_ALL, SET_SELECTED_OBJECT, BROWSE_SELECTED_OBJECT } from 'utils/filter/constants';
import { formatStr } from 'utils/general';

export const COMPANIES_PAGE_IDENTIFIER = 'Companies';
export const ALCH_CLASS_TYPE_ARRAY = `['${CLASS_TYPE.alchemist}']`;
export const ALCHX_CLASS_TYPE_ARRAY = `['${CLASS_TYPE.alchemistx}']`;

export const COMPANIES_LOAD_CONTENT_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, COMPANIES_PAGE_IDENTIFIER);

export const COMPANIES_MARK_ALL = formatStr(MARK_ALL, COMPANIES_PAGE_IDENTIFIER);
export const SET_SELECTED_COMPANY = formatStr(SET_SELECTED_OBJECT, COMPANIES_PAGE_IDENTIFIER);
export const COMPANIES_BROWSE_SELECTED_OBJECT = formatStr(BROWSE_SELECTED_OBJECT, COMPANIES_PAGE_IDENTIFIER);

export const DOWNLOAD_CSV = 'alchemist/Companies/DOWNLOAD_CSV';
export const SELECT_COMPANY_LIST = 'alchemist/Companies/SELECT_COMPANY_LIST';
export const RETURN_TO_COMPANY_LIST = 'alchemist/Companies/RETURN_TO_COMPANY_LIST';
export const OPEN_MOBILE_FILTERS_MODAL = 'alchemist/Companies/OPEN_MOBILE_FILTERS_MODAL';
export const CLOSE_MOBILE_FILTERS_MODAL = 'alchemist/Companies/CLOSE_MOBILE_FILTERS_MODAL';

export const SET_SELECT_ALL_LOADING = 'alchemist/Companies/SET_SELECT_ALL_LOADING';
export const SET_SELECT_ALL_LOADED = 'alchemist/Companies/SET_SELECT_ALL_LOADED';

export const SET_CSV_LOADED = 'alchemist/Companies/SET_CSV_LOADED';

export const TOGGLE_COMPANIES_FILTERS = 'alchemist/Companies/TOGGLE_COMPANIES_FILTERS';

import { LIST_CARD_MARGIN_PX, LIST_CARD_WIDTH_PX } from './constants';

export function getListsPerRowNumber() {
  const listsSection = document.querySelector('.dsa-people-lists');
  const computedStyle = listsSection ? getComputedStyle(listsSection) : null;
  const sectionWidth = (listsSection?.clientWidth || 0) - (parseFloat(computedStyle?.paddingLeft));
  const singleListWidth = LIST_CARD_WIDTH_PX + LIST_CARD_MARGIN_PX;

  return Math.floor(sectionWidth / singleListWidth) || 1;
}

import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

const BasicListContent = ({ children, ParentElement = Row }) => (
  <ParentElement className="dsa-list__content">
    {children}
  </ParentElement>
);

BasicListContent.propTypes = {
  children: PropTypes.any,
  ParentElement: PropTypes.any,
};

export default BasicListContent;


import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import TooltipSimple from 'components/Tooltips/TooltipSimple';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';

import ResultsCount from './ResultsCount';
import ResetButton from './ResetButton';
import { ConsumeSearchContextHOC } from './SearchContext';


const SearchInput = ({
  className = '', isSearchFocused, inputRef, onSearch, hideResultsForNull, totalCount, setIsSearchFocused,
  placeholder, isDesktop, isMobileTablet, isMobile, hideCount = false, isLoading, search, sm, isPeopleSearchInput,
  withTooltip = false, hideResetButton, hideSearchIcon, showDropdownArrow, icon,
}) => {
  let timeOut = null;
  const InputWrapper = withTooltip ? TooltipSimple : React.Fragment;
  const tooltipProps = withTooltip ? {
    tooltipText: placeholder,
    placement: 'bottom',
    className: 'dsa-search-tooltip__container',
  } : {};

  return (
    <div className={classNames('dsa-search__input', className, { 'is-focused': (isSearchFocused || search) && !sm })}>
      {!hideSearchIcon && <FontAwesomeIcon icon={icon || faSearch} />}
      <InputWrapper {...tooltipProps}>
        <input
          ref={inputRef}
          name="search"
          placeholder={isPeopleSearchInput && isMobile ? 'Search' : placeholder}
          onChange={onSearch}
          value={search}
          onFocus={() => { clearTimeout(timeOut); if (setIsSearchFocused) setIsSearchFocused(true); }}
          onBlur={() => { timeOut = setTimeout(() => setIsSearchFocused && setIsSearchFocused(false), 10); }}
          className={classNames('dsa-search_input', { 'pl-5': hideSearchIcon })}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
        />
      </InputWrapper>
      {showDropdownArrow && <FontAwesomeIcon icon={isSearchFocused ? faAngleUp : faAngleDown} />}
      {isDesktop && (!hideResultsForNull || search) && !sm &&
        <ResultsCount isLoading={isLoading} totalCount={totalCount} hideCount={hideCount} value={search?.length > 0} />}
      {isMobileTablet && search && !hideResetButton && <ResetButton onClick={() => onSearch('')} />}
    </div>);
};

SearchInput.propTypes = {
  onSearch: PropTypes.func,
  setIsSearchFocused: PropTypes.func,
  isSearchFocused: PropTypes.bool,
  sm: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobileTablet: PropTypes.bool,
  hideCount: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideResultsForNull: PropTypes.bool,
  search: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  totalCount: PropTypes.number,
  inputRef: PropTypes.object,
  isPeopleSearchInput: PropTypes.bool,
  isMobile: PropTypes.bool,
  withTooltip: PropTypes.bool,
  hideResetButton: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
  showDropdownArrow: PropTypes.bool,
  icon: PropTypes.any,
};

export default ConsumeSearchContextHOC(ResponsiveHoC(SearchInput));

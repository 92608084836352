import { makeSelectObjectArrayFromRefArray, makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { makeSubSelector, makeSubSelectorToJS } from 'utils/selectors';

const selectIfsRegistrationDomain = (state) => state.get('ifsRegistration');

const selectIfsInvitationRef = makeSubSelectorToJS(selectIfsRegistrationDomain, ['invitationRef']);
const selectIfsInvitation = makeSelectObjectFromRefSelector(selectIfsInvitationRef);
const selectIsConfirmModalOpen = makeSubSelectorToJS(selectIfsRegistrationDomain, ['isConfirmModalOpen']);
const selectMarkedCompaniesRef = makeSubSelectorToJS(selectIfsRegistrationDomain, ['markedCompanies']);
const makeSelectMarkedCompanies = () => makeSelectObjectArrayFromRefArray(selectMarkedCompaniesRef);
const selectRegCompanyRefs = makeSubSelector(selectIfsRegistrationDomain, ['registrantCompanies']);
const makeSelectRegistrantCompanies = () => makeSelectObjectArrayFromRefArray(selectRegCompanyRefs);

const selectIsTimezoneShownOnMobile = makeSubSelector(selectIfsRegistrationDomain, ['isTimezoneListShownOnMobile']);
const selectTimezone = makeSubSelector(selectIfsRegistrationDomain, ['timezone']);

export { selectIfsInvitation, selectIsConfirmModalOpen, selectMarkedCompaniesRef, makeSelectMarkedCompanies, makeSelectRegistrantCompanies, selectTimezone, selectIsTimezoneShownOnMobile };

import React from 'react';
import PropTypes from 'prop-types';

const OnePagerSection = (props) => (
  <div className={`dsa-one-pager__section ${props.className}`}>
    {props.children}
  </div>
);


OnePagerSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

OnePagerSection.defaultProps = {
  className: '',
};

export default OnePagerSection;

import React from 'react';
import { Helmet } from 'react-helmet';
import { parse, stringify } from 'qs';
import jwtDecode from 'jwt-decode';


import MainContent from 'components/MainContent';
import { updateLocation } from 'utils/general';
import { getSsoTokenFromCookie } from 'utils/auth';

class LmsEmbedded extends React.Component {
  constructor(props) {
    super(props);
    const startingQParams = parse(window.location.search, { ignoreQueryPrefix: true });
    this.lmsPath = startingQParams.lmsPath || '/student-home';

    const jwtLMS = getSsoTokenFromCookie();
    // if the token is not available (ie someone visits /lms manually) just show the homepage of lms
    const lmsDomain = jwtLMS ? jwtDecode(jwtLMS).lms_domain : 'https://lms.alchemistaccelerator.com';
    // &jwt=${jwtLMS} is only required for interoperability between vault staging envs and lms dev env
    // in production the cookie would be shared between the 2 domains automatically
    this.embedUrl = `${lmsDomain}/?rest_route=/simple-jwt-login/v1/autologin&jwt=${jwtLMS}&redirectUrl=`;

    this.receiveMessage = (event) => {
      if (event.origin !== lmsDomain) {
        return;
      }
      let lmsPath;
      try {
        lmsPath = JSON.parse(event.data).path;
      } catch (e) {
        return;
      }
      if (lmsPath !== this.lmsPath) {
        updateLocation(`${window.location.pathname}?${stringify({ ...startingQParams, lmsPath })}`);
      }
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage, false);
  }

  render() {
    return (
      <MainContent noSidebar>
        <Helmet>
          <title>The Vault</title>
          <meta
            name="description"
            content="The AlchemistX Accelerator LMS"
          />
        </Helmet>
        <iframe className="flex-fill" title="LMS" src={`${this.embedUrl}${this.lmsPath}`} />
      </MainContent>
    );
  }
}

LmsEmbedded.propTypes = {};


export default LmsEmbedded;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * HoC to handle active meta on reduxFormInputs used out of ReduxForm
 * @param WrappedInput
 */
export const SimplifyReduxFormInput = (WrappedInput) =>
  class extends React.Component {
    static propTypes = {
      onChange: PropTypes.func,
      directOnChange: PropTypes.func,
      value: PropTypes.any,
      name: PropTypes.string,
      error: PropTypes.string,
    };

    constructor(props) {
      super(props);
      this.state = { active: false };
      this.setActive = this.setActive.bind(this);
    }

    setActive(active) {
      this.setState(({ touched }) => ({ active, touched: touched || active }));
    }

    render() {
      const {
        onChange: onChangeProp, directOnChange, value, name, error, ...props
      } = this.props;
      const { touched, active } = this.state;
      const onFocus = () => this.setActive(true);
      const onBlur = () => this.setActive(false);
      const onChange = directOnChange || ((e) => onChangeProp(e?.target ? e.target.value : e));

      return (
        <WrappedInput
          input={{
            value, name, onFocus, onBlur, onChange,
          }}
          meta={{ active, error, touched }}
          {...props}
        />);
    }
  };

export default SimplifyReduxFormInput;

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { updateIncluded } from 'actions';
import { displayAlert } from 'containers/AlertBox/actions';
import { COLOR } from 'containers/AlertBox/constants';
import { API_IFS_INVITATIONS_BASE_URL, RESOURCE_IFS_INVITATIONS } from 'containers/App/constants';
import { extractData } from 'utils/jsonApiExtract';
import { patch } from 'utils/request';
import { logError } from 'utils/log';
import { preprocessError } from 'utils/Forms/sagas';

import { makeSelectObject } from '../../App/selectors';
import { SAVE_CANCELATION, SAVE_VERIFY } from './constants';
import { setInvitation } from './actions';


function* updateVerifyAttendance({
  invitationId, invitationCode, status,
}) {
  const inputData = { data: { type: RESOURCE_IFS_INVITATIONS, id: invitationId, attributes: { verify_attendance: status } } };
  const uri = `${API_IFS_INVITATIONS_BASE_URL}/${invitationCode}?include=user,participant_set.ifs`;
  try {
    const invVerifyResponse = yield call(patch, uri, inputData);

    const { inclusions, items: [invitationRef] } = extractData(invVerifyResponse);

    yield put(updateIncluded(inclusions));
    const invitation = yield select(makeSelectObject(invitationRef));
    yield put(setInvitation(invitationRef, status, invitation.messageType));
  } catch (e) {
    if (e.response && e.response.status === 400) {
      logError(e);
      const { errorJson } = yield preprocessError(e);
      if (errorJson?.errors?.[0]?.detail) {
        yield put(displayAlert(errorJson.errors[0].detail, COLOR.warning));
      }
    } else {
      yield put(displayAlert(`Something went wrong when ${status ? 'accepting' : 'declining'} the connection`, COLOR.danger));
      throw e;
    }
  }
}

function* updateCancelAttendance({
  invitationCode, session,
}) {
  const uri = `${API_IFS_INVITATIONS_BASE_URL}/${invitationCode}/cancel/${session}?include=user,participant_set.ifs`;
  try {
    const invVerifyResponse = yield call(patch, uri);

    const { inclusions, items: [invitationRef] } = extractData(invVerifyResponse);

    yield put(updateIncluded(inclusions));
    yield put(setInvitation(invitationRef));
  } catch (e) {
    if (e.response && e.response.status === 400) {
      logError(e);
      const { errorJson } = yield preprocessError(e);
      if (errorJson?.errors?.[0]?.detail) {
        yield put(displayAlert(errorJson.errors[0].detail, COLOR.warning));
      }
    } else {
      yield put(displayAlert('Something went wrong when canceling your attendance', COLOR.danger));
      throw e;
    }
  }
}

export default function* interestSaga() {
  // Message Sagas
  yield takeLatest(SAVE_VERIFY, updateVerifyAttendance);
  yield takeLatest(SAVE_CANCELATION, updateCancelAttendance);
}

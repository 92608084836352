/*
 *
 * AlertBox actions
 *
 */

import { CLOSE_ALERT, COLOR, SHOW_ALERT, DISPLAY_ALERT } from './constants';

const closeAlert = () => ({ type: CLOSE_ALERT });
const showAlert = (message, color = COLOR.success) => ({ type: SHOW_ALERT, message, color });
const displayAlert = (message, color = COLOR.success, className, isOnClickHandlerNeeded) => ({ type: DISPLAY_ALERT, message, color, className, isOnClickHandlerNeeded });

export { closeAlert, showAlert, displayAlert };

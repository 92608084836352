import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

import { AAHighlight } from 'components/Search/AAHighlight';
import { PERMISSION, RESOURCE_PEOPLE } from 'containers/App/constants';
import { makeSelectHighestUserAccess, selectHasAccount, makeSelectAuthPrincipals } from 'containers/AuthProvider/selectors';
import { refUrl } from 'utils/refs';


const LinkToSinglePage = ({
  obj, target = '_self', userHasAccount, noColor, noLink, noLinkClassName, userHighestAccess, linkClassName = '',
}) => {
  if (!obj) { return ''; }
  const isPerson = obj.type === RESOURCE_PEOPLE;
  const isExtLink = (isPerson && userHighestAccess === PERMISSION.demoday) || !userHasAccount;
  const LinkComponent = isExtLink ? 'a' : Link;
  const to = isExtLink ? obj.linkedin_profile : refUrl(obj);

  return !to || noLink
    ? <AAHighlight noLinkClassName={noLinkClassName}>{obj.name}</AAHighlight>
    : (
      <LinkComponent
        className={classNames(`dsa-link ${linkClassName}`, { '_no-color_': noColor })}
        target={isExtLink ? '_blank' : target}
        to={!isExtLink ? to : null}
        href={isExtLink ? to : null}
      >
        <AAHighlight>{obj.name}</AAHighlight>
      </LinkComponent>);
};

LinkToSinglePage.propTypes = {
  obj: PropTypes.object,
  target: PropTypes.string,
  userHasAccount: PropTypes.bool,
  noColor: PropTypes.bool,
  noLink: PropTypes.bool,
  noLinkClassName: PropTypes.string,
  userHighestAccess: PropTypes.string,
  linkClassName: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  userHasAccount: selectHasAccount,
  myRoles: makeSelectAuthPrincipals(),
  userHighestAccess: makeSelectHighestUserAccess(),
});

export default connect(mapStateToProps)(LinkToSinglePage);

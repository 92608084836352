import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import omit from 'lodash/omit';

import TooltipPrim from 'components/Tooltips/TooltipPrim';
import { Text } from 'components/Text/Body';


const RadioLabel = ({
  className, isChecked, children, label, caption, disabled, isLabelFirst, showCheck, isAdminField, stopPropagationOnClick, ...props
}) => {
  const InnerTop = caption ? InnerTopDiv : React.Fragment;
  const fieldLabel = typeof label === 'function' ? label() : label;
  return (
    <Label
      className={classNames(className, { 'is-checked': isChecked, '_with-caption_': !!caption, disabled })}
      onClick={(e) => {
        if (stopPropagationOnClick) {
          e.stopPropagation();
        }
      }}
    >
      <InnerTop>
        <input
          type="checkbox"
          className="d-none"
          checked={isChecked}
          disabled={disabled}
          {...omit(props, ['isCheckboxBtnLike'])}
        />
        {isLabelFirst && <TooltipPrim className={classNames('dsa-checkbox__sec-label me-5', { 'dsa-admin-txt': isAdminField })}>{fieldLabel}</TooltipPrim>}
        {!props.isCheckboxBtnLike && (
          isChecked
            ? <div className={classNames('dsa-radio-btn__border _checked_', { '_check-icon_': showCheck })}><FontAwesomeIcon icon={showCheck ? faCircleCheck : faCircleSolid} /></div>
            : <div className="dsa-radio-btn__border"><FontAwesomeIcon icon={faCircle} /></div>
        )}
        {!isLabelFirst && <TooltipPrim className={classNames('dsa-checkbox__sec-label', { 'ms-5': !props.isCheckboxBtnLike })}>{fieldLabel}</TooltipPrim>}
      </InnerTop>
      {caption &&
        <div className="dsa-checkbox__inner-bottom">
          <Text><i>{caption}</i></Text>
        </div>}
    </Label>
  );
};

RadioLabel.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isChecked: PropTypes.bool,
  spread: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isCheckboxBtnLike: PropTypes.bool,
  isLabelFirst: PropTypes.bool,
  showCheck: PropTypes.bool,
  isAdminField: PropTypes.bool,
  stopPropagationOnClick: PropTypes.bool,
};

RadioLabel.defaultProps = {
  stopPropagationOnClick: true,
};

export default RadioLabel;

const InnerTopDiv = ({ children }) => <div className="dsa-checkbox__inner-top">{children}</div>;
InnerTopDiv.propTypes = { children: PropTypes.any };

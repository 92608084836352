import React from 'react';
import PropTypes from 'prop-types';

import TooltipPrim from 'components/Tooltips/TooltipPrim';

import ListColBase from './ListColBase';

const ListCol = ({
  children, highlightText, ...props
}) => (
  <ListColBase {...props}>
    {!props.hideCol && <TooltipPrim highlightText={highlightText} tooltipClassName="list-col">{children}</TooltipPrim>}
  </ListColBase>);

ListCol.propTypes = {
  children: PropTypes.any,
  multiLine: PropTypes.bool,
  hideCol: PropTypes.bool,
  textCenter: PropTypes.bool,
  width: PropTypes.string,
  highlightText: PropTypes.string,
};

export default ListCol;

import { fromJS } from 'immutable';

export function preprocessAppliedFiltersForPeople(appliedFilters) {
  let newFilters = fromJS({});

  if (appliedFilters.get('search')) {
    newFilters = newFilters.set(
      'nicename,lastname,title,bragline,company.name,tags.text,areas_of_interest.text,investmentcriteria:search',
      appliedFilters.get('search').replace(/%/, '\\%')
    );
  }

  return newFilters;
}

export function preprocessAppliedFiltersForCompanies(appliedFilters) {
  let newFilters = fromJS({});

  if (appliedFilters.get('search')) {
    newFilters = newFilters.set('name:search', appliedFilters.get('search').replace(/%/, '\\%'));
  }

  return newFilters;
}

/*
 *
 * GdprFooter reducer
 *
 */

import { fromJS } from 'immutable';

import { OPEN_DOC_MODAL } from './constants';

const initialState = fromJS({});

function gdprFooterReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DOC_MODAL:
      return state.set('docType', action.docType);
    default:
      return state;
  }
}

export default gdprFooterReducer;
